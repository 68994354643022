import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Redirect } from 'react-router-dom';
import { getTokenInfo } from '../../../UseToken';

import { object_type, api_crud_base, api_list_route, api_crud_route, data_interface, endpoints } from './Config';

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import { confirmAlert } from 'react-confirm-alert';

import {

	reqGET,
	reqPOST,
	reqDEL,
	reqPUT

} from '../../../Utils';

//import { useHistory, Redirect } from "react-router-dom";
//import TextInput from 'react-autocomplete-input';


export default function Regist(props) {

	const [, set_dados] = useState(null);
	const dados = useRef(null);

	const [isloading, setLoading] = useState(true);
	const [isRedirect, setRedirect] = useState(false);

	const [show_vat_form, set_show_vat_form] = useState('');
	const [show_delete_button, set_show_delete_button] = useState('d-none');
	const [show_district_city_village, set_show_district_city_village] = useState('');


	//Temporário
	const [avaliable_credits, set_avaliable_credits] = useState([])

	const [countries, set_countries] = useState([])
	const [districts, set_districts] = useState([])
	const [cities, set_cities] = useState([])
	const [villages, set_villages] = useState([])

	const [is_edit, set_is_edit] = useState(false)


	const custom_select_styles = {
		container: base => ({
			...base,
			width: '100%',
		}),
		control: base => ({
			...base,
			borderRadius: "10px",
			padding: "2px 10px",
			fontSize: '14px'
		}),
	}

	const fetch_id_info = async (id_serch) => {
		
		return await reqGET(`${api_crud_route}/${getTokenInfo().companycode}/${id_serch}`)
			.then(res => {
				set_dados(dados.current = res.data[0]);
			})
			.catch(error => error/*TODO: GO TO FAIL FETCH*/)
	}

	// PARA INSTANCIAR O COMPONENTE COM O ID 

	const clean_data = () => {
		// DEEP COPY OF data_interface 
		set_dados(dados.current = JSON.parse(JSON.stringify(data_interface)))
		set_dados(dados.current.info.companycode = getTokenInfo().companycode)
		//console.log(dados.current)
	}

	useEffect(() => {
		clean_data();

		try {
			let test = props.location.state.operation;

			if (test === 'edit') {
				set_is_edit(true)
				//if (props.location.state !== undefined) {
				//console.log('->>>>>>>>>>>>>>>>:' + props.location.state.operation)
				//if (props.location.state.operation === 'edit') {
				//console.log('AQUI DENTRO DO STATE')

				
				fetch_id_info(props.location.state.item['id']).then(() => {
					setLoading(false);

					setRedirect(false);
				})
			}
		} catch {
			clean_data()

			setLoading(false);

			setRedirect(false);
			return

		}
		// BACK
		//props.history.push(endpoints.list)
		return () => {
			clean_data()
		}

	}, [props])


	const handleSubmit = async e => {
		e.preventDefault();

		if (is_edit === true) {
			//alert('Atualizar PUT')

			confirmAlert({
				title: 'Edição',
				message: 'Tem a certeza que pretende continuar?',
				buttons: [
					{
						label: 'Sim',
						onClick: () => {

							reqPUT(api_crud_route, dados.current)
								.then(() => { props.history.push(endpoints.list) })
								.catch(() => alert('Problema ao atualizar Registo!'))
						}
					},
					{
						label: 'Não',
						onClick: () => { return }
					}
				]
			})
		} else {
			confirmAlert({
				title: 'Novo Registo',
				message: 'Tem a certeza que pretende continuar?',
				buttons: [
					{
						label: 'Sim',
						onClick: () => {

							reqPOST(api_crud_route, dados.current)
								.then(() => { props.history.push(endpoints.list) })
								.catch(() => alert('Problema ao atualizar Registo!'))
						}
					},
					{
						label: 'Não',
						onClick: () => { return }
					}
				]
			})
		}
	}

	function apagar() {


		confirmAlert({
			title: 'Eliminar Pergunta',
			message: 'Pretende mesmo eliminar este registo ?',
			buttons: [
				{
					label: 'Sim',
					onClick: () => {

						reqDEL(api_crud_route + '/', { id: dados.current.id })
							.then(res => {
								/* alert(res.status === 200 ?
									'Registo Apagado com Sucesso' : 'Problema ao Apagar Registo'); 
								*/
								props.history.push(endpoints.list)
							})
							.catch(error => alert(error))

					}
				},
				{
					label: 'Não',
					onClick: () => { return }
				}
			]
		});
	};

	const handle_change_content = (e) => {
		const { name, value } = e.target;

		set_dados(dados.current.content[name] = value)
		if(name === 'question') {
			set_dados(dados.current.info['name'] = value)
		}

		// console.log('Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«')
		// console.log('Dados Current:' + JSON.stringify(dados))
	}

	return isloading ?
		<main>
			<div className={`dash-cards-2 header`}>
				<h2 className="dash-title">LOADING</h2>
				<p>LOADING</p>
				<div className="overlay">
					<div className="overlay__inner">
						<div className="overlay__content"><span className="spinner"></span></div>
					</div>
				</div>
			</div>
		</main>
		:
		isRedirect
			?
			<Redirect to={endpoints.list} replace />
			:
			<main>
				<div className={`dash-cards-2 header`}>
					<h2 className="dash-title">Suporte</h2>
					<p>Home / Suporte / Inserir Pergunta</p>
				</div>

				<div className={`dash-cards-2 body`}>
					<div className="card">
						<div className="card-body">
							<form id="form" onSubmit={handleSubmit}>
								<input type="hidden" name="id" value={dados.current.id} />

								<h2 className="dash-title">Suporte</h2>
								<div className="form-group">
									<span className="flex-1">
										<label>Pergunta</label>
										<input type="text" name="question" value={dados.current.content.question} className="input-form" placeholder="Introduza a pergunta" onChange={handle_change_content} required />
									</span>
								</div>
								<div className="form-group">
									<span className="flex-1">
										<label>Resposta</label>
										<textarea type="text" name="answer" value={dados.current.content.answer} className="input-form" placeholder="Introduza a resposta" onChange={handle_change_content} />
									</span>
								</div>

							</form>
						</div>
					</div>
				</div>

				<div className={'dash-cards-2 footer'}>
					<nav className="navbar bottom-navbar navbar-light">
						<div className="navbar-footer">
							<ul className="navbar-nav float-left"></ul>
							<ul className="navbar-nav float-right">
								<li className="nav-item">
									<Link className="nav-link btn-cancel" to={{ pathname: endpoints.list }} replace>
										<img alt='' src={'./img/cancelar.svg'} />
										<span>Cancelar</span>
									</Link>
								</li>

								<li className={"nav-item " + show_delete_button}>
									<button onClick={apagar} className="nav-link btn-delete">
										<img alt='' src={'./img/eliminar_b.svg'} />
										<span>Eliminar</span>
									</button>
								</li>
								<li className="nav-item">
									<button type="submit" form="form" className="nav-link">
										<img alt='' src={'./img/save.svg'} />
										<span>Salvar</span>
									</button>
								</li>
							</ul>
						</div>
					</nav>
				</div>
			</main >
}
