import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Redirect } from 'react-router-dom';
//import Hosts from '../../../Hosts';

import { confirmAlert } from 'react-confirm-alert';


import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'


import { api_crud_base, api_crud_route, data_interface, endpoints, object_type } from './Config';

import {

	reqGET,
	reqPOST,
	reqDEL,
	reqPUT,

	obter_cidades_por_distrito,
	obter_freguesias_por_cidade,
	obter_paises_em_pt,
	obter_distrito_por_cidade,
	obter_distritos_pt,

	get_vat_info,

	pattern_vat,
	pattern_email, passwordHash,

} from '../../../Utils';

import { getTokenInfo } from './../../../UseToken';

export default function Regist(props) {




	//confirmAlert(options);

	const [, set_dados] = useState(null);
	const dados = useRef(null);

	let newDate = new Date();
	let today = newDate.getFullYear() + '-' + (newDate.getMonth() + 1).toString().padStart(2, "0") + '-' + newDate.getDate().toString().padStart(2, "0");

	const [isloading, setLoading] = useState(true);
	const [isRedirect, setRedirect] = useState(false);
	const [refresh, setRefresh] = useState(0);

	const [show_vat_form, set_show_vat_form] = useState('');
	const [show_delete_button, set_show_delete_button] = useState('d-none');
	const [show_district_city_village, set_show_district_city_village] = useState('');
	const [save_label, set_save_label] = useState('Salvar');


	const [disabled, set_disabled] = useState(false)
	const [, set_is_input_disabled] = useState(true);

	//Temporário
	const [hidePessoal, set_hide_pessoal] = useState('')
	const [hideEmpresarial, set_hide_empresarial] = useState('')

	const client_types = [
		{ value: "Pessoal", label: "Pessoal" },
		{ value: "Empresarial", label: "Empresarial" }
	];

	const civil_states = [
		{ value: "Solteiro", label: "Solteiro" },
		{ value: "Casado", label: "Casado" },
		{ value: "Divorciado", label: "Divorciado" },
		{ value: "Viúvo", label: "Viúvo" }
	];
	const identification_types = [
		{ value: "Bilhete de Identidade", label: "Bilhete de Identidade" },
		{ value: "Cartão de Cidadão", label: "Cartão de Cidadão" }
	];
	const employment_situations = [
		{ value: "Desempregado", label: "Desempregado" },
		{ value: "Empregado por conta de outrem", label: "Empregado por conta de outrem" },
		{ value: "Empregado por conta própria", label: "Empregado por conta própria" }
	];

	const [countries, set_countries] = useState([])
	const [districts, set_districts] = useState([])
	const [cities, set_cities] = useState([])
	const [villages, set_villages] = useState([])

	const [is_edit, set_is_edit] = useState(false)


	const custom_select_styles = {
		container: base => ({
			...base,
			width: '100%',
		}),
		control: base => ({
			...base,
			borderRadius: '10px',

			padding: '2px 10px',
			fontSize: '14px'
		})
	}





	const change_country = e => {

		set_cities([])
		set_villages([])

		set_dados(dados.current.addresses[0].city = '');
		set_dados(dados.current.addresses[0].village = '');
		set_dados(dados.current.addresses[0].region = '');

		if (e.value === "Portugal") {

			set_show_district_city_village('')

			if (districts.length === 0) {

				obter_distritos_pt(set_districts)
			}
		}
		else {
			set_show_district_city_village('d-none')
		}

		set_dados(dados.current.addresses[0].country = e.value);
	}

	const change_district = e => {

		set_cities([])
		set_villages([])

		obter_cidades_por_distrito(e.value, set_cities)

		set_dados(dados.current.addresses[0].city = '');
		set_dados(dados.current.addresses[0].village = '');
		set_dados(dados.current.addresses[0].region = e.value);
	}

	const change_city = e => {

		set_villages([])

		obter_freguesias_por_cidade(e.value, set_villages)

		set_dados(dados.current.addresses[0].village = '');
		set_dados(dados.current.addresses[0].city = e.value);
	}

	const change_village = e => {
		set_dados(dados.current.addresses[0].village = e.value);
		set_dados(dados)
	}

	const change_client_type = e => {
		set_dados(dados.current.info.tipo_cliente = e.value);

		if (e.value.localeCompare('Pessoal') === 0) {
			set_hide_pessoal('');
			set_hide_empresarial('d-none');
		}
		else {
			set_hide_pessoal('d-none');
			set_hide_empresarial('');
		}
	}

	const change_civil_state = e => {
		set_dados(dados.current.info.estado_civil = e.value);
	}

	const change_identification_type = e => {
		set_dados(dados.current.info.identificacao.tipo_identificacao = e.value);
	}

	const change_employment_situation = e => {
		set_dados(dados.current.info.dados_emprego.situacao_emprego = e.value);
	}

	const change_district_by_city = async e => {

		return await obter_distrito_por_cidade(e.value)
			.then(res =>
				change_district({ value: res })
			).catch(() => { return } /* alert('Distrito não encontrado!') */)
	}

	const fetch_id_info = async (id_serch) => {


		return await reqGET(`${api_crud_route}/${id_serch}`)
			.then(res => {
				set_dados(dados.current = res.data[0]);
				//console.log(res)

			})
			.catch(error => alert(error)/*TODO: GO TO FAIL FETCH*/)
	}

	const clean_data = () => {
		// DEEP COPY OF data_interface 
		set_dados(dados.current = JSON.parse(JSON.stringify(data_interface)))
		set_dados(dados.current.info.companycode = getTokenInfo().companycode)
		set_dados(dados.current.info.office = getTokenInfo().office)


		//console.log(dados.current)
	}

	// PARA INSTANCIAR O COMPONENTE COM O ID 
	//const props_tmp = props
	useEffect(() => {

		clean_data();

		obter_paises_em_pt(set_countries);

		try {
			let test = props.location.state.operation;

			if (test === 'edit') {
				//console.log('NO EDIT')
				set_is_input_disabled(false)
				set_disabled(false)

				set_is_edit(true)

				set_show_vat_form('d-none')

				set_show_delete_button('')

				fetch_id_info(props.location.state.item['id']).then(() => {

					if (dados.current.addresses[0].country === 'Portugal') {

						obter_distritos_pt(set_districts)

						obter_cidades_por_distrito(dados.current.addresses[0].region, set_cities)

						obter_freguesias_por_cidade(dados.current.addresses[0].city, set_villages)
						setLoading(false);
					}
					else {
						set_show_district_city_village('d-none')
						setLoading(false);
					}
				})
				return
			}

			if (test === 'newFromCredit') {

				set_show_vat_form('');

				//console.log(props.location.state.item['vat'])
				dados.current.info.vat = props.location.state.item['vat']
				set_dados(dados)
				setLoading(false);
				set_save_label('Salvar e continuar Crédito')

				return

			}

		} catch {
			// VAI REGISTAR
			set_show_vat_form('');

			clean_data()

			setLoading(false);

			setRedirect(false);
			return

		}
		// BACK
		//props.history.push(endpoints.list)
		return () => {
			clean_data()
		}

	}, [props])

	/* 
		const load_profile = async (tipo) => {
	
			return await reqGET(`${api_crud_base}/profiles/special/${tipo}`)
				.then(res => {
					//console.log(res.data)
					return res.data[0]
	
					//console.log(financials)
				}
				)
			//return tmp
		} */


	const load_entity = async (field, value, entity) => {

		let search_string = ''

		if (entity === '') {
			search_string = `${api_crud_base}/entities/list/${getTokenInfo().companycode}/*`
		}
		else
			search_string = `${api_crud_base}/entities/list/${getTokenInfo().companycode}/${entity}`

		reqGET(search_string)
			.then(res => {
				console.log(res.data)
				// res.data.map((k) => {
				// 	if(k['vat'] === vat) {
				// 		set_dados(dados.current.info.Cliente.id = k['id']);
				// 		set_dados(dados.current.info.Cliente.name = k['name']);
				// 	}
				// });

				let ja_existe = false
				res.data.forEach((k) => {
					//console.log(res.data)
					if (k[field] === value && value !== '') {

						ja_existe = true
						//set_visible_client_name(true)
						//set_dados(dados.current.info.Cliente.name = k['vat']);
					}
				})

				if (ja_existe === false) {
					set_is_input_disabled(true)

					if (field === 'username') {
						set_is_input_disabled(false)
					}
					//<Link to={{ pathname: '/client_regist', state: { item: vat, operation: 'newFromCredit' } }} />

					//console.log('NãO EXISTE ________________>');
					return

				} else {
					set_disabled(true)
					confirmAlert({
						title: 'Aviso!',
						message: `O ${field === 'vat' ? 'NIF' : field} já existe no sistema.`,
						buttons: [
							{
								label: 'Voltar',
								onClick: () => {

									if (field === 'username') {
										set_is_input_disabled(true)
										set_disabled(false)
										document.getElementById('username').style.color = 'red';



										return
									} else if (field === 'vat') {
										set_is_input_disabled(true)
										return /*props.history.push(endpoints.list)*/
									}

									return


								}
							},

						], closeOnEscape: true,
						closeOnClickOutside: true,

					})

					//console.log(ja_existe);
				}
			}
			)
	}




	const handleSubmit = async e => {
		e.preventDefault();

		if (dados.current.info.rgpd === '') {
			alert('Obrigatório aceitar autorização RGPD');
			return
		}
		if (dados.current.info.ativo === '') {
			alert('Obrigatório ativar o cliente');
			return
		}

		let guardarLink = endpoints.list

		if (is_edit === true) {
			//alert('Atualizar PUT')

			confirmAlert({
				title: 'Edição',
				message: 'Tem a certeza que pretende continuar?',
				buttons: [
					{
						label: 'Sim',
						onClick: () => {
							let password = dados.current.info.password;
							dados.current.info.password = passwordHash(password);

							reqPUT(api_crud_route, dados.current)
								.then(() => { props.history.push(endpoints.list) })
								.catch(() => alert('Problema ao atualizar Registo!'))


						}
					},
					{
						label: 'Não',
						onClick: () => { return }
					}
				]
			})

		} else {
			try {
				if (props.location.state.operation === 'newFromCredit') {
					//console.log('------->newFromCredit')
					let password = dados.current.info.password;
					dados.current.info.password = passwordHash(password);

					guardarLink = {
						pathname: '/credit_regist',
						state: {
							item: {
								cliente: {
									id: dados.current.id,
									vat: dados.current.info.vat,
									name: dados.current.info.name,
								},
							},
							operation: 'newFromClient'
						}
					}

					reqPOST(api_crud_route, dados.current)
						.then(() => alert('Registo Inserido com Sucesso!'))
						.catch(() => alert('Problema ao atualizar Registo!'))
					props.history.push(guardarLink)

				}
			} catch {

				confirmAlert({
					title: 'Novo Registo',
					message: 'Tem a certeza que pretende continuar?',
					buttons: [
						{
							label: 'Sim',
							onClick: () => {
								let password = dados.current.info.password;
								dados.current.info.password = passwordHash(password);

								reqPOST(api_crud_route, dados.current)
									.then(() => { props.history.push(endpoints.list) })
									.catch(() => alert('Problema ao atualizar Registo!'))


							}
						},
						{
							label: 'Não',
							onClick: () => { return }
						}
					]
				})


			}
		}
	}

	const handleSubmitVat = async e => {
		e.preventDefault();

		setLoading(true);
		get_vat_info(dados.current.info.vat)
			.then(res => {

				if ((res.data.name !== '---') && (res.data.name !== '')) {
					clean_data();

					set_dados(dados.current.info.vat = res.data.vatNumber);
					set_dados(dados.current.info.name = res.data.name);
					set_dados(dados.current.addresses[0].address_detail = res.data.address);

					let aMorada = res.data.address.split("\n");
					// Verificar se pais é PT
					if (res.data.countryCode === 'pt') {

						change_country({ value: 'Portugal' })


						set_dados(dados.current.addresses[0].street_name = aMorada[1]);

						let city = aMorada[1];
						if (city !== undefined) {
							city = city.toLowerCase();
							city = city.charAt(0).toUpperCase() + city.slice(1);
							change_district_by_city({ value: city }).then(() => change_city({ value: city }))


							// After District -> City
							change_district_by_city({ value: city }).then(() => change_city({ value: city }))

							let aMoradaCP = aMorada[2].split(" ");
							set_dados(dados.current.addresses[0].postal_code = aMoradaCP[0]);
						}
					} else {

						set_dados(dados.current.addresses[0].street_name = aMorada[1]);
					}
				}
				else {


					confirmAlert({
						title: 'Aviso',
						message: 'VAT não encotrado na base VIES.',
						buttons: [
							{
								label: 'Voltar',
								onClick: () => { return }
							}
						]
					})
				}
				setLoading(false)
			})

			.catch(error => { setLoading(false); alert(error) });
	}


	function apagar() {
		confirmAlert({
			title: 'Eliminar Entidade',
			message: 'Pretende mesmo eliminar este registo ?',
			buttons: [
				{
					label: 'Sim',
					onClick: () => {
						// Verificar se existe informação associada ao Cliente
						//reqGET(`${api_crud_base}/credits/credits/special/credits/${object_type}/${getTokenInfo().companycode}/${dados.current.id}`).then(
						reqGET(`${api_crud_base}/credits/credits/special/credits/${getTokenInfo().companycode}/${object_type}/${dados.current.id}`).then(

							res => {
								//console.log(res.data)
								if (res.data.length > 0) {

									confirmAlert({
										title: 'Aviso',
										message: 'A Entidade tem registos associados. Apenas pode ser editada.',
										buttons: [
											{
												label: 'Voltar',
												onClick: () => { return }
											}
										]
									})
								} else {
									//alert('Eliminou')
									reqDEL(api_crud_route + '/', { id: dados.current.id })
										.then(res => {
											/* alert(res.status === 200 ?
												'Registo Apagado com Sucesso' : 'Problema ao Apagar Registo'); */

											props.history.push(endpoints.list);
										})
										.catch(error => alert(error))
								}
							}
						)
					}
				},
				{
					label: 'Não',
					onClick: () => { return }
				}
			]
		});
	};


	const handle_change_contacts_person_responsables = (e, contacts_index = 0, person_responsables_index = 0) => {
		const { name, value } = e.target;

		/* DEBUG*/
		/* //console.log('NAME:' + name)
		//console.log('Value:' + value) */

		set_dados(dados.current.contacts[contacts_index].person_responsables[person_responsables_index][name] = value)

		/* //console.log('Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«')
		//console.log('Dados Current:' + JSON.stringify(dados)) */

	}


	const handle_change_addresses = (e, addresses_index = 0) => {
		const { name, value } = e.target;

		/* DEBUG*/
		/* //console.log('NAME:' + name)
		//console.log('Value:' + value) */

		set_dados(dados.current.addresses[addresses_index][name] = value)

		/* 	//console.log('Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«')
			//console.log('Dados Current:' + JSON.stringify(dados)) */

	}


	const handle_change_info = (e) => {
		const { name, value } = e.target;

		/* DEBUG*/
		/* 
				//console.log('NAME:' + name)
				//console.log('Value:' + value)
		 */
		set_dados(dados.current.info[name] = value)

		if ((is_edit === false) && (name === 'vat')) {
			if (dados.current.info['vat'].length === 9) {
				load_entity(dados.current.info['vat'], object_type)
			}
		}

		/* //console.log('Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«')
		//console.log('Dados Current:' + JSON.stringify(dados)) */

	}

	/* const handle_change_password = (e) => {
		const { value } = e.target;

		//console.log(value)
		//console.log(dados.current.info.password)
		if (dados.current.info.password !== '' && value === dados.current.info.password) {
			set_disabled(false)
		}
		else {
			alert('Password de confirmação diferente da password inserida')
			set_disabled(true)
		}
	} */

	const handle_change_contacts = (e, index = 0) => {
		const { name, value } = e.target;

		/* DEBUG*/

		/* //console.log('NAME:' + name)
		//console.log('Value:' + value) */

		set_dados(dados.current.contacts[index][name] = value)

		/* //console.log('Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«')
		//console.log('Dados Current:' + JSON.stringify(dados)) */

	}

	const handle_change_info_identification = (e) => {
		const { name, value } = e.target;

		/* DEBUG*/

		/* 	//console.log('NAME:' + name)
			//console.log('Value:' + value) */

		set_dados(dados.current.info.identificacao[name] = value)

		/* //console.log('Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«')
		//console.log('Dados Current:' + JSON.stringify(dados)) */

	}

	const handle_change_info_employment = (e) => {
		const { name, value } = e.target;

		/* DEBUG*/

		/* 	//console.log('NAME:' + name)
			//console.log('Value:' + value) */

		set_dados(dados.current.info.dados_emprego[name] = value)

		/* 	//console.log('Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«')
			//console.log('Dados Current:' + JSON.stringify(dados)) */

	}

	const handleSingleCheck = e => {
		const { name } = e.target;

		if (dados.current.info[name] === false)
			set_dados(dados.current.info[name] = true)
		else
			set_dados(dados.current.info[name] = false)

		setRefresh(refresh + 1)
	}

	const handleSingleCheck_ver = name => {

		if (dados.current.info[name] === true) {
			return true
		}
		else
			return false
	}

	return isloading ?
		<main>
			<div className={`dash-cards-2 header`}>
				<h2 className="dash-title">LOADING</h2>
				<p>LOADING</p>
				<div className="overlay">
					<div className="overlay__inner">
						<div className="overlay__content"><span className="spinner"></span></div>
					</div>
				</div>
			</div>
		</main>
		:
		isRedirect
			?
			<Redirect to={endpoints.list} replace />
			:
			<main>
				<div className={`dash-cards-2 header`}>
					<h2 className="dash-title">Clientes</h2>
					<p>Home / Clientes / Inserir Cliente</p>
				</div>

				<div className={`dash-cards-2 body`}>
					<div className="card">
						<div className="card-body">
							<form className={show_vat_form} id="form-vat" onSubmit={handleSubmitVat}>
								<div className="form-group">
									<span className="flex-1">
										{/*<input type="text" name="dados.info.vat" value={dados.info.vat} pattern="[0-9]{9}" className="input-form" placeholder="Introduza o NIF" onChange={e => set_dados({ ...dados, info: { ...dados.info, vat: e.target.value } })} required /> */}
										<input type="text" pattern={pattern_vat} minLength="9" inputMode='numeric' name="vat" value={dados.current.info.vat} className="input-form" placeholder="Introduza o NIF" onChange={handle_change_info} required />
									</span>
									<span className="flex-1">

										<input type="submit" value="Pesquisar" />
									</span>
								</div>
							</form>

							<form id="form" onSubmit={handleSubmit}>
								<input type="hidden" name="id" value={dados.current.id} />

								<h2 className="dash-title">Dados Cliente</h2>
								<div className="form-group">
									<span className="flex-1">
										<label>Nome</label>
										<input type="text" name="name" value={dados.current.info.name} className="input-form" placeholder="Introduza o nome" onChange={handle_change_info} required />
									</span>
									<span className="flex-1">
										<label>NIF</label>
										<input type="text" pattern={pattern_vat} minLength="9" inputMode='numeric' name="vat" disabled={true} value={dados.current.info.vat} className="input-form" placeholder="Número de Contribuinte" onChange={handle_change_info} required />
									</span>
									<span className="flex-1">
										<label>Tipo de Cliente</label>
										<Select
											className="div-select"

											options={client_types}
											name="client_type"
											value={dados.current.info.tipo_cliente === '' ? null : { label: dados.current.info.tipo_cliente, value: dados.current.info.tipo_cliente }}
											placeholder={"Introduza o tipo de cliente"}
											onChange={change_client_type}
											styles={custom_select_styles}
										/>
									</span>
								</div>
								<div className={hidePessoal !== '' ? hidePessoal : 'form-group'}>
									<span className="flex-1">
										<label>Tipo de Identificação</label>
										<Select
											className="div-select"

											options={identification_types}
											name="identification_type"
											value={dados.current.info.identificacao.tipo_identificacao === '' ? null : { label: dados.current.info.identificacao.tipo_identificacao, value: dados.current.info.identificacao.tipo_identificacao }}
											placeholder={"Introduza o tipo de identificação"}
											onChange={change_identification_type}
											styles={custom_select_styles}
										/>
									</span>
									<span className="flex-1">
										<label>Número de Identificação</label>
										<input type="text" pattern="\d*" inputMode='numeric' maxLength="11" name="numero_identificacao" value={dados.current.info.identificacao.numero_identificacao} className="input-form" placeholder="Introduza o número de identificação" onChange={handle_change_info_identification} />
									</span>
									<span className="flex-1">
										<label>Validade</label>
										<input type="date" name="validade_identificacao" value={dados.current.info.identificacao.validade_identificacao} min={today} className="input-form" placeholder="Introduza a validade" onChange={handle_change_info_identification} />
									</span>
								</div>
								<div className={hidePessoal !== '' ? hidePessoal : 'form-group'}>
									<span className="flex-1">
										<label>Data de Nascimento</label>
										<input type="date" name="data_nascimento" value={dados.current.info.data_nascimento} max={today} className="input-form" placeholder="Introduza a data de nascimento" onChange={handle_change_info} />
									</span>
									<span className="flex-1">
										<label>Estado Civil</label>
										<Select
											className="div-select"

											options={civil_states}
											name="civil_state"
											value={dados.current.info.estado_civil === '' ? null : { label: dados.current.info.estado_civil, value: dados.current.info.estado_civil }}
											placeholder={"Introduza o estado civil"}
											onChange={change_civil_state}
											styles={custom_select_styles}
										/>
									</span>
									<span className="flex-1">

									</span>
								</div>
								<div className="form-group">
									<span className="flex-1">
										<label>Telefone</label>

										<PhoneInput
											defaultCountry="PT"
											placeholder="Introduza o número de telefone"
											value={dados.current.contacts[0].phone !== '' ? dados.current.contacts[0].phone : null}
											onChange={e => set_dados(dados.current.contacts[0].phone = e)} />
										{/* <input type="tel" name="phone" value={dados.current.contacts[0].phone} pattern="[0-9]{9}" className="input-form" placeholder="Introduza o número de telefone" onChange={handle_change_contacts} /> */}
									</span>
									<span className="flex-1">
										<label>Telemóvel</label>
										<PhoneInput
											defaultCountry="PT"
											placeholder="Introduza o número de telemóvel"
											value={dados.current.contacts[0].mobile_phone !== '' ? dados.current.contacts[0].mobile_phone : null}
											onChange={e => set_dados(dados.current.contacts[0].mobile_phone = e)} />
										{/* <input type="tel" name="mobile_phone" value={dados.current.contacts[0].mobile_phone} pattern="[0-9]{9}" className="input-form" placeholder="Introduza o telemóvel" onChange={handle_change_contacts} /> */}
									</span>
									<span className="flex-1">
										<label>E-mail</label>
										<input type="email" pattern={pattern_email} name="email" value={dados.current.contacts[0].email} className="input-form" placeholder="Introduza o e-mail" onChange={handle_change_contacts} />
									</span>
								</div>
								<div className="form-group">
									<span className="flex-1">
										<label>País</label>
										<Select
											className="div-select"
											options={countries}
											name="pais"
											value={dados.current.addresses[0].country === '' ? null : { label: dados.current.addresses[0].country, value: dados.current.addresses[0].country }}
											placeholder={"Introduza o paí­s"}
											onChange={change_country}
											styles={custom_select_styles}
										/>
									</span>
									<span className={`flex-1 ${show_district_city_village}`}>
										<label>Distrito</label>
										<Select
											className="div-select"
											options={districts}
											name="distrito"
											value={dados.current.addresses[0].region === '' ? null : { label: dados.current.addresses[0].region, value: dados.current.addresses[0].region }}
											placeholder={"Introduza o distrito"}
											onChange={change_district}
											styles={custom_select_styles}
										/>
									</span>
									<span className={`flex-1 ${show_district_city_village}`}>
										<label>Concelho</label>
										<Select
											className="div-select"
											options={cities}
											name="concelho"
											value={dados.current.addresses[0].city === '' ? null : { label: dados.current.addresses[0].city, value: dados.current.addresses[0].city }}
											placeholder={"Introduza o concelho"}
											onChange={change_city}
											styles={custom_select_styles}
										/>
									</span>
								</div>
								<div className="form-group">
									<span className={`flex-1 ${show_district_city_village}`}>
										<label>Freguesia</label>
										<Select
											className="div-select"
											options={villages}
											name="freguesia"
											value={dados.current.addresses[0].village === '' ? null : { label: dados.current.addresses[0].village, value: dados.current.addresses[0].village }}
											placeholder={"Introduza a Freguesia"}
											onChange={change_village}
											styles={custom_select_styles}
										/>
									</span>
									<span className={`flex-1 `}>
										<label>Localidade</label>
										<input type="text" name="localidade" value={dados.current.addresses[0].localidade} className="input-form" placeholder="Introduza a Localidade" onChange={handle_change_addresses} required />
									</span>
									<span className="flex-1">
										<label>Código-postal</label>
										<input type="text" name="postal_code" value={dados.current.addresses[0].postal_code} className="input-form" placeholder="Introduza o código-postal" onChange={handle_change_addresses} required />
									</span>
									<span className="flex-1">

									</span>
								</div>
								<div className="form-group">
									<span className="flex-2">
										<label>Morada</label>
										<textarea name="address_detail" value={dados.current.addresses[0].address_detail} className="input-form" rows="4" onChange={handle_change_addresses} required>
										</textarea>
									</span>
									<span className="flex-1"></span>
								</div>

								<div className={hidePessoal}>
									<h2 className="dash-title">Dados Emprego</h2>
									<div className="form-group">
										<span className="flex-1">
											<label>Situação</label>
											<Select
												className="div-select"
												options={employment_situations}
												name="situacao_emprego"
												value={dados.current.info.dados_emprego.situacao_emprego === '' ? null : { label: dados.current.info.dados_emprego.situacao_emprego, value: dados.current.info.dados_emprego.situacao_emprego }}
												placeholder={"Introduza a situação face ao emprego"}
												onChange={change_employment_situation}
												styles={custom_select_styles}
											/>
										</span>
										<span className="flex-1">
											<label>Profissão</label>
											<input type="text" name="profissao" value={dados.current.info.dados_emprego.profissao} className="input-form" placeholder="Introduza a profissão" onChange={handle_change_info_employment} />
										</span>
										<span className="flex-1">
											<label>Entidade Empregadora</label>
											<input type="text" name="entidade_patronal" value={dados.current.info.dados_emprego.entidade_patronal} className="input-form" placeholder="Introduza a entidade empregadora" onChange={handle_change_info_employment} />
										</span>
									</div>
									<div className="form-group">
										<span className="flex-1">
											<label>Vencimento mensal</label>
											<input type="text" name="vencimento_mensal" value={dados.current.info.dados_emprego.vencimento_mensal} className="input-form" placeholder="Introduza o vencimento mensal" onChange={handle_change_info_employment} />
										</span>
										<span className="flex-1">
											<label>Outros rendimentos</label>
											<input type="text" name="outros_rendimentos" value={dados.current.info.dados_emprego.outros_rendimentos} className="input-form" placeholder="Introduza outros rendimentos" onChange={handle_change_info_employment} />
										</span>
										<span className="flex-1">

										</span>
									</div>
								</div>
								<h2 className={hideEmpresarial !== '' ? hideEmpresarial : 'dash-title'}>Dados Intermediário</h2>
								<div className={hideEmpresarial !== '' ? hideEmpresarial : 'form-group'}>
									<span className="flex-1">
										<label>Nome</label>
										<input type="text" name="name" value={dados.current.contacts[0].person_responsables[0].name} className="input-form" placeholder="Introduza o intermediário" onChange={handle_change_contacts_person_responsables} />
									</span>
									<span className="flex-1">
										<label>Contacto Telefónico (Intermediário)</label>
										<PhoneInput
											defaultCountry="PT"
											placeholder="Introduza o contacto telefónico"
											value={dados.current.contacts[0].person_responsables[0].mobile_phone !== '' ? dados.current.contacts[0].person_responsables[0].mobile_phone : null}
											onChange={e => set_dados(dados.current.contacts[0].person_responsables[0].mobile_phone = e)} />
										{/* <input type="tel" name="mobile_phone" value={dados.current.contacts[0].mobile_phone} pattern="[0-9]{9}" className="input-form" placeholder="Introduza o telemóvel" onChange={handle_change_contacts} /> */}
									</span>
									<span className="flex-1">
										<label>E-mail</label>
										<input type="email" pattern={pattern_email} name="email" value={dados.current.contacts[0].person_responsables[0].email} className="input-form" placeholder="Introduza o e-mail do intermediário" onChange={handle_change_contacts_person_responsables} />
									</span>
								</div>
								{/* 
								<h2 className="dash-title">Dados Login</h2>
								<div className="form-group">
									<span className="flex-1">
										<label>Username</label>
										<input type="text" name="username" value={dados.current.info.username} pattern="[A-Za-z.]+" className="input-form" placeholder="Introduza o username" onChange={handle_change_info} />
									</span>
									<span className="flex-1">
										<label>Password</label>
										<input type="password" name="password" min="9" className="input-form" placeholder="Introduza a password" onChange={handle_change_info} />
									</span>
									<span className="flex-1">
										<label>Confirmacao da Password</label>
										<input type="password" name="password_conf" min="9" className="input-form" placeholder="Introduza a confirmação da password" onBlur={handle_change_password} />
									</span>
								</div> */}
								<div className="form-group">
									<span className="flex-1">
										<label>Autorização RGPD</label>
										<span className="div-checkbox-group">
											<label className="div-checkbox">
												O Cliente autorizou a recolha de dados
												<input
													name="rgpd"
													className="checkbox-form"
													type="checkbox"
													value={dados.current.info.rgpd === true ? true : false}
													onChange={handleSingleCheck}
													defaultChecked={handleSingleCheck_ver('rgpd')}
												/>
												<span className="checkmark"></span>
											</label>
										</span>
										<label>Estado</label>
										<span className="div-checkbox-group">
											<label className="div-checkbox">
												Cliente ativo
												<input
													name="ativo"
													className="checkbox-form"
													type="checkbox"
													value={dados.current.info.activo ? true : false}
													onChange={handleSingleCheck}
													defaultChecked={handleSingleCheck_ver('ativo')}
												/>
												<span className="checkmark"></span>
											</label>
										</span>
									</span>
								</div>
							</form>
						</div>
					</div>
				</div>

				<div className={'dash-cards-2 footer'}>
					<nav className="navbar bottom-navbar navbar-light">
						<div className="navbar-footer">
							<ul className="navbar-nav float-left"></ul>
							<ul className="navbar-nav float-right">
								<li className="nav-item">
									<Link className="nav-link btn-cancel" to={{ pathname: endpoints.list }}  >

										<img alt='' src={'./img/cancelar.svg'} />
										<span>Cancelar</span>
									</Link>
								</li>

								<li className={"nav-item " + show_delete_button}>
									<button onClick={apagar} className="nav-link btn-delete">

										<img alt='' src={'./img/eliminar_b.svg'} />
										<span>Eliminar</span>
									</button>
								</li>
								<li className="nav-item">
									<button disabled={disabled ? true : false} type="submit" form="form" className="nav-link">

										<img alt='' src={'./img/save.svg'} />
										<span>{save_label}</span>
									</button>
								</li>
							</ul>
						</div>
					</nav>
				</div>
			</main >
}



/* const options = {
	title: 'Title',
	message: 'Message',
	buttons: [
		{
			label: 'Yes',
			onClick: () => alert('Click Yes')
		},
		{
			label: 'No',
			onClick: () => alert('Click No')
		}
	],
	childrenElement: () => <div />,
	customUI: ({ onClose }) => <div>Custom UI</div>,
	closeOnEscape: true,
	closeOnClickOutside: true,
	willUnmount: () => { },
	afterClose: () => { },
	onClickOutside: () => { },
	onKeypressEscape: () => { },
	overlayClassName: "overlay-custom-class-name"
}; */