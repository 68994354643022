import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from 'react-router-dom';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

import { api_crud_route, api_list_route, endpoints, data_interface } from './Config';

import { reqGET, reqPOST, reqDEL } from '../../../Utils';

import { getTokenInfo } from '../../../UseToken';
import UseSidebar from '../../../UseSidebar';




import { confirmAlert } from 'react-confirm-alert';

//import Hosts from '../../Hosts';

export default function List() {

    const [data, setData] = useState([]);
    const [isloading, setLoading] = useState(true);
    const { pathname } = useLocation();

    const [, set_SIDE_BAR] = useState([])
    const [permissions, set_permissions] = useState({})


    const [, set_dados] = useState(null);
	const dados = useRef(null);

    //const [edit_button, show_edit_button] = useState('none');



    //console.log(sideBarData)

    //console.log('Permissoes')
    const clean_data = () => {
		// DEEP COPY OF data_interface 
		set_dados(dados.current = JSON.parse(JSON.stringify(data_interface)))
		set_dados(dados.current.info.companycode = getTokenInfo().companycode)
		//console.log(dados.current)
	}


    useEffect(() => {
        clean_data();

        console.log('PathName', pathname)


        let get_permissions = (e, sidebar) => {

            let handleSingleCheck = (e, sidebar) => {

                const name = e;
                //console.log('PathName', name)
                //console.log('SIDEBAR', sidebar)

                let ver_submenu = (node) => {
                    if (node.submenu.length !== 0) {

                        node.submenu.forEach(subitem => {

                            if (subitem.path === name) {
                                //console.log('NO SUBITEM ', name)
                                set_permissions({ edit: subitem.edit, view: true, remove: subitem.remove });
                            }
                            else {
                                ver_submenu(subitem)
                            }

                        })
                    }
                }

                sidebar.forEach(node => {
                    //console.log('Node ', node)
                    if (node.path === name) {
                        //console.log('NO ITEM ', node.path)
                        //console.log(node)
                        set_permissions({ edit: node.edit, view: true, remove: node.remove });
                        //console.log('no true')
                    } else
                        ver_submenu(node)
                })
            }


            handleSingleCheck(e, sidebar)

        }

        // getTokenInfo().companycode

        //console.log(data_interface)





        reqGET(api_list_route(getTokenInfo().companycode))
            .then(res => {
                setData(res.data);


                UseSidebar().getSidebar(getTokenInfo().profile)
                    .then(v => {
                        //console.log(v);
                        get_permissions(pathname, v)
                        set_SIDE_BAR(v)
                        setLoading(false)
                    }/* set_SIDE_BAR(v) */);




            })
            .catch(() => 404

                //  TODO: ENVIAR PÝGINA 404
                //alert(error)); 


            )
    }, [pathname]);


    //APAGAR REGISTO
    function apagar(id) {
        confirmAlert({
            title: 'Apagar Registo',
            message: 'Tem a certeza que quer apagar esta Pergunta?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        reqDEL(api_crud_route, { id: id })
                            .then(() => {
                                //alert('Registo Apagado com Sucesso');

                                window.location.reload(false);
                            })
                            .catch(e => alert('Problema ao Apagar Registo'))
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { return }
                }
            ]
            })

       
    }

    const handleSubmit = async e => {
		e.preventDefault();

		
			confirmAlert({
				title: 'Novo Registo',
				message: 'Tem a certeza que pretende continuar?',
				buttons: [
					{
						label: 'Sim',
						onClick: () => {

							reqPOST(api_crud_route, dados.current)
								.then(() => { 
                                    window.location.reload(false);
                                })
								.catch(() => alert('Problema ao atualizar Registo!'))
						}
					},
					{
						label: 'Não',
						onClick: () => { return }
					}
				]
			})
		
	}

    const handle_change_content = (e) => {
		const { name, value } = e.target;

		set_dados(dados.current.content[name] = value)
		if(name === 'question') {
			set_dados(dados.current.info['name'] = value)
		}

		// console.log('Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«')
		// console.log('Dados Current:' + JSON.stringify(dados))
	}

    return (isloading ?
        <main>
            <div className={`dash-cards-2 header`}>
                <h2 className="dash-title">LOADING</h2>
                <p>LOADING</p>
                <div className="overlay">
                    <div className="overlay__inner">
                        <div className="overlay__content"><span className="spinner"></span></div>
                    </div>
                </div>

            </div>
        </main>

        :

        <main>
            <div className={`dash-cards-2 header`}>
                <h2 className="dash-title">Suporte</h2>
                <p>Home / Suporte / Lista de Perguntas</p>
            </div>

            <div className={`dash-cards-2 body`}>
                <div className="card">
                    <div className="card-body">
                        <form id="form" onSubmit={handleSubmit}>
                            <input type="hidden" name="id" value={dados.current.id} />

                            <h2 className="dash-title">Nova Pergunta</h2>
                            <div className="form-group">
                                <span className="flex-1">
                                    <label>Pergunta</label>
                                    <input type="text" name="question" value={dados.current.content.question} className="input-form" placeholder="Introduza a pergunta" onChange={handle_change_content} required />
                                </span>
                            </div>
                            <div className="form-group">
                                <span className="flex-1">
                                    <label>Resposta</label>
                                    <textarea type="text" name="answer" value={dados.current.content.answer} className="input-form" placeholder="Introduza a resposta" onChange={handle_change_content} />
                                </span>
                            </div>

                            <button type="submit" form="form" className="button button-update">
								<span>Inserir</span>
							</button>

                        </form>
                    </div>
                </div>

                <div className="card">
                    <br />
                    <Accordion allowZeroExpanded allowMultipleExpanded >
                        {
                        data.map((v, k) => {
                            return (
                                <AccordionItem key={k}>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            {v.content.question}
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>{v.content.answer}</p>
                                        <br/>
                                        <button onClick={() => apagar(v['id'])} className="button btn-delete" style={{ fontSize: '13px', marginLeft: '5px' }}>
                                            Apagar
                                        </button>

                                        <Link className="button button-update" to={{ pathname: endpoints.regist, state: { item: v, operation: 'edit' } }} style={{ fontSize: '13px' }} replace>
                                            Editar
                                        </Link>
                                        <br/>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            )
                        })
                        }
                    </Accordion>
                </div>
            </div>
        </main>
    )
}