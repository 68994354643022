import React, { useState, useEffect } from 'react';
import { Redirect, Link } from 'react-router-dom';

import { reqGET } from '../../../Utils';
import { api_crud_route, data_interface, endpoints } from './Config';

import { getTokenInfo } from './../../../UseToken';

export default function View(props) {

    const [dados, set_dados] = useState(data_interface);

    const [isloading, setLoading] = useState(true);
    const [isRedirect, setRedirect] = useState(false);

    // const [hidePessoal, set_hide_pessoal] = useState('')
    // const [hideEmpresarial, set_hide_empresarial] = useState('')

    // Para Instanciar todo o Objecto 
    const fetchInfo = async (id) => {
        await reqGET(`${api_crud_route}/${getTokenInfo().companycode}/${id}`)
            .then(res => {
                set_dados(res.data[0]);
            })
            .catch(() => 404
                //  TODO: ENVIAR PÃ�GINA 404
                //  alert(error)); 
            );
    }

    useEffect(() => {

        if (props.location.state !== undefined) {


            fetchInfo(props.location.state.item['id'])
                .then(() =>


                    setLoading(false))



        }
        else {
            setLoading(false);
            setRedirect(true);
        }

    }, [props]);






    return isRedirect ?
        <Redirect to={endpoints.list} replace />
        :
        isloading ?
            <main>
                <div className={`dash-cards-2 header`}>
                    <h2 className="dash-title">LOADING</h2>
                    <p>LOADING</p>
                    <div className="overlay">
                        <div className="overlay__inner">
                            <div className="overlay__content"><span className="spinner"></span></div>
                        </div>
                    </div>
                </div>
            </main>
            :
            <main>
                <div className={`dash-cards-2 header`}>
                    <h2 className="dash-title">Tipos de crédito</h2>
                    <p>Home / Tipos de crédito / {dados.info.name}</p>
                </div>

                <div className={`dash-cards-2 body`}>
                    <div className="card">
                        <div className="card-body">
                            <h2 className="dash-title">{dados.info.name}</h2>
                            <div className="div-break"></div>
                            <h2 className='dash-title'>Etapas</h2>
                            <div className="div-break"></div>
                            <div className="div-group">
                                <span className="flex-2">
                                    {
                                        typeof dados.info.etapa !== 'undefined' && dados.info.etapa.length > 0
                                            ?
                                            dados.info.etapa.map((v, k) => {
                                                return (
                                                    <p key={k}>{v.name}</p>
                                                )
                                            })
                                            : ''
                                    }
                                </span>
                            </div>

                        </div>
                    </div>
                </div>

                <div className={'dash-cards-2 footer'}>
                    <nav className="navbar bottom-navbar navbar-light">
                        <div className="navbar-footer">
                            <ul className="navbar-nav float-left"></ul>
                            <ul className="navbar-nav float-right">
                                <li className="nav-item">
                                    <Link className="nav-link btn-cancel" to={{ pathname: endpoints.list }} replace>
                                        <img alt='' src={'./img/seta_2.svg'} />
                                        <span>Voltar</span>
                                    </Link>
                                </li>

                            </ul>
                        </div>
                    </nav>
                </div>
            </main>
}