import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from 'react-router-dom';
import "./style.css";
import UseSidebar from './../../UseSidebar';
//import SideBarDataComp from "./SideBarData";
//import useSidebar from '../../UseSidebar';
import { getTokenInfo } from './../../UseToken';

function Sidebar() {

    //const showSidebar = () => setSidebar(!sidebar)
    // //console.log(sidebar);
    // //console.log(showSidebar);


    //PRINT ROUTE
    const { pathname } = useLocation();
    //console.log(pathname);

    //const { sideBarData, getSidebar } = useSidebar();

    const [sideBarData, set_SIDE_BAR] = useState([])
    //console.log(props);






    useEffect(() => {

        UseSidebar().getSidebar(getTokenInfo().profile).then(v => set_SIDE_BAR(v))


        //console.log('IN SIDEBAR', sideBarData)

        //console.log('SideBarData', SideBarData)
        return () => { }
    }, [])
    //const SideBarData = SideBarDataComp(props);

    //console.log(props)

    return (
        <div className={'sidebar left-sidebar'}  >

            <div className="sidebar-menu">
                <nav className="sidebar-nav">
                    <ul id="sidebarnav" className="in">
                        {
                            sideBarData.map((item, key) => {
                                //props.sidebar_info.map((item, key) => {
                                if (item.allow === false) {
                                    return ''
                                }
                                // PARA O CASO DE UMA CLASSNAME ESPECIFICA
                                if (item.title.localeCompare('') === 0) {
                                    return (
                                        <li key={key} className={item.cName}></li>
                                    )
                                }
                                else

                                    if (item.submenu.length > 0) {

                                        let menuActive = false;

                                        item.submenu.map(submenu => {
                                            if (submenu.allow === false) {
                                                return ''
                                            }

                                            if (item.path.localeCompare(pathname) === 0 || submenu.path.localeCompare(pathname) === 0) {
                                                menuActive = true;
                                            }

                                            return menuActive

                                        })

                                        return (
                                            <li key={key} className={menuActive ? "sidebar-item has-submenu selected" : "sidebar-item has-submenu"}>
                                                {/*<li key={key} className={item.path.localeCompare(pathname) == 0 ? "sidebar-item selected" : "sidebar-item"}>*/}

                                                <NavLink isActive={() => [item.path].includes(pathname)} activeClassName='active' to={item.path} className="sidebar-link has-arrow" >
                                                    <img alt='' src={menuActive ? "./img/" + item.icon_b : "./img/" + item.icon} />
                                                    <span className="hide-menu">{item.title}</span>
                                                </NavLink>

                                                <ul area-expanded='false' className={menuActive ? "collapse first-level base-level-line in" : "collapse first-level base-level-line"}>
                                                    {
                                                        item.submenu.map((submenu, key_2) => {
                                                            if (submenu.allow === false) {
                                                                return ''
                                                            }
                                                            return (
                                                                <li key={key_2} className='sidebar-item'>
                                                                    <NavLink isActive={() => [submenu.path].includes(pathname)} activeClassName='active' to={submenu.path} className={submenu.title.localeCompare('') === 0 ? 'd-none' : 'sidebar-link'} >
                                                                        <span className={submenu.cName}></span>
                                                                        <span>{submenu.title}</span>
                                                                    </NavLink>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </li>
                                        )
                                    }
                                    else {

                                        return (
                                            <li key={key} className={item.path.localeCompare(pathname) === 0 ? "sidebar-item selected" : "sidebar-item"}>
                                                <NavLink isActive={() => [item.path].includes(pathname)} activeClassName='active' to={item.path} className="sidebar-link" replace>
                                                    <img alt='' src={item.path.localeCompare(pathname) === 0 ? "./img/" + item.icon_b : "./img/" + item.icon} />
                                                    <span>{item.title}</span>
                                                </NavLink>
                                            </li>
                                        )
                                    }
                            })
                        }
                    </ul>
                </nav>
            </div>
        </div >

    )


}



export default Sidebar;