import React from 'react';
import "./style.css";

export default function Footer() {
    return (
        <footer>
            <nav className="navbar bottom-navbar navbar-light">

                <div className="navbar-footer">
                    <ul className="navbar-nav float-right">
                        <li className="nav-item">
                            <span className="nav-link">
                                Cancelar
                            </span>
                        </li>
                        <li className="nav-item">
                            <span className="nav-link">
                                Salvar
                            </span>
                        </li>
                    </ul>
                </div>
            </nav>
        </footer>
    );
}