import React, { useState, useEffect } from 'react';
import { Redirect, Link } from 'react-router-dom';

import { reqGET } from '../../../Utils';

import { getTokenInfo } from './../../../UseToken';
import { api_list_route, data_interface, endpoints } from './Config';

export default function View(props) {

    const [dados, set_dados] = useState(data_interface);

    const [isloading, setLoading] = useState(true);
    const [isRedirect, setRedirect] = useState(false);




    useEffect(() => {


        // Para Instanciar todo o Objecto 
        const fetchInfo = async (id) => {
            await reqGET(`${api_list_route(getTokenInfo().companycode)}/${id}`)
                .then(res => {
                    console.log(res)

                    if (res !== undefined) {
                        set_dados(res.data[0]);
                    }
                    else
                        //set_dados(res.data[0]);
                        props.history.push(endpoints.list)
                    //console.log(res.data[0])

                    // res

                    //console.log(dados)

                })
                .catch(() => 404
                    //  TODO: ENVIAR PÝGINA 404
                    //  alert(error)); 
                );
        }
        if (props.location.state !== undefined) {


            fetchInfo(props.location.state.item['id'])
                .then(() =>


                    setLoading(false))



        }
        else {
            setLoading(false);
            setRedirect(true);
        }

    }, [props]);



    //const handleSingleCheck_ver = (subtitle) => subtitle === '' ? false : true


    return isRedirect ?
        <Redirect to={endpoints.list} replace />
        :
        isloading ?
            <main>
                <div className={`dash-cards-2 header`}>
                    <h2 className="dash-title">LOADING</h2>
                    <p>LOADING</p>
                    <div className="overlay">
                        <div className="overlay__inner">
                            <div className="overlay__content"><span className="spinner"></span></div>
                        </div>
                    </div>
                </div>
            </main>
            :
            <main>
                <div className={`dash-cards-2 header`}>
                    <h2 className="dash-title">Perfil</h2>
                    <p>Home / Perfis / Inserir Perfil</p>
                </div>

                <div className={`dash-cards-2 body`}>
                    <div className="card">
                        <div className="card-body">

                            <div>

                                <h2 className="dash-title">Perfil</h2>
                                <div className="form-group">
                                    <span className="flex-1">
                                        <label>Nome do Perfil</label>
                                        <span>{dados.info.name}</span>
                                    </span>
                                </div>

                                <h2 className="dash-title">Menus</h2>
                                <div className="form-group">
                                    <span className="flex-1">
                                        <label >Lista de Entradas Disponíveis</label>
                                        <br />
                                        {
                                            dados.info.menu.map((item, key) => {

                                                if (item.title === '')
                                                    return <div key={key}></div>
                                                else
                                                    return (

                                                        <div key={key}>
                                                            <span className="flex-1">

                                                                <label key={key} className="div-checkbox">
                                                                    {item.title}
                                                                    <input
                                                                        name={item.title}
                                                                        className="checkbox-form"
                                                                        type="checkbox"
                                                                        value={item.title}
                                                                        disabled={true}
                                                                        defaultChecked={item.allow}
                                                                    />
                                                                    <span key={key} className="checkmark"></span>
                                                                </label>
                                                            </span>
                                                            <div>

                                                                {

                                                                    item.submenu.map((sub, key2) => {

                                                                        if (sub.title === '')
                                                                            return <div key={key2}></div>
                                                                        else
                                                                            return (

                                                                                <div key={key2} className="form-group">

                                                                                    <span className="flex-1">

                                                                                        <label key={sub.title} className="div-checkbox">
                                                                                            --{sub.title}
                                                                                            <input
                                                                                                name={sub.title}
                                                                                                className="checkbox-form"
                                                                                                type="checkbox"
                                                                                                value={sub.title}
                                                                                                disabled={true}
                                                                                                defaultChecked={sub.allow}
                                                                                            />
                                                                                            <span className="checkmark"></span>
                                                                                        </label>
                                                                                    </span>
                                                                                    {sub.title.includes('Lista') ?
                                                                                        <span className="flex-2">

                                                                                            <ol>
                                                                                                <li>
                                                                                                    <label className="div-checkbox">Editar
                                                                                                        <input
                                                                                                            name={sub.title}
                                                                                                            className="checkbox-form"
                                                                                                            type="checkbox"
                                                                                                            value={sub.title}
                                                                                                            disabled={true}
                                                                                                            defaultChecked={sub.edit}
                                                                                                        />
                                                                                                        <span className="checkmark"></span>
                                                                                                    </label>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <label className="div-checkbox">Remover
                                                                                                        <input
                                                                                                            name={sub.title}
                                                                                                            className="checkbox-form"
                                                                                                            type="checkbox"
                                                                                                            value={sub.title}
                                                                                                            disabled={true}
                                                                                                            defaultChecked={sub.remove}
                                                                                                        />
                                                                                                        <span className="checkmark"></span>
                                                                                                    </label>
                                                                                                </li>

                                                                                            </ol>
                                                                                        </span>
                                                                                        :
                                                                                        <></>

                                                                                    }

                                                                                </div>
                                                                            )
                                                                    })
                                                                }
                                                            </div>
                                                            <h2 className="dash-title">          </h2>
                                                        </div>
                                                    )
                                            }
                                            )
                                        }
                                    </span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className={'dash-cards-2 footer'}>
                    <nav className="navbar bottom-navbar navbar-light">
                        <div className="navbar-footer">
                            <ul className="navbar-nav float-left"></ul>
                            <ul className="navbar-nav float-right">
                                <li className="nav-item">
                                    <Link className="nav-link btn-cancel" to={{ pathname: endpoints.list }} replace>
                                        <img alt='' src={'./img/seta_2.svg'} />
                                        <span>Voltar</span>
                                    </Link>
                                </li>

                            </ul>
                        </div>
                    </nav>
                </div>
            </main>
}