import Hosts from '../../../Hosts';

const object_type = 'Promotor'

const api_crud_base = Hosts.SIMCore_API
const api_crud_route = `${Hosts.SIMCore_API}/entities`
const api_list_route = companycode => `${Hosts.SIMCore_API}/entities/list/${companycode}/${object_type}`

const base_endpoint = '/promotor'

const endpoints = {
    base: base_endpoint,
    regist: `${base_endpoint}_regist`,
    list: `${base_endpoint}_list`,
    view: `${base_endpoint}_view`,
}

const data_interface = {
    id: '',
    info: {
        companycode: '',
        username: '',
        password: '',
        name: '',
        rgpd: false,
        ativo: true,
        vat: '',
        identificacao: {
            tipo_identificacao: '',
            numero_identificacao: '',
            validade_identificacao: ''
        },
        office: { id: '', name: '' },
        observation: '',
        entity_type: ''
    },
    addresses: [{
        village: '',
        city: '',
        type: [],
        region: '',
        country: '',
        apartado: '',
        localidade: '',
        contats: [],
        principal: '',
        observation: '',
        postal_code: '',
        street_name: '',
        address_detail: '',
    }],
    contacts: [{
        email: '',
        phone: '',
        principal: 'true',
        extensions: [],
        observation: '',
        mobile_phone: '',
        building_number: '',
        person_responsables: [{
            name: '',
            email: '',
            extensions: '',
            observation: '',
            mobile_phone: ''
        }],
    }],
    profile: { name: "Promotor" },
    notifications: []
}

export {
    object_type,
    endpoints,
    api_crud_base,
    api_crud_route,
    data_interface,
    api_list_route
}
