import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Redirect } from 'react-router-dom';
//import Hosts from '../../../Hosts';
import { getTokenInfo } from './../../../UseToken';

import { object_type, api_crud_base, api_crud_route, data_interface, endpoints } from './Config';

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import { confirmAlert } from 'react-confirm-alert';

import {

	reqGET,
	reqPOST,
	reqDEL,
	reqPUT,

	obter_cidades_por_distrito,
	obter_freguesias_por_cidade,
	obter_paises_em_pt,
	obter_distrito_por_cidade,
	obter_distritos_pt,

	get_vat_info,

	pattern_vat,
	pattern_email,
	passwordHash,

} from '../../../Utils';

//import { useHistory, Redirect } from "react-router-dom";
//import TextInput from 'react-autocomplete-input';


export default function Regist(props) {
	//export default function Financeira(props) ;
	//const Fornecedor = props => {

	// BEGIN


	//var history = useHistory();

	//Para Voltar ao URL Anterior
	//history.push(endpoints.list)

	//const id = useRef('');
	const [, set_dados] = useState(null);
	const dados = useRef(null);
	//const [local_interface, set_local_interface] = useState(JSON.parse(JSON.stringify(data_interface)));

	const [disabled, set_disabled] = useState(false)
	//console.log(dados.current)

	let newDate = new Date();
	let today = newDate.getFullYear() + '-' + (newDate.getMonth() + 1).toString().padStart(2, "0") + '-' + newDate.getDate().toString().padStart(2, "0");

	const [isloading, setLoading] = useState(true);
	const [isRedirect, setRedirect] = useState(false);

	const [show_vat_form, set_show_vat_form] = useState('');
	const [show_delete_button, set_show_delete_button] = useState('d-none');
	const [show_district_city_village, set_show_district_city_village] = useState('');


	//Temporário
	const identification_types = [
		{ value: "Bilhete de Identidade", label: "Bilhete de Identidade" },
		{ value: "Cartão de Cidadão", label: "Cartão de Cidadão" }
	];


	const tipo_colaborador = [
		{ value: "Colaborador", label: "Colaborador" },
		{ value: "Gestor de Escritório", label: "Gestor de Escritório" }
	];

	const civil_states = [
		{ value: "Solteiro", label: "Solteiro" },
		{ value: "Casado", label: "Casado" },
		{ value: "Divorciado", label: "Divorciado" },
		{ value: "Viúvo", label: "Viúvo" }
	];





	const [offices, set_offices] = useState([])
	const [countries, set_countries] = useState([])
	const [districts, set_districts] = useState([])
	const [cities, set_cities] = useState([])
	const [villages, set_villages] = useState([])

	const [is_edit, set_is_edit] = useState(false)


	const custom_select_styles = {
		option: (provided, state) => ({
			...provided,
		}),
		container: base => ({
			...base,
			width: '100%',
		}),
		control: base => ({
			...base,
			borderRadius: '10px',
			padding: '2px 10px',
			fontSize: '14px',
			zIndex: 999
		}),
		menuPortal: base => ({
			...base,
			zIndex: 999
		}),
		menu: base => ({
			...base,
			zIndex: 999
		}),
	}



	const change_country = e => {

		set_cities([])
		set_villages([])

		set_dados(dados.current.addresses[0].city = '');
		set_dados(dados.current.addresses[0].village = '');
		set_dados(dados.current.addresses[0].region = '');

		if (e.value === "Portugal") {

			set_show_district_city_village('')

			if (districts.length === 0) {
				//console.log('DISTRITOS VAZIOS')
				obter_distritos_pt(set_districts)
			}
		}
		else {
			set_show_district_city_village('d-none')
		}

		set_dados(dados.current.addresses[0].country = e.value);
	}

	const change_district = e => {

		set_cities([])
		set_villages([])

		obter_cidades_por_distrito(e.value, set_cities)

		set_dados(dados.current.addresses[0].city = '');
		set_dados(dados.current.addresses[0].village = '');
		set_dados(dados.current.addresses[0].region = e.value);
	}

	const change_city = e => {

		set_villages([])

		obter_freguesias_por_cidade(e.value, set_villages)

		set_dados(dados.current.addresses[0].village = '');
		set_dados(dados.current.addresses[0].city = e.value);
	}

	const change_village = e => {
		set_dados(dados.current.addresses[0].village = e.value);
		set_dados(dados)
	}




	const change_office = e => {
		const { label, value } = e;

		//console.log(e)
		set_dados(dados.current.info.office.id = value);
		set_dados(dados.current.info.office.name = label);
		//console.log(dados.current)



	}



	const change_colaborador_tipo = e => {
		const { value } = e;

		//console.log(e)
		set_dados(dados.current.profile.name = value);

		//console.log(dados.current)



	}

	const change_civil_state = e => {
		set_dados(dados.current.info.estado_civil = e.value);
	}

	const change_identification_type = e => {
		set_dados(dados.current.info.identificacao.tipo_identificacao = e.value);
	}


	const change_district_by_city = async e => {

		return await obter_distrito_por_cidade(e.value)
			.then(res =>
				change_district({ value: res })
			).catch(() => { return } /* alert('Distrito não encontrado!') */)
	}


	const load_offices = async () => {
		reqGET(`${api_crud_base}/entities/list/${getTokenInfo().companycode}/Escritorio`)
			.then(res => {
				//console.log(res.data)
				let tmp = res.data.map(k => ({ value: k['id'], label: k['name'], ativo: k['ativo'] }))
				set_offices(...[tmp.filter(k=> k.ativo === 'true')])//console.log(offices)
			}
			)
	}

	const fetch_id_info = async (id_serch) => {


		return await reqGET(`${api_crud_route}/${id_serch}`)
			.then(res => {
				set_dados(dados.current = res.data[0]);
				//console.log(res)

			})
			.catch(error => alert(error)/*TODO: GO TO FAIL FETCH*/)
	}

	// PARA INSTANCIAR O COMPONENTE COM O ID 
	//const props_tmp = props

	const clean_data = () => {
		// DEEP COPY OF data_interface 
		set_dados(dados.current = JSON.parse(JSON.stringify(data_interface)))
		set_dados(dados.current.info.companycode = getTokenInfo().companycode)

		set_dados(dados.current.info.office = getTokenInfo().office)
		//console.log(dados.current)
	}

	useEffect(() => {



		clean_data();

		obter_paises_em_pt(set_countries);
		load_offices();

		try {
			let test = props.location.state.operation;

			if (test === 'edit') {
				//console.log('NO EDIT')
				set_is_edit(true)

				set_show_vat_form('d-none')

				set_show_delete_button('')

				fetch_id_info(props.location.state.item['id']).then(() => {

					//console.log(dados.current)
					if (dados.current.addresses[0].country === 'Portugal') {

						obter_distritos_pt(set_districts)

						obter_cidades_por_distrito(dados.current.addresses[0].region, set_cities)

						obter_freguesias_por_cidade(dados.current.addresses[0].city, set_villages)
						setLoading(false);
					}
					else {
						set_show_district_city_village('d-none')
						setLoading(false);
					}
				})
				return
			}


			// VAI REGISTAR
			if (test === undefined) {
				set_show_vat_form('');


				setLoading(false);

				return
			}

		} catch {
			// VAI REGISTAR
			set_show_vat_form('');

			clean_data()

			setLoading(false);

			setRedirect(false);
			return

		}

		// BACK
		//props.history.push(endpoints.list)
		return () => {
			clean_data()
		}

	}, [props])




	const handleSubmit = async e => {
		e.preventDefault();

		if (is_edit === true) {
			//alert('Atualizar PUT')

			confirmAlert({
				title: 'Edição',
				message: 'Tem a certeza que pretende continuar?',
				buttons: [
					{
						label: 'Sim',
						onClick: () => {
							let password = dados.current.info.password;
							dados.current.info.password = passwordHash(password);

							reqPUT(api_crud_route, dados.current)
								.then(() => { props.history.push(endpoints.list) })
								.catch(() => alert('Problema ao atualizar Registo!'))
						}
					},
					{
						label: 'Não',
						onClick: () => { return }
					}
				]
			})
		} else {
			confirmAlert({
				title: 'Novo Registo',
				message: 'Tem a certeza que pretende continuar?',
				buttons: [
					{
						label: 'Sim',
						onClick: () => {
							let password = dados.current.info.password;
							dados.current.info.password = passwordHash(password);

							reqPOST(api_crud_route, dados.current)
								.then(() => { props.history.push(endpoints.list) })
								.catch(() => alert('Problema ao atualizar Registo!'))
						}
					},
					{
						label: 'Não',
						onClick: () => { return }
					}
				]
			})
		}
	}


	const handleSubmitVat = async e => {
		e.preventDefault();

		setLoading(true);
		get_vat_info(dados.current.info.vat)
			.then(res => {

				if ((res.data.name !== '---') && (res.data.name !== '')) {
					clean_data();
					set_dados(dados.current.info.vat = res.data.vatNumber);
					set_dados(dados.current.info.name = res.data.name);
					set_dados(dados.current.addresses[0].address_detail = res.data.address);

					let aMorada = res.data.address.split("\n");
					// Verificar se pais é PT
					if (res.data.countryCode === 'pt') {

						change_country({ value: 'Portugal' })

						set_dados(dados.current.addresses[0].street_name = aMorada[1]);

						let city = aMorada[1];
						if (city !== undefined) {
							city = city.toLowerCase();
							city = city.charAt(0).toUpperCase() + city.slice(1);
							change_district_by_city({ value: city }).then(() => change_city({ value: city }))


							// After District -> City
							change_district_by_city({ value: city }).then(() => change_city({ value: city }))

							let aMoradaCP = aMorada[2].split(" ");
							set_dados(dados.current.addresses[0].postal_code = aMoradaCP[0]);
						}
					} else {
						set_dados(dados.current.addresses[0].street_name = aMorada[1]);
					}
				}

				else {


					confirmAlert({
						title: 'Aviso',
						message: 'VAT não encotrado na base VIES.',
						buttons: [
							{
								label: 'Voltar',
								onClick: () => { return }
							}
						]
					})
				}
				setLoading(false)
			})

			.catch(error => { setLoading(false); alert(error) });
	}



	function apagar() {
		confirmAlert({
			title: 'Eliminar Entidade',
			message: 'Pretende mesmo eliminar este registo ?',
			buttons: [
				{
					label: 'Sim',
					onClick: () => {
						// Verificar se existe informação associada ao Cliente
						//reqGET(`${api_crud_base}/credits/credits/special/credits/${object_type}/${getTokenInfo().companycode}/${dados.current.id}`).then(
						reqGET(`${api_crud_base}/credits/credits/special/credits/${getTokenInfo().companycode}/${object_type}/${dados.current.id}`).then(

							res => {
								//console.log(res.data)
								if (res.data.length > 0) {

									confirmAlert({
										title: 'Aviso',
										message: 'A Entidade tem registos associados. Apenas pode ser editada.',
										buttons: [
											{
												label: 'Voltar',
												onClick: () => { return }
											}
										]
									})
								} else {
									//alert('Eliminou')
									reqDEL(api_crud_route + '/', { id: dados.current.id })
										.then(res => {
											/* alert(res.status === 200 ?
											   'Registo Apagado com Sucesso' : 'Problema ao Apagar Registo'); */

											props.history.push(endpoints.list);
										})
										.catch(error => alert(error))
								}
							}
						)
					}
				},
				{
					label: 'Não',
					onClick: () => { return }
				}
			]
		});
	};

	const load_entity = async (vat, entity) => {
		reqGET(`${api_crud_base}/entities/list/${getTokenInfo().companycode}/${entity}`)
			.then(res => {
				//console.log(res.data)
				// res.data.map((k) => {
				// 	if(k['vat'] === vat) {
				// 		set_dados(dados.current.info.Cliente.id = k['id']);
				// 		set_dados(dados.current.info.Cliente.name = k['name']);
				// 	}
				// });

				let ja_existe = false
				res.data.forEach((k) => {
					//console.log(res.data)
					if (k['vat'] === vat) {
						ja_existe = true
						//set_visible_client_name(true)
						//set_dados(dados.current.info.Cliente.name = k['vat']);
					}
				})

				if (ja_existe === false) {
					//<Link to={{ pathname: '/client_regist', state: { item: vat, operation: 'newFromCredit' } }} />

					//console.log('NãO EXISTE ________________>');
					return

				} else {
					confirmAlert({
						title: 'Aviso',
						message: 'Já Existe um Registo com este VAT.',
						buttons: [
							{
								label: 'Voltar',
								onClick: () => { props.history.push(endpoints.list) }
							},

						]
					})

					//console.log(ja_existe);
				}
			}
			)
	}



	const handle_change_addresses = (e, addresses_index = 0) => {
		const { name, value } = e.target;

		/* DEBUG*/
		/* 	//console.log('NAME:' + name)
			//console.log('Value:' + value) */

		set_dados(dados.current.addresses[addresses_index][name] = value)

		/* //console.log('Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«')
		//console.log('Dados Current:' + JSON.stringify(dados)) */

	}


	const handle_change_info = (e) => {
		const { name, value } = e.target;

		/* DEBUG*/

		/* 	//console.log('NAME:' + name)
			//console.log('Value:' + value)
	 */
		set_dados(dados.current.info[name] = value)


		if ((is_edit === false) && (name === 'vat')) {
			if (dados.current.info['vat'].length === 9) {
				load_entity(dados.current.info['vat'], object_type)
			}
		}
		/* 
				//console.log('Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«')
				//console.log('Dados Current:' + JSON.stringify(dados)) */

	}

	const handle_change_password = (e) => {
		const { value } = e.target;

		if (dados.current.info.password !== '' && value === dados.current.info.password) {
			set_disabled(false)
		}
		else {
			alert('Password de confirmação diferente da password inserida')
			set_disabled(true)
		}
	}

	const handle_change_contacts = (e, index = 0) => {
		const { name, value } = e.target;

		/* DEBUG*/

		/* 	//console.log('NAME:' + name)
			//console.log('Value:' + value)
	 */
		set_dados(dados.current.contacts[index][name] = value)

		/* 	//console.log('Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«')
			//console.log('Dados Current:' + JSON.stringify(dados)) */

	}

	const handle_change_info_identification = (e) => {
		const { name, value } = e.target;

		/* DEBUG*/

		/* 	//console.log('NAME:' + name)
			//console.log('Value:' + value)
	 */
		set_dados(dados.current.info.identificacao[name] = value)

		/* 	//console.log('Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«')
			//console.log('Dados Current:' + JSON.stringify(dados))
	 */
	}

	const handleSingleCheck = () => {

		if(dados.current.info.ativo === true) {
			console.log('false')
			set_dados(dados.current.info.ativo = false)
		}
		else {
			console.log('true')
			set_dados(dados.current.info.ativo = true)
		}

	}

	return isloading ?
		<main>
			<div className={`dash-cards-2 header`}>
				<h2 className="dash-title">LOADING</h2>
				<p>LOADING</p>
				<div className="overlay">
					<div className="overlay__inner">
						<div className="overlay__content"><span className="spinner"></span></div>
					</div>
				</div>
			</div>
		</main>
		:
		isRedirect
			?
			<Redirect to={endpoints.list} replace />
			:
			<main>
				<div className={`dash-cards-2 header`}>
					<h2 className="dash-title">Colaboradores</h2>
					<p>Home / Colaborador / Inserir Colaborador</p>
				</div>

				<div className={`dash-cards-2 body`}>
					<div className="card">
						<div className="card-body">
							<form className={show_vat_form} id="form-vat" onSubmit={handleSubmitVat}>
								<div className="form-group">
									<span className="flex-1">
										{/*<input type="text" name="dados.info.vat" value={dados.info.vat} pattern="[0-9]{9}" className="input-form" placeholder="Introduza o NIF" onChange={e => set_dados({ ...dados, info: { ...dados.info, vat: e.target.value } })} required /> */}
										<input type="text" pattern={pattern_vat} minLength="9" inputMode='numeric' name="vat" value={dados.current.info.vat} className="input-form" placeholder="Introduza o NIF" onChange={handle_change_info} required />
									</span>
									<span className="flex-1">
										<input type="submit" value="Pesquisar" />
									</span>
								</div>
							</form>

							<form id="form" onSubmit={handleSubmit}>
								<input type="hidden" name="id" value={dados.current.id} />

								<h2 className="dash-title">Dados Colaborador</h2>
								<div className="form-group">
									<span className="flex-2">
										<label>Nome</label>
										<input type="text" name="name" value={dados.current.info.name} className="input-form" placeholder="Introduza o nome" onChange={handle_change_info} required />
									</span>
									<span className="flex-1">
										<label>Estado</label>
										<span className="div-checkbox-group">
											<label className="div-checkbox">
												Ativo
												<input
													name='ativo'
													className="checkbox-form"
													type="checkbox" 
													value={dados.current.info.ativo}
													onChange={() => handleSingleCheck()}
													defaultChecked={dados.current.info.ativo}
												/>
												<span className="checkmark"></span>
											</label>
										</span>
									</span>
								</div>

								<div className="form-group">
									<span className="flex-1">
										<label>NIF</label>
										<input type="text" pattern={pattern_vat} minLength="9" name="vat" disabled={true} value={dados.current.info.vat} className="input-form" placeholder="Número de Contribuinte" onChange={handle_change_info} required />
									</span>
									<span className="flex-1">
										<label>NISS</label>
										<input type="text" inputMode='numeric' pattern="^[0-9]{11}$" maxLength='11' name="niss" value={dados.current.info.niss} className="input-form" placeholder="Introduza o niss" onChange={handle_change_info} />
									</span>
									<span className="flex-1">
										<label>Escritório</label>
										<Select
											className="div-select"
											menuPortalTarget={document.body}
											menuPosition={'fixed'}
											options={offices}
											name="office"
											value={dados.current.info.office.name === '' ? null : { label: dados.current.info.office.name, value: dados.current.info.office.id }}
											placeholder={"Introduzao escritório "}
											onChange={change_office}
											styles={custom_select_styles}
										/>
									</span>
								</div>

								<div className="form-group">
									<span className="flex-1">
										<label>Tipo de Identificação</label>
										<Select
											className="div-select"
											menuPortalTarget={document.body}
											menuPosition={'fixed'}
											options={identification_types}
											name="identification_type"
											value={dados.current.info.identificacao.tipo_identificacao === '' ? null : { label: dados.current.info.identificacao.tipo_identificacao, value: dados.current.info.identificacao.tipo_identificacao }}
											placeholder={"Introduza o tipo de identificação"}
											onChange={change_identification_type}
											styles={custom_select_styles}
										/>
									</span>
									<span className="flex-1">
										<label>Número de Identificação</label>
										<input type="text" pattern="\d*" inputMode='numeric' maxLength="11" name="numero_identificacao" value={dados.current.info.identificacao.numero_identificacao} className="input-form" placeholder="Introduza o número de identificação" onChange={handle_change_info_identification} />
									</span>
									<span className="flex-1">
										<label>Validade</label>
										<input type="date" name="validade_identificacao" value={dados.current.info.identificacao.validade_identificacao} min={today} className="input-form" placeholder="Introduza a validade" onChange={handle_change_info_identification} />
									</span>
								</div>
								<div className="form-group">
									<span className="flex-1">
										<label>Data de Nascimento</label>
										<input type="date" name="data_nascimento" value={dados.current.info.data_nascimento} max={today} className="input-form" placeholder="Introduza a data de nascimento" onChange={handle_change_info} />
									</span>
									<span className="flex-1">
										<label>Estado Civil</label>
										<Select
											className="div-select"
											menuPortalTarget={document.body}
											menuPosition={'fixed'}
											options={civil_states}
											name="civil_state"
											value={dados.current.info.estado_civil === '' ? null : { label: dados.current.info.estado_civil, value: dados.current.info.estado_civil }}
											placeholder={"Introduza o estado civil"}
											onChange={change_civil_state}
											styles={custom_select_styles}
										/>
									</span>
									<span className="flex-1">
										<span className="flex-1">
											<label>Tipo Colaborador</label>
											<Select
												className="div-select"
												menuPortalTarget={document.body}
												menuPosition={'fixed'}
												options={tipo_colaborador}
												name="profile"
												value={dados.current.profile.name === '' ? null : { label: dados.current.profile.name, value: dados.current.profile.name }}
												placeholder={"Introduzao escritório "}
												onChange={change_colaborador_tipo}
												styles={custom_select_styles}
											/>
										</span>

									</span>
								</div>
								<div className="form-group">
									<span className="flex-1">
										<label>Telefone</label>

										<PhoneInput
											defaultCountry="PT"
											placeholder="Introduza o número de telefone"
											value={dados.current.contacts[0].phone !== '' ? dados.current.contacts[0].phone : null}
											onChange={e => set_dados(dados.current.contacts[0].phone = e)} />
										{/* <input type="tel" name="phone" value={dados.current.contacts[0].phone} pattern="[0-9]{9}" className="input-form" placeholder="Introduza o número de telefone" onChange={handle_change_contacts} /> */}
									</span>
									<span className="flex-1">
										<label>Telemóvel</label>
										<PhoneInput
											defaultCountry="PT"
											placeholder="Introduza o número de telemóvel"
											value={dados.current.contacts[0].mobile_phone !== '' ? dados.current.contacts[0].mobile_phone : null}
											onChange={e => set_dados(dados.current.contacts[0].mobile_phone = e)} />
										{/* <input type="tel" name="mobile_phone" value={dados.current.contacts[0].mobile_phone} pattern="[0-9]{9}" className="input-form" placeholder="Introduza o telemóvel" onChange={handle_change_contacts} /> */}
									</span>
									<span className="flex-1">
										<label>E-mail</label>
										<input type="email" pattern={pattern_email} name="email" value={dados.current.contacts[0].email} className="input-form" placeholder="Introduza o e-mail" onChange={handle_change_contacts} />
									</span>
								</div>
								<div className="form-group">
									<span className="flex-1">
										<label>País</label>
										<Select
											className="div-select"
											menuPortalTarget={document.body}
											menuPosition={'fixed'}
											options={countries}
											name="pais"
											value={dados.current.addresses[0].country === '' ? null : { label: dados.current.addresses[0].country, value: dados.current.addresses[0].country }}
											placeholder={"Introduza o paí­s"}
											onChange={change_country}
											styles={custom_select_styles}
										/>
									</span>
									<span className={`flex-1 ${show_district_city_village}`}>
										<label>Distrito</label>
										<Select
											className="div-select"
											menuPortalTarget={document.body}
											menuPosition={'fixed'}
											options={districts}
											name="distrito"
											value={dados.current.addresses[0].region === '' ? null : { label: dados.current.addresses[0].region, value: dados.current.addresses[0].region }}
											placeholder={"Introduza o distrito"}
											onChange={change_district}
											styles={custom_select_styles}
										/>
									</span>
									<span className={`flex-1 ${show_district_city_village}`}>
										<label>Concelho</label>
										<Select
											className="div-select"
											menuPortalTarget={document.body}
											menuPosition={'fixed'}
											options={cities}
											name="concelho"
											value={dados.current.addresses[0].city === '' ? null : { label: dados.current.addresses[0].city, value: dados.current.addresses[0].city }}
											placeholder={"Introduza o concelho"}
											onChange={change_city}
											styles={custom_select_styles}
										/>
									</span>
								</div>
								<div className="form-group">
									<span className={`flex-1 ${show_district_city_village}`}>
										<label>Freguesia</label>
										<Select
											className="div-select"
											menuPortalTarget={document.body}
											menuPosition={'fixed'}
											options={villages}
											name="freguesia"
											value={dados.current.addresses[0].village === '' ? null : { label: dados.current.addresses[0].village, value: dados.current.addresses[0].village }}
											placeholder={"Introduza a Freguesia"}
											onChange={change_village}
											styles={custom_select_styles}
										/>
									</span>
									<span className={`flex-1 `}>
										<label>Localidade</label>
										<input type="text" name="localidade" value={dados.current.addresses[0].localidade} className="input-form" placeholder="Introduza a Localidade" onChange={handle_change_addresses} required />
									</span>
									<span className="flex-1">
										<label>Código-postal</label>
										<input type="text" name="postal_code" value={dados.current.addresses[0].postal_code} className="input-form" placeholder="Introduza o código-postal" onChange={handle_change_addresses} required />
									</span>
									<span className="flex-1">

									</span>
								</div>
								<div className="form-group">
									<span className="flex-2">
										<label>Morada</label>
										<textarea name="address_detail" value={dados.current.addresses[0].address_detail} className="input-form" rows="4" onChange={handle_change_addresses} required>
										</textarea>
									</span>
									<span className="flex-1"></span>
								</div>

								<h2 className="dash-title">Dados Login</h2>
								<div className="form-group">
									<span className="flex-1">
										<label>Username</label>
										<input type="text" name="username" value={dados.current.info.username} pattern="[A-Za-z0-9.]+" className="input-form" placeholder="Introduza o username" onChange={handle_change_info} />
									</span>
									<span className="flex-1">
										<label>Password</label>
										<input type="password" name="password" min="9" className="input-form" placeholder="Introduza a password" onChange={handle_change_info} />
									</span>
									<span className="flex-1">
										<label>Confirmacao da Password</label>
										<input type="password" name="password_conf" min="9" className="input-form" placeholder="Introduza a confirmação da password" onBlur={handle_change_password} />
									</span>
								</div>

							</form>
						</div>
					</div>
				</div>

				<div className={'dash-cards-2 footer'}>
					<nav className="navbar bottom-navbar navbar-light">
						<div className="navbar-footer">
							<ul className="navbar-nav float-left"></ul>
							<ul className="navbar-nav float-right">
								<li className="nav-item">
									<Link className="nav-link btn-cancel" to={{ pathname: endpoints.list }}  >

										<img alt='' src={'./img/cancelar.svg'} />
										<span>Cancelar</span>
									</Link>
								</li>

								<li className={"nav-item " + show_delete_button}>
									<button onClick={apagar} className="nav-link btn-delete">

										<img alt='' src={'./img/eliminar_b.svg'} />
										<span>Eliminar</span>
									</button>
								</li>
								<li className="nav-item">
									<button disabled={disabled ? true : false} type="submit" form="form" className="nav-link">

										<img alt='' src={'./img/save.svg'} />
										<span>Salvar</span>
									</button>
								</li>
							</ul>
						</div>
					</nav>
				</div>
			</main >
}
