import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Redirect, Link } from 'react-router-dom';

import DataTable from 'react-data-table-component';

import jsPDF from 'jspdf'
import 'jspdf-autotable'


import { reqGET } from '../../../Utils';
import { api_crud_base, data_interface, endpoints } from './Config';

import { getTokenInfo } from '../../../UseToken';

export default function View(props) {

    const [dados, set_dados] = useState(data_interface);

	let newDate = new Date();
    let lastWeek = new Date();
    lastWeek.setDate(lastWeek.getDate() - 7);
	let today = newDate.getFullYear() + '-' + (newDate.getMonth() + 1).toString().padStart(2, "0") + '-' + newDate.getDate().toString().padStart(2, "0");
	let last_week = lastWeek.getFullYear() + '-' + (lastWeek.getMonth() + 1).toString().padStart(2, "0") + '-' + lastWeek.getDate().toString().padStart(2, "0");

    const [isloading, setLoading] = useState(true);
    const [isRedirect, setRedirect] = useState(false);
    const [refresh, setRefresh] = useState(0);

    const [show_table, set_show_table] = useState('d-none');

	const [credit_types, set_credit_types] = useState([])
	const [financials, set_financials] = useState([])
	const [offices, set_offices] = useState([])
	const [responsables, set_responsables] = useState([])
	const [promotores, set_promotores] = useState([])

	const [data_inicio, set_data_inicio] = useState(last_week)
	const [data_fim, set_data_fim] = useState(today)

    //const [total, set_total] = useState(0.0);
    const [data_creditos, set_credits] = useState([]);
    const [data_creditos_2, set_credits_2] = useState([]);
    const [columns_creditos, set_column_credits] = useState([]);


    const change_data_inicio = (e) => {
        set_data_inicio(e.target.value)
    }
    const change_data_fim = (e) => {
        set_data_fim(e.target.value)
    }


    // const columns_creditos = [
    //     {
    //         name: <div className="table-header">Proposta</div>,
    //         sortable: true,
    //         maxWidth: '100px',
    //         selector: 'proposta',
    //         left: true,
    //     },
    //     {
    //         name: <div className="table-header">Escritório</div>,
    //         selector: 'escritorio',
    //         sortable: true,
    //         left: true,
    //     },
    //     {
    //         name: <div className="table-header">Responsável</div>,
    //         selector: 'colaborador',
    //         sortable: true,
    //         left: true,
    //     },
    //     {
    //         name: <div className="table-header">Promotor</div>,
    //         selector: 'promotor',
    //         sortable: true,
    //         left: true,
    //     },
    //     {
    //         name: <div className="table-header">Tipo de Crédito</div>,
    //         selector: 'tipo_credito',
    //         sortable: true,
    //         left: true,
    //     },
    //     {
    //         name: <div className="table-header">Valor Financiado</div>,
    //         cell: row => <div>{row.valor_financiado}€</div>,
    //         maxWidth: '150px',
    //         sortable: true,
    //         left: true,
    //     },
    //     {
    //         name: <div className="table-header">Estado</div>,
    //         cell: row => 'Saber mais +',
    //         maxWidth: '150px',
    //         left: true,
    //     },
    // ];
    
    //var columns_creditos = [];
    const ExpandableComponent = ({ data }) =>
        <div className="div-expand">
            <div dangerouslySetInnerHTML={{ __html: data.timeline }} />
        </div>;

    const defaultComponentOptions = {
        rowsPerPageText: 'Registos por página:',
        rangeSeparatorText: 'de',
        noRowsPerPage: false,
        selectAllRowsItem: false,
        selectAllRowsItemText: 'Todos',
        responsive: true,
    };

    // const [hidePessoal, set_hide_pessoal] = useState('')
    // const [hideEmpresarial, set_hide_empresarial] = useState('')

    const change_opt1 = (e) => {
        console.log(e)

        if(e.value === 'Escritorio') {
            reqGET(`${api_crud_base}/entities/list/${getTokenInfo().companycode}/Escritorio`)
                .then(res => {
                    console.log(res.data)
                })
        }

        // `${Hosts.SIMCore_API}/entities/list/${companycode}/${object_type}`
    }

    const change_opt2 = (e) => {

    }


    const load_credit_types = async () => {
		reqGET(`${api_crud_base}/credits/types/${getTokenInfo().companycode}`)
			.then(res => {
				//console.log(res.data)
				set_credit_types(...[res.data.map(k => ({ value: k['id'], label: k.info['name'], etapa: k.info['etapa'] }))])
				//console.log(credit_types)
			})
	}

    const load_financials_0 = async () => {

		return await reqGET(`${api_crud_base}/financials/${getTokenInfo().companycode}`)
			.then(res => {
				//console.log(res.data)
				set_financials(...[res.data.map(k => ({ value: k['id'], label: k.info['name'] }))])
				//return res.data.map(k => ({ value: k['id'], label: k['financials'] }))

				console.log(financials)
			})
		//return tmp
	}

	const load_financials = async (tipo) => {

		return await reqGET(`${api_crud_base}/financials/list/special/${getTokenInfo().companycode}/${tipo}`)
			.then(res => {
				//console.log(res.data)
				//set_financials(...[res.data.map(k => ({ value: k['id'], label: k['financials'] }))])
				return res.data.map(k => ({ value: k['id'], label: k['financials'] }))

				//console.log(financials)
			})
		//return tmp
	}

    const load_offices = async () => {
        reqGET(`${api_crud_base}/entities/list/${getTokenInfo().companycode}/Escritorio`)
			.then(res => {
				//console.log(res.data)
				let tmp = res.data.map(k => ({ value: k['id'], label: k['name'], ativo: k['ativo'] }))
				set_offices(...[tmp.filter(k=> k.ativo === 'true')])//console.log(offices)
			})
	}

	const load_reponsables = async (office) => {
		reqGET(`${api_crud_base}/entities/list/special/${getTokenInfo().companycode}/${office}/Colaborador`)
			.then(res => {
				//console.log(res.data)

                let responsables_aux = []

                res.data.forEach(v => {
					responsables_aux.push({ value: v['id'], label: v.info['name'] })
				})

                reqGET(`${api_crud_base}/entities/list/special/${getTokenInfo().companycode}/${office}/Gestor de Escritório`)
                    .then(res2 => {
                        //console.log(res.data)

                        res2.data.forEach(v => {
                            responsables_aux.push({ value: v['id'], label: v.info['name'] })
                        })

                        //set_responsables(...[res2.data.map(k2 => ({ value: k2['id'], label: k2.info['name'] }))])
                        //console.log(responsables)


                        set_responsables(responsables_aux)
                        
                    }
                    )

				//set_responsables(...[res.data.map(k => ({ value: k['id'], label: k.info['name'] }))])
				//console.log(responsables)
			})

            
	}

	const load_promotores = async (office) => {
		reqGET(`${api_crud_base}/entities/list/special/${getTokenInfo().companycode}/${office}/Promotor`)
			.then(res => {
				//console.log(res.data)
				set_promotores(...[res.data.map(k => ({ value: k['id'], label: k.info['name'] }))])
				//console.log(promtores)
			}
			)
	}

    const change_credit_type = e => {

        set_financials([])

        if(e!== null && typeof e !== 'undefined' && typeof e !== 'null') {
            const { label } = e;

            load_financials(label).then(financial_list => {
                //console.log(financial_list)
                if (financial_list.length === 0) {
                }
                else {
                    set_financials(financial_list)

                }
            })
        }
		
	}

	const change_financials = e => {
		//const { label, value } = e;

	}

	const change_office = e => {

        set_responsables([])
	    set_promotores([])

        if(e!== null && typeof e !== 'undefined' && typeof e !== 'null') {
            const { label } = e;

		    load_reponsables(label)
		    load_promotores(label)
        }

		//console.log(e)

	}

	const change_responsable = e => {
		//const { label, value } = e;

		//console.log(e)


	}

	const change_promotor = e => {
		//const { label, value } = e;

		//console.log(e)
	}

    // Para Instanciar todo o Objecto 
    const fetchInfo = async () => {
        await reqGET(`${api_crud_base}/maps/types/${getTokenInfo().companycode}`)
            .then(res => {
                set_dados(res.data[0]);
            })
            .catch(() => 404
                //  TODO: ENVIAR PÃ�GINA 404
                //  alert(error)); 
            );
    }

    const load_credits = async (type, id) => {
        //setLoading(true)

        if(type === '') {
            await reqGET(`${api_crud_base}/credits/credits/${getTokenInfo().companycode}`)
                .then(res => {
                    // console.log(res.data)
                    set_credits(...[
                        res.data.map(k => {

                            let data_fim = ''
                            let aux = k.info.workflow.slice(-1)[0].estado
                            aux.forEach((v) => {
                                if(v['completo'] === true && v['selected'] === true)
                                    data_fim = k.info.workflow.slice(-1)[0].deadline

                            })

                            return {
                                proposta: k['id'],
                                cliente: k.info['Cliente']['name'],
                                escritorio: k.info.Escritorio['name'],
                                escritorio_id: k.info.Escritorio['id'],
                                colaborador: k.info.Colaborador['name'],
                                colaborador_id: k.info.Colaborador['id'],
                                promotor: k.info.Promotor['name'],
                                promotor_id: k.info.Promotor['id'],
                                financials: k.info.financials['name'],
                                financials_id: k.info.financials['id'],
                                tipo_credito: k.info.tipo_credito['name'],
                                tipo_credito_id: k.info.tipo_credito['id'],
                                valor_financiado: k.commissioning['valor_financiado'],
                                data_fim: data_fim
                            }

                        })
                    ])
                    setRefresh(refresh + 1)
                    //console.log(credits)
                })
        }
        else {
            await reqGET(`${api_crud_base}/credits/credits/special/credits/${getTokenInfo().companycode}/${type}/${id}`)
                .then(res => {
                    //console.log(res.data)
                    set_credits(...[
                        res.data.map(k => {     
                       
                            let data_fim = ''
                            let aux = k.info.workflow.slice(-1)[0].estado
                            aux.forEach((v) => {
                                if(v['completo'] === true && v['selected'] === true)
                                    data_fim = k.info.workflow.slice(-1)[0].deadline

                            })

                            return {
                                proposta: k['id'],
                                cliente: k.info['Cliente']['name'],
                                escritorio: k.info.Escritorio['name'],
                                escritorio_id: k.info.Escritorio['id'],
                                colaborador: k.info.Colaborador['name'],
                                colaborador_id: k.info.Colaborador['id'],
                                promotor: k.info.Promotor['name'],
                                promotor_id: k.info.Promotor['id'],
                                financials: k.info.financials['name'],
                                financials_id: k.info.financials['id'],
                                tipo_credito: k.info.tipo_credito['name'],
                                tipo_credito_id: k.info.tipo_credito['id'],
                                valor_financiado: k.commissioning['valor_financiado'],
                                data_fim: data_fim
                            }

                        })
                    ])
                    setRefresh(refresh + 1)
                    //console.log(credits)
                })
        }
    }

    const check_credits = async (type, id) => {
        var data_creditos_aux = data_creditos

        if(typeof id !== 'undefined' && id !== '') {
            if(data_creditos_aux) {
                data_creditos_aux.forEach((v, k) => {
                    if(parseInt(v[`${type}_id`]) === parseInt(id)){
                        // console.log('continuar')
                        // console.log(type)
                    }
                    else {
                        // console.log('retirar')
                        // console.log(type)
                        data_creditos_aux.splice(k, 1);
                        set_credits_2(data_creditos_aux)
                        setRefresh(refresh + 1)

                        check_credits(type, id)
                    }
                })
            }
        }
    }

    const check_date = async () => {

        var data_creditos_aux = data_creditos
        
        console.log('data_inicio', data_inicio)
        console.log('data_fim', data_fim)


        if(data_creditos_aux) {
            data_creditos_aux.forEach((v, k) => {

                if(v.data_fim !== '' && v.data_fim >= data_inicio && v.data_fim <= data_fim) {
                    console.log(v)
                }
                else {
                    data_creditos_aux.splice(k, 1);
                    set_credits_2(data_creditos_aux)
                    setRefresh(refresh + 1)

                }
            })
        }

    }

	const handleSubmit = async e => {
		e.preventDefault();

        set_show_table('d-none')

        const {tipo_credito, Escritorio, Colaborador, Promotor, Financials} = Object.fromEntries(new FormData(e.target))
        //console.log(Object.fromEntries(new FormData(e.target)))

        var columns_creditos_aux = [];

        console.log('tipo_credito', tipo_credito)
        console.log('Escritorio', Escritorio)
        console.log('financials', Financials)
        console.log('Colaborador', Colaborador)
        console.log('Promotor', Promotor)

        set_credits_2(data_creditos)
        await load_credits('', '')                
            .then(() => {

                check_date()

                check_credits('tipo_credito', tipo_credito)
                check_credits('financials', Financials)
                check_credits('escritorio', Escritorio)
                check_credits('colaborador', Colaborador)
                check_credits('promotor', Promotor)

                set_show_table('')
            })

        columns_creditos_aux.push({
            name: <div className="table-header">Proposta</div>,
            sortable: true,
            maxWidth: '100px',
            selector: 'proposta',
            left: true,
        });

        columns_creditos_aux.push({
            name: <div className="table-header">Nome</div>,
            selector: 'cliente',
            sortable: true,
            left: true,
        })
        columns_creditos_aux.push({
            name: <div className="table-header">Tipo de Crédito</div>,
            selector: 'tipo_credito',
            sortable: true,
            left: true,
        })
        columns_creditos_aux.push({
            name: <div className="table-header">Financeira</div>,
            selector: 'financials',
            sortable: true,
            left: true,
        })

        columns_creditos_aux.push({
            name: <div className="table-header">Valor Financiado</div>,
            cell: row => <div>{parseFloat(row.valor_financiado).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}€</div>,
            maxWidth: '150px',
            sortable: true,
            left: true,
        })

        set_column_credits(columns_creditos_aux);
        setRefresh(refresh + 1)
	}

    useEffect(() => {

        set_credits([])

        load_credit_types()
		load_offices()
        load_financials_0()

        //fetchInfo();
        
        setLoading(false);
        setRedirect(false);

    }, [props]);

    const custom_select_styles = {
		container: base => ({
			...base,
			width: '100%',
		}),
		control: base => ({
			...base,
			borderRadius: '10px',

			padding: '2px 10px',
			fontSize: '14px'
		})
	}

    function toPDF() {
        var data_creditos_2_aux = []
        var total = 0.0
        var i = 0;

        data_creditos_2.forEach((v, k) => {
            total = parseFloat(total) + parseFloat(v.valor_financiado)

            data_creditos_2_aux.push(v)
    
            //console.log('0 :: ' + parseFloat(data_creditos_2_aux[i].valor_financiado))
            //console.log('1 :: ' + parseFloat(data_creditos_2_aux[i].valor_financiado).toFixed(2))
            //console.log('2 :: ' + parseFloat(data_creditos_2_aux[i].valor_financiado).toFixed(2).replace('.', ','))
            //console.log('3 :: ' + parseFloat(data_creditos_2_aux[i].valor_financiado).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'))
            //console.log('4 :: ' + parseFloat(data_creditos_2_aux[i].valor_financiado).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.').toString())

            if(!data_creditos_2_aux[k].valor_financiado.includes('€')) {
                var aux = parseFloat(data_creditos_2_aux[k].valor_financiado).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.').toString()
                data_creditos_2_aux[k].valor_financiado = aux + '€'
            }
            //setRefresh(refresh + 1)
        })
 
        const doc = new jsPDF('l','pt','a4');
        doc.autoTable({
            theme: 'plain',
            styles: {
                fontSize: 8
            },
            columnStyles: { valor_financiado: { halign: 'right' } }, 
            //headStyles: { backgroundColor: 'red' },
            headStyles: {
                fontSize: 10,
                fillColor: "#FFFFFF",
                textColor: '#000000',
                //lineWidth: 0.1,
                //lineColor: '#c4c4c4',
            },
            footStyles: {
                fontSize: 10,
                fillColor: "#FFFFFF",
                textColor: '#000000',
                //lineWidth: {top: 0, right: 0, bottom: 0.1, left: 0},
                //lineColor: "#c4c4c4",
                halign: 'right'
            },
            //head: [
            //    ['Escritório', 'Colaborador Responsável', 'Promotor', 'Financeira', 'Tipo de Crédito', 'Valor Financiado']
            //],
            margin: { top: 70 },
            body: data_creditos_2_aux,
            foot: [
                [
                    { content: data_creditos_2_aux.length + (data_creditos_2_aux.length === 1 ? ' registo' : ' registos'), styles: { lineWidth: {top: 0.1, right: 0, bottom: 0, left: 0}, lineColor: "#000000"} },
                    { content: '', colSpan: 4 }, 
                    { content: total.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + '€', styles: { lineWidth: {top: 0.1, right: 0, bottom: 0, left: 0}, lineColor: "#000000"} }
                ],
                [
                    { content: '', colSpan: 6, styles: { lineWidth: {top: 0.1, right: 0, bottom: 0, left: 0}, lineColor: "#000000"} },
                ]
            ],
            showFoot: 'lastPage',
            columns: [
                { header: 'Cliente', dataKey: 'cliente', },
                { header: 'Responsável', dataKey: 'colaborador' },
                { header: 'Tipo de Crédito', dataKey: 'tipo_credito' },
                { header: 'Financeira', dataKey: 'financials' },
                { header: 'Valor Financiado', dataKey: 'valor_financiado' },
            ],
        })

        // Optional - set properties on the document
        doc.setProperties({
            title: 'Mapa XPTO',
            subject: 'This is the subject',
            author: 'Assec SIM!',
            keywords: 'generated, javascript, web 2.0, ajax',
            creator: 'MEEE'
        });

        var pageCount = doc.internal.getNumberOfPages();
        for(i = 1; i <= pageCount; i++) {
            doc.setPage(i);

            // Top Page
            doc.setFont('helvetica', 'normal')
            doc.setFontSize(16)
            doc.text('FASQUIA IMPORTANTE', 41, 30, { align: 'left' })
            doc.text('Contribuinte N.º 502 222 352', doc.internal.pageSize.width / 2, 30, { align: 'center' })
            // Second Line
                doc.setFont('helvetica', 'normal')
                doc.setFontSize(8)
                doc.text('Mapa de Faturação Global', doc.internal.pageSize.width / 2, 45, { align: 'center' })
            // Third Line
                doc.text('Data: Início ' + data_inicio + ' Fim ' + data_fim, 41, 60, { align: 'left' })

            // Bottom Page
            doc.setFont('helvetica', 'normal')
            doc.setFontSize(8)
            doc.text('Fasquia Importante - Sim!Credit (c) 2022 | Lic. 0001 ', 41, doc.internal.pageSize.height - 30, { align: 'left' })
            doc.text('Página ' + String(i) + ' de ' + String(pageCount), doc.internal.pageSize.width - 41, doc.internal.pageSize.height - 30, { align: 'right' })


            // Border Table Header
            doc.setDrawColor(0, 0, 0);
            doc.line(41, 70, doc.internal.pageSize.width - 41, 70); // horizontal line
            doc.line(41, 90, doc.internal.pageSize.width - 41, 90); // horizontal line
            doc.line(41, 70, 41, 90); // vertical line
            doc.line(doc.internal.pageSize.width - 41, 70, doc.internal.pageSize.width - 41, 90); // vertical line
        }

        doc.save('table.pdf')
    }

    return isRedirect ?
        <Redirect to={endpoints.list} replace />
        :
        isloading ?
            <main>
                <div className={`dash-cards-2 header`}>
                    <h2 className="dash-title">LOADING</h2>
                    <p>LOADING</p>
                    <div className="overlay">
                        <div className="overlay__inner">
                            <div className="overlay__content"><span className="spinner"></span></div>
                        </div>
                    </div>
                </div>
            </main>
            :
            <main>
                <div className={`dash-cards-2 header`}>
                    <h2 className="dash-title">Mapas</h2>
                    <p>Home / Mapas </p>
                </div>

                <div className={`dash-cards-2 body`}>
                    <form  id="form" onSubmit={handleSubmit}>
                        <div className="card">
                            <div className="card-body">

                                <div className="div-group">
                                    <div className="form-group div-search">
                                        <span className="flex-1">
                                            {/*<label>Escritório</label>*/}
                                            <Select
                                                isClearable
                                                className="div-select"
                                                options={offices}
                                                name="Escritorio"
                                                placeholder="Escritório"
                                                onChange={change_office}
                                                styles={custom_select_styles}
                                            />
                                        </span>

                                        <span className="flex-1">
                                            {/*<label>Entidade Financeira</label>*/}
                                            <Select
                                                isClearable
                                                className="div-select"
                                                options={financials}
                                                name="Financials"
                                                placeholder={"Entidade Financeira"}
                                                onChange={change_financials}
                                                styles={custom_select_styles}
                                            />
                                        </span>

                                        <span className="flex-1">
                                        {/*<label>Tipo de Crédito:</label>*/}
                                        <Select
                                                isClearable
                                                className="div-select"
                                                options={credit_types}
                                                name="tipo_credito"
                                                placeholder="Tipo de crédito"
                                                onChange={change_credit_type}
                                                styles={custom_select_styles}
                                            />
                                        </span>

                                        <span className="flex-1">
                                            {/*<label>Colaborador Responsável:</label>*/}
                                            <Select
                                                isClearable
                                                className="div-select"
                                                options={responsables}
                                                name="Colaborador"
                                                placeholder="Colaborador responsável"
                                                onChange={change_responsable}
                                                styles={custom_select_styles}
                                            />
                                        </span>

                                        <span className="flex-1">
                                            {/*<label>Promotor:</label>*/}
                                            <Select
                                                isClearable
                                                className="div-select"
                                                options={promotores}
                                                name="Promotor"
                                                placeholder="Promotor"
                                                onChange={change_promotor}
                                                styles={custom_select_styles}
                                            />
                                        </span>
                                    </div>
                                </div>

                                <div className="div-group">
                                    {
                                    dados.info.mapa.map((v, k) => {
                                        return (
                                            <span key={k} className="flex-1">
                                                    <h2 className='dash-title d-none'>{v.name}</h2>

                                                    <div className="div-break"></div>
                                                    {
                                                    v.filtro.map((v_1, k_1) => { 
                                                        if(v_1.mapeamento === 'tipo_credito') {
                                                            return(
                                                                <div key={k_1} className="form-group div-search">
                                                                    <span className="flex-1">
                                                                        {/*<label>Tipo de Crédito:</label>*/}
                                                                        <Select
                                                                            isClearable
                                                                            className="div-select"
                                                                            options={credit_types}
                                                                            name="tipo_credito"
                                                                            placeholder="Tipo de crédito"
                                                                            onChange={change_credit_type}
                                                                            styles={custom_select_styles}
                                                                        />
                                                                    </span>
                                                                </div>
                                                            )
                                                        }
                                                        else if(v_1.mapeamento === 'financials') {
                                                            return (
                                                                <div key={k_1} className="form-group div-search">
                                                                    <span className="flex-1">
                                                                        {/*<label>Entidade Financeira</label>*/}
                                                                        <Select
                                                                            isClearable
                                                                            className="div-select"
                                                                            options={financials}
                                                                            name="financials"
                                                                            placeholder={"Entidade Financeira"}
                                                                            onChange={change_financials}
                                                                            styles={custom_select_styles}
                                                                        />
                                                                    </span>
                                                                </div>
                                                            )
                                                        }
                                                        else if(v_1.mapeamento === 'Escritorio') {
                                                            return (
                                                                <div key={k_1} className="form-group div-search">
                                                                    <span className="flex-1">
                                                                        {/*<label>Escritório:</label>*/}
                                                                        <Select
                                                                            isClearable
                                                                            className="div-select"
                                                                            options={offices}
                                                                            name="Escritorio"
                                                                            placeholder="Escritório"
                                                                            onChange={change_office}
                                                                            styles={custom_select_styles}
                                                                        />
                                                                    </span>
                                                                </div>
                                                            )
                                                        }
                                                        else if(v_1.mapeamento === 'Colaborador') {
                                                            return (
                                                                <div key={k_1} className="form-group div-search">
                                                                    <span className="flex-1">
                                                                    {/*<label>Colaborador Responsável:</label>*/}
                                                                    <Select
                                                                        isClearable
                                                                        className="div-select"
                                                                        options={responsables}
                                                                        name="Colaborador"
                                                                        placeholder="Colaborador responsável"
                                                                        onChange={change_responsable}
                                                                        styles={custom_select_styles}
                                                                    />
                                                                    </span>
                                                                </div>
                                                            )
                                                        }
                                                        else if(v_1.mapeamento === 'Promotor') {
                                                            return (
                                                                <div key={k_1} className="form-group div-search">
                                                                    <span className="flex-1">
                                                                    {/*<label>Promotor:</label>*/}
                                                                    <Select
                                                                        isClearable
                                                                        className="div-select"
                                                                        options={promotores}
                                                                        name="Promotor"
                                                                        placeholder="Promotor"
                                                                        onChange={change_promotor}
                                                                        styles={custom_select_styles}
                                                                    />
                                                                    </span>
                                                                </div>
                                                            )
                                                        }
                                                        else
                                                            return ""
                                                    })
                                                    }
                                                    <div className="div-break"></div>

                                                    <div className="form-group div-search">
                                                        <span className="flex-5">   
                                                        </span>

                                                        <span className="flex-2">
                                                            <input type="date" name="inicio" value={data_inicio !== '' ? data_inicio : last_week} onChange={change_data_inicio} />
                                                        </span>
                                                        <span className="flex-2">
                                                            <input type="date" name="fim" value={data_fim !== '' ? data_fim : today} onChange={change_data_fim} />
                                                        </span>
                                                        <span className="flex-1">
                                                            <input type="submit" value="Pesquisar" className="nav-link btn-map-search" />
                                                        </span>

                                                    </div>
                                            </span>
                                        )
                                    })
                                    }
                                </div>

                                <div className="div-break"></div>

                                <div className="div-table expand flex-1">
                                    <div className={"table-body " + show_table}>
                                        <button type="button" className={"download pdf " + (data_creditos_2.length > 0 ? '' : 'd-none')} onClick={toPDF}></button>

                                        <DataTable
                                            noDataComponent='Sem Registos'
                                            columns={columns_creditos}
                                            data={data_creditos_2}
                                            noHeader
                                            subHeader
                                            pagination
                                            paginationComponentOptions={defaultComponentOptions}
                                            paginationPerPage={25}
                                            paginationRowsPerPageOptions={[25, 50, 100]}
                                            expandableRows
                                            expandOnRowClicked
                                            expandableRowsComponent={< ExpandableComponent />}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>

                <div className={'dash-cards-2 footer'}>
                    <nav className="navbar bottom-navbar navbar-light">
                        <div className="navbar-footer">
                            <ul className="navbar-nav float-left"></ul>
                            <ul className="navbar-nav float-right">
                                <li className="nav-item">
                                    <Link className="nav-link btn-cancel" to={{ pathname: endpoints.list }} replace>
                                        <img alt='' src={'./img/seta_2.svg'} />
                                        <span>Voltar</span>
                                    </Link>
                                </li>

                            </ul>
                        </div>
                    </nav>
                </div>
            </main>
}