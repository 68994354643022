import React, { useState, useEffect } from "react";
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

import Hosts from '../../Hosts';


import { reqGET } from '../../Utils';

import { getTokenInfo } from './../../UseToken';

export default function ContactList() {
    const [dados, set_dados] = useState([]);
    const [isloading, setLoading] = useState(true);

    const columns = [
        {
            name: <div className="table-header">Nome</div>,
            selector: 'name',
            cell: row => <div>{row.name}</div>,
            width: '250px',
            sortable: true,
            left: true,
        },
        {
            name: <div className="table-header">Perfil</div>,
            selector: 'profile',
            width: '170px',
            sortable: true,
            left: true,
        },
        {
            name: <div className="table-header">Escritório</div>,
            selector: 'office',
            sortable: true,
            left: true,
        },
        {
            name: <div className="table-header">NIF</div>,
            selector: 'vat',
            sortable: true,
            left: true,
        },

        {
            name: <div className="table-header">Telemóvel</div>,
            selector: 'mobile_phone',
            sortable: true,
            left: true,
        },

        {
            name: <div className="table-header">Telefone</div>,
            selector: 'phone',
            sortable: true,
            left: true,
        },
        {
            name: <div className="table-header">E-mail</div>,
            selector: 'email',
            sortable: true,
            left: true,
        }
    ];

    const defaultComponentOptions = {
        rowsPerPageText: 'Registos por página:',
        rangeSeparatorText: 'de',
        noRowsPerPage: false,
        selectAllRowsItem: false,
        selectAllRowsItemText: 'Todos',
    };



    const fetchInfo = async () => {

        //return await reqGET(`${Hosts.SIMCore_API}/entities/list/*`)
        //console.log(getTokenInfo().companycode)
        return await reqGET(`${Hosts.SIMCore_API}/entities/list/${getTokenInfo().companycode}/*`)
            .then(res => {
                //console.log(res.data)
                let tmp = res.data

                let tmp_office = getTokenInfo().office.name
                let gt_profile = getTokenInfo().profile

                /*
                if (gt_profile === 'Administrador')
                    set_dados(tmp);

                else if (gt_profile === 'Master' || gt_profile === 'Colaborador' || gt_profile === 'Gestor de Escritório') {
                    //tmp = res.data.filter(v => v.office.name === tmp_office)
                    tmp = res.data.filter(v => v.office === tmp_office && v.profile !== 'Administrador' && v.profile !== '')
                    set_dados(tmp);
                }
                else {
                    tmp = res.data.filter(v => v.office === tmp_office && v.profile !== 'Administrador' && v.profile !== 'Cliente' && v.profile !== '')
                    set_dados(tmp);
                }
                */
                set_dados(tmp);


            })
            .catch(error => error/*TODO: GO TO FAIL FETCH*/)
    }



    useEffect(() => {

        //console.log('AQUI')
        fetchInfo()
            .then(() => setLoading(false))
            .catch(() => 404)/*TODO: GO TO FAIL FETCH*/




    }, []);






    const tableData = {
        columns,
        data: dados,
        filterPlaceholder: 'Procurar'
    };

    return isloading ?
        <main>
            <div className={`dash-cards-2 header`}>
                <h2 className="dash-title">LOADING</h2>
                <p>LOADING</p>
                <div className="overlay">
                    <div className="overlay__inner">
                        <div className="overlay__content"><span className="spinner"></span></div>
                    </div>
                </div>
            </div>
        </main>
        :
        <main>
            <div className={`dash-cards-2 header`}>
                <h2 className="dash-title">Contactos</h2>
                <p>Home / Contactos / Lista de Contactos</p>
            </div>

            <div className={`dash-cards-2 body`}>
                <div className="card">
                    <div className="div-table">
                        <div className="table-body">
                            <DataTableExtensions {...tableData}>

                                <DataTable
                                    noDataComponent='Sem Registos'

                                    noHeader
                                    pagination
                                    paginationComponentOptions={defaultComponentOptions}
                                    paginationPerPage={25}
                                    paginationRowsPerPageOptions={[25, 50, 100]}
                                />
                            </DataTableExtensions>
                        </div>
                    </div>
                </div>
            </div>
        </main>

}