
import { reqGET } from "../src/Utils";
import { api_list_route } from '../src/components/WEBContent/PerfisMenus/Config';

import SidebarData from "./components/Sidebar/SideBarData";




import Financeira from './components/Financeira/Init'

import Cliente from './components/Entities/Cliente/Init'
import Colaborador from './components/Entities/Colaborador/Init'
import Promotor from './components/Entities/Promotor/Init'
import Escritorio from './components/Entities/Escritorio/Init'
import Master from './components/Entities/Master/Init'

import Mapas_Creditos from './components/Mapas/Mapas/Credits/Init'
import Mapas_Comissoes from './components/Mapas/Mapas/Comissions/Init'
import Mapas_Clientes from './components/Mapas/Mapas/Clients/Init'
import Mapas_Pagamento from './components/Mapas/Mapas/Payment/Init'

import Credit_Commission from './components/Credits/Commissions/Init'

import Credits from './components/Credits/Credits/Init'
import Credit_types from './components/Credits/Credit_types/Init'
import Credit_config from './components/Credits/Credit_config/Init'

import Main from './components/Main/Init'

import Perfil from './components/WEBContent/PerfisMenus/Init'

import Support_ADM from './components/WEBContent/Support/Init'

/* 
import Corporate from './components/Corporate/Corporate'

import Support from './components/Support/Support';

import ContactList from './components/Contact/ContactList';
 */

import { getTokenInfo } from './UseToken';
import Perfis_Menus from './components/WEBContent/OLDPerfis_Menus/Init'
export default function UseSidebar() {






  const getSidebar = async (profile, isEdit = false) => {

    console.log('------> profile:',profile)
    let sidebar_init = SidebarData({

      rotas: {
        Financeira: Financeira

        , Cliente: Cliente
        , Colaborador: Colaborador
        , Promotor: Promotor
        , Escritorio: Escritorio
        , Master: Master

        , Credits: Credits
        , Credit_types: Credit_types
        , Credit_config: Credit_config
        , Mapas_Creditos: Mapas_Creditos  //
        , Mapas_Comissoes: Mapas_Comissoes  //
        , Mapas_Clientes: Mapas_Clientes //
        , Mapas_Pagamento: Mapas_Pagamento 
        , Credit_Commission: Credit_Commission //
        , Perfil: Perfil
        , Support_ADM: Support_ADM
        , Perfis_Menus: Perfis_Menus
        , Main: Main

      }

    })

    let companycodeLS = getTokenInfo().companycode
    console.log(companycodeLS)

    if (companycodeLS === null || profile === null) {

      return sidebar_init
    }

    else {
      let profile = getTokenInfo().profile
      console.log('-------------->PROFILES_LIST',profile)
      try{
      let result = await reqGET(api_list_route(companycodeLS))
       
      .then(res => {
          console.log('-------------->PROFILES_LIST',res)
          let profiles_list = res.data.filter(item => item.info.name === profile)
           console.log('PROFILES_LIST', profiles_list)

          // VERIFICAR SE o QUE SE ENCONTRA EM BASE DE DADOS ESTÁ Consistente com NOVOS MENUS em ficheiro SideBarData.js

          if (isEdit === true) {
            //console.log('ISEDIT É ', isEdit)
            return profiles_list[0].info.menu.length === sidebar_init.length ? profiles_list[0].info.menu : sidebar_init


          } else {
            // console.log('ISEDIT É ', isEdit)

            return profiles_list.length > 0 ? profiles_list[0].info.menu : sidebar_init

          }

        }).catch(error => { console.log(error)})

        
        return result
      }catch (error) {
        console.log(error)
       // return sidebar_init
}
      
    }
  }

  return {
    getSidebar,
  }
}