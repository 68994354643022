import React, { useState, useEffect } from "react";
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { Link } from 'react-router-dom';

import { api_crud_route, api_list_route, endpoints } from './Config';

import { reqGET, reqDELETE } from '../../../Utils';
import { getTokenInfo } from './../../../UseToken';
export default function List() {



    const [data, setData] = useState([]);
    const [isloading, setLoading] = useState(true);

    useEffect(() => {

        //console.log('AQUI NA LISTA')

        //console.log(props)
        reqGET(api_list_route(getTokenInfo().companycode))
            .then(res => {
                console.log(res.data)
                setData(res.data);
                setLoading(false)
            })
            .catch(() => 404

                //  TODO: ENVIAR PÃ�GINA 404
                //alert(error)); 
            )
    }, []);


    //APAGAR REGISTO
    function apagar(id) {
        if (window.confirm('Tem a certeza que quer apagar esta Entidade?')) {

            reqDELETE(api_crud_route, { id: id })
                .then(() => {
                    setLoading(true);
                    alert('Registo Apagado com Sucesso');
                })
                .catch(e => alert('Problema ao Apagar Registo'))
        }
    }

    const columns = [
        {
            name: 'Nome',
            selector: 'name',
            cell: row => <div>{row.info.name}</div>,
            width: '350px',
            sortable: true,
            let: true,
        },

        {
            name: '',
            right: true,

            export: false,
            //print: false,

            cell: row =>

                <span>

                    <Link className="button button-update button-view" to={{ pathname: endpoints.view, state: { item: row, operation: 'view' } }} replace>
                        <img alt='visualizar' src={'./img/view.svg'} />
                    </Link>

                    {/*style={{ display: 'none' }}*/}
                    <Link className="button button-update" to={{ pathname: endpoints.regist, state: { item: row, operation: 'edit' } }} style={{ display: '' }} replace>
                        <img alt='editar' src={'./img/editar.svg'} />
                    </Link>


                    <button onClick={() => apagar(row['id'])} className="button btn-delete" style={{ display: 'none' }}>
                        <img alt='apagar' src={'./img/eliminar.svg'} />
                    </button>
                </span >

        },
    ];

    const defaultComponentOptions = {
        rowsPerPageText: 'Registos por pÃ¡gina:',
        rangeSeparatorText: 'de',
        noRowsPerPage: false,
        selectAllRowsItem: false,
        selectAllRowsItemText: 'Todos',
    };


    const tableData = {
        columns,
        data,
        filterPlaceholder: 'Procurar'
    };


    return (isloading ?
        <main>
            <div className={`dash-cards-2 header`}>
                <h2 className="dash-title">LOADING</h2>
                <p>LOADING</p>
                <div className="overlay">
                    <div className="overlay__inner">
                        <div className="overlay__content"><span className="spinner"></span></div>
                    </div>
                </div>

            </div>
        </main>

        :

        <main>
            <div className={`dash-cards-2 header`}>
                <h2 className="dash-title">Perfis</h2>
                <p>Home / Perfis / Lista de Perfis</p>
            </div>

            <div className={`dash-cards-2 body`}>
                <div className="card">
                    <div className="div-table">
                        <div className="table-body">
                            <DataTableExtensions {...tableData}>

                                <DataTable
                                    noDataComponent='Sem Registos'
                                    noHeader
                                    pagination
                                    exportHeaders
                                    paginationComponentOptions={defaultComponentOptions}
                                    paginationPerPage={25}
                                    paginationRowsPerPageOptions={[25, 50, 100]}
                                />
                            </DataTableExtensions>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}