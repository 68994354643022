import React, { useState, useEffect } from "react";
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { Link } from 'react-router-dom';

import { api_crud_route, api_list_route, api_crud_base, endpoints } from './Config';

import { reqGET, reqPUT, reqDELETE } from '../../../Utils';

import { getTokenInfo } from '../../../UseToken';

export default function List() {



    const [data, setData] = useState([]);
    const [isloading, setLoading] = useState(true);
    
    useEffect(() => {
        const companycode = getTokenInfo().companycode;
        const office = getTokenInfo().office.id;


        if (getTokenInfo().profile === 'Master' || getTokenInfo().profile === 'Administrador') {
            reqGET(api_list_route(getTokenInfo().companycode))
                .then(res => { 
                    
                    let aux_data = []
                    res.data.forEach(k => {
                        let aux = k.info.workflow.slice(-1)[0].estado
                        aux.forEach((v) => {
                            if(v['completo'] === true && v['selected'] === true) {  

                                aux_data.push(k)

                            }
                        })
                    })

                    setData(aux_data); 
                    // setData(res.data); 
                    setLoading(false) 
                })
                .catch(() => 404

                    //  TODO: ENVIAR PÃ�GINA 404
                    //alert(error)); 
                )
        }
        else {

            //reqGET(api_list_route(getTokenInfo().companycode))
            reqGET(`${api_crud_base}/credits/credits/special/office/${companycode}/${office}`)
                .then(res => { 
                    
                    let aux_data = []
                    res.data.forEach(k => {
                        let aux = k.info.workflow.slice(-1)[0].estado
                        aux.forEach((v) => {
                            if(v['completo'] === true && v['selected'] === true) {  

                                aux_data.push(k)

                            }
                        })
                    })

                    setData(aux_data); 
                    // setData(res.data); 
                    setLoading(false) })

                .catch(() => 404

                    //  TODO: ENVIAR PÃ�GINA 404
                    //alert(error)); 
                )
        }
    }, []);

    const reload = () => {
        const companycode = getTokenInfo().companycode;
        const office = getTokenInfo().office.id;

        if (getTokenInfo().profile === 'Master' || getTokenInfo().profile === 'Administrador') {
            reqGET(api_list_route(getTokenInfo().companycode))
                .then(res => { 

                    let aux_data = []
                    res.data.forEach(k => {
                        let aux = k.info.workflow.slice(-1)[0].estado
                        aux.forEach((v) => {
                            if(v['completo'] === true && v['selected'] === true) {  

                                aux_data.push(k)

                            }
                        })
                    })

                    setData(aux_data); 
                    // setData(res.data); 
                    setLoading(false) 
                })
                .catch(() => 404

                    //  TODO: ENVIAR PÃ�GINA 404
                    //alert(error)); 
                )
        }
        else {

            //reqGET(api_list_route(getTokenInfo().companycode))
            reqGET(`${api_crud_base}/credits/credits/special/office/${companycode}/${office}`)
                .then(res => { 

                    let aux_data = []
                    res.data.forEach(k => {
                        let aux = k.info.workflow.slice(-1)[0].estado
                        aux.forEach((v) => {
                            if(v['completo'] === true && v['selected'] === true) {  

                                aux_data.push(k)

                            }
                        })
                    })

                    setData(aux_data); 
                    //setData(res.data); 
                    setLoading(false) })
                .catch(() => 404

                    //  TODO: ENVIAR PÃ�GINA 404
                    //alert(error)); 
                )
        }
    }


    //APAGAR REGISTO
    function apagar(id) {
        if (window.confirm('Tem a certeza que quer apagar esta Entidade?')) {

            reqDELETE(api_crud_route, { id: id })
                .then(() => {
                    setLoading(true);
                    alert('Registo Apagado com Sucesso');
                })
                .catch(e => alert('Problema ao Apagar Registo'))
        }
    }

    const commission = (id_credito, id_entidade, type) => {
        // console.log('credito', id_credito)
        // console.log('entidade', id_entidade)
        // console.log('tipo', type)

        data.forEach((v) => {

            if(v.id === id_credito) {

                if (window.confirm('Tem a certeza que quer confirmar o pagamento desta Comissão?')) {
                    let newDate = new Date();
                    let today = newDate.getFullYear() + '-' + (newDate.getMonth() + 1).toString().padStart(2, "0") + '-' + newDate.getDate().toString().padStart(2, "0");

                    if(v.commissioning.commissoes[`${type}`]['payment'] === undefined) {
                        v.commissioning.commissoes[`${type}`]['payment'] = {data: today, status: true};
                    }
                    else {
                        v.commissioning.commissoes[`${type}`]['payment'] = {data: today, status: true};
                    }

                    reqPUT(api_crud_route, v)
                        .then(() => {
                            reload()
                            //console.log('Registo Atualizado com Sucesso!')
                        })
                        .catch(() => console.log('Problema ao atualizar Registo!'))

                }
            }
        })		
    }

    const columns = [
        {
            name: 'Cliente',
            selector: 'info.Cliente.name',
            sortable: true,
            let: true,
        },
        {
            name: 'NIF',
            selector: 'info.Cliente.vat',
            maxWidth: '110px',
            minWidth: '50px',
            sortable: true,
            let: true,
        },
        {
            name: 'Colaborador',
            selector: 'info.Colaborador.name',
            maxWidth: '150px',
            minWidth: '150px',
            sortable: true,
            let: true,
        },
        {
            name: 'Tipo de Crédito',
            selector: 'info.tipo_credito.name',
            sortable: true,
            left: true,
        },
        {
            name: 'Valor',
            cell: row => <div>{row.commissioning.valor_financiado}€</div>,
            maxWidth: '150px',
            minWidth: '150px',
            sortable: true,
            left: true,
        },
        {
            name: 'Pagar Comissão',
            right: true,
            maxWidth: '300px',
            minWidth: '300px',
            export: false,
            //print: false,
            cell: row =>
                <span>
                    {
                    row.commissioning.commissoes.Escritorio['payment'] === undefined || (row.commissioning.commissoes.Escritorio['payment'] !== undefined && row.commissioning.commissoes.Escritorio['payment']['status'] !== true) ?
                        <button type="button" onClick={() => commission(row['id'], row.commissioning.commissoes.Escritorio['id'], 'Escritorio')} className="button btn-update" style={{ backgroundColor: '#4a204d', border: '1px solid #4a204d', fontSize: '11px' }}>
                            Escritório
                        </button>
                    :
                        <button type="button" className="button btn-update" style={{ backgroundColor: '#469f48', border: '1px solid #469f48', fontSize: '11px', cursor: 'default' }} disabled={true}>
                            Escritório
                        </button>
                    }
                    {
                    row.commissioning.commissoes.Colaborador['payment'] === undefined || (row.commissioning.commissoes.Colaborador['payment'] !== undefined && row.commissioning.commissoes.Colaborador['payment']['status'] !== true) ?
                        row.commissioning.commissoes.Colaborador['valor'] !== '0' ?
                            <button type="button" onClick={() => commission(row['id'], row.commissioning.commissoes.Colaborador['id'], 'Colaborador')} className="button btn-update" style={{ backgroundColor: '#4a204d', border: '1px solid #4a204d', fontSize: '11px' }}>
                                Colaborador
                            </button>
                        :
                            ''
                    :
                        <button type="button" className="button btn-update" style={{ backgroundColor: '#469f48', border: '1px solid #469f48', fontSize: '11px', cursor: 'default' }} disabled={true}>
                            Colaborador
                        </button>
                    }
                    {
                    row.commissioning.commissoes.Promotor['payment'] === undefined || (row.commissioning.commissoes.Promotor['payment'] !== undefined && row.commissioning.commissoes.Promotor['payment']['status'] !== true) ?
                        row.commissioning.commissoes.Promotor['valor'] !== '0' ?
                            <button type="button" onClick={() => commission(row['id'], row.commissioning.commissoes.Promotor['id'], 'Promotor')} className="button btn-update" style={{ backgroundColor: '#4a204d', border: '1px solid #4a204d', fontSize: '11px' }}>
                                Promotor
                            </button>
                        :
                            ''
                    :
                        <button type="button" className="button btn-update" style={{ backgroundColor: '#469f48', border: '1px solid #469f48', fontSize: '11px', cursor: 'default' }} disabled={true}>
                            Promotor
                        </button>
                    }
                </span >
        },
    ];

    const defaultComponentOptions = {
        rowsPerPageText: 'Registos por página:',
        rangeSeparatorText: 'de',
        noRowsPerPage: false,
        selectAllRowsItem: false,
        selectAllRowsItemText: 'Todos',
    };


    const tableData = {
        columns,
        data,
        filterPlaceholder: 'Procurar'
    };


    return (isloading ?
        <main>
            <div className={`dash-cards-2 header`}>
                <h2 className="dash-title">LOADING</h2>
                <p>LOADING</p>
                <div className="overlay">
                    <div className="overlay__inner">
                        <div className="overlay__content"><span className="spinner"></span></div>
                    </div>
                </div>

            </div>
        </main>

        :

        <main>
            <div className={`dash-cards-2 header`}>
                <h2 className="dash-title">Comissões</h2>
                <p>Home / Comissões / Processar Comissões</p>
            </div>

            <div className={`dash-cards-2 body`}>
                <div className="card">
                    <div className="div-table">
                        <div className="table-body">
                            <DataTableExtensions {...tableData}>

                                <DataTable
                                    noDataComponent='Sem Registos'
                                    noHeader
                                    pagination
                                    exportHeaders
                                    paginationComponentOptions={defaultComponentOptions}
                                    paginationPerPage={25}
                                    paginationRowsPerPageOptions={[25, 50, 100]}
                                />
                            </DataTableExtensions>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}