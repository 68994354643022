import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Redirect, Link } from 'react-router-dom';

import DataTable from 'react-data-table-component';

import jsPDF from 'jspdf'
import 'jspdf-autotable'


import { reqGET } from '../../../../Utils';
import { api_crud_base, data_interface, endpoints } from './Config';

import { getTokenInfo } from '../../../../UseToken';

export default function View(props) {

    const [dados, set_dados] = useState(data_interface);

	let newDate = new Date();
    let lastWeek = new Date();
    lastWeek.setDate(lastWeek.getDate() - 7);
	let today = newDate.getFullYear() + '-' + (newDate.getMonth() + 1).toString().padStart(2, "0") + '-' + newDate.getDate().toString().padStart(2, "0");
	let last_week = lastWeek.getFullYear() + '-' + (lastWeek.getMonth() + 1).toString().padStart(2, "0") + '-' + lastWeek.getDate().toString().padStart(2, "0");

    const [isloading, setLoading] = useState(true);
    const [isRedirect, setRedirect] = useState(false);
    const [refresh, setRefresh] = useState(0);

    const [show_table, set_show_table] = useState('d-none');

	const [credit_types, set_credit_types] = useState([])
	const [financials, set_financials] = useState([])
	const [offices, set_offices] = useState([])
	const [responsables, set_responsables] = useState([])
	const [promotores, set_promotores] = useState([])

	const [office, set_office] = useState('')

	const [vat_search, set_vat_search] = useState('')
	const [vat_entitie, set_vat_entitie] = useState(null)

	const [data_inicio, set_data_inicio] = useState(last_week)
	const [data_fim, set_data_fim] = useState(today)

    //const [total, set_total] = useState(0.0);
    const [data_creditos, set_credits] = useState([]);
    const [data_creditos_2, set_credits_2] = useState([]);
    const [columns_creditos, set_column_credits] = useState([]);

    const change_data_inicio = (e) => {
        set_data_inicio(e.target.value)
    }
    const change_data_fim = (e) => {
        set_data_fim(e.target.value)
    }

    const defaultComponentOptions = {
        rowsPerPageText: 'Registos por página:',
        rangeSeparatorText: 'de',
        noRowsPerPage: false,
        selectAllRowsItem: false,
        selectAllRowsItemText: 'Todos',
        responsive: true,
    };

    // const [hidePessoal, set_hide_pessoal] = useState('')
    // const [hideEmpresarial, set_hide_empresarial] = useState('')

    const load_credit_types = async () => {
		reqGET(`${api_crud_base}/credits/types/${getTokenInfo().companycode}`)
			.then(res => {
				//console.log(res.data)
				set_credit_types(...[res.data.map(k => ({ value: k['id'], label: k.info['name'], etapa: k.info['etapa'] }))])
				//console.log(credit_types)
			})
	}

    const load_financials_0 = async () => {

		return await reqGET(`${api_crud_base}/financials/${getTokenInfo().companycode}`)
			.then(res => {
				//console.log(res.data)
				set_financials(...[res.data.map(k => ({ value: k['id'], label: k.info['name'] }))])
				//return res.data.map(k => ({ value: k['id'], label: k['financials'] }))
			})
		//return tmp
	}

	const load_financials = async (tipo) => {

		return await reqGET(`${api_crud_base}/financials/list/special/${getTokenInfo().companycode}/${tipo}`)
			.then(res => {
				//console.log(res.data)
				//set_financials(...[res.data.map(k => ({ value: k['id'], label: k['financials'] }))])
				return res.data.map(k => ({ value: k['id'], label: k['financials'] }))

				//console.log(financials)
			})
		//return tmp
	}

    const load_offices = async () => {
        reqGET(`${api_crud_base}/entities/list/${getTokenInfo().companycode}/Escritorio`)
			.then(res => {
				//console.log(res.data)
				let tmp = res.data.map(k => ({ value: k['id'], label: k['name'], ativo: k['ativo'] }))
				set_offices(...[tmp.filter(k=> k.ativo === 'true')])//console.log(offices)
			})
	}

	const load_reponsables = async (office) => {

        if(office === '') {

            let responsables_aux = []

            reqGET(`${api_crud_base}/entities/list/${getTokenInfo().companycode}/Colaborador`)
                .then(res => {
                    let ja_existe = false
                    res.data.forEach((k) => {
                        if (k['vat'] === vat_search) {
                            ja_existe = true

                            responsables_aux.push({ value: k['id'], label: k.info['name'] })
                         
                            
                            set_responsables(responsables_aux)
                        }

                    })
                    if (ja_existe === false) {
                        // console.log('ja_existe', ja_existe);

                        reqGET(`${api_crud_base}/entities/list/${getTokenInfo().companycode}/Gestor de Escritório`)
                            .then(res_2 => {
                                let ja_existe = false
                                res_2.data.forEach((k) => {
                                    if (k['vat'] === vat_search) {
                                        ja_existe = true

                                        responsables_aux.push({ value: k['id'], label: k.info['name'] })

                                        set_responsables(responsables_aux)
                                    }
                
                                })
                                if (ja_existe === false) {
                                    // console.log('ja_existe', ja_existe);
                                } else {
                                    // console.log('ja_existe', ja_existe);
                                }
                
                            })

                    } else {
                        // console.log('ja_existe', ja_existe);
                    }

                })

        }
        else {

            reqGET(`${api_crud_base}/entities/list/special/${getTokenInfo().companycode}/${office}/Colaborador`)
                .then(res => {
                    //console.log(res.data)

                    let responsables_aux = []

                    res.data.forEach(v => {
                        responsables_aux.push({ value: v['id'], label: v.info['name'] })
                    })

                    reqGET(`${api_crud_base}/entities/list/special/${getTokenInfo().companycode}/${office}/Gestor de Escritório`)
                        .then(res2 => {
                            //console.log(res.data)

                            res2.data.forEach(v => {
                                responsables_aux.push({ value: v['id'], label: v.info['name'] })
                            })

                            //set_responsables(...[res2.data.map(k2 => ({ value: k2['id'], label: k2.info['name'] }))])
                            //console.log(responsables)


                            set_responsables(responsables_aux)
                            
                        }
                        )

                    //set_responsables(...[res.data.map(k => ({ value: k['id'], label: k.info['name'] }))])
                    //console.log(responsables)
                })
        
        }
            
	}

	const load_promotores = async (office) => {
		reqGET(`${api_crud_base}/entities/list/special/${getTokenInfo().companycode}/${office}/Promotor`)
			.then(res => {
				//console.log(res.data)
				set_promotores(...[res.data.map(k => ({ value: k['id'], label: k.info['name'] }))])
				//console.log(promtores)
			}
			)
	}

    const change_credit_type = e => {

        //set_financials([])
        return

        if(e!== null && typeof e !== 'undefined' && typeof e !== 'null') {
            const { label } = e;

            load_financials(label).then(financial_list => {
                //console.log(financial_list)
                if (financial_list.length === 0) {
                }
                else {
                    set_financials(financial_list)

                }
            })
        }
		
	}

	const change_financials = e => {
		//const { label, value } = e;

	}

	const change_office = e => {

        if(e !== null)
            set_office(e.label)

        set_responsables([])
	    set_promotores([])

        if(e!== null && typeof e !== 'undefined' && typeof e !== 'null') {
            const { label } = e;

		    load_reponsables(label)
		    load_promotores(label)
        }

		//console.log(e)

	}

	const change_responsable = e => {
		//const { label, value } = e;

		//console.log(e)


	}

	const change_promotor = e => {
		//const { label, value } = e;

		//console.log(e)
	}

    // Para Instanciar todo o Objecto 
    const fetchInfo = async () => {
        await reqGET(`${api_crud_base}/maps/types/${getTokenInfo().companycode}`)
            .then(res => {
                set_dados(res.data[0]);
            })
            .catch(() => 404
                //  TODO: ENVIAR PÃ�GINA 404
                //  alert(error)); 
            );
    }

    const load_credits = async (type, id) => {
        //setLoading(true)

        let data_fim_aux = ''
        let data_fim_aux_2 = ''
        let data_i = ''
        let data_f = ''
        let res_aux = [] 
        if(type === '') {
            await reqGET(`${api_crud_base}/credits/credits/${getTokenInfo().companycode}`)
                .then(res => {
                    // console.log(res.data)
                    // set_credits(...[
                        // res.data.map(k => {
                        res.data.forEach(k => {
                            let aux = k.info.workflow.slice(-1)[0].estado
                            aux.forEach((v) => {
                                if(v['completo'] === true && v['selected'] === true) {                                    
                                    data_i = new Date(data_inicio)
                                    data_f = new Date(data_fim)

                                    if(k.commissioning.commissoes.Escritorio['payment'] !== undefined && k.commissioning.commissoes.Escritorio['payment']['status'] === true && k.commissioning.commissoes.Escritorio['valor'] !== '0') {
                                        data_fim_aux = new Date(k.commissioning.commissoes.Escritorio['payment']['data'])
                                        data_fim_aux_2 = k.commissioning.commissoes.Escritorio['payment']['data']

                                        if(data_fim_aux_2 !== '' && data_fim_aux.getTime() >= data_i.getTime() && data_fim_aux.getTime() <= data_f.getTime()) {
                                            res_aux.push({
                                                proposta: k['id'],
                                                cliente: k.info['Cliente']['name'],
                                                cliente_vat: k.info['Cliente']['vat'],
                                                escritorio: k.info.Escritorio['name'],
                                                escritorio_id: k.info.Escritorio['id'],
                                                escritorio_commissioning: k.commissioning.commissoes.Escritorio['valor'],
                                                colaborador: k.info.Colaborador['name'],
                                                colaborador_id: k.info.Colaborador['id'],
                                                colaborador_commissioning: k.commissioning.commissoes.Colaborador['valor'],
                                                promotor: k.info.Promotor['name'],
                                                promotor_id: k.info.Promotor['id'],
                                                promotor_commissioning: k.commissioning.commissoes.Promotor['valor'],
                                                financials: k.info.financials['name'],
                                                financials_id: k.info.financials['id'],
                                                tipo_credito: k.info.tipo_credito['name'],
                                                tipo_credito_id: k.info.tipo_credito['id'],
                                                valor_financiado: k.commissioning['valor_financiado'],
                                                valor_comissao: k.commissioning['valor_comissao'],
                                                valor_percent: k.commissioning.commissoes.Escritorio['valor'],
                                                valor_comissao_data: k.commissioning.commissoes.Escritorio['payment']['data'],
                                                comissao_entidade: k.info.Escritorio['name'],
                                                comissao_entidade_id: k.info.Escritorio['id'],
                                                data_fim: k.info.workflow.slice(-1)[0].deadline
                                            })
                                        }
                                    }
                                    if(k.commissioning.commissoes.Colaborador['payment'] !== undefined && k.commissioning.commissoes.Colaborador['payment']['status'] === true && k.commissioning.commissoes.Colaborador['valor'] !== '0') {
                                        data_fim_aux = new Date(k.commissioning.commissoes.Colaborador['payment']['data'])
                                        data_fim_aux_2 = k.commissioning.commissoes.Colaborador['payment']['data']

                                        if(data_fim_aux_2 !== '' && data_fim_aux.getTime() >= data_i.getTime() && data_fim_aux.getTime() <= data_f.getTime()) {
                                            res_aux.push({
                                                proposta: k['id'],
                                                cliente: k.info['Cliente']['name'],
                                                cliente_vat: k.info['Cliente']['vat'],
                                                escritorio: k.info.Escritorio['name'],
                                                escritorio_id: k.info.Escritorio['id'],
                                                escritorio_commissioning: k.commissioning.commissoes.Escritorio['valor'],
                                                colaborador: k.info.Colaborador['name'],
                                                colaborador_id: k.info.Colaborador['id'],
                                                colaborador_commissioning: k.commissioning.commissoes.Colaborador['valor'],
                                                promotor: k.info.Promotor['name'],
                                                promotor_id: k.info.Promotor['id'],
                                                promotor_commissioning: k.commissioning.commissoes.Promotor['valor'],
                                                financials: k.info.financials['name'],
                                                financials_id: k.info.financials['id'],
                                                tipo_credito: k.info.tipo_credito['name'],
                                                tipo_credito_id: k.info.tipo_credito['id'],
                                                valor_financiado: k.commissioning['valor_financiado'],
                                                valor_comissao: k.commissioning['valor_comissao'],
                                                valor_percent: k.commissioning.commissoes.Colaborador['valor'],
                                                valor_comissao_data: k.commissioning.commissoes.Colaborador['payment']['data'],
                                                comissao_entidade: k.info.Colaborador['name'],
                                                comissao_entidade_id: k.info.Colaborador['id'],
                                                data_fim: k.info.workflow.slice(-1)[0].deadline
                                            })
                                        }
                                    }
                                    if(k.commissioning.commissoes.Promotor['payment'] !== undefined && k.commissioning.commissoes.Promotor['payment']['status'] === true && k.commissioning.commissoes.Promotor['valor'] !== '0') {
                                        data_fim_aux = new Date(k.commissioning.commissoes.Promotor['payment']['data'])
                                        data_fim_aux_2 = k.commissioning.commissoes.Promotor['payment']['data']

                                        if(data_fim_aux_2 !== '' && data_fim_aux.getTime() >= data_i.getTime() && data_fim_aux.getTime() <= data_f.getTime()) {
                                            res_aux.push({
                                                proposta: k['id'],
                                                cliente: k.info['Cliente']['name'],
                                                cliente_vat: k.info['Cliente']['vat'],
                                                escritorio: k.info.Escritorio['name'],
                                                escritorio_id: k.info.Escritorio['id'],
                                                escritorio_commissioning: k.commissioning.commissoes.Escritorio['valor'],
                                                colaborador: k.info.Colaborador['name'],
                                                colaborador_id: k.info.Colaborador['id'],
                                                colaborador_commissioning: k.commissioning.commissoes.Colaborador['valor'],
                                                promotor: k.info.Promotor['name'],
                                                promotor_id: k.info.Promotor['id'],
                                                promotor_commissioning: k.commissioning.commissoes.Promotor['valor'],
                                                financials: k.info.financials['name'],
                                                financials_id: k.info.financials['id'],
                                                tipo_credito: k.info.tipo_credito['name'],
                                                tipo_credito_id: k.info.tipo_credito['id'],
                                                valor_financiado: k.commissioning['valor_financiado'],
                                                valor_comissao: k.commissioning['valor_comissao'],
                                                valor_percent: k.commissioning.commissoes.Promotor['valor'],
                                                valor_comissao_data: k.commissioning.commissoes.Promotor['payment']['data'],
                                                comissao_entidade: k.info.Promotor['name'],
                                                comissao_entidade_id: k.info.Promotor['id'],
                                                data_fim: k.info.workflow.slice(-1)[0].deadline
                                            })
                                        }
                                    }
                                }
                            })
                        })
                    //])

                    //console.log(res_aux)
                    return res_aux

                    //setRefresh(refresh + 1)
                    //console.log(credits)
                })
        }
        else {
            await reqGET(`${api_crud_base}/credits/credits/special/credits/${getTokenInfo().companycode}/${type}/${id}`)
                .then(res => {
                    //console.log(res.data)
                    //set_credits(...[
                        //res.data.map(k => {     
                        res.data.forEach(k => {     
                       
                            let aux = k.info.workflow.slice(-1)[0].estado
                            aux.forEach((v) => {
                                if(v['completo'] === true && v['selected'] === true) {
                                    data_i = new Date(data_inicio)
                                    data_f = new Date(data_fim)
                                    
                                    if(k.commissioning.commissoes.Escritorio['payment'] !== undefined && k.commissioning.commissoes.Escritorio['payment']['status'] === true && k.commissioning.commissoes.Escritorio['valor'] !== '0') {
                                        data_fim_aux = new Date(k.commissioning.commissoes.Escritorio['payment']['data'])
                                        data_fim_aux_2 = k.commissioning.commissoes.Escritorio['payment']['data']

                                        if(data_fim_aux_2 !== '' && data_fim_aux.getTime() >= data_i.getTime() && data_fim_aux.getTime() <= data_f.getTime()) {
                                            res_aux.push({
                                                proposta: k['id'],
                                                cliente: k.info['Cliente']['name'],
                                                cliente_vat: k.info['Cliente']['vat'],
                                                escritorio: k.info.Escritorio['name'],
                                                escritorio_id: k.info.Escritorio['id'],
                                                escritorio_commissioning: k.commissioning.commissoes.Escritorio['valor'],
                                                colaborador: k.info.Colaborador['name'],
                                                colaborador_id: k.info.Colaborador['id'],
                                                colaborador_commissioning: k.commissioning.commissoes.Colaborador['valor'],
                                                promotor: k.info.Promotor['name'],
                                                promotor_id: k.info.Promotor['id'],
                                                promotor_commissioning: k.commissioning.commissoes.Promotor['valor'],
                                                financials: k.info.financials['name'],
                                                financials_id: k.info.financials['id'],
                                                tipo_credito: k.info.tipo_credito['name'],
                                                tipo_credito_id: k.info.tipo_credito['id'],
                                                valor_financiado: k.commissioning['valor_financiado'],
                                                valor_comissao: k.commissioning['valor_comissao'],
                                                valor_percent: k.commissioning.commissoes.Escritorio['valor'],
                                                valor_comissao_data: k.commissioning.commissoes.Escritorio['payment']['data'],
                                                comissao_entidade: k.info.Escritorio['name'],
                                                comissao_entidade_id: k.info.Escritorio['id'],
                                                data_fim: k.info.workflow.slice(-1)[0].deadline
                                            })
                                        }
                                    }
                                    if(k.commissioning.commissoes.Colaborador['payment'] !== undefined && k.commissioning.commissoes.Colaborador['payment']['status'] === true && k.commissioning.commissoes.Colaborador['valor'] !== '0') {
                                        data_fim_aux = new Date(k.commissioning.commissoes.Colaborador['payment']['data'])
                                        data_fim_aux_2 = k.commissioning.commissoes.Colaborador['payment']['data']

                                        if(data_fim_aux_2 !== '' && data_fim_aux.getTime() >= data_i.getTime() && data_fim_aux.getTime() <= data_f.getTime()) {
                                            res_aux.push({
                                                proposta: k['id'],
                                                cliente: k.info['Cliente']['name'],
                                                cliente_vat: k.info['Cliente']['vat'],
                                                escritorio: k.info.Escritorio['name'],
                                                escritorio_id: k.info.Escritorio['id'],
                                                escritorio_commissioning: k.commissioning.commissoes.Escritorio['valor'],
                                                colaborador: k.info.Colaborador['name'],
                                                colaborador_id: k.info.Colaborador['id'],
                                                colaborador_commissioning: k.commissioning.commissoes.Colaborador['valor'],
                                                promotor: k.info.Promotor['name'],
                                                promotor_id: k.info.Promotor['id'],
                                                promotor_commissioning: k.commissioning.commissoes.Promotor['valor'],
                                                financials: k.info.financials['name'],
                                                financials_id: k.info.financials['id'],
                                                tipo_credito: k.info.tipo_credito['name'],
                                                tipo_credito_id: k.info.tipo_credito['id'],
                                                valor_financiado: k.commissioning['valor_financiado'],
                                                valor_comissao: k.commissioning['valor_comissao'],
                                                valor_percent: k.commissioning.commissoes.Colaborador['valor'],
                                                valor_comissao_colaborador: k.commissioning.commissoes.Colaborador['payment'],
                                                valor_comissao_data: k.commissioning.commissoes.Promotor['payment']['data'],
                                                comissao_entidade: k.info.Promotor['name'],
                                                comissao_entidade_id: k.info.Promotor['id'],
                                                data_fim: k.info.workflow.slice(-1)[0].deadline
                                            })
                                        }
                                    }
                                    if(k.commissioning.commissoes.Promotor['payment'] !== undefined && k.commissioning.commissoes.Promotor['payment']['status'] === true && k.commissioning.commissoes.Promotor['valor'] !== '0') {
                                        data_fim_aux = new Date(k.commissioning.commissoes.Promotor['payment']['data'])
                                        data_fim_aux_2 = k.commissioning.commissoes.Promotor['payment']['data']

                                        if(data_fim_aux_2 !== '' && data_fim_aux.getTime() >= data_i.getTime() && data_fim_aux.getTime() <= data_f.getTime()) {
                                            res_aux.push({
                                                proposta: k['id'],
                                                cliente: k.info['Cliente']['name'],
                                                cliente_vat: k.info['Cliente']['vat'],
                                                escritorio: k.info.Escritorio['name'],
                                                escritorio_id: k.info.Escritorio['id'],
                                                escritorio_commissioning: k.commissioning.commissoes.Escritorio['valor'],
                                                colaborador: k.info.Colaborador['name'],
                                                colaborador_id: k.info.Colaborador['id'],
                                                colaborador_commissioning: k.commissioning.commissoes.Colaborador['valor'],
                                                promotor: k.info.Promotor['name'],
                                                promotor_id: k.info.Promotor['id'],
                                                promotor_commissioning: k.commissioning.commissoes.Promotor['valor'],
                                                financials: k.info.financials['name'],
                                                financials_id: k.info.financials['id'],
                                                tipo_credito: k.info.tipo_credito['name'],
                                                tipo_credito_id: k.info.tipo_credito['id'],
                                                valor_financiado: k.commissioning['valor_financiado'],
                                                valor_comissao: k.commissioning['valor_comissao'],
                                                valor_percent: k.commissioning.commissoes.Promotor['valor'],
                                                valor_comissao_data: k.commissioning.commissoes.Promotor['payment']['data'],
                                                comissao_entidade: k.info.Promotor['name'],
                                                comissao_entidade_id: k.info.Promotor['id'],
                                                data_fim: k.info.workflow.slice(-1)[0].deadline
                                            })
                                        }
                                    }
                                }
                            })
                        })
                    //])

                    return res_aux

                    //setRefresh(refresh + 1)
                    //console.log(credits)
                })
        }

        //console.log(res_aux)
        return res_aux
        
    }

    const check_credits = async (type, id, array) => {
        var data_creditos_aux = array
        var data_creditos_aux_2 = []

        if(typeof id !== 'undefined' && id !== '') {

            if(type === 'vat') {
                if(data_creditos_aux) {
                    data_creditos_aux.forEach((v, k) => {
                        if(parseInt(v[`cliente_vat`]) === parseInt(id)){
                            //console.log('continuar')
                            data_creditos_aux_2.push(v)
                        }
                        else {
                            //console.log('retirar')
                        }
                    })
                }
            }
            else {

                if(data_creditos_aux) {
                    data_creditos_aux.forEach((v, k) => {
                        if(parseInt(v[`${type}_id`]) === parseInt(id)){
                            //console.log('continuar')
                            data_creditos_aux_2.push(v)
                        }
                        else {
                            //console.log('retirar')
                        }
                    })
                }
            }
            //console.log(data_creditos_aux_2)
            return data_creditos_aux_2
        }

        return array
        
    }

    const check_date = async (array) => {

        var data_creditos_aux = array

        if(data_creditos_aux) {
            data_creditos_aux.forEach((v, k) => {

                if(v.data_fim !== '' && v.data_fim >= data_inicio && v.data_fim <= data_fim) {
                    //console.log('...', v)
                }
                else {
                    data_creditos_aux.splice(k, 1);
                    set_credits_2(data_creditos_aux)
                }
            })
        }

        return data_creditos_aux

    }

	const handleSubmit = async e => {
		e.preventDefault();

        set_show_table('d-none')

        const {tipo_credito, Escritorio, Colaborador, Promotor, Financials, vat_search} = Object.fromEntries(new FormData(e.target))
        //console.log(Object.fromEntries(new FormData(e.target)))

        var columns_creditos_aux = [];

        // console.log('tipo_credito', tipo_credito)
        // console.log('Escritorio', Escritorio)
        // console.log('financials', Financials)
        // console.log('Colaborador', Colaborador)
        // console.log('Promotor', Promotor)

        
        await load_credits('', '')                
            .then((res) => {
                
                set_credits_2(res)

                //check_date(res)

                check_credits('tipo_credito', tipo_credito, res)
                    .then((res_2) => {
                        check_credits('financials', Financials, res_2)
                            .then((res_3) => {
                                check_credits('escritorio', Escritorio, res_3)
                                    .then((res_4) => {
                                        check_credits('colaborador', Colaborador, res_4)
                                            .then((res_5) => {
                                                check_credits('promotor', Promotor, res_5)
                                                    .then((res_6) => {
                                                        check_credits('vat', vat_search, res_6)
                                                            .then((res_7) => {
                                                                set_credits(res_7)
                                                                set_credits_2(res_7)
                                                                set_show_table('')
                                                            })
                                                    })
                                            }) 
                                    })
                            })
                    })
                
            })

        columns_creditos_aux.push({
            name: <div className="table-header">Proposta</div>,
            sortable: true,
            selector: 'proposta',
            left: true,
        });
        columns_creditos_aux.push({
            name: <div className="table-header">Nome</div>,
            selector: 'cliente',
            sortable: true,
            left: true,
        })
        columns_creditos_aux.push({
            name: <div className="table-header">NIF</div>,
            selector: 'cliente_vat',
            maxWidth: '100px',
            sortable: true,
            left: true,
        })
        columns_creditos_aux.push({
            name: <div className="table-header">Tipo de Crédito</div>,
            selector: 'tipo_credito',
            sortable: true,
            left: true,
        })
        columns_creditos_aux.push({
            name: <div className="table-header">Financeira</div>,
            selector: 'financials',
            sortable: true,
            left: true,
        })
        columns_creditos_aux.push({
            name: <div className="table-header">Valor Financiado</div>,
            cell: row => <div>{parseFloat(row.valor_financiado).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}€</div>,
            maxWidth: '150px',
            sortable: true,
            right: true,
        })

        columns_creditos_aux.push({
            name: <div className="table-header">Valor da comissão</div>,
            cell: row => {
                let valor_final = parseFloat(row.valor_comissao) * parseFloat(row.valor_percent) / 100
                return (
                    <div>
                        {parseFloat(valor_final).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}€
                    </div>
                )
            },
            maxWidth: '150px',
            sortable: true,
            right: true,
        })
        columns_creditos_aux.push({
            name: <div className="table-header">Colaborador/Promotor</div>,
            selector: 'comissao_entidade',
            sortable: true,
            left: true,
        })
        columns_creditos_aux.push({
            name: <div className="table-header">Data</div>,
            selector: 'valor_comissao_data',
            sortable: true,
            right: true,
        })
        set_column_credits(columns_creditos_aux);
        setRefresh(refresh + 1)
	}


    const change_vat = (e) => {
        const { name, value } = e.target

        set_vat_search(value)

        if (value.length === 9) {
			load_entitie(value)
		}
    }

    const load_entitie = async (vat) => {
		reqGET(`${api_crud_base}/entities/list/${getTokenInfo().companycode}/Colaborador`)
			.then(res => {
				let ja_existe = false
				res.data.forEach((k) => {
					if (k['vat'] === vat) {
						ja_existe = true

                        // let responsables_aux = []
                        // responsables_aux.push({ value: k['id'], label: k['name'] })
                        // set_responsables(responsables_aux)

                        set_vat_entitie({ value: k['id'], label: k['name'], type: 'cliente' })

					}

				})
				if (ja_existe === false) {
					// console.log('ja_existe', ja_existe);

                    reqGET(`${api_crud_base}/entities/list/${getTokenInfo().companycode}/Promotor`)
                        .then(res_2 => {
                            let ja_existe = false
                            res_2.data.forEach((k) => {
                                if (k['vat'] === vat) {
                                    ja_existe = true

                                    // let promotores_aux = []
                                    // promotores_aux.push({ value: k['id'], label: k['name'] })
                                    // set_promotores(promotores_aux)

                                    set_vat_entitie({ value: k['id'], label: k['name'], type: 'promotor' })
                                }
            
                            })
                            if (ja_existe === false) {
                                // console.log('ja_existe', ja_existe);


                                reqGET(`${api_crud_base}/entities/list/${getTokenInfo().companycode}/Gestor de Escritório`)
                                    .then(res_3 => {
                                        let ja_existe = false
                                        res_3.data.forEach((k) => {
                                            if (k['vat'] === vat) {
                                                ja_existe = true

                                                // let responsables_aux = []
                                                // responsables_aux.push({ value: k['id'], label: k['name'] })
                                                // set_responsables(responsables_aux)

                                                set_vat_entitie({ value: k['id'], label: k['name'], type: 'colaborador' })
                                            }
                        
                                        })
                                        if (ja_existe === false) {
                                            // console.log('ja_existe', ja_existe);
                                        } else {
                                            // console.log('ja_existe', ja_existe);
                                        }
                        
                                    })

                            } else {
                                // console.log('ja_existe', ja_existe);
                            }
            
                        })

				} else {
					// console.log('ja_existe', ja_existe);
				}

			})
	}
    
    useEffect(() => {

        set_credits([])

        load_credit_types()
		load_offices()
        load_financials_0()

        //fetchInfo();
        
        setLoading(false);
        setRedirect(false);

    }, [props]);

    const custom_select_styles = {
		container: base => ({
			...base,
			width: '100%',
		}),
		control: base => ({
			...base,
			borderRadius: '10px',

			padding: '2px 10px',
			fontSize: '14px'
		})
	}

    function toPDF() {
        var data_creditos_2_aux = []
        var total = 0.0
        var total_c = 0.0
        var i = 0;

        //console.log(data_creditos_2)
        data_creditos_2.forEach((v, k) => {
            total = parseFloat(total) + parseFloat(v.valor_financiado.replace(',', '.'))
            total_c = parseFloat(total_c) + parseFloat(v.valor_comissao.replace(',', '.'))

            //console.log('valor_financiado', v.valor_financiado)
            //console.log('valor_comissao', v.valor_comissao)
            //console.log('total', total)
            //console.log('total_c', total_c)

            data_creditos_2_aux.push(v)
    
            //console.log('0 :: ' + parseFloat(data_creditos_2_aux[i].valor_financiado))
            //console.log('1 :: ' + parseFloat(data_creditos_2_aux[i].valor_financiado).toFixed(2))
            //console.log('2 :: ' + parseFloat(data_creditos_2_aux[i].valor_financiado).toFixed(2).replace('.', ','))
            //console.log('3 :: ' + parseFloat(data_creditos_2_aux[i].valor_financiado).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'))
            //console.log('4 :: ' + parseFloat(data_creditos_2_aux[i].valor_financiado).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.').toString())

            if(!data_creditos_2_aux[k].valor_financiado.includes('€')) {
                var aux = parseFloat(data_creditos_2_aux[k].valor_financiado).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.').toString()
                data_creditos_2_aux[k]['valor_financiado_2'] = aux + '€'
            }

            if(!data_creditos_2_aux[k].valor_comissao.includes('€')) {
                var aux_2 = parseFloat(data_creditos_2_aux[k].valor_comissao).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.').toString()
                data_creditos_2_aux[k]['valor_comissao_2'] = aux_2 + '€'
            }
            //setRefresh(refresh + 1)
        })

        //return

        const doc = new jsPDF('l','pt','a4');
        doc.autoTable({
            theme: 'plain',
            styles: {
                fontSize: 8
            },
            columnStyles: { 
                tipo_credito: {
                    cellWidth: 90 
                },
                financials: {
                    cellWidth: 90 
                },
                valor_financiado_2: { 
                    halign: 'right', 
                    cellWidth: 100 
                }, 
                valor_comissao_2: { 
                    halign: 'right', 
                    cellWidth: 100 
                },
                valor_comissao_data: { 
                    halign: 'right'
                } 
            }, 
            //headStyles: { backgroundColor: 'red' },
            headStyles: {
                fontSize: 10,
                fillColor: "#FFFFFF",
                textColor: '#000000',
                //lineWidth: 0.1,
                //lineColor: '#c4c4c4',
            },
            footStyles: {
                fontSize: 10,
                fillColor: "#FFFFFF",
                textColor: '#000000',
                //lineWidth: {top: 0, right: 0, bottom: 0.1, left: 0},
                //lineColor: "#c4c4c4",
                halign: 'right'
            },
            //head: [
            //    ['Escritório', 'Colaborador Responsável', 'Promotor', 'Financeira', 'Tipo de Crédito', 'Valor Financiado']
            //],
            margin: { top: 70 },
            body: data_creditos_2_aux,
            foot: [
                [
                    { content: data_creditos_2_aux.length + (data_creditos_2_aux.length === 1 ? ' registo' : ' registos'), styles: { lineWidth: {top: 0.1, right: 0, bottom: 0, left: 0}, lineColor: "#000000" } },
                    { content: '', colSpan: 4 }, 
                    { content: total.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + '€', styles: { lineWidth: {top: 0.1, right: 0, bottom: 0, left: 0}, lineColor: "#000000", width: 250 } },
                    { content: total_c.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + '€', styles: { lineWidth: {top: 0.1, right: 0, bottom: 0, left: 0}, lineColor: "#000000", width: 250 } },
                    { content: '', colSpan: 2 } 
                ],
                [
                    { content: '', colSpan: 9, styles: { lineWidth: {top: 0.1, right: 0, bottom: 0, left: 0}, lineColor: "#000000"} },
                ]
            ],
            showFoot: 'lastPage',
            columns: [
                { header: 'Cliente', dataKey: 'cliente', },
                { header: 'NIF', dataKey: 'cliente_vat', },
                { header: 'Responsável', dataKey: 'colaborador' },
                { header: 'Tipo de Crédito', dataKey: 'tipo_credito' },
                { header: 'Financeira', dataKey: 'financials' },
                { header: 'Valor Financiado', dataKey: 'valor_financiado_2' },
                { header: 'Valor da Comissão', dataKey: 'valor_comissao_2' },
                { header: 'Colaborador/Promotor', dataKey: 'comissao_entidade' },
                { header: 'Data', dataKey: 'valor_comissao_data' },
            ],
        })

        // Optional - set properties on the document

        if(office !== '') {
            doc.setProperties({
                title: 'Mapa Comissões Pagas',
                subject: 'Fasquia Importasnte Lda',
                author: 'Assec SIM!',
                keywords: 'generated, javascript, web 2.0, ajax',
                creator: 'Sim!Credit'
            });
        }
        else {
            doc.setProperties({
                title: 'Mapa Comissões Pagas',
                subject: office,
                author: 'Assec SIM!',
                keywords: 'generated, javascript, web 2.0, ajax',
                creator: 'Sim!Credit'
            });
        }

        var pageCount = doc.internal.getNumberOfPages();
        for(i = 1; i <= pageCount; i++) {
            doc.setPage(i);

            // Top Page
            doc.setFont('helvetica', 'normal')
            doc.setFontSize(16)

            if(office !== '') {
                doc.text(office, 41, 30, { align: 'left' })
            }
            else {
                doc.text('FASQUIA IMPORTANTE LDA', 41, 30, { align: 'left' })
            }
            doc.text('Contribuinte N.º 502 222 352', doc.internal.pageSize.width / 2, 30, { align: 'center' })
            // Second Line
                doc.setFont('helvetica', 'normal')
                doc.setFontSize(8)
                doc.text('Mapa de Comissões Pagas', doc.internal.pageSize.width / 2, 45, { align: 'center' })
            // Third Line
                doc.text('Data: Início ' + data_inicio + ' Fim ' + data_fim, 41, 60, { align: 'left' })

            // Bottom Page
            doc.setFont('helvetica', 'normal')
            doc.setFontSize(8)
            doc.text('Fasquia Importante - Sim!Credit (c) 2022 | Lic. 0001 ', 41, doc.internal.pageSize.height - 30, { align: 'left' })
            doc.text('Página ' + String(i) + ' de ' + String(pageCount), doc.internal.pageSize.width - 41, doc.internal.pageSize.height - 30, { align: 'right' })


            // Border Table Header
            doc.setDrawColor(0, 0, 0);
            doc.line(41, 70, doc.internal.pageSize.width - 41, 70); // horizontal line
            doc.line(41, 90, doc.internal.pageSize.width - 41, 90); // horizontal line
            doc.line(41, 70, 41, 90); // vertical line
            doc.line(doc.internal.pageSize.width - 41, 70, doc.internal.pageSize.width - 41, 90); // vertical line
        }

        doc.save('comissoes_pagas_' + today + '.pdf')
    }

    return isRedirect ?
        <Redirect to={endpoints.list} replace />
        :
        isloading ?
            <main>
                <div className={`dash-cards-2 header`}>
                    <h2 className="dash-title">LOADING</h2>
                    <p>LOADING</p>
                    <div className="overlay">
                        <div className="overlay__inner">
                            <div className="overlay__content"><span className="spinner"></span></div>
                        </div>
                    </div>
                </div>
            </main>
            :
            <main>
                <div className={`dash-cards-2 header`}>
                    <h2 className="dash-title">Faturação</h2>
                    <p>Home / Mapas / Faturação </p>
                </div>

                <div className={`dash-cards-2 body`}>
                    <form  id="form" onSubmit={handleSubmit}>
                        <div className="card">
                            <div className="card-body">

                                <div className="div-group">
                                    <div className="form-group div-search">
                                        <span className="flex-1">
                                            {/*<label>Escritório</label>*/}
                                            <Select
                                                isClearable
                                                className="div-select"
                                                options={offices}
                                                name="Escritorio"
                                                placeholder="Escritório"
                                                onChange={change_office}
                                                styles={custom_select_styles}
                                            />
                                        </span>

                                        <span className="flex-1 d-none">
                                            {/*<label>Entidade Financeira</label>*/}
                                            <Select
                                                isClearable
                                                className="div-select"
                                                options={financials}
                                                name="Financials"
                                                placeholder={"Entidade Financeira"}
                                                onChange={change_financials}
                                                styles={custom_select_styles}
                                            />
                                        </span>

                                        <span className="flex-1 d-none">
                                        {/*<label>Tipo de Crédito:</label>*/}
                                        <Select
                                                isClearable
                                                className="div-select"
                                                options={credit_types}
                                                name="tipo_credito"
                                                placeholder="Tipo de crédito"
                                                onChange={change_credit_type}
                                                styles={custom_select_styles}
                                            />
                                        </span>

                                        <span className="flex-1">
                                            {/*<label>Colaborador Responsável:</label>*/}
                                            <Select
                                                isClearable
                                                className="div-select"
                                                options={responsables}
                                                name="Colaborador"
                                                placeholder="Colaborador responsável"
                                                onChange={change_responsable}
                                                styles={custom_select_styles}
                                            />
                                        </span>

                                        <span className="flex-1">
                                            {/*<label>Promotor:</label>*/}
                                            <Select
                                                isClearable
                                                className="div-select"
                                                options={promotores}
                                                name="Promotor"
                                                placeholder="Promotor"
                                                onChange={change_promotor}
                                                styles={custom_select_styles}
                                            />
                                        </span>
                                    </div>
                                </div>

                                <div className="div-group">
                                    <span className="flex-1">
                                        <div className="form-group div-search">
                                            <span className="flex-2">
                                                <input type="text" name="vat_search" value={vat_search} placeholder='Introduza o NIF a verificar' onChange={change_vat} />
                                            </span>

                                            <span className="flex-3">   
                                            </span>

                                            <span className="flex-2">
                                                <input type="date" name="inicio" value={data_inicio !== '' ? data_inicio : last_week} onChange={change_data_inicio} />
                                            </span>
                                            <span className="flex-2">
                                                <input type="date" name="fim" value={data_fim !== '' ? data_fim : today} onChange={change_data_fim} />
                                            </span>
                                            <span className="flex-1">
                                                <input type="submit" value="Pesquisar" className="nav-link btn-map-search" />
                                            </span>

                                        </div>
                                    </span>
                                </div>

                                <div className="div-break"></div>

                                <div className="div-table expand flex-1">
                                    <div className={"table-body " + show_table}>
                                        <button type="button" className={"download pdf " + (data_creditos_2.length > 0 ? '' : 'd-none')} onClick={toPDF}></button>

                                        <DataTable
                                            noDataComponent='Sem Registos'
                                            columns={columns_creditos}
                                            data={data_creditos_2}
                                            noHeader
                                            subHeader
                                            pagination
                                            paginationComponentOptions={defaultComponentOptions}
                                            paginationPerPage={25}
                                            paginationRowsPerPageOptions={[25, 50, 100]}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>

                <div className={'dash-cards-2 footer'}>
                    <nav className="navbar bottom-navbar navbar-light">
                        <div className="navbar-footer">
                            <ul className="navbar-nav float-left"></ul>
                            <ul className="navbar-nav float-right">
                                <li className="nav-item">
                                    <Link className="nav-link btn-cancel" to={{ pathname: endpoints.list }} replace>
                                        <img alt='' src={'./img/seta_2.svg'} />
                                        <span>Voltar</span>
                                    </Link>
                                </li>

                            </ul>
                        </div>
                    </nav>
                </div>
            </main>
}