import React, { useState, useEffect } from "react";
import DataTable from 'react-data-table-component';

import "react-data-table-component-extensions/dist/index.css";


import { api_crud_route, } from './Config';

import { reqPOST, reqPUT, reqGET } from '../../../Utils';

export default function List() {


    const [dados, setData] = useState([]);
    const [isloading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(0);

    useEffect(() => {


        reqGET(api_crud_route)
            .then(res => {
                //console.log(res.data);


                if (res.data.length === 0) {

                    let data = {
                        id: '',
                        info: {
                            etapa: [],
                            estado: [],
                        },
                        contacts: []
                    };
                    setData(data)
                } else {
                    //console.log('Esntourasdasd')
                    setData(res.data[0]);
                }
                setLoading(false);
            }



                //   setRefresh(refresh + 1)

            )
            .catch(error =>

                //  TODO: ENVIAR PÝGINA 404
                alert(error));

    }, []);


    //APAGAR REGISTO
    function apagar(name, type_table) {
        if (window.confirm('Tem a certeza que quer apagar este estado?')) {

            /* //console.log(type_table)
            //console.log(dados.info) */

            dados.info[type_table] = dados.info[type_table].filter(v => v.name !== name);
            //console.log(dados.info[type_table])

            setData(dados)


            reqPUT(api_crud_route, dados)
                .then(() => {
                    alert('Registo Atualizado com Sucesso!')
                })
                .catch(() => alert('Problema ao Atualizar Registo!'))

            setRefresh(refresh + 1)
        }
    }

    const columns_estado = [
        {
            name: 'Nome',
            selector: 'name',
            sortable: true,
            let: true,
        },
        {
            name: '',
            right: true,

            export: false,
            //print: false,

            cell: row =>

                <span>

                    {/*   <Link className="button button-update button-view" to={{ pathname: endpoints.view, state: { item: row, operation: 'view' } }} replace>
                        <img alt='visualizar' src={'./img/view.svg'} />
                    </Link>

                    {/*style={{ display: 'none' }}
                    <Link className="button button-update" to={{ pathname: endpoints.regist, state: { item: row, operation: 'edit' } }} style={{ display: 'none' }} replace>
                        <img alt='editar' src={'./img/editar.svg'} />
                    </Link>
        */}

                    <button onClick={() => apagar(row['name'], 'estado')} className="button btn-delete" style={{ display: '' }}>
                        <img alt='apagar' src={'./img/eliminar.svg'} />
                    </button>
                </span >

        },
    ];


    const columns_etapa = [
        {
            name: 'Nome',
            selector: 'name',
            sortable: true,
            let: true,
        },
        {
            name: '',
            right: true,

            export: false,
            //print: false,

            cell: row =>

                <span>

                    {/*   <Link className="button button-update button-view" to={{ pathname: endpoints.view, state: { item: row, operation: 'view' } }} replace>
                        <img alt='visualizar' src={'./img/view.svg'} />
                    </Link>

                    {/*style={{ display: 'none' }}
                    <Link className="button button-update" to={{ pathname: endpoints.regist, state: { item: row, operation: 'edit' } }} style={{ display: 'none' }} replace>
                        <img alt='editar' src={'./img/editar.svg'} />
                    </Link>
        */}

                    <button onClick={() => apagar(row['name'], 'etapa')} className="button btn-delete" style={{ display: '' }}>
                        <img alt='apagar' src={'./img/eliminar.svg'} />
                    </button>
                </span >

        },
    ];



    const defaultComponentOptions = {
        rowsPerPageText: 'Registos por página:',
        rangeSeparatorText: 'de',
        noRowsPerPage: false,
        selectAllRowsItem: false,
        selectAllRowsItemText: 'Todos',
    };

    /*   const tableData = {
          columns,
          dados,
          filterPlaceholder: 'Procurar'
      };
  
   */

    const handleSubmit = async e => {

        e.preventDefault()
        const { name } = e.target
        /*  //console.log("NAME:" + name)
 
 
         //console.log(dados) */

        if (dados.info[name].some(v => v.name === e.target.elements[name].value) === false) {
            dados.info[name].push({ name: e.target.elements[name].value })
            setData(dados)
            reqPUT(api_crud_route, dados)
                .then(e => {
                    if (e.status !== 200) {
                        reqPOST(`${api_crud_route}`, dados)
                            .then(() => alert('Registo Inserido com Sucesso!'))
                            .catch(() => alert('Problema ao Inserir Registo!'))
                    }
                    else
                        alert('Registo Atualizado com Sucesso!')
                })
                .catch(() => alert('Problema ao Atualizar Registo!'))

        } else {
            alert('Registo Já Existente')
        }







        e.target.elements[name].value = '';

        setRefresh(refresh + 1)



    }



    return (isloading ?
        <main>
            <div className={`dash-cards-2 header`}>
                <h2 className="dash-title">LOADING</h2>
                <p>LOADING</p>
                <div className="overlay">
                    <div className="overlay__inner">
                        <div className="overlay__content"><span className="spinner"></span></div>
                    </div>
                </div>

            </div>
        </main>

        :

        <main>
            <div className={`dash-cards-2 header`}>
                <h2 className="dash-title">Configuração Etapas-Estados</h2>
                <p>Home / Configuração / Configuração Etapas-Estados</p>
            </div>

            <div className={`dash-cards-2 body`}>
                <div className="card list-group">
                    <div className="form-group">
                        <div className='flex-1'>
                            <h2 className="dash-title">Etapas</h2>
                            <form id="form1" name='etapa' onSubmit={handleSubmit}>
                                <input type="text" name="etapa" pattern="[A-Za-z\u00C0-\u00FF0-9\s\-\+\/]+" className="input-form" placeholder="Introduza o nome" required />
                                <input type="submit" name="Adicionar" />
                            </form>
                        </div>
                        <div className='flex-1'>

                            <h2 className="dash-title">Estados</h2>
                            <form id="form2" name='estado' onSubmit={handleSubmit}>
                                <input type="text" name="estado" pattern="[A-Za-z\u00C0-\u00FF0-9\s\-\+\/]+" className="input-form" placeholder="Introduza o nome" required />
                                <input type="submit" name="Adicionar" />
                            </form>
                        </div>
                    </div>
                    <div className="div-table div-group">
                        <div className="flex-1">
                            <div className="table-body">
                                <DataTable
                                    noDataComponent='Sem Registos'
                                    columns={columns_etapa}
                                    data={dados.info.etapa}
                                    noHeader
                                    pagination
                                    exportHeaders
                                    paginationComponentOptions={defaultComponentOptions}
                                    paginationPerPage={25}
                                    paginationRowsPerPageOptions={[25, 50, 100]}
                                />
                            </div>
                        </div>
                        <div className="flex-1">
                            <div className="table-body">
                                <DataTable
                                    noDataComponent='Sem Registos'
                                    columns={columns_estado}
                                    data={dados.info.estado}
                                    noHeader
                                    pagination
                                    exportHeaders
                                    paginationComponentOptions={defaultComponentOptions}
                                    paginationPerPage={25}
                                    paginationRowsPerPageOptions={[25, 50, 100]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}