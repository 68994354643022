import Hosts from '../../../Hosts';

//const object_type = 'Credits'

const api_crud_base = Hosts.SIMCore_API
const api_crud_route = `${Hosts.SIMCore_API}/web/content`
const api_list_route = `${Hosts.SIMCore_API}/web/content`

const base_endpoint = '/app_configs'

const endpoints = {
    base: base_endpoint,
    regist: `${base_endpoint}_regist`,
    list: `${base_endpoint}_list`,
    view: `${base_endpoint}_view`,
}

const data_interface = {
    id: '',
    info: {
        perfil: [{ name: '' }],
        menu: [{ name: '' }]
    },
    content: {
        corporate: {
            title: '',
            text: '',
        }
    }
}


export {
    endpoints,
    api_crud_base,
    api_crud_route,
    data_interface,
    api_list_route
}