import Hosts from '../../Hosts';


//const object_type = 'Credits'

const api_crud_base = Hosts.SIMCore_API
const api_crud_route = `${Hosts.SIMCore_API}/credits/credits`
const api_list_route = `${Hosts.SIMCore_API}/credits/credits`

const base_endpoint = '/'

const endpoints = {
    base: base_endpoint,
    regist: `${base_endpoint}_regist`,
    list: `${base_endpoint}_list`,
    //view: `${base_endpoint}_view`,
    view: `${base_endpoint}`,
}

const data_interface = {
    id: '',
    info: {
        companycode: '',
        tipo_credito: {
            id: '',
            name: ''
        },
        financials: {
            id: '',
            name: ''
        },
        cliente: {
            id: '',
            vat: '',
            name: ''
        },
        escritorio: {
            id: '',
            name: ''
        },
        responsavel: {
            id: '',
            name: ''
        },
        promotor: {
            id: '',
            name: ''
        },
        master: {
            id: '',
            name: ''
        },

        workflow: [],

        history: [],

        observation: ''
    },
    commissioning: {
        valor_estimado: '',
        valor_financiado: '',
        valor_escritura: '',
        commissoes: {
            escritorio: {
                id: '',
                name: '',
                valor: '5'
            },
            responsavel: {
                id: '',
                name: '',
                valor: '45'
            },
            promotor: {
                id: '',
                name: '',
                valor: '30'
            },
            master: {
                id: '',
                name: '',
                valor: '20'
            },
        }
    }
}

export {
    endpoints,
    api_crud_base,
    api_crud_route,
    data_interface,
    api_list_route
}