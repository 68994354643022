import React, { useState, useEffect } from 'react';
import { Redirect, Link } from 'react-router-dom';

import DataTable from 'react-data-table-component';

import { reqGET } from '../../../Utils';
import { api_list_route, data_interface, endpoints } from './Config';

import { getTokenInfo } from './../../../UseToken';
export default function View(props) {

    const [dados, set_dados] = useState(data_interface);

    const [isloading, setLoading] = useState(true);
    const [isRedirect, setRedirect] = useState(false);

    const [onlyMaster, set_only_master] = useState('d-none')
    /* const [hidePessoal, set_hide_pessoal] = useState('sss')
    const [hideEmpresarial, set_hide_empresarial] = useState('')
 */
    const [data_table, set_data_table] = useState([])
    const [columns_table, set_columns_table] = useState([])

    // Para Instanciar todo o Objecto 
    const fetchInfo = async (id_serch) => {
        //await reqGET(`${api_crud_route}/${getTokenInfo().companycode}/${id}`)
        return await reqGET(`${api_list_route(getTokenInfo().companycode)}/${id_serch}`)

            .then(res => {
                set_dados(res.data[0]);

                //console.log(getTokenInfo().profile)
                if (getTokenInfo().profile === 'Master') {
                    set_only_master('')
                }
                else if(getTokenInfo().profile === 'Administrador') {
                    set_only_master('')
                }
                else {
                    set_only_master('d-none')
                }

                set_data_table([
                    {
                        id: 1,
                        cargo: 'Master',
                        nome: 'Master' + res.data[0].commissioning.commissoes.Master.name,
                        valor: res.data[0].commissioning.commissoes.Master.valor + '%'
                    },
                    {
                        id: 2,
                        cargo: 'Escritório',
                        nome: 'Escritório: ' + res.data[0].commissioning.commissoes.Escritorio.name,
                        valor: res.data[0].commissioning.commissoes.Escritorio.valor + '%'
                    },
                    {
                        id: 3,
                        cargo: 'Responsável',
                        nome: 'Responsável: ' + res.data[0].commissioning.commissoes.Colaborador.name,
                        valor: res.data[0].commissioning.commissoes.Colaborador.valor + '%'
                    },
                    {
                        id: 4,
                        cargo: 'Promotor',
                        nome: 'Promotor: ' + res.data[0].commissioning.commissoes.Promotor.name,
                        valor: res.data[0].commissioning.commissoes.Promotor.valor + '%'
                    },
                ]);
                set_columns_table([
                    {
                        name: 'Cargo',
                        selector: 'cargo',
                        sortable: false,
                    },
                    {
                        name: 'Nome',
                        selector: 'nome',
                        sortable: false,
                    },

                    {
                        name: 'Valor (€)',
                        cell: row => <div>{(parseFloat(row.valor) * parseFloat(res.data[0].commissioning.valor_comissao) / 100).toFixed(2)}€</div>,
                        sortable: false,
                        right: true
                    },
                    {
                        name: 'Valor (%)',
                        selector: 'valor',
                        sortable: false,
                        right: true
                    },
                ]);
            })
            .catch(() => 404
                //  TODO: ENVIAR PÝGINA 404
                //  alert(error)); 
            );
    }

    useEffect(() => {

        if (props.location.state !== undefined) {
            fetchInfo(props.location.state.item['id'])
                .then(() =>
                    setLoading(false))
        }
        else {
            setLoading(false);
            setRedirect(true);
        }

    }, [props]);

    /*     function sortResults(json, prop, asc) {
            json.sort(function (a, b) {
                if (asc) {
                    return (a[prop] > b[prop]) ? 1 : ((a[prop] < b[prop]) ? -1 : 0);
                } else {
                    return (b[prop] > a[prop]) ? 1 : ((b[prop] < a[prop]) ? -1 : 0);
                }
            });
        } */

    return isRedirect ?
        <Redirect to={endpoints.list} replace />
        :
        isloading ?
            <main>
                <div className={`dash-cards-2 header`}>
                    <h2 className="dash-title">LOADING</h2>
                    <p>LOADING</p>
                    <div className="overlay">
                        <div className="overlay__inner">
                            <div className="overlay__content"><span className="spinner"></span></div>
                        </div>
                    </div>
                </div>
            </main>
            :
            <main>
                <div className={`dash-cards-2 header`}>
                    <h2 className="dash-title">Crédito</h2>
                    <p>Home / Crédito / {dados.info.Cliente.name}</p>
                </div>

                <div className={`dash-cards-2 body`}>
                    <div className="card">
                        <div className="card-body">
                            <h2 className="dash-title">{dados.info.Cliente.name} ({dados.info.Cliente.vat})</h2>
                            <div className="div-break" />
                            <div className="div-group">
                                <span className="flex-1">
                                    <h4>Tipo de Crédito</h4>
                                    <p>{dados.info.tipo_credito.name}</p>
                                </span>
                                <span className="flex-1">
                                    <h4>Entidade Financeira</h4>
                                    <p>{dados.info.financials.name}</p>
                                </span>
                                <span className="flex-1">

                                </span>
                            </div>
                            <div className="div-group">
                                <span className="flex-1">
                                    <h4>Escritório</h4>
                                    <p>{dados.info.Escritorio.name}</p>
                                </span>
                                <span className="flex-1">
                                    <h4>Responsável</h4>
                                    <p>{dados.info.Colaborador.name}</p>
                                </span>
                                <span className="flex-1">
                                    <h4>Promotor</h4>
                                    <p>{dados.info.Promotor.name}</p>
                                </span>
                            </div>
                            <div className="div-break"></div>
                            <div className="div-group">
                                <span className="flex-1">
                                    <h4>Valor Financiado</h4>
                                    <p>{dados.commissioning.valor_financiado}</p>
                                </span>
                                <span className="flex-1">
                                    <h4>Valor Estimado</h4>
                                    <p>{dados.commissioning.valor_estimado}</p>
                                </span>
                                <span className="flex-1">
                                    <h4>Valor da Escritura</h4>
                                    <p>{dados.commissioning.valor_escritura}</p>
                                </span>
                            </div>
                            <div className="div-break"></div>
                            <div className="div-group">
                                <span className="flex-2">
                                    <h4>Observações</h4>
                                    <p>{dados.observation}</p>
                                </span>
                                <span className="flex-1" />
                            </div>

                            <h2 className={"dash-title " + onlyMaster}>Comissionamento</h2>
                            <div className={"form-group " + onlyMaster}>
                                <span className="flex-1">
                                    <h4>Valor Comissão:</h4>
                                    <p>{dados.commissioning.valor_comissao !== '' ? dados.commissioning.valor_comissao + '€' : 'Não definido'}</p>
                                </span>
                                <span className="flex-2">
                                </span>
                            </div>
                            <div className={"form-group " + onlyMaster}>
                                <div className="flex-1">
                                    <div className={"div-table " + onlyMaster}>
                                        <div className="table-body">
                                            <DataTable
                                                noDataComponent='Sem Registos'
                                                noHeader
                                                columns={columns_table}
                                                data={data_table}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group-2" style={{ width: "100%" }}>
                                <h2 className="dash-title">Processo</h2>
                                {
                                    dados.info.workflow.map((value, key) => {
                                        let selected_aux = ''
                                        let class_aux = ''

                                        if (value.data !== '') {
                                            class_aux = 'on-course';
                                        }
                                        if (value.estado)
                                            value.estado.forEach((v) => {
                                                if (v.selected) {
                                                    selected_aux = v.name

                                                    if (v.pendente === false && v.completo === true && v.initial === false) {
                                                        class_aux = 'success';
                                                    }
                                                    else if (v.pendente === false && v.completo === false && v.initial === false) {
                                                        class_aux = 'insuccess';
                                                    }
                                                }
                                            })
                                        return (
                                            <div key={key} className={class_aux}>
                                                <label>{value.name}</label>
                                                <div className="form-group">
                                                    <span className="flex-1">
                                                        <label>Data</label>
                                                        <p>{value.data}</p>
                                                    </span>
                                                    <span className="flex-1">
                                                        <label>Responsável</label>
                                                        <p>{value.Colaborador.name}</p>
                                                    </span>
                                                    <span className="flex-1">
                                                        <label>Etapa</label>
                                                        <p>{selected_aux}</p>
                                                    </span>
                                                    <span className="flex-1">
                                                        <label>Notificação</label>
                                                        <p>{value.notificacao.name}</p>
                                                    </span>
                                                    <span className="flex-1">
                                                        <label>Deadline</label>
                                                        <p>{value.deadline}</p>
                                                    </span>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'dash-cards-2 footer'}>
                    <nav className="navbar bottom-navbar navbar-light">
                        <div className="navbar-footer">
                            <ul className="navbar-nav float-left"></ul>
                            <ul className="navbar-nav float-right">
                                <li className="nav-item">
                                    <Link className="nav-link btn-cancel" to={{ pathname: endpoints.list }} replace>
                                        <img alt='' src={'./img/seta_2.svg'} />
                                        <span>Voltar</span>
                                    </Link>
                                </li>

                            </ul>
                        </div>
                    </nav>
                </div>
            </main>
}