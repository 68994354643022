import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Redirect } from 'react-router-dom';
import { getTokenInfo } from './../../UseToken';

import { object_type, api_crud_base, api_list_route, api_crud_route, data_interface, endpoints } from './Config';

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import { confirmAlert } from 'react-confirm-alert';

import {

	reqGET,
	reqPOST,
	reqDEL,
	reqPUT,

	obter_cidades_por_distrito,
	obter_distrito_por_cidade,
	obter_freguesias_por_cidade,
	obter_paises_em_pt,
	obter_distritos_pt,

	get_vat_info,

	pattern_vat,
	pattern_email,


} from '../../Utils';

//import { useHistory, Redirect } from "react-router-dom";
//import TextInput from 'react-autocomplete-input';


export default function Regist(props) {

	const [, set_dados] = useState(null);
	const dados = useRef(null);

	const [isloading, setLoading] = useState(true);
	const [isRedirect, setRedirect] = useState(false);

	const [show_vat_form, set_show_vat_form] = useState('');
	const [show_delete_button, set_show_delete_button] = useState('d-none');
	const [show_district_city_village, set_show_district_city_village] = useState('');


	//Temporário
	const [avaliable_credits, set_avaliable_credits] = useState([])

	const [countries, set_countries] = useState([])
	const [districts, set_districts] = useState([])
	const [cities, set_cities] = useState([])
	const [villages, set_villages] = useState([])

	const [options_select, ] = useState([
		{label: 'De - Até', value: 'De - Até'}, 
		// {label: 'Entre', value: 'Entre'}, 
		{label: 'Acima de', value: 'Acima de'}, 
		{label: 'Aprovação', value: 'Aprovação'}, 
		{label: 'Ativação', value: 'Ativação'}
	])

	const [valor_acumulado, set_valor_acumulado] = useState([])

	const [is_edit, set_is_edit] = useState(false)

	const [refresh, setRefresh] = useState(0) 

	const custom_select_styles = {
		container: base => ({
			...base,
			width: '100%',
		}),
		control: base => ({
			...base,
			borderRadius: "10px",
			padding: "2px 10px",
			fontSize: '14px'
		}),
	}


	const change_country = e => {

		set_cities([])
		set_villages([])

		set_dados(dados.current.addresses[0].city = '');
		set_dados(dados.current.addresses[0].village = '');
		set_dados(dados.current.addresses[0].region = '');

		if (e.value === "Portugal") {

			set_show_district_city_village('')

			if (districts.length === 0) {
				//console.log('DISTRITOS VAZIOS')
				obter_distritos_pt(set_districts)
			}
		}
		else {
			set_show_district_city_village('d-none')
			//obter_paises_em_pt()
		}

		set_dados(dados.current.addresses[0].country = e.value);
	}

	const change_district = e => {

		set_cities([])
		set_villages([])

		obter_cidades_por_distrito(e.value, set_cities)

		set_dados(dados.current.addresses[0].city = '');
		set_dados(dados.current.addresses[0].village = '');
		set_dados(dados.current.addresses[0].region = e.value);
	}

	const change_city = e => {

		set_villages([])

		obter_freguesias_por_cidade(e.value, set_villages)

		set_dados(dados.current.addresses[0].village = '');
		set_dados(dados.current.addresses[0].city = e.value);
	}

	const change_district_by_city = async e => {

		return await obter_distrito_por_cidade(e.value)
			.then(res =>
				change_district({ value: res })
			).catch(() => { return } /* alert('Distrito não encontrado!') */)
	}

	const change_village = e => {
		set_dados(dados.current.addresses[0].village = e.value);
		set_dados(dados)
	}

	const fetch_id_info = async (id_serch) => {

		let list_route = api_list_route(getTokenInfo().companycode)
		//console.log(list_route)
		//return await reqGET(`${api_crud_route}/${id_serch}`)
		return await reqGET(`${list_route}/${id_serch}`)
			.then(res => {
				set_dados(dados.current = res.data[0]);
			})
			.catch(error => error/*TODO: GO TO FAIL FETCH*/)
	}

	// PARA INSTANCIAR O COMPONENTE COM O ID 

	const load_credits = async (id) => {
		let newDate = new Date();
        // let today = newDate.getFullYear() + '-' + (newDate.getMonth() + 1).toString().padStart(2, "0") + '-' + newDate.getDate().toString().padStart(2, "0");
        // let month = (newDate.getMonth() + 1).toString().padStart(2, "0");
        let year = newDate.getFullYear();

		reqGET(`${api_crud_base}/credits/credits/special/financial/${getTokenInfo().companycode}/${id}`)
			.then(res => {
				//console.log('credits financeira', res.data)
 
				set_valor_acumulado([])

				res.data.forEach(el => {
					if(el.info.workflow.pop() !== undefined && el.info.workflow.pop().data !== '') {

						let data_proposta = new Date(el.info.workflow.pop().data);
						let ano_proposta = data_proposta.getFullYear();
						// let mes_proposta = (data_proposta.getMonth() + 1).toString().padStart(2, "0");

						if (year === ano_proposta) {
							// console.log('el_w', el.info.workflow.pop())
							// console.log('el_c_vf', el.commissioning.valor_financiado)
							// console.log('el_tc', el.info.tipo_credito.id)

							let aux = valor_acumulado
							if(aux[el.info.tipo_credito.id] !== undefined && aux[el.info.tipo_credito.id] !== '') {
								aux[el.info.tipo_credito.id] = parseFloat(aux[el.info.tipo_credito.id]) + parseFloat(el.commissioning.valor_financiado)
							}
							else {
								aux[el.info.tipo_credito.id] = parseFloat(el.commissioning.valor_financiado)
							}
							set_valor_acumulado(aux)

							setRefresh(refresh + 1)
						}

					}
					
				})

			})

	}

	const clean_data = () => {
		// DEEP COPY OF data_interface 
		set_dados(dados.current = JSON.parse(JSON.stringify(data_interface)))
		set_dados(dados.current.info.companycode = getTokenInfo().companycode)
		set_dados(dados.current.info.office = getTokenInfo().office)
		//console.log(dados.current)
	}

	useEffect(() => {


		const obter_creditos_disponiveis = () => {
			// DEEP COPY OF data_interface 

			reqGET(`${api_crud_base}/credits/types/${getTokenInfo().companycode}`)
				.then(res => {
					set_avaliable_credits(res.data)
				})
		}

		obter_creditos_disponiveis();

		clean_data();

		obter_paises_em_pt(set_countries);
		try {
			let test = props.location.state.operation;

			if (test === 'edit') {
				set_is_edit(true)
				//if (props.location.state !== undefined) {
				//console.log('->>>>>>>>>>>>>>>>:' + props.location.state.operation)
				//if (props.location.state.operation === 'edit') {
				set_show_vat_form('d-none')
				set_show_delete_button('')
				//console.log('AQUI DENTRO DO STATE')
				fetch_id_info(props.location.state.item['id']).then(() => {

					if (dados.current.addresses[0].country === 'Portugal') {

						obter_distritos_pt(set_districts)

						obter_cidades_por_distrito(dados.current.addresses[0].region, set_cities)

						obter_freguesias_por_cidade(dados.current.addresses[0].city, set_villages)

						load_credits(props.location.state.item['id'])

						setLoading(false);
					} else {
						set_show_district_city_village('d-none')
						setLoading(false);

					}
				})
			}
		} catch {
			// VAI REGISTAR
			set_show_vat_form('');

			clean_data()

			setLoading(false);

			setRedirect(false);
			return

		}
		// BACK
		//props.history.push(endpoints.list)
		return () => {
			clean_data()
		}

	}, [props])


	const handleSubmit = async e => {
		e.preventDefault();

		if (is_edit === true) {
			//alert('Atualizar PUT')

			confirmAlert({
				title: 'Edição',
				message: 'Tem a certeza que pretende continuar?',
				buttons: [
					{
						label: 'Sim',
						onClick: () => {

							reqPUT(api_crud_route, dados.current)
								.then(() => { props.history.push(endpoints.list) })
								.catch(() => alert('Problema ao atualizar Registo!'))
						}
					},
					{
						label: 'Não',
						onClick: () => { return }
					}
				]
			})
		} else {
			confirmAlert({
				title: 'Novo Registo',
				message: 'Tem a certeza que pretende continuar?',
				buttons: [
					{
						label: 'Sim',
						onClick: () => {

							reqPOST(api_crud_route, dados.current)
								.then(() => { props.history.push(endpoints.list) })
								.catch(() => alert('Problema ao atualizar Registo!'))
						}
					},
					{
						label: 'Não',
						onClick: () => { return }
					}
				]
			})
		}
	}

	const handleSingleCheck = e => {
		const tmp_data = { id: parseInt(e.target.value), name: e.target.name, commissions: [] };


		//console.log(tmp_data)

		let tmp = dados.current.credits.filter(k => k.id === tmp_data.id)

		/* 	//console.log('tmp')
			//console.log(tmp) */

		if (tmp.length === 0) {
			dados.current.credits.push(tmp_data)

		}
		else { //Vai Retirar
			dados.current.credits = dados.current.credits.filter(k => k.id !== tmp_data.id)
		}
		/* //console.log('dados.current.credits')
		//console.log(dados.current.credits) */

		//set_dados(dados.current.credits, )
		setRefresh(refresh + 1)

	}


	const handleSingleCheck_ver = id => {

		if (dados.current.credits.length !== 0) {
			let tmp = dados.current.credits.filter(k => k.id === id)
			return tmp.length > 0 ? true : false
		}
		else
			return false
	}


	const handleSubmitVat = async e => {
		e.preventDefault();

		setLoading(true);
		get_vat_info(dados.current.info.vat)
			.then(res => {
				//console.log('---------- VAT INFO --------------')
				//console.log(res)

				if ((res.data.name !== '---') && (res.data.name !== '')) {
					clean_data();
					set_dados(dados.current.info.vat = res.data.vatNumber);
					set_dados(dados.current.info.name = res.data.name);
					set_dados(dados.current.addresses[0].address_detail = res.data.address);

					let aMorada = res.data.address.split("\n");
					// Verificar se pais é PT
					if (res.data.countryCode === 'pt') {
						//console.log('----------------------> É PT')

						change_country({ value: 'Portugal' })


						set_dados(dados.current.addresses[0].street_name = aMorada[1]);

						let city = aMorada[1];
						if (city !== undefined) {
							city = city.toLowerCase();
							city = city.charAt(0).toUpperCase() + city.slice(1);
							change_district_by_city({ value: city }).then(() => change_city({ value: city }))


							// After District -> City
							change_district_by_city({ value: city }).then(() => change_city({ value: city }))

							let aMoradaCP = aMorada[2].split(" ");
							set_dados(dados.current.addresses[0].postal_code = aMoradaCP[0]);
						}
					} else {
						//console.log('---------------------->NÃO È PT')
						set_dados(dados.current.addresses[0].street_name = aMorada[1]);
					}
				} else {


					confirmAlert({
						title: 'Aviso',
						message: 'VAT não encotrado na base VIES.',
						buttons: [
							{
								label: 'Voltar',
								onClick: () => { return }
							}
						]
					})
				}
				setLoading(false)
			})

			.catch(error => { setLoading(false); alert(error) });
	}

	function apagar() {


		confirmAlert({
			title: 'Eliminar Financeira',
			message: 'Pretende mesmo eliminar este registo ?',
			buttons: [
				{
					label: 'Sim',
					onClick: () => {
						// Verificar se existe informação associada ao Cliente
						reqGET(`${api_crud_base}/credits/credits/special/credits/${getTokenInfo().companycode}/${object_type}/${dados.current.id}`).then(
							res => {


								if (res.data.length > 0) {
									confirmAlert({
										title: 'Aviso',
										message: 'A Fincanceira tem registos associados. Apenas pode ser editada.',
										buttons: [
											{
												label: 'Voltar',
												onClick: () => { return }
											}
										]
									})
								} else {
									//alert('Eliminou')

									reqDEL(api_crud_route + '/', { id: dados.current.id })
										.then(res => {
											/* alert(res.status === 200 ?
												'Registo Apagado com Sucesso' : 'Problema ao Apagar Registo'); 
 */
											props.history.push(endpoints.list)
										})
										.catch(error => alert(error))

								}
							}
						)
					}
				},
				{
					label: 'Não',
					onClick: () => { return }
				}
			]
		});
	};



	const load_entity = async (vat, entity) => {
		reqGET(`${api_crud_base}/financials/${getTokenInfo().companycode}`)
			.then(res => {
				//console.log(res.data)
				// res.data.map((k) => {
				// 	if(k['vat'] === vat) {
				// 		set_dados(dados.current.info.Cliente.id = k['id']);
				// 		set_dados(dados.current.info.Cliente.name = k['name']);
				// 	}
				// });

				let ja_existe = false
				res.data.forEach((k) => {
					//console.log(res.data)
					if (k['vat'] === vat) {
						ja_existe = true
						//set_visible_client_name(true)
						//set_dados(dados.current.info.Cliente.name = k['vat']);
					}
				})

				if (ja_existe === false) {
					//<Link to={{ pathname: '/client_regist', state: { item: vat, operation: 'newFromCredit' } }} />

					//console.log('NãO EXISTE ________________>');
					return

				} else {
					confirmAlert({
						title: 'Aviso',
						message: 'Já Existe um Registo com este VAT.',
						buttons: [
							{
								label: 'Voltar',
								onClick: () => { props.history.push(endpoints.list) }
							},

						]
					})

					//console.log(ja_existe);
				}
			}
			)
	}

	const handle_change_contacts_person_responsables = (e, contacts_index = 0, person_responsables_index = 0) => {
		const { name, value } = e.target;

		/* DEBUG*/
		/* 	//console.log('NAME:' + name)
			//console.log('Value:' + value) */

		set_dados(dados.current.contacts[contacts_index].person_responsables[person_responsables_index][name] = value)

		/* //console.log('«««««««««««««««««««««««')
		//console.log('Dados Current:' + JSON.stringify(dados)) */

	}

	const handle_change_addresses = (e, addresses_index = 0) => {
		const { name, value } = e.target;

		/* DEBUG*/
		/* 	//console.log('NAME:' + name)
			//console.log('Value:' + value) */

		set_dados(dados.current.addresses[addresses_index][name] = value)

		/* 	//console.log('«««««««««««««««««««««««')
			//console.log('Dados Current:' + JSON.stringify(dados)) */

	}


	const handle_change_info = (e) => {
		const { name, value } = e.target;

		/* DEBUG*/
		/* 
				//console.log('NAME:' + name)
				//console.log('Value:' + value)
		 */
		set_dados(dados.current.info[name] = value)

		if ((is_edit === false) && (name === 'vat')) {
			if (dados.current.info['vat'].length === 9) {
				load_entity(dados.current.info['vat'], object_type)
			}
		}

		/* //console.log('«««««««««««««««««««««««')
		//console.log('Dados Current:' + JSON.stringify(dados)) */

	}

	const handle_change_contacts = (e, index = 0) => {
		const { name, value } = e.target;

		/* DEBUG*/

		/* //console.log('NAME:' + name)
		//console.log('Value:' + value)
 */
		set_dados(dados.current.contacts[index][name] = value)

		/* 	//console.log('«««««««««««««««««««««««')
			//console.log('Dados Current:' + JSON.stringify(dados)) */

	}

	const add_range = (id) => {
		dados.current.credits.forEach(el => {
			if(el.id === id) {
				if(el['commissions'] === undefined) {
					el['commissions'] = [{type: {}, from: '', to: '', value: '', status: true}, {type: {}, from: '', to: '', value: '', status: false}]
				}
				else {
					el['commissions'].push({type: {}, from: '', to: '', value: '', status: false})
				}
			}
		})

		// console.log('«««««««««««««««««««««««')
		// console.log('Dados Current:' + JSON.stringify(dados.current.credits))

		setRefresh(refresh + 1)
	}

	const remove_range = (tipo, order) => {
		// console.log('tipo', tipo)
		// console.log('order', order)

		dados.current.credits.forEach(el => {
			if(el.id === tipo) {
				//console.log(el.commissions[order])
				el.commissions.splice(order, 1);
			}
		})

		setRefresh(refresh + 1)
		// console.log('«««««««««««««««««««««««')
		// console.log('Dados Current:' + JSON.stringify(dados.current.credits))
	}

	const change_commission = (e, tipo, order) => {
		// console.log('e', e)
		// console.log('t', tipo)
		// console.log('o', order)
		dados.current.credits.forEach(el => {
			if(el.id === tipo) {
				if(el['commissions'] === undefined || el['commissions'].length === 0)
					el['commissions'] = [{type: e, from: '', to: '', value: '', status: false}]

				el.commissions[order].type = e
			}
		})

		// console.log('«««««««««««««««««««««««')
		// console.log('Dados Current:' + JSON.stringify(dados.current.credits))

		setRefresh(refresh + 1)
	}

	const change_commission_value = (e, tipo, order) => {
		const { name, value } = e.target;
		// console.log('e', e)
		// console.log('name', name)
		// console.log('value', value)
		// console.log('t', tipo)
		// console.log('o', order)

		let value_2 = value.replace(",", ".");

		dados.current.credits.forEach(el => {
			if(el.id === tipo) {
				if(el['commissions'] === undefined || el['commissions'].length === 0)
					el['commissions'] = [{type: e, from: '', to: '', value: value_2, status: false}]

				el.commissions[order].value = value_2
			}
		})

		// console.log('«««««««««««««««««««««««')
		// console.log('Dados Current:' + JSON.stringify(dados.current.credits))

		setRefresh(refresh + 1)
	}

	const handle_change_credits_commission = e => {
		const { name, value, dataset } = e.target;
		const { tipo, order } = dataset;

		dados.current.credits.forEach(el => {
			if(el.id === parseInt(tipo)) {
				el.commissions[order][name] = value
			}
		})

		setRefresh(refresh + 1)
	}

	const handleComissionCheck = e => {
		const { name, value, dataset } = e.target;
		const { tipo, order } = dataset;

		// console.log('name', name)
		// console.log('value', value)
		// console.log('tipo', tipo)
		// console.log('order', order)

		dados.current.credits.forEach(el => {
			if(el.id === parseInt(tipo)) {
				// console.log(el)
				// console.log(el.commissions)

				el.commissions.forEach(elC => {
					elC['status'] = false
				})

				el.commissions[order]['status'] = true


				// console.log(el.commissions)
			}
		})

		setRefresh(refresh + 1)

	}

	return isloading ?
		<main>
			<div className={`dash-cards-2 header`}>
				<h2 className="dash-title">LOADING</h2>
				<p>LOADING</p>
				<div className="overlay">
					<div className="overlay__inner">
						<div className="overlay__content"><span className="spinner"></span></div>
					</div>
				</div>
			</div>
		</main>
		:
		isRedirect
			?
			<Redirect to={endpoints.list} replace />
			:
			<main>
				<div className={`dash-cards-2 header`}>
					<h2 className="dash-title">Financeiras</h2>
					<p>Home / Financeiras / Inserir Financeira</p>
				</div>

				<div className={`dash-cards-2 body`}>
					<div className="card">
						<div className="card-body">
							<form className={show_vat_form} id="form-vat" onSubmit={handleSubmitVat}>
								<div className="form-group">
									<span className="flex-1">
										{/*<input type="text" name="dados.info.vat" value={dados.info.vat} pattern="[0-9]{9}" className="input-form" placeholder="Introduza o NIF" onChange={e => set_dados({ ...dados, info: { ...dados.info, vat: e.target.value } })} required /> */}
										<input type="text" pattern={pattern_vat} minLength="9" name="vat" value={dados.current.info.vat} className="input-form" placeholder="Introduza o NIF" onChange={handle_change_info} required />
									</span>
									<span className="flex-1">
										<input type="submit" value="Pesquisar" />
									</span>
								</div>
							</form>

							<form id="form" onSubmit={handleSubmit}>
								<input type="hidden" name="id" value={dados.current.id} />

								<h2 className="dash-title">Dados Financeira</h2>
								<div className="form-group">
									<span className="flex-1">
										<label>Nome</label>
										<input type="text" name="name" value={dados.current.info.name} className="input-form" placeholder="Introduza o nome" onChange={handle_change_info} required />
									</span>
									<span className="flex-1">
										<label>Nome Abreviado</label>
										<input type="text" name="name_mini" value={dados.current.info.name_mini} className="input-form" placeholder="Introduza o nome abreviado" onChange={handle_change_info} />
									</span>
								</div>
								<div className="form-group">
									<span className="flex-1">
										<label>NIF</label>
										<input type="text" pattern={pattern_vat} minLength="9" name="vat" value={dados.current.info.vat} disabled={true /* !is_edit ? true : false */} className="input-form" placeholder="Número de Contribuinte" onChange={handle_change_info} required />
									</span>
									<span className="flex-1">
										<label>E-mail</label>
										<input type="email" pattern={pattern_email} name="email" value={dados.current.contacts[0].email} className="input-form" placeholder="Introduza o E-mail" onChange={handle_change_contacts} />
									</span>
								</div>
								<div className="form-group">
									<span className="flex-1">
										<label>Telefone</label>

										<PhoneInput
											defaultCountry="PT"
											placeholder="Introduza o número de telefone"
											value={dados.current.contacts[0].phone !== '' ? dados.current.contacts[0].phone : null}
											onChange={e => set_dados(dados.current.contacts[0].phone = e)} />
										{/* <input type="tel" name="phone" value={dados.current.contacts[0].phone} pattern="[0-9]{9}" className="input-form" placeholder="Introduza o número de telefone" onChange={handle_change_contacts} /> */}
									</span>
									<span className="flex-1">
										<label>Telemóvel</label>
										<PhoneInput
											defaultCountry="PT"
											placeholder="Introduza o número de telemóvel"
											value={dados.current.contacts[0].mobile_phone !== '' ? dados.current.contacts[0].mobile_phone : null}
											onChange={e => set_dados(dados.current.contacts[0].mobile_phone = e)} />
										{/* <input type="tel" name="mobile_phone" value={dados.current.contacts[0].mobile_phone} pattern="[0-9]{9}" className="input-form" placeholder="Introduza o telemóvel" onChange={handle_change_contacts} /> */}
									</span>

								</div>
								<div className="form-group">
									<span className="flex-1">
										<label>País</label>
										<Select
											menuPortalTarget={document.body}
											menuPosition={'fixed'}

											className="div-select"
											options={countries}
											name="pais"
											value={dados.current.addresses[0].country === '' ? null : { label: dados.current.addresses[0].country, value: dados.current.addresses[0].country }}
											placeholder={"Introduza o País"}
											onChange={change_country}
											styles={custom_select_styles}
										/>
									</span>
									<span className={`flex-1 ${show_district_city_village}`}>
										<label>Distrito</label>
										<Select
											menuPortalTarget={document.body}
											menuPosition={'fixed'}

											className="div-select"
											options={districts}
											name="distrito"
											value={dados.current.addresses[0].region === '' ? null : { label: dados.current.addresses[0].region, value: dados.current.addresses[0].region }}
											placeholder={"Introduza o Distrito"}
											onChange={change_district}
											styles={custom_select_styles}
										/>
									</span>
									<span className={`flex-1 ${show_district_city_village}`}>
										<label>Concelho</label>
										<Select
											menuPortalTarget={document.body}
											menuPosition={'fixed'}

											className="div-select"
											options={cities}
											name="concelho"
											value={dados.current.addresses[0].city === '' ? null : { label: dados.current.addresses[0].city, value: dados.current.addresses[0].city }}
											placeholder={"Introduza o Concelho"}
											onChange={change_city}
											styles={custom_select_styles}
										/>
									</span>
								</div>
								<div className="form-group">
									<span className={`flex-1 ${show_district_city_village}`}>
										<label>Freguesia</label>
										<Select
											menuPortalTarget={document.body}
											menuPosition={'fixed'}

											className="div-select"
											options={villages}
											name="freguesia"
											value={dados.current.addresses[0].village === '' ? null : { label: dados.current.addresses[0].village, value: dados.current.addresses[0].village }}
											placeholder={"Introduza a Freguesia"}
											onChange={change_village}
											styles={custom_select_styles}
										/>
									</span>
									<span className={`flex-1 `}>
										<label>Localidade</label>
										<input type="text" name="localidade" value={dados.current.addresses[0].localidade} className="input-form" placeholder="Introduza a Localidade" onChange={handle_change_addresses} />
									</span>
									<span className="flex-1">
										<label>Código-Postal</label>
										<input type="text" name="postal_code" value={dados.current.addresses[0].postal_code} className="input-form" placeholder="Introduza o Código-Postal" onChange={handle_change_addresses} />
									</span>
									<span className="flex-1">
										<label>Apartado</label>
										<input type="text" name="apartado" value={dados.current.addresses[0].apartado} className="input-form" placeholder="Introduza o Apartado" onChange={handle_change_addresses} />
									</span>
								</div>
								<div className="form-group">
									<span className="flex-2">
										<label>Morada</label>
										<textarea name="address_detail" value={dados.current.addresses[0].address_detail} className="input-form" rows="4" onChange={handle_change_addresses}>
										</textarea>
									</span>
									<span className="flex-1"></span>
								</div>

								<h2 className="dash-title">Dados de Gestor de Parceria</h2>
								<div className="form-group">
									<span className="flex-1">
										<label>Gestor de Parceria</label>
										<input type="text" name="name" value={dados.current.contacts[0].person_responsables[0].name} className="input-form" placeholder="Introduza o Intermediário" onChange={handle_change_contacts_person_responsables} />

									</span>

									<span className="flex-1">
										<label>Contacto Telefónico (Gestor de Parceria)</label>
										<PhoneInput
											defaultCountry="PT"
											placeholder="Introduza o contacto telefónico"
											value={dados.current.contacts[0].person_responsables[0].mobile_phone !== '' ? dados.current.contacts[0].person_responsables[0].mobile_phone : null}
											onChange={e => set_dados(dados.current.contacts[0].person_responsables[0].mobile_phone = e)} />
										{/* <input type="tel" name="mobile_phone" value={dados.current.contacts[0].mobile_phone} pattern="[0-9]{9}" className="input-form" placeholder="Introduza o telemóvel" onChange={handle_change_contacts} /> */}
									</span>

									{/* <span className="flex-1">
										<label>Telemóvel (Intermediário)</label>
										<input type="tel" name="mobile_phone" value={dados.current.contacts[0].person_responsables[0].mobile_phone} pattern="[0-9]{9}" className="input-form" placeholder="Introduza o Telemóvel do Intermediário" onChange={handle_change_contacts_person_responsables} />
									</span> */}
									<span className="flex-1">
										<label>Email (Gestor de Parceria)</label>
										<input type="email" pattern={pattern_email} name="email" value={dados.current.contacts[0].person_responsables[0].email} className="input-form" placeholder="Introduza o Email do Intermediário" onChange={handle_change_contacts_person_responsables} />
									</span>
								</div>

								<h2 className="dash-title">Tipo Crédito</h2>
								<div className="form-group">
									<span className="div-checkbox-group">
										{
											avaliable_credits.map((value, key) => {
												/* let checked = false;
												isChecked.map(value_2 => {
													if (typeof value_2.id !== 'undefined' && value.id.toString().localeCompare(value_2.id) === 0) {
														checked = true;
													}
												}) */
												return (
													<label key={key} className="div-checkbox">
														{value.info.name}
														<input
															name={value.info.name}
															className="checkbox-form"
															type="checkbox"
															value={value.id}
															onChange={handleSingleCheck}
															defaultChecked={handleSingleCheck_ver(value.id)}
														/>
														<span className="checkmark"></span>
													</label>
												)
											})
										}
									</span>
								</div>

								<h2 className="dash-title">Comissões</h2>
								<div className="form-group comissoes">
									<table style={{width: '100%', minWidth: '970px'}}>
										<thead>
											<tr>
												<td style={{width: '20%'}}><b>Tipo de Crédito</b></td>
												<td style={{width: '55%', minWidth: '650px'}}><b>Comissões por Volume</b></td>
												<td style={{width: '25%', minWidth: '245px'}}><b>Percentagem</b></td>
											</tr>
										</thead>
										<tbody>
											{
											dados.current.credits.map((value, key) => {
												return (
													<tr key={key}>
														<td>
															{value.name}
															<br />
															<b className={valor_acumulado[value.id] !== undefined && valor_acumulado[value.id] !== '' ? '' : 'd-none'}>Total anual atual: </b> 
															{valor_acumulado[value.id] !== undefined && valor_acumulado[value.id] !== '' ? '' + valor_acumulado[value.id] + ' €': ''}</td>
														<td>
															{
															value['commissions'] !== undefined && value['commissions'].length > 0 ?
																value.commissions.map((vC, kC) => {
																	return (
																		<div key={kC}>
																			{
																			kC === 0 ?
																				<button 
																					type="button"
																					className='btn' 
																					style={{
																						float: 'left', 
																						marginRight: '15px', 
																						marginTop: '5px', 
																						borderRadius: '50%', 
																						width: '30px', height: '30px', 
																						padding: '1px 0 0',
																						zIndex: '1000'
																					}}
																					onClick={() => add_range(value.id)}>
																					+
																				</button>
																			:
																				<button 
																					type="button"
																					className='btn' 
																					style={{
																						float: 'left', 
																						marginRight: '15px', 
																						marginTop: '5px', 
																						borderRadius: '50%', 
																						width: '30px', height: '30px', 
																						padding: '1px 0 0',
																						zIndex: '1000'
																					}}
																					onClick={() => remove_range(value.id, kC)}>
																					-
																				</button>
																			}
																			<span 
																				style={{
																					width: '30%', 
																					minWidth: '150px', 
																					marginBottom: '10px',
																					display: 'inline-block'
																				}}
																			>
																				<Select
																					menuPortalTarget={document.body}
																					menuPosition={'fixed'}
																					className="div-select"

																					options={options_select}

																					value={vC.type !== undefined ? vC.type : ''}
																					placeholder={"Introduza o filtro"}
																					onChange={(e) => change_commission(e, value.id, kC)}
																					styles={custom_select_styles}
																				/>
																			</span>

																			<span
																				className={vC.type !== undefined && vC.type.label === 'De - Até' ? '' : 'd-none'}
																			>
																				<span
																					style={{
																						width: '15%', 
																						minWidth: '130px', 
																						marginLeft: '10px',
																						marginBottom: '10px',
																						display: 'inline-block'
																					}} 
																				>
																					<div className="form-group" style={{marginBottom: '0'}}>
																						<input type="number" min="0" name="from" data-tipo={value.id} data-order={kC} value={vC.from !== undefined ? vC.from : ''} className="input-form" style={{textAlign: 'right'}} placeholder="Introduza o valor" onChange={handle_change_credits_commission} />
																						<span style={{padding: '12px'}}>€</span>
																					</div>
																				</span>
																				<span 
																					style={{
																						width: '50px', 
																						textAlign: 'center',
																						display: 'inline-block'
																					}}
																				>
																					até
																				</span>
																				<span 
																					style={{
																						width: '15%', 
																						minWidth: '130px', 
																						marginBottom: '10px',
																						display: 'inline-block'
																					}}
																				>
																					<div className="form-group" style={{marginBottom: '0'}}>
																						<input type="number" min="0" name="to" data-tipo={value.id} data-order={kC} value={vC.to !== undefined ? vC.to : ''} className="input-form" style={{textAlign: 'right'}} placeholder="Introduza o valor" onChange={handle_change_credits_commission} />
																						<span style={{padding: '12px'}}>€</span>
																					</div>
																				</span>
																			</span>

																			<span
																				className={vC.type !== undefined && vC.type.label === 'Entre' ? '' : 'd-none'}
																			>
																				<span
																					style={{
																						width: '15%', 
																						minWidth: '130px', 
																						marginLeft: '10px',
																						marginBottom: '10px',
																						display: 'inline-block'
																					}} 
																				>
																					<div className="form-group" style={{marginBottom: '0'}}>
																						<input type="number" min="0" name="from" data-tipo={value.id} data-order={kC} value={vC.from !== undefined ? vC.from : ''} className="input-form" style={{textAlign: 'right'}} placeholder="Introduza o valor" onChange={handle_change_credits_commission} />
																						<span style={{padding: '12px'}}>€</span>
																					</div>
																				</span>
																				<span 
																					style={{
																						width: '50px', 
																						textAlign: 'center',
																						display: 'inline-block'
																					}}
																				>
																					e
																				</span>
																				<span 
																					style={{
																						width: '15%', 
																						minWidth: '130px', 
																						marginBottom: '10px',
																						display: 'inline-block'
																					}}
																				>
																					<div className="form-group" style={{marginBottom: '0'}}>
																						<input type="number" min="0" name="to" data-tipo={value.id} data-order={kC} value={vC.to !== undefined ? vC.to : ''} className="input-form" style={{textAlign: 'right'}} placeholder="Introduza o valor" onChange={handle_change_credits_commission} />
																						<span style={{padding: '12px'}}>€</span>
																					</div>
																				</span>
																			</span>

																			<span
																				className={vC.type !== undefined && vC.type.label === 'Acima de' ? '' : 'd-none'}
																			>
																				<span
																					style={{
																						width: '15%', 
																						minWidth: '130px', 
																						marginLeft: '10px',
																						marginBottom: '10px',
																						display: 'inline-block'
																					}} 
																				>
																					<div className="form-group" style={{marginBottom: '0'}}>
																						<input type="number" min="0" name="from" data-tipo={value.id} data-order={kC} value={vC.from !== undefined ? vC.from : ''} className="input-form" style={{textAlign: 'right'}} placeholder="Introduza o valor" onChange={handle_change_credits_commission} />
																						<span style={{padding: '12px'}}>€</span>
																					</div>
																				</span>
																			</span>

																			<span
																				className={vC.type !== undefined && (vC.type.label === 'Aprovação' || vC.type.label === 'Ativação') ? '' : 'd-none'}
																			>
																				<span
																					style={{
																						width: '15%', 
																						minWidth: '130px', 
																						marginLeft: '10px',
																						marginBottom: '10px',
																						display: 'inline-block'
																					}} 
																				>
																					<div className="form-group" style={{marginBottom: '0'}}>
																						<input type="number" min="0" name="from" data-tipo={value.id} data-order={kC} value={vC.from !== undefined ? vC.from : ''} className="input-form" style={{textAlign: 'right'}} placeholder="Introduza o valor" onChange={handle_change_credits_commission} />
																						<span style={{padding: '12px'}}>€</span>
																					</div>
																				</span>
																			</span>
																		</div>
																	)
																})
															:
																<div>
																	<button 
																		type="button"
																		className='btn' 
																		style={{
																			float: 'left', 
																			marginRight: '15px', 
																			marginTop: '5px', 
																			borderRadius: '50%', 
																			width: '30px', height: '30px', 
																			padding: '1px 0 0',
																			zIndex: '1000'
																		}}
																		onClick={() => add_range(value.id)}>
																		+
																	</button>
																	<span 
																		style={{
																			width: '200px', 
																			marginBottom: '10px',
																			display: 'inline-block'
																		}}
																	>
																		<Select
																			menuPortalTarget={document.body}
																			menuPosition={'fixed'}

																			className="div-select"
																			options={options_select}
																			value={null}
																			placeholder={"Introduza o filtro"}
																			onChange={(e) => change_commission(e, value.id, '0')}
																			styles={custom_select_styles}
																		/>
																	</span>
																</div>
															}
														</td>
														<td style={{textAlign: 'right'}}>
														{
															value['commissions'] !== undefined && value['commissions'].length > 0 ?
																value.commissions.map((vC, kC) => {
																	return (
																		<div 
																			key={kC} 
																			className={vC.type !== undefined && (vC.type.label === 'Aprovação' || vC.type.label === 'Ativação') ? 'd-none' : ''}
																		>
																			<span 
																				className="div-checkbox-group" 
																				style={{
																					width: '50px', 
																					padding: '8px',
																					float: 'left',
																					display: 'inline-block'
																				}}
																			>
																				<label key={key} className="div-checkbox">
																					<input
																						name={"status-" + value.id}
																						className="checkbox-form"
																						type="radio"
																						// type="checkbox"
																						data-tipo={value.id}
																						data-order={kC}
																						onChange={handleComissionCheck}
																						defaultChecked={vC['status'] !== undefined && vC.status === true ? true : false}
																						// defaultChecked={handleSingleCheck_ver(value.id)}
																					/>
																					<span className="checkmark"></span>
																				</label>
																			</span>
																			<span className={vC['status'] !== undefined && vC.status === true ? '' : 'd-none'} style={{padding: '12px'}}>Ativo</span>
																			<span 
																				style={{
																					width: '50%', 
																					marginBottom: '10px',
																					display: 'inline-block'
																				}}
																			>
																				<div className="form-group" style={{marginBottom: '0'}}>
																					<input type="number" min="0" step="any" name="value" value={vC.value} className={"input-form " + (vC['status'] !== undefined && vC.status === true ? 'active' : '')} style={{textAlign: 'right'}} onChange={(e) => change_commission_value(e, value.id, kC)} />
																					<span style={{padding: '12px'}}>%</span>
																				</div>
																			</span>
																		</div>
																	)
																})
															:
																null
														}
														</td>
													</tr>
												)
											})
											}
										</tbody>
									</table>
								</div>
							</form>
						</div>
					</div>
				</div>

				<div className={'dash-cards-2 footer'}>
					<nav className="navbar bottom-navbar navbar-light">
						<div className="navbar-footer">
							<ul className="navbar-nav float-left"></ul>
							<ul className="navbar-nav float-right">
								<li className="nav-item">
									<Link className="nav-link btn-cancel" to={{ pathname: endpoints.list }} replace>
										<img alt='' src={'./img/cancelar.svg'} />
										<span>Cancelar</span>
									</Link>
								</li>

								<li className={"nav-item " + show_delete_button}>
									<button onClick={apagar} className="nav-link btn-delete">
										<img alt='' src={'./img/eliminar_b.svg'} />
										<span>Eliminar</span>
									</button>
								</li>
								<li className="nav-item">
									<button type="submit" form="form" className="nav-link">
										<img alt='' src={'./img/save.svg'} />
										<span>Salvar</span>
									</button>
								</li>
							</ul>
						</div>
					</nav>
				</div>
			</main >
}
