import Hosts from '../../../Hosts';

//const object_type = 'Credits'

const api_crud_base = Hosts.SIMCore_API
const api_crud_route = `${Hosts.SIMCore_API}/maps/configs`
const api_list_route = `${Hosts.SIMCore_API}/maps/configs`

const base_endpoint = '/maps_configs'

const endpoints = {
    base: base_endpoint,
    regist: `${base_endpoint}_regist`,
    list: `${base_endpoint}_list`,
    view: `${base_endpoint}_view`,
}

const data_interface = {
    id: '',
    info: {
        mapa: [{ name: '', mapeamento: '' }],
        filtro: [{ name: '', mapeamento: '' }]
    },
    config: {
        mapeamento: [
            {
                valor: '',
                chave: ''
            }
        ]

    }

}


export {
    endpoints,
    api_crud_base,
    api_crud_route,
    data_interface,
    api_list_route
}