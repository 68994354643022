import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Redirect } from 'react-router-dom';

import DataTable from 'react-data-table-component';

import { getTokenInfo } from './../../../UseToken';

import { api_crud_base, api_list_route, api_crud_route, data_interface, endpoints } from './Config';

import { confirmAlert } from 'react-confirm-alert';

import {

	reqGET,
	reqPOST,
	reqDEL,
	reqPUT,

	// obter_cidades_por_distrito,
	// obter_freguesias_por_cidade,
	// obter_paises_em_pt,
	// obter_distrito_por_cidade,
	// obter_distritos_pt,

	// get_vat_info,

	pattern_vat
} from '../../../Utils';

//import { useHistory, Redirect } from "react-router-dom";
//import TextInput from 'react-autocomplete-input';


export default function Regist(props) {

	//export default function Financeira(props) ;
	//const Fornecedor = props => {

	// BEGIN


	//var history = useHistory();

	//Para Voltar ao URL Anterior
	//history.push(endpoints.list)

	//const id = useRef('');
	const [, set_dados] = useState(null);
	const dados = useRef(null);
	//const [local_interface, set_local_interface] = useState(JSON.parse(JSON.stringify(data_interface)));

	//console.log(dados.current)


	const [isloading, setLoading] = useState(true);
	const [isRedirect, setRedirect] = useState(false);
	const [refresh, setRefresh] = useState(0);

	const [show_delete_button, set_show_delete_button] = useState('d-none');

	const [onlyMaster, set_only_master] = useState('d-none')

	const [credit_types, set_credit_types] = useState([])
	const [financials, set_financials] = useState([])
	//const [financials_credits, set_financials_credits] = useState([])
	const [financials_credits_commission, set_financials_credits_commission] = useState(0)
	//const [financials_credits_commission_euro, set_financials_credits_commission_euro] = useState(0)
	const [financials_credits_aprovado, set_financials_credits_aprovado] = useState(0.0)
	const [financials_credits_ativado, set_financials_credits_ativado] = useState(0.0)
	const [cartao_aprovado, set_cartao_aprovado] = useState(0.0)
	const [cartao_ativado, set_cartao_ativado] = useState(0.0)
	const [estado_atual_credito, set_estado_atual_credito] = useState('')
	const [offices, set_offices] = useState([])
	const [responsables, set_responsables] = useState([])
	const [promotores, set_promotores] = useState([])
	const [disabled, set_disabled] = useState(true)
	const [is_visible_client_name, set_visible_client_name] = useState(false)

	const [data_table, set_data_table] = useState([])
	const [columns_table, set_columns_table] = useState([])

	//Temporário
	const [is_edit, set_is_edit] = useState(false)
	const [required, set_required] = useState(false)

	
	const custom_select_styles = {
		option: (provided, state) => ({
			...provided,
		}),
		container: base => ({
			...base,
			width: '100%'
		}),
		control: base => ({
			...base,
			borderRadius: "10px",
			padding: "2px 10px",
			fontSize: '14px',
			zIndex: 999
		}),
		menuPortal: base => ({
			...base,
			zIndex: 999
		}),
		menu: base => ({
			...base,
			zIndex: 999
		}),
	}

	const load_credit_types = async () => {
		reqGET(`${api_crud_base}/credits/types/${getTokenInfo().companycode}`)
			.then(res => {
				//console.log(res.data)
				set_credit_types(...[res.data.map(k => ({ value: k['id'], label: k.info['name'], etapa: k.info['etapa'] }))])
				//console.log(credit_types)
			})
	}

	const load_financials = async (tipo) => {

		return await reqGET(`${api_crud_base}/financials/list/special/${getTokenInfo().companycode}/${tipo}`)
			.then(res => {
				//console.log(res.data)
				set_financials(...[res.data.map(k => ({ value: k['id'], label: k['financials'] }))])

				return res.data.map(k => ({ value: k['id'], label: k['financials'] }))

				//console.log(financials)
			})
		//return tmp
	}

	const load_financial = async (id) => {

		console.log('----->ID:', id)
		return await reqGET(`${api_crud_base}/financials/${getTokenInfo().companycode}/${id}`)
			.then(res => {
				console.log('----->ID RES:', res)
				if(res.data.length!==0){
					return {id: res.data[0]['id'], credits: res.data[0]['credits'] }
				}
				else
				return {id: '', credits: [] }
				

			})
	}

	const load_workflow = async (id) => {

		reqGET(`${api_crud_base}/credits/types/${getTokenInfo().companycode}/${id}`)
			.then(res => {
				let aux_array = [];
				//console.log(res.data[0]);
				if (res.data[0].info.workflow)
					res.data[0].info.workflow.forEach((k) => {
						load_etapas(k['id'])
							.then(res => {
								res.forEach((k_2) => {
									k_2.estado['name'] = k['name'];
								})

								aux_array.push({
									id: k['id'],
									name: k['name'],
									ordem: k['ordem'],
									estado: 'on',
									etapas: res,
									responsavel: {
										id: '',
										name: ''
									},
									notificacao: {
										id: '',
										name: ''
									},
									data: '',
									deadline: ''
								})

								/* set_estados(...[{ 
									id: k['id'], 
									name: k['name'], 
									ordem: k['ordem'], 
									estado: 'on',
									etapas: res,
									responsavel: {
										id: '',
										name: ''
									},
									data: '',
									deadline: ''
								}]) */


								set_dados(dados.current.info.workflow = aux_array);
							})
					})

				// set_estados(...[res.data[0].info.estados.map(k => ({ 
				// 	id: k['id'], 
				// 	name: k['name'], 
				// 	ordem: k['ordem'], 
				// 	estado: 'on',
				// 	etapas: etapas,
				// 	responsavel: {
				// 		id: '',
				// 		name: ''
				// 	},
				// 	data: '',
				// 	deadline: ''
				// }))])
			})
	}

	const load_etapas = async (id) => {
		return await reqGET(`${api_crud_base}/credits/states/${id}`)
			.then(res => {
				//console.log(res.data[0])
				return res.data[0].info.estados.map(k => ({
					value: k['id'],
					label: k['name'],
					ordem: k['ordem'],
					estado: { id: id, name: '' },
					sucesso: res.data[0].info.sucesso,
					insucesso: res.data[0].info.insucesso,
					activo: false
				}))
				//console.log(etapas)
			})
	}

	const load_offices = async () => {
		reqGET(`${api_crud_base}/entities/list/${getTokenInfo().companycode}/Escritorio`)
			.then(res => {
				//console.log(res.data)
				let tmp = res.data.map(k => ({ value: k['id'], label: k['name'], ativo: k['ativo'] }))
				set_offices(...[tmp.filter(k=> k.ativo === 'true')])
				
				//console.log(offices)
			}
			)
	}

	const load_reponsables = async (office) => {
		reqGET(`${api_crud_base}/entities/list/special/${getTokenInfo().companycode}/${office}/Colaborador`)
			.then(res => {
				//console.log(res.data)

				let responsables_aux = []

				res.data.forEach(v => {
					if(v.info['ativo'] === true || v.info['ativo'] === "true")
						responsables_aux.push({ value: v['id'], label: v.info['name'] })
				})

				//set_responsables(...[res.data.map(k => ({ value: k['id'], label: k.info['name'] }))])
				//console.log(responsables)

				reqGET(`${api_crud_base}/entities/list/special/${getTokenInfo().companycode}/${office}/Gestor de Escritório`)
					.then(res2 => {
						//console.log(res.data)

						res2.data.forEach(v => {
							if(v.info['ativo'] === true || v.info['ativo'] === "true")
								responsables_aux.push({ value: v['id'], label: v.info['name'] })
						})

						//set_responsables(...[res2.data.map(k2 => ({ value: k2['id'], label: k2.info['name'] }))])
						//console.log(responsables)


						set_responsables(responsables_aux)
						
					}
					)

			}
			)
	}

	const load_promotores = async (office) => {
		reqGET(`${api_crud_base}/entities/list/special/${getTokenInfo().companycode}/${office}/Promotor`)
			.then(res => {
				//console.log(res.data)
				set_promotores(...[res.data.map(k => ({ value: k['id'], label: k.info['name'] }))])
				//console.log(promtores)
			}
			)
	}

	const load_cliente = async (vat) => {
		reqGET(`${api_crud_base}/entities/list/${getTokenInfo().companycode}/Cliente`)
			.then(res => {
				//console.log(res.data)
				// res.data.map((k) => {
				// 	if(k['vat'] === vat) {
				// 		set_dados(dados.current.info.Cliente.id = k['id']);
				// 		set_dados(dados.current.info.Cliente.name = k['name']);
				// 	}
				// });
				let ja_existe = false
				res.data.forEach((k) => {
					if (k['vat'] === vat) {
						ja_existe = true
						set_dados(dados.current.info.Cliente.id = k['id']);
						set_dados(dados.current.info.Cliente.name = k['name']);
						set_visible_client_name(true)
						//set_dados(dados.current.info.Cliente.name = k['vat']);
					}

				})
				if (ja_existe === false) {

					//<Link to={{ pathname: '/client_regist', state: { item: vat, operation: 'newFromCredit' } }} />
					//console.log('NÃƒO EXISTE ________________>');
					//console.log(history)


					confirmAlert({
						title: 'Entidade Inexistente',
						message: 'Pretende registar nova entidade ?',
						buttons: [
							{
								label: 'Sim',
								onClick: () => {
									props.history.push(
										{
											pathname: '/client_regist',
											state: { item: { vat: vat }, operation: 'newFromCredit' }
										}
									)
								}

							},
							{
								label: 'Não',
								onClick: () => { props.history.push('/credit_regist') }
							}
						]
					});



				} else {
					set_disabled(false)
					
					//console.log(ja_existe);
				}

			}
			)
	}


	const change_credit_type = e => {
		const { label, value, etapa } = e;

		//console.log(e)

		set_financials([])
		//
		load_financials(label).then(financial_list => {

			//console.log(financial_list)
			if (financial_list.length === 0) {
				set_disabled(true)
				
			}
			else {
				set_disabled(false)
				
				set_financials(financial_list)
			}
		})

		set_dados(dados.current.info.financials['id'] = '');
		set_dados(dados.current.info.financials['name'] = '');
		set_dados(dados.current.info.tipo_credito['id'] = value);
		set_dados(dados.current.info.tipo_credito['name'] = label);

		etapa.forEach((v) => {
			v.data = ''
			v.Colaborador = { id: '', name: '' }
			v.notificacao = { id: '', name: '' }
			v.deadline = ''
		})
		set_dados(dados.current.info.workflow = etapa);

		//console.log(dados.current.info.workflow)

		if (check_required_info('tipo_credito') && check_required_info('Escritorio')) {
			set_required(true)
		}
		else {
			set_required(false)
		}
	}

	const change_financials = e => {
		const { label, value } = e;

		// console.log('value', value)
		// console.log('label', label)

		//console.log('--------------------->financials', financials)

		set_dados(dados.current.info.financials['id'] = value);
		set_dados(dados.current.info.financials['name'] = label);






		load_financial(value).then(financial_commissions => {

			//set_financials_credits(financial_list)
			financial_commissions.credits.forEach(el => {
				if(el.id === dados.current.info.tipo_credito['id']) {
					el.commissions.forEach(elC => {
						if(elC.status === true) {
							if(elC.value !== '' && elC.value !== '0') {
								set_financials_credits_commission(elC.value)
								let v_comissao = (parseFloat(dados.current.commissioning.valor_financiado) * parseFloat(elC.value) / parseInt(100)).toFixed(2)
								//console.log('v_c', v_comissao)
								set_dados(dados.current.commissioning.valor_comissao = v_comissao)
							}
							else {
								if(elC.type.label === 'Aprovação' || elC.type.label === 'Ativação') {
									// console.log('elC1', elC)
									// console.log('estado atual', estado_atual_credito)

									if(elC.type.label === 'Aprovação') {
										set_financials_credits_aprovado(elC.from)
									}
									if(elC.type.label === 'Ativação') {
										set_financials_credits_ativado(elC.from)
									}

									//let v_comissao = parseFloat(elC.from).toFixed(2)
									//set_dados(dados.current.commissioning.valor_comissao = v_comissao)
								}
							}
						}
						else {
							if(elC.type.label === 'Aprovação' || elC.type.label === 'Ativação') {
								// console.log('elC2', elC)

								if(elC.type.label === 'Aprovação') {
									set_financials_credits_aprovado(elC.from)
								}
								if(elC.type.label === 'Ativação') {
									set_financials_credits_ativado(elC.from)
								}
								//console.log('estado atual', estado_atual_credito)
								//let v_comissao = parseFloat(elC.from).toFixed(2)
								//set_dados(dados.current.commissioning.valor_comissao = v_comissao)
							}
						}
					})
				}
			})

		})

		if (check_required_info('tipo_credito') && check_required_info('Escritorio')) {
			set_required(true)
		}
		else {
			set_required(false)
		}
	}

	const change_office = e => {
		const { label, value } = e;

		set_responsables([])
		set_promotores([])


		load_reponsables(label)
		load_promotores(label)


		//console.log(e)
		set_dados(dados.current.info.Colaborador.id = '');
		set_dados(dados.current.info.Colaborador.name = '');
		set_dados(dados.current.info.Promotor.id = '');
		set_dados(dados.current.info.Promotor.name = '');
		set_dados(dados.current.info.Escritorio.id = value);
		set_dados(dados.current.info.Escritorio.name = label);

		set_dados(dados.current.commissioning.commissoes.Escritorio.id = value);
		set_dados(dados.current.commissioning.commissoes.Escritorio.name = label);
		set_dados(dados.current.commissioning.commissoes.Colaborador.id = '');
		set_dados(dados.current.commissioning.commissoes.Colaborador.name = '');
		set_dados(dados.current.commissioning.commissoes.Promotor.id = '');
		set_dados(dados.current.commissioning.commissoes.Promotor.name = '');

		//console.log(dados.current)
		load_workflow(dados.current.info.tipo_credito.id)

		//if (check_required_info('tipo_credito') && check_required_info('financials') && check_required_info('Escritorio') && check_required_info('Colaborador')) {
		if (check_required_info('tipo_credito') && check_required_info('Escritorio')) {
			set_required(true)
		}
		else {
			set_required(false)
		}
	}

	const change_responsable = e => {
		const { label, value } = e;

		//console.log(e)
		set_dados(dados.current.info.Colaborador.id = value);
		set_dados(dados.current.info.Colaborador.name = label);

		set_dados(dados.current.commissioning.commissoes.Colaborador.id = value);
		set_dados(dados.current.commissioning.commissoes.Colaborador.name = label);
		//console.log(dados.current)

		//if (check_required_info('tipo_credito') && check_required_info('financials') && check_required_info('Escritorio') && check_required_info('Colaborador')) {
		if (check_required_info('tipo_credito') && check_required_info('Escritorio')) {
			set_required(true)
		}
		else {
			set_required(false)
		}

	}

	const change_promotor = e => {
		const { label, value } = e;

		//console.log(e)
		set_dados(dados.current.info.Promotor.id = value);
		set_dados(dados.current.info.Promotor.name = label);
		//console.log(dados.current)

		if(value !== '') {
			set_dados(dados.current.commissioning.commissoes['Escritorio']['valor'] = '30');
			set_dados(dados.current.commissioning.commissoes['Colaborador']['valor'] = '0');
			set_dados(dados.current.commissioning.commissoes['Promotor']['valor'] = '50');
		}
		else {
			set_dados(dados.current.commissioning.commissoes['Escritorio']['valor'] = '80');
			set_dados(dados.current.commissioning.commissoes['Colaborador']['valor'] = '0');
			set_dados(dados.current.commissioning.commissoes['Promotor']['valor'] = '0');
		}

		set_dados(dados.current.commissioning.commissoes.Promotor.id = value);
		set_dados(dados.current.commissioning.commissoes.Promotor.name = label);
	}


	const change_etapa = async e => {
		// console.log(e);
		// console.log('aprov', financials_credits_aprovado)
		// console.log('ativ', financials_credits_ativado)

		if (dados.current.info.workflow)
			dados.current.info.workflow.forEach(v => {
				if (e && e.estado.name === v.name) {
					v.estado.forEach(v_1 => {
						if (v_1.name === e.value) {
							//console.log(v_1.name + ' === ' + e.value);
							v_1.selected = true;
						}
						else {
							v_1.selected = false;
						}

						// console.log('tipo credito', dados.current.info.tipo_credito.name)
						if(dados.current.info.tipo_credito.name === 'Cartão de Crédito') {
							if(e.estado.name === 'Aprovação' && e.value === 'Aprovado') {
								
								if(financials_credits_aprovado !== undefined) {
									let v_comissao = parseFloat(financials_credits_aprovado).toFixed(2)
									set_dados(dados.current.commissioning.valor_comissao = v_comissao)
									set_cartao_aprovado(v_comissao)
									setRefresh(refresh + 1)
								}
								
							}
							else if(e.estado.name === 'Aprovação' && e.value !== 'Aprovado') {
								
								if(financials_credits_aprovado !== undefined) {
									set_dados(dados.current.commissioning.valor_comissao = 0.00)
									setRefresh(refresh + 1)
								}
								
							}
							if(e.estado.name === 'Ativação' && e.value === 'Realizada') {
								
								if(financials_credits_ativado !== undefined) {
									let v_comissao = parseFloat(financials_credits_ativado).toFixed(2)
									set_dados(dados.current.commissioning.valor_comissao = v_comissao)
									set_cartao_ativado((parseFloat(v_comissao) - parseFloat(cartao_aprovado)).toFixed(2))
									setRefresh(refresh + 1)
								}
								
							}
							else if(e.estado.name === 'Ativação' && e.value !== 'Realizada') {
								
								if(financials_credits_ativado !== undefined) {
									let v_comissao = parseFloat(financials_credits_aprovado).toFixed(2)
									set_dados(dados.current.commissioning.valor_comissao = v_comissao)
									set_cartao_ativado(0.0)
									setRefresh(refresh + 1)
								}
								
							}

							// if(estado_atual_credito === 'Completo') {
							// 	console.log('etapa nome', e.estado.name)
							// 	console.log('estado', estado_atual_credito)
							// }
						}
					})
				}
			})

		set_dados(dados)
		setRefresh(refresh + 1)
	}
	const change_etapa_responsavel = async e => {
		if (dados.current.info.workflow)
			dados.current.info.workflow.forEach(v => {

				if (e.estado && e.estado.name === v.name) {
					v.Colaborador.id = e.value;
					v.Colaborador.name = e.label;
				}
			})
		setRefresh(refresh + 1)
	}
	const change_etapa_notificacao = async e => {
		if (dados.current.info.workflow)
			dados.current.info.workflow.forEach(v => {

				if (e.estado && e.estado.name === v.name) {
					v.notificacao.id = e.value;
					v.notificacao.name = e.label;
				}
			})
		setRefresh(refresh + 1)
	}


	const change_estado_data = async (e, name) => {
		//console.log(e);
		//console.log(name);
		//console.log(estados);

		/* setEtapaData(e); */

		if (dados.current.info.workflow)
			dados.current.info.workflow.forEach(v => {
				if (name && name === v.name) {
					v.data = e;
				}
			})

		//set_dados(dados)
		setRefresh(refresh + 1)

	}
	const change_estado_deadline = async (e, name) => {
		//console.log(e);
		//console.log(estados);

		//setEtapaDeadline(e);
		if (dados.current.info.workflow)
			dados.current.info.workflow.forEach(v => {
				if (name && name === v.name) {
					v.deadline = e;
				}
			})

		//set_dados(dados.current.info.workflow = estados);
		//set_dados(dados)
		setRefresh(refresh + 1)
	}


	/* 	const change_client = e => {
			//console.log(dados.current.info.Cliente.vat)
			load_cliente(dados.current.info.Cliente.vat);
			//console.log(dados.current)
		}
	 */
	const fetch_id_info = async (id_serch) => {
		return await reqGET(`${api_list_route(getTokenInfo().companycode)}/${id_serch}`)
			.then(res => {
				set_dados(dados.current = res.data[0]);

				//console.log(getTokenInfo().profile)
				if (getTokenInfo().profile === 'Master' || getTokenInfo().profile === 'Administrador' || getTokenInfo().profile === 'Colaborador' || getTokenInfo().profile === 'Gestor de Escritório') {
					set_only_master('')
					set_disabled(false)
					
				}
				else {
					set_only_master('d-none')
					set_disabled(true)
					//set_disabled(false)
					
				}

				set_data_table([
					{
						id: 1,
						name: 'Master',
						cargo: 'Master',
						nome: 'Master' + res.data[0].commissioning.commissoes.Master.name,
						valor: res.data[0].commissioning.commissoes.Master.valor
					},
					{
						id: 2,
						name: 'Escritorio',
						cargo: 'Escritório',
						nome: 'Escritório: ' + res.data[0].commissioning.commissoes.Escritorio.name,
						valor: res.data[0].commissioning.commissoes.Escritorio.valor
					},
					{
						id: 3,
						name: 'Colaborador',
						cargo: 'Responsavel',
						nome: 'Responsável: ' + res.data[0].commissioning.commissoes.Colaborador.name,
						valor: res.data[0].commissioning.commissoes.Colaborador.valor
					},
					{
						id: 4,
						name: 'Promotor',
						cargo: 'Promotor',
						nome: 'Promotor: ' + res.data[0].commissioning.commissoes.Promotor.name,
						valor: res.data[0].commissioning.commissoes.Promotor.valor
					},
				]);
				set_columns_table([
					{
						name: 'Cargo',
						selector: 'cargo',
						sortable: false,
					},
					{
						name: 'Nome',
						selector: 'nome',
						sortable: false,
					},
					{
						name: 'Valor',
						selector: 'valor',
						cell: row => {
							let valor = row['valor']
							
							return(
								<div>
									<input name="percentagem" data-id={row['id']} data-cargo={row['name']} value={valor} onChange={(e) => handle_change_percentagens(e)} style={{width: '80px', textAlign: 'right'}} /> %
								</div>
							)
						},
						sortable: false,
						right: true
					},
				]);

			})
			.catch(error => error/*TODO: GO TO FAIL FETCH*/)
	}

	// PARA INSTANCIAR O COMPONENTE COM O ID 
	//const props_tmp = props
	useEffect(() => {


		let load_financials_new = async(credit_type_label) =>{
			//dados.current.info.financials.name
			let financial_list = await load_financials(credit_type_label)
			set_financials(financial_list)
		/* 	.then(financial_list => {

				//console.log(financial_list)
				if (financial_list.length === 0) {
					set_disabled(true)
					
				}
				else {
					set_disabled(false)
					
					set_financials(financial_list)
				}

				set_dados(dados.current.info.financials.name)
			}) */

		}



		const clean_data = () => {
			// DEEP COPY OF data_interface 
			set_dados(dados.current = JSON.parse(JSON.stringify(data_interface)))
			set_dados(dados.current.info.companycode = getTokenInfo().companycode)
			//console.log(dados.current)
		}


		clean_data();

		load_credit_types();
		load_offices();

		//setLoading(false);

		try {
			let test = props.location.state.operation;

			if (test === 'edit') {
				set_required(true)
				set_disabled(false)
				
				set_is_edit(true)
				//if (props.location.state !== undefined) {
				//console.log('->>>>>>>>>>>>>>>>:' + props.location.state.operation)
				//if (props.location.state.operation === 'edit') {
				set_show_delete_button('')
				set_visible_client_name(true)
				//console.log('AQUI DENTRO DO STATE')
				fetch_id_info(props.location.state.item['id']).then(() => {

					set_responsables([])
					set_promotores([])
					load_reponsables(dados.current.info.Escritorio.name)
					load_promotores(dados.current.info.Escritorio.name)


					//change_financials({ label: dados.current.info.financials.name, value: dados.current.info.financials.id })

					//load_financials(dados.current.info.financials.name).then(list => set_financials(list))
					
					load_financials_new(dados.current.info.tipo_credito.name)


					if(dados.current.info.workflow) {
						dados.current.info.workflow.forEach((v_1) => {
							if (v_1.estado)
								v_1.estado.forEach((v_2) => {
									let aux_estado = '';

									if (v_1.data.localeCompare("") !== 0) {
										if (v_2.selected) {
											if (v_2.pendente === false && v_2.completo === true) {
												aux_estado = 'Completo';
												// console.log(aux_estado)
												//dados.current.info.estado = aux_estado
												set_estado_atual_credito(aux_estado)

												if(dados.current.info.financials.id !== '') {
													load_financial(dados.current.info.financials.id).then(financial_commissions => {
							
														//set_financials_credits(financial_list)
														financial_commissions.credits.forEach(el => {
															if(el.id === dados.current.info.tipo_credito['id']) {
																el.commissions.forEach(elC => {
																	if(elC.status === true) {
																		if(elC.value !== '' && elC.value !== '0') {
																			set_financials_credits_commission(elC.value)
																			let v_comissao = (parseFloat(dados.current.commissioning.valor_financiado) * parseFloat(elC.value) / parseInt(100)).toFixed(2)
																			// console.log('v_c', v_comissao)
																			set_dados(dados.current.commissioning.valor_comissao = v_comissao)
																		}
																		else {
																			if(elC.type.label === 'Aprovação' || elC.type.label === 'Ativação') {
																				// console.log('estado atual', estado_atual_credito)

																				if(elC.type.label === 'Aprovação') {
																					set_financials_credits_aprovado(elC.from)
																					//set_cartao_aprovado(parseFloat(elC.from).toFixed(2))
																				}
																				if(elC.type.label === 'Ativação') {
																					set_financials_credits_ativado(elC.from)
																					//set_cartao_ativado((parseFloat(elC.from) - parseFloat(cartao_aprovado)).toFixed(2))
																				}

																				//let v_comissao = parseFloat(elC.from).toFixed(2)
																				//set_dados(dados.current.commissioning.valor_comissao = v_comissao)
																			}
																		}
																	}
																	else {
																		if(elC.type.label === 'Aprovação' || elC.type.label === 'Ativação') {
											
																			if(elC.type.label === 'Aprovação') {
																				set_financials_credits_aprovado(elC.from)
																				// set_cartao_aprovado(parseFloat(elC.from).toFixed(2))

																				if(v_1.name === 'Aprovação') {
																					let y = v_1.estado.filter(x => x.selected === true)
																					if(y[0].name === 'Aprovado') {
																						// console.log('Aprovado')
																						set_cartao_aprovado(parseFloat(elC.from).toFixed(2))
																					}
																				}
																			}
																			if(elC.type.label === 'Ativação') {
																				set_financials_credits_ativado(elC.from)
																				// set_cartao_ativado((parseFloat(elC.from) - parseFloat(cartao_aprovado)).toFixed(2))

																				if(v_1.name === 'Ativação') {
																					let y = v_1.estado.filter(x => x.selected === true)
																					if(y[0].name === 'Realizada') {
																						// console.log('Realizada')
																						set_cartao_ativado((parseFloat(elC.from) - parseFloat(cartao_aprovado)).toFixed(2))
																					}
																				}
																			}
																			//console.log('estado atual', estado_atual_credito)
																			//let v_comissao = parseFloat(elC.from).toFixed(2)
																			//set_dados(dados.current.commissioning.valor_comissao = v_comissao)
																		}
																	}
																})
															}
														})
											
													})
												}
											}
											else if (v_2.selected === true && v_2.initial !== true && v_2.pendente !== true && v_2.completo === false) {
												aux_estado = 'Cancelado';
												// console.log(aux_estado)
												//dados.current.info.estado = aux_estado
												set_estado_atual_credito(aux_estado)

											}
											else {
												aux_estado = v_1.name;
												// console.log(aux_estado)
												//dados.current.info.estado = aux_estado
												set_estado_atual_credito(aux_estado)

												if(dados.current.info.financials.id !== '') {
													load_financial(dados.current.info.financials.id).then(financial_commissions => {
							
														//set_financials_credits(financial_list)
														financial_commissions.credits.forEach(el => {
															if(el.id === dados.current.info.tipo_credito['id']) {
																el.commissions.forEach(elC => {
																	if(elC.status === true) {
																		if(elC.value !== '' && elC.value !== '0') {
																			set_financials_credits_commission(elC.value)
																			let v_comissao = (parseFloat(dados.current.commissioning.valor_financiado) * parseFloat(elC.value) / parseInt(100)).toFixed(2)
																			// console.log('v_c', v_comissao)
																			set_dados(dados.current.commissioning.valor_comissao = v_comissao)
																		}
																		else {
																			if(elC.type.label === 'Aprovação' || elC.type.label === 'Ativação') {
																				// console.log('estado atual', estado_atual_credito)

																				if(elC.type.label === 'Aprovação') {
																					set_financials_credits_aprovado(elC.from)
																				}
																				if(elC.type.label === 'Ativação') {
																					set_financials_credits_ativado(elC.from)
																				}

																				//let v_comissao = parseFloat(elC.from).toFixed(2)
																				//set_dados(dados.current.commissioning.valor_comissao = v_comissao)
																			}
																		}
																	}
																	else {

																		console.log('elC.value', elC.value)

																		if(elC.type.label === 'Aprovação' || elC.type.label === 'Ativação') {
																			console.log('elC2', elC)
											
																			if(elC.type.label === 'Aprovação') {
																				set_financials_credits_aprovado(elC.from)
																			}
																			if(elC.type.label === 'Ativação') {
																				set_financials_credits_ativado(elC.from)
																			}
																			//console.log('estado atual', estado_atual_credito)
																			//let v_comissao = parseFloat(elC.from).toFixed(2)
																			//set_dados(dados.current.commissioning.valor_comissao = v_comissao)
																		}
																	}
																})
															}
														})
											
													})
												}
											}
										}
									}
									else {
									}
									
								})

						});
					}


					//set_financials_credits_commission()

					setLoading(false);
				})
			}
			else {
				if (test === 'newFromClient') {
					//console.log('------->newFromClient')

					set_show_delete_button('')
					//let tmp = props.location.state.item['cliente']
					//console.log(tmp)

					dados.current.info.Cliente = props.location.state.item['cliente']
					set_visible_client_name(true)
					set_dados(dados)
					setLoading(false);

					set_disabled(false)
					
				}
				else {



					// VAI REGISTAR
					if (test === 'null') {
						setLoading(false);
					}
					else {
						//console.log('ALI ELSE')
						setLoading(false);

						//set_show_vat_form('')
						setRedirect(true);
						/* else if (props.location.state.operation === 'new') {
							set_show_vat_form('');
							setLoading(false);
						} */
					}
				}
			}
		} catch {

			clean_data()

			setLoading(false);
		}

		// BACK
		//props.history.push(endpoints.list)
		return () => {
			clean_data()
		}

	}, [props])


	function check_required_info(name) {
		if (dados.current.info[name] && dados.current.info[name].id === '') {
			return false
		}
		else {
			return true;
		}
	}

	const handleSubmit = async e => {
		e.preventDefault();

		if (is_edit === true) {
			//alert('Atualizar PUT')

			let v0 = dados.current.commissioning.commissoes['Master']['valor']
			let v1 = dados.current.commissioning.commissoes['Escritorio']['valor']
			let v2 = dados.current.commissioning.commissoes['Colaborador']['valor']
			let v3 = dados.current.commissioning.commissoes['Promotor']['valor']

			let vt = parseFloat(v0) + parseFloat(v1) + parseFloat(v2) + parseFloat(v3)

			if(vt !== 100) {

				confirmAlert({
					title: 'Aviso',
					message: 'Valor das comissões inválidos!',
					buttons: [
						{
							label: 'Continuar',
							onClick: () => {
								
							}
	
						}
					]
				});
				
			}
			else {
	
				if (!check_required_info('tipo_credito')) {
					alert('Tipo de Crédito em falta.')
					return
				}
				//if (!check_required_info('financials')) {
				//	alert('Financeira em falta.')
				//	return
				//}
				if (!check_required_info('Escritorio')) {
					alert('Escritório em falta.')
					return
				}
				//if (!check_required_info('Colaborador')) {
				//	alert('Colaborador em falta.')
				//	return
				//}
	
				dados.current.info.workflow.forEach(v => {
					if (v.notificacao && v.notificacao.id !== '')
						v.estado.forEach(v_1 => {
	
							if (v_1.selected === true && v_1.completo === false) {
								reqGET(`${api_crud_base}/entities/${v.notificacao.id}`)
									.then(res => {
										//console.log(res.data)
	
										res.data[0].notifications.push({
											value: props.location.state.item['id'],
											label: v.name,
											data: v.deadline,
											is_checked: false,
											cliente: { id: dados.current.info.Cliente.id, name: dados.current.info.Cliente.name },
											notificacao: { id: v.notificacao.id, name: v.notificacao.name },
											type: { id: props.location.state.item['id'], endpoint: '/credits/credits/' },
											message: ''
										})
	
										reqPUT(`${api_crud_base}/entities/`, res.data[0])
											.then(() => alert('Registo Atualizado com Sucesso!'))
											.catch(() => alert('Problema ao atualizar Registo!'))
									})
							}
						})
				})
	
				reqPUT(api_crud_route, dados.current)
					.then(() => alert('Registo Atualizado com Sucesso!'))
					.catch(() => alert('Problema ao atualizar Registo!'))

				props.history.push(endpoints.list)

			}


			

		} else {

			if(dados.current.commissioning.commissoes['Promotor']['id'] !== '') {
				set_dados(dados.current.commissioning.commissoes['Escritorio']['valor'] = '30');
				set_dados(dados.current.commissioning.commissoes['Colaborador']['valor'] = '0');
				set_dados(dados.current.commissioning.commissoes['Promotor']['valor'] = '50');
			}
			else {
				set_dados(dados.current.commissioning.commissoes['Escritorio']['valor'] = '80');
				set_dados(dados.current.commissioning.commissoes['Colaborador']['valor'] = '0');
				set_dados(dados.current.commissioning.commissoes['Promotor']['valor'] = '0');
			}

			if (!check_required_info('tipo_credito')) {
				alert('Tipo de Crédito em falta.')
				return
			}
			//if (!check_required_info('financials')) {
			//	alert('Financeira em falta.')
			//	return
			//}
			if (!check_required_info('Escritorio')) {
				alert('Escritório em falta.')
				return
			}
			//if (!check_required_info('Colaborador')) {
			//	alert('Colaborador em falta.')
			//	return
			//}

			//alert('Inserir POST')
			reqPOST(api_crud_route, dados.current)
				.then(() => alert('Registo Inserido com Sucesso!'))
				.catch(() => alert('Problema ao atualizar Registo!'))

			props.history.push(endpoints.list)
		}

		
	}



	function apagar() {


		confirmAlert({
			title: 'Eliminar Crédito',
			message: 'Pretende mesmo eliminar este registo ?',
			buttons: [
				{
					label: 'Sim',
					onClick: () => {


						alert('Eliminou')
						reqDEL(api_crud_route + '/', { id: dados.current.id })
							.then(res => {
								alert(res.status === 200 ?
									'Registo Apagado com Sucesso' : 'Problema ao Apagar Registo');

								props.history.push(endpoints.list);
							})
							.catch(error => alert(error))



					}
				},
				{
					label: 'Não',
					onClick: () => { return }
				}
			]
		});
	};


	const handle_change_info = (e) => {
		const { name, value } = e.target;

		/* DEBUG*/

		//console.log('NAME:' + name)
		//console.log('Value:' + value)

		set_dados(dados.current.info[name] = value)

		//console.log('Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«')
		//console.log('Dados Current:' + JSON.stringify(dados))

	}

	const handle_change_info_client = (e) => {
		const { name, value } = e.target;

		/* DEBUG*/

		/* //console.log(e)

		//console.log('NAME:' + name)
		//console.log('Value:' + value) */

		set_dados(dados.current.info.Cliente[name] = value)

		if (dados.current.info.Cliente['vat'].length < 9) {
			dados.current.info.Cliente.name = ''
			set_disabled(true)
			
			set_visible_client_name(false)
		}

		if (dados.current.info.Cliente['vat'].length === 9) {
			load_cliente(dados.current.info.Cliente.vat)
		}


		//console.log('Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«')
		//console.log('Dados Current:' + JSON.stringify(dados))

	}

	const handle_change_commissioning = (e) => {
		const { name, value } = e.target;

		/* DEBUG*/

		//console.log('NAME:' + name)
		//console.log('Value:' + value)

		if(name === 'valor_financiado') {
			if(financials_credits_commission !== undefined) {
				let v_comissao = (parseFloat(value) * parseFloat(financials_credits_commission) / parseInt(100)).toFixed(2)
				set_dados(dados.current.commissioning.valor_comissao = v_comissao)
			}
		}

		set_dados(dados.current.commissioning[name] = value)

		//console.log('Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«')
		//console.log('Dados Current:' + JSON.stringify(dados))

	}

	const handle_change_percentagens = (e) => {
		const { name, value, dataset } = e.target;
		const { id, cargo } = dataset
		// console.log('name', name)
		// console.log('value', value)
		// console.log('id', id)
		// console.log('cargo', cargo)

		if(value <= 100) {

			if(dados.current.commissioning.commissoes[cargo] !== undefined)
				set_dados(dados.current.commissioning.commissoes[cargo]['valor'] = value)

			let total = 0.0
			let aux = data_table
			// console.log('aux', aux)
			// console.log('data_table', data_table)
			aux.forEach(el => {
				if(el.name === cargo) {
					// console.log('el', el)
					el.valor = value
				}
				total = parseFloat(total) + parseFloat(el.valor)
			})

			if(total !== 100) {
				confirmAlert({
					title: 'Aviso',
					message: 'Valor das comissões é diferente de 100%!',
					buttons: [
						{
							label: 'Continuar',
							onClick: () => {
								
							}
	
						}
					]
				});
			}

			// console.log('total', total)
			set_data_table(aux);

			if(dados.current.commissioning.commissoes[cargo] !== undefined)
				set_dados(dados.current.commissioning.commissoes[cargo]['valor'] = value)
			setRefresh(refresh + 1)

		}
		else {

			confirmAlert({
				title: 'Aviso',
				message: 'Valor inválido!',
				buttons: [
					{
						label: 'Continuar',
						onClick: () => {
							
						}

					}
				]
			});
		}

		
		// setRefresh(refresh + 1)

	}

	/* 	function sortResults(json, prop, asc) {
			json.sort(function (a, b) {
				if (asc) {
					return (a[prop] > b[prop]) ? 1 : ((a[prop] < b[prop]) ? -1 : 0);
				} else {
					return (b[prop] > a[prop]) ? 1 : ((b[prop] < a[prop]) ? -1 : 0);
				}
			});
		} */

	const customStyles = {
		option: (provided, state) => ({
			...provided,
		}),
		control: (provided) => ({
			...provided,
			borderRadius: "10px",
			padding: "2px 10px",
		})
	}

	return isloading ?
		<main>
			<div className={`dash-cards-2 header`}>
				<h2 className="dash-title">LOADING</h2>
				<p>LOADING</p>
				<div className="overlay">
					<div className="overlay__inner">
						<div className="overlay__content"><span className="spinner"></span></div>
					</div>
				</div>
			</div>
		</main>
		:
		isRedirect
			?
			<Redirect to={endpoints.list} replace />
			:
			<main>
				<div className={`dash-cards-2 header`}>
					<h2 className="dash-title">Créditos</h2>
					<p>Home / Créditos / Inserir Crédito</p>
				</div>

				<div className={`dash-cards-2 body`}>
					<div className="card">
						<div className="card-body">
							<form id="form" onSubmit={handleSubmit}>

								<div className="form-group">
									<span className="flex-1">
										<label>NIF (Cliente):</label>
										{/* <input type="text" disabled={disabled ? true : false} name="vat" value={dados.current.info.Cliente.vat} className="form-control" placeholder="Introduza o NIF do cliente" onBlur={(e) => { change_client() }} onChange={handle_change_info_client} /> */}
										<input type="text" pattern={pattern_vat} minLength="9" inputMode='numeric' disabled={is_edit && disabled ? true : false} name="vat" value={dados.current.info.Cliente.vat} className="form-control" placeholder="Introduza o NIF do cliente" onChange={handle_change_info_client} />
									</span>

									<span className="flex-1" style={{ display: is_visible_client_name === true ? '' : 'none' }}>
										<label>Nome (Cliente):</label>
										{/* <input type="text" disabled={disabled ? true : false} name="vat" value={dados.current.info.Cliente.vat} className="form-control" placeholder="Introduza o NIF do cliente" onBlur={(e) => { change_client() }} onChange={handle_change_info_client} /> */}
										<input type="text" disabled={true} name="name" value={dados.current.info.Cliente.name} className="form-control" />
									</span>

									<span className="flex-2">

									</span>
								</div>


								<input type="hidden" name="id" value={dados.current.id} />

								<div className="form-group">
									<span className="flex-1" style={{ display: is_visible_client_name === true ? '' : 'none' }}>
										<label>Tipo de Crédito:</label>
										<Select
											className="div-select"
											isDisabled={disabled ? true : false}
											menuPortalTarget={document.body}
											menuPosition={'fixed'}
											options={credit_types}
											name="credit_type"
											value={dados.current.info.tipo_credito.id === '' ? null : { label: dados.current.info.tipo_credito.name, value: dados.current.info.tipo_credito.id }}
											placeholder="Introduza o tipo de crédito"
											onChange={change_credit_type}
											styles={custom_select_styles}
										/>
									</span>
									<span className="flex-1">
										<label>Entidade Financeira</label>
										<Select
											menuPortalTarget={document.body}
											menuPosition={'fixed'}

											className="div-select"
											//isDisabled={disabled ? true : false}
											options={financials}
											name="financials"
											value={dados.current.info.financials.id === '' ? null : { label: dados.current.info.financials.name, value: dados.current.info.financials.id }}
											placeholder={disabled ? 'Sem Financeira Associada' : "Introduza a Entidade Financeira"}
											onChange={change_financials}
											styles={custom_select_styles}
										/>
									</span>
								</div>

								<div className="form-group">
									<span className="flex-1">
										<label>Escritório:</label>
										<Select
										
											menuPortalTarget={document.body}
											menuPosition={'fixed'}

											className="div-select"
											isDisabled={disabled ? true : false}
											options={offices}
											name="office"
											value={dados.current.info.Escritorio.id === '' ? null : { label: dados.current.info.Escritorio.name, value: dados.current.info.Escritorio.id }}
											placeholder="Introduza o escritório"
											onChange={change_office}
											styles={custom_select_styles}
										/>
									</span>
									<span className="flex-1">
										<label>Colaborador Responsável:</label>
										<Select
											menuPortalTarget={document.body}
											menuPosition={'fixed'}
											className="div-select"
											//isDisabled={disabled ? true : false}
											options={responsables}
											name="responsable"
											value={dados.current.info.Colaborador && dados.current.info.Colaborador.id === '' ? null : { label: dados.current.info.Colaborador.name, value: dados.current.info.Colaborador.id }}
											placeholder="Introduza o colaborador responsável"
											onChange={change_responsable}
											styles={custom_select_styles}
										/>
									</span>

									<span className="flex-1" disabled>
										<label>Promotor:</label>

										<Select
											className="div-select"
											//isDisabled={disabled ? true : false}
											options={promotores}
											name="promotor"
											value={dados.current.info.Promotor.id === '' ? null : { label: dados.current.info.Promotor.name, value: dados.current.info.Promotor.id }}
											placeholder="Introduza o promotor"
											onChange={change_promotor}
											styles={custom_select_styles}
										/>
									</span>
								</div>

								<div className="form-group">
									<span className="flex-1">
										<label>Valor Financiado:</label>
										<input type="number" min="0" disabled={disabled ? true : false} name="valor_financiado" value={dados.current.commissioning.valor_financiado} pattern="^[0-9]+(\.[0-9]{1,2})?$" className="form-control" placeholder="Introduza o valor financiado" onChange={handle_change_commissioning} required />
									</span>
									<span className="flex-1">
										<div className={dados.current.info.tipo_credito.name.includes('Habita') === true ? '' : 'd-none'}>
											<label>Valor Estimado de Avaliação</label>
											<input type="number" min="0" disabled={disabled ? true : false} name="valor_estimado" value={dados.current.commissioning.valor_estimado} pattern="^[0-9]+(\.[0-9]{1,2})?$" className="form-control" placeholder="Introduza o valor estimado de avaliação" onChange={handle_change_commissioning} />
										</div>
									</span>
									<span className="flex-1">
										<div className={dados.current.info.tipo_credito.name.includes('Habita') === true ? '' : 'd-none'}>
											<label>Valor de Escritura</label>
											<input type="number" min="0" disabled={disabled ? true : false} name="valor_escritura" value={dados.current.commissioning.valor_escritura} pattern="^[0-9]+(\.[0-9]{1,2})?$" className="form-control" placeholder="Introduza o valor de escritura" onChange={handle_change_commissioning} />
										</div>
									</span>
								</div>
								<div className="form-group">
									<span className="flex-2">
										<label>Observações</label>
										<textarea type="text" name="observation" value={dados.current.info.observation} rows="4" onChange={handle_change_info} ></textarea>
									</span>
									<span className="flex-1">

									</span>
								</div>

								<h2 className={"dash-title " + onlyMaster}>Comissionamento</h2>
								<div className={"form-group " + onlyMaster}>
									<span className={"flex-1" + (parseFloat(cartao_aprovado) > 0.0 ? '' : ' d-none')}>
										<label>Cartão Aprovado:</label>
										<input type="number" min="0" value={cartao_aprovado} pattern="^[0-9]+(\.[0-9]{1,2})?$" className={"form-control" + (parseFloat(cartao_aprovado) > 0.0 ? '' : ' d-none')} readOnly={true} />
									</span>
									<span className={"flex-1" + (parseFloat(cartao_ativado) > 0.0 ? '' : ' d-none')}>
										<label>Cartão Ativado:</label>
										<input type="number" min="0" value={cartao_ativado} pattern="^[0-9]+(\.[0-9]{1,2})?$" className={"form-control" + (parseFloat(cartao_ativado) > 0.0 ? '' : ' d-none')} readOnly={true} />
									</span>
									<span className="flex-1">
										<label>Valor Comissão:</label>
										<input type="number" step=".01" min="0" name="valor_comissao" value={dados.current.commissioning.valor_comissao} pattern="^[0-9]+(\.[0-9]{1,2})?$" className="form-control" placeholder="Introduza o valor da comissão" onChange={handle_change_commissioning} />
									</span>
									<span className="flex-2">
									</span>
								</div>
								<div className={"form-group " + onlyMaster}>
									<div className="flex-1">
										<div className="div-table d-none">
											<div className="table-body">
												<DataTable
													noDataComponent='Sem Registos'
													noHeader
													columns={columns_table}
													data={data_table}
												/>
											</div>
										</div>

										<div className="div-table">
											<div className="table-body">
												<table style={{width: '100%'}}>
													<thead>
														<tr>
															<td style={{width: '30%'}}><label>Cargo</label></td>
															<td style={{width: '40%'}}><label>Nome</label></td>
															<td style={{width: '30%', textAlign: 'right'}}><label>Valor</label></td>
														</tr>
													</thead>
													<tbody>
														{
														data_table.map((el, k) => {
															return (
																<tr key={k}>
																	<td>{el.cargo}</td>
																	<td>{el.nome}</td>
																	<td style={{textAlign: 'right'}}>
																		<input type="number" min="0" name="percentagem" data-id={el['id']} data-cargo={el['name']} defaultValue={el.valor} onBlur={(e) => handle_change_percentagens(e)} style={{width: '80px', textAlign: 'right'}} /> %
																	</td>
																</tr>
															)
														})
														}
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>

								<div className="form-group-2">
									<h2 className="dash-title">Processos</h2>
									{
										dados.current.info.workflow.map((value, key) => {
											var responsables_aux = [];
											var etapas_aux = [];
											var selected_aux = '';
											var class_aux = '';
											if (value.data !== '') {
												class_aux = 'on-course';
											}
											if (responsables)
												responsables.forEach(v => {
													responsables_aux.push({ value: v.value, label: v.label, estado: { id: value.id, name: value.name } });
												})
											if (value.estado)
												value.estado.forEach(v => {
													if (v.selected) {
														selected_aux = { value: v.name, label: v.name }

														if (v.pendente === false && v.completo === true && v.initial === false) {
															class_aux = 'success';
														}
														else if (v.pendente === false && v.completo === false && v.initial === false) {
															class_aux = 'insuccess';
														}
														else if(v.name === 'NA') {
															class_aux = 'success';
														}
													}

													etapas_aux.push({ value: v.name, label: v.name, estado: { id: value.name, name: value.name }, selected: v.selected });
												})
											return (
												<div key={key} className={class_aux}>
													<label>{value.name}</label>
													<div className="form-group">
														<span className="flex-1">
															<label>Data</label>
															<input type="date" className="form-control" name="data" value={value.data} onChange={(e) => change_estado_data(e.target.value, value.name)} />
														</span>
														<span className="flex-1">
															<label>Responsável</label>
															{
																<Select
																	className="div-select"
																	options={responsables_aux}
																	name="estado"
																	value={value.Colaborador.id === '' ? null : { label: value.Colaborador.name, value: value.Colaborador.id }}
																	placeholder="Introduza o responsável"
																	onChange={change_etapa_responsavel}
																	menuPlacement="top"
																	styles={customStyles}
																/>
															}
														</span>
														<span className="flex-1">
															<label>Etapa</label>
															{
																<Select
																	className="div-select"
																	options={etapas_aux}
																	name="estado"
																	value={selected_aux === '' ? null : selected_aux}
																	placeholder="Introduza a etapa"
																	onChange={change_etapa}
																	menuPlacement="top"
																	styles={customStyles}
																/>
															}
														</span>
														<span className="flex-1">
															<label>Notificação</label>
															{
																<Select
																	className="div-select"
																	options={responsables_aux}
																	name="estado"
																	value={value.notificacao.id === '' ? null : { label: value.notificacao.name, value: value.notificacao.id }}
																	placeholder="Introduza o notificação"
																	onChange={change_etapa_notificacao}
																	menuPlacement="top"
																	styles={customStyles}
																/>
															}
														</span>
														<span className="flex-1">
															<label>Deadline</label>
															<input type="date" className="form-control" name="deadline" value={value.deadline} min={value.data !== '' ? value.data : ''} onChange={(e) => change_estado_deadline(e.target.value, value.name)} />
														</span>
													</div>
												</div>
											)
										})
									}
								</div>

							</form>
						</div>
					</div>
				</div>

				<div className={'dash-cards-2 footer'}>
					<nav className="navbar bottom-navbar navbar-light">
						<div className="navbar-footer">
							<ul className="navbar-nav float-left"></ul>
							<ul className="navbar-nav float-right">
								<li className="nav-item">
									<Link className="nav-link btn-cancel" to={{ pathname: endpoints.list }}  >

										<img alt='' src={'./img/cancelar.svg'} />
										<span>Cancelar</span>
									</Link>
								</li>

								<li className={"nav-item " + show_delete_button}>
									<button onClick={apagar} className="nav-link btn-delete">

										<img alt='' src={'./img/eliminar_b.svg'} />
										<span>Eliminar</span>
									</button>
								</li>
								<li className="nav-item" data-class={required} data-class2={disabled}>
								<button disabled={(disabled || !required) ? true : false} type="submit" form="form" className="nav-link">
	{/*<button disabled={(disabled) ? true : false} type="submit" form="form" className="nav-link">*/}

										<img alt='' src={'./img/save.svg'} />
										<span>Salvar</span>
									</button>
								</li>
							</ul>
						</div>
					</nav>
				</div>
			</main >
}
