import { useState } from 'react';


import jwt_decode from "jwt-decode";


const getTokenInfo = () => {

  const tokenString = localStorage.getItem('token');
  //console.log(tokenString)
  if (tokenString !== null)
    return jwt_decode(JSON.parse(tokenString).token);
  else
    return null
};


export default function useToken() {



  const getToken = () => {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken?.token
  };



  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {

    // console.log(userToken)
    if (userToken) {

      localStorage.setItem('token', JSON.stringify(userToken));


      setToken(userToken);
    }


    /*  localStorage.setItem('username', userToken.user_info[0].info.name);
 
     localStorage.setItem('companycode', userToken.user_info[0].info.companycode);
 
     localStorage.setItem('profile', userToken.user_info[0].profile.name);
 
     localStorage.setItem('office', userToken.user_info[0].info.office.name);
 
 
     localStorage.setItem('user_info', JSON.stringify(userToken.user_info[0]));
  */


  };

  return {
    setToken: saveToken,
    token,
    getTokenInfo
  }
}

export {
  getTokenInfo

}