import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { Redirect } from 'react-router-dom';
import Hosts from '../../../Hosts';

import { getTokenInfo } from './../../../UseToken';

import { api_crud_base, api_crud_route, data_interface, endpoints } from './Config';

import {

	reqGET,
	reqPOST,
	reqDEL,
	reqPUT,

} from '../../../Utils';
import { api_list_route } from './Config';
//import { ref } from 'yup';

//import { useHistory, Redirect } from "react-router-dom";
//import TextInput from 'react-autocomplete-input';


export default function Regist(props) {
	//export default function Financeira(props) ;
	//const Fornecedor = props => {

	// BEGIN


	//var history = useHistory();

	//Para Voltar ao URL Anterior
	//history.push(endpoints.list)

	//const id = useRef('');
	const [, set_dados] = useState(null);
	const dados = useRef(null);
	//const [local_interface, set_local_interface] = useState(JSON.parse(JSON.stringify(data_interface)));

	//console.log(dados.current)


	const [isloading, setLoading] = useState(true);
	const [isRedirect, setRedirect] = useState(false);
	const [refresh, setRefresh] = useState(0);

	const [show_delete_button, set_show_delete_button] = useState('d-none');

	const [is_edit, set_is_edit] = useState(false)

	const [mapas, set_mapas] = useState([])
	const [mapas_selected, set_mapas_selected] = useState([])

	const [filtros, set_filtros] = useState([])
	//const [filtros_selected, set_filtros_selected] = useState([])

	const fetch_id_info = async (id_serch) => {

		return await reqGET(`${api_list_route(getTokenInfo().companycode)}/${id_serch}`)
			.then(res => {
				console.log('FETCH INFO', res.data[0])
				set_dados(dados.current = res.data[0]);
			})
			.catch(error => error/*TODO: GO TO FAIL FETCH*/)
	}

	// PARA INSTANCIAR O COMPONENTE COM O ID 
	//const props_tmp = props
	useEffect(() => {

		const load_mapas_filtros = async () => {
			reqGET(`${api_crud_base}/maps/configs`)
				.then(res => {
					console.log(res.data)
					set_mapas(...[res.data[0].info.mapa.map(k => ({ value: k['name'], label: k['name'], }))])
					set_filtros(...[res.data[0].info.filtro.map(k => ({ value: k['name'], label: k['name'], name: k['name'], mapeamento: k['mapeamento'] }))])
					//console.log(etapas)
				}
				)
		}



		const clean_data = async () => {
			// DEEP COPY OF data_interface 
			set_dados(dados.current = JSON.parse(JSON.stringify(data_interface)))
			set_dados(dados.current.info.companycode = getTokenInfo().companycode)

		}

		clean_data();

		load_mapas_filtros();

		try {
			let test = props.location.state.operation;

			if (test === 'edit') {
				set_is_edit(true)
				//if (props.location.state !== undefined) {
				//console.log('->>>>>>>>>>>>>>>>:', props.location.state)

				//if (props.location.state.operation === 'edit') {
				set_show_delete_button('')
				//console.log('AQUI DENTRO DO STATE', props.location.state.item['id'])
				fetch_id_info(props.location.state.item['id']).then(() => {
					setLoading(false);


					//console.log(dados.current)

					change_selected(dados.current.info.mapa)

					//console.log(etapas)
					//console.log(etapas_selected)
				})
			}
			else  // VAI REGISTAR
				if (props.location.state.operation === 'new') {
					setLoading(false);
				}
				else {
					//console.log('ALI ELSE')
					setLoading(false);

					setRedirect(true);
					/* else if (props.location.state.operation === 'new') {
						setLoading(false);
					} */
				}

		} catch {

			clean_data()

			setLoading(false);
		}

		// BACK
		//props.history.push(endpoints.list)
		return () => {
			clean_data()
		}

	}, [props])

	const change_mapas = async e => {
		//console.log(e);
		set_mapas_selected(e);
		if (dados.current.info.mapa.length < e.length) {
			//console.log('adicionar')
			mapas.forEach((v) => {
				if (e.length === 0) {
					dados.current.info.mapa = []
				}
				e.forEach((v_1, k_1) => {
					if (typeof v_1 !== 'undefined' && v.value === v_1) {
						//console.log(dados.current.info.mapa)
						//console.log(v.value)
						//console.log(v_1)
						if (!dados.current.info.mapa.some(v_2 => v_2.name === v_1)) {
							if (dados.current.info.mapa.length === 1 && dados.current.info.mapa[0].name === '') {
								dados.current.info.mapa = [];
							}
							dados.current.info.mapa.push({
								name: v.label,
								mapeamento: '',
								ordem: k_1,
								filtro: []
							})
						}
						else {
							//console.log('existe: ' + v_1)
						}
					}
				});
			});
		}
		else if (dados.current.info.mapa.length < e.length) {
			//console.log('retirar')
			dados.current.info.mapa.forEach((v, k) => {
				if (v.name !== e.toString()) {
					dados.current.info.mapa.splice(k, 1);

					setRefresh(refresh + 1)
				}
			})
		}
		else {
			let mapas_aux = []
			e.forEach((v, k) => {
				dados.current.info.mapa.forEach((v_1) => {
					if (v === v_1.name) {
						v_1.ordem = k;
						mapas_aux.push(v_1)
					}
				})
			})
			dados.current.info.mapa = mapas_aux

			setRefresh(refresh + 1)
		}

		set_dados(dados)
	}

	const change_mapa_filtros = async (e, parent_name) => {
		//console.log(e)
		//console.log(dados.current.info)
		
		if(dados.current.info.mapa.length > 0) {
			dados.current.info.mapa.forEach((v) => {
				console.log(v)
				if (typeof v !== 'undefined' && v.name === parent_name) {
					if (v.filtro.length < e.length) {
						console.log('adicionar')
						if (v.name === parent_name.toString()) {
							filtros.forEach((v_1) => {
								if (e.length === 0) {
									v.filtro = []
								}
								e.forEach((v_2) => {
									if (typeof v_2 !== 'undefined' && v_1.value === v_2.toString()) {
										if (!v.filtro.some(v_3 => v_3.name === v_2.toString())) {
											v.filtro.push({
												name: v_1.name,
												mapeamento: v_1.mapeamento,
												ordem: ''
											})

											setRefresh(refresh + 1)
										}
									}
								})
							})
						}

					}
					else if (v.filtro.length > e.length) {
						console.log('retirar')
						dados.current.info.mapa.forEach((v) => {
							if (v.name === parent_name.toString()) {
								if (v.filtro) {
									v.filtro.forEach((v_1, k_1) => {
										if (!e.includes(v_1.name)) {
											v.filtro.splice(k_1, 1);

											setRefresh(refresh + 1)
										}
									})
								}
							}
						})
					}
					else {
						console.log('reordenar')
						//console.log(e) 

						var mapas_aux = []
						dados.current.info.etapa.forEach((v) => {
							if (v.name === parent_name) {
								//console.log(v)

								e.forEach((v_1) => {
									v.filtro.forEach((v_2) => {
										if (v_2.name === v_1) {
											mapas_aux.push(v_2)
										}
									})
								})

								//console.log(v.estado)
								//console.log(mapas_aux)
								v.estado = mapas_aux
							}
						})
					}
				}
				else {
					console.log('novo1')
					filtros.forEach((v_1) => {
						if (e.length === 0) {
							v.filtro = []
						}
						e.forEach((v_2) => {
							if (typeof v_2 !== 'undefined' && v_1.value === v_2.toString()) {
								if (!v.filtro.some(v_3 => v_3.name === v_2.toString())) {
									v.filtro.push({
										name: v_1.name,
										mapeamento: v_1.mapeamento,
										ordem: ''
									})

									setRefresh(refresh + 1)
								}
							}
						})
					})
				}
			})
		}
		else {
			console.log('novo2')
			console.log(filtros)
			filtros.forEach((v_1) => {
				if (e.length === 0) {
					dados.current.info.mapa[0].filtro = []
				}
				e.forEach((v_2) => {
					if (typeof v_2 !== 'undefined' && v_1.value === v_2.toString()) {
						
						console.log(dados.current)
						dados.current.info.mapa.push({
							name: parent_name,
							ordem: '',
							filtro: [{
								name: v_1.name,
								mapeamento: v_1.mapeamento,
								ordem: ''
						   	}]
						})

						setRefresh(refresh + 1)
						
					}
				})
			})
		}

		//set_dados(dados);
		setRefresh(refresh + 1)
		//useForceUpdate()
		//console.log('set_dados');
	}

	function change_selected(mapas) {
		let mapas_aux = []
		if (mapas)
			mapas.forEach((v) => {
				//console.log(v)
				mapas_aux.push(v.name)
			})
		//console.log(mapas_aux)
		set_mapas_selected(mapas_aux)
		//set_dados(dados.current)
	}

	const handleSubmit = async e => {
		e.preventDefault();

		if (is_edit === true) {
			//alert('Atualizar PUT')

			reqPUT(api_crud_route, dados.current)
				.then(() => alert('Registo Atualizado com Sucesso!'))
				.catch(() => alert('Problema ao atualizar Registo!'))
		} else {
			//alert('Inserir POST')
			reqPOST(api_crud_route, dados.current)
				.then(() => alert('Registo Inserido com Sucesso!'))
				.catch(() => alert('Problema ao atualizar Registo!'))
		}

		props.history.push(endpoints.list)
	}


	function apagar(id, e) {
		if (window.confirm('Tem a certeza que quer apagar este mapa?')) {

			var data = {
				id: dados.current.id,
				info: {}
			}

			reqDEL(Hosts.SIMCore_API + '/maps/types/', data)
				.then(res => {
					alert(res.status === 200 ?
						'Registo Apagado com Sucesso' : 'Problema ao Apagar Registo');

					window.location.href = endpoints.list;
				})
				.catch(error => alert(error))
		}
	}



	const handle_change_info = (e) => {
		const { name, value } = e.target;

		/* DEBUG*/

		//console.log('NAME:' + name)
		//console.log('Value:' + value)

		set_dados(dados.current.info[name] = value)

		//console.log('Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«')
		//console.log('Dados Current:' + JSON.stringify(dados))

	}

	const dual_list_box_lang = {
		availableFilterHeader: 'Filter available',
		availableHeader: 'Disponiveis',
		moveAllLeft: 'Removel todos',
		moveAllRight: 'Adicionar todos',
		moveLeft: 'Remover',
		moveRight: 'Adicionar',
		moveBottom: 'Mover para o fundo',
		moveDown: 'Mover para baixo',
		moveUp: 'Mover para cima',
		moveTop: 'Mover para o topo',
		noAvailableOptions: 'Sem opções disponiveis',
		noSelectedOptions: 'Sem opções selecionadas',
		selectedFilterHeader: 'Filter selected',
		selectedHeader: 'Selecionados'
	}

	

	return isloading ?
		<main>
			<div className={`dash-cards-2 header`}>
				<h2 className="dash-title">LOADING</h2>
				<p>LOADING</p>
				<div className="overlay">
					<div className="overlay__inner">
						<div className="overlay__content"><span className="spinner"></span></div>
					</div>
				</div>
			</div>
		</main>
		:
		isRedirect
			?
			<Redirect to={endpoints.list} replace />
			:
			<main>
				<div className={`dash-cards-2 header`}>
					<h2 className="dash-title">Mapas</h2>
					<p>Home / Mapas / Inserir Mapas</p>
				</div>

				<div className={`dash-cards-2 body`}>
					<div className="card">
						<div className="card-body">
							<form id="form" onSubmit={handleSubmit}>
								<input type="hidden" name="id" value={dados.current.id} />

								<h2 className="dash-title">Dados</h2>
								<div className="form-group">
									<span className="flex-1">
										<label>Nome</label>
										<input type="text" name="name" value={dados.current.info.name} pattern="[A-Za-z\u00C0-\u00FF0-9\s\-\+]+" className="input-form" placeholder="Introduza o nome" onChange={handle_change_info} required />
									</span>
								</div>
								<div className="form-group">
									<span className="flex-1">
										<label className="dash-title">Filtros do Mapa</label>
										<DualListBox
											id={0}
											options={mapas}
											selected={mapas_selected}
											onChange={change_mapas}
											preserveSelectOrder
											showOrderButtons
											lang={dual_list_box_lang}
										/>
									</span>
								</div>
								<div className="form-group-2">
									<h2 className="dash-title">Filtros</h2>
									{
										mapas_selected.map((v) => {
											return (
												mapas.map((v_1, k_1) => {
													if (v === v_1.value) {
														var filtros_selected_aux = [];
														var filtros_selected_2_aux = [];

														dados.current.info.mapa.forEach((v_3) => {
															if (v_1.value === v_3.name) {
																if (v_3.filtro) {
																	v_3.filtro.forEach((v_4) => {
																		filtros_selected_aux.push(v_4.name);
																		filtros_selected_2_aux.push({ value: v_4.name, label: v_4.name, mapeamento: v_4.mapeamento, parent: v_1.value });
																	})
																}
															}
														})

														return (
															<div key={k_1}>
																<div className="form-group">
																	<span className="flex-1">
																		<label className="dash-title">{v_1.label}</label>
																		<DualListBox
																			id={k_1 + 1}
																			options={filtros}
																			selected={filtros_selected_aux}
																			onChange={(e) => change_mapa_filtros(e, v_1.value)}
																			//onChange={change_mapa_filtros}
																			preserveSelectOrder
																			showOrderButtons
																			lang={dual_list_box_lang}
																		/>
																	</span>
																</div>
															</div>
														)
													} else
														return ''

												})
											)
										})
									}
								</div>

							</form>
						</div>
					</div>
				</div>

				<div className={'dash-cards-2 footer'}>
					<nav className="navbar bottom-navbar navbar-light">
						<div className="navbar-footer">
							<ul className="navbar-nav float-left"></ul>
							<ul className="navbar-nav float-right">
								<li className="nav-item">
									<Link className="nav-link btn-cancel" to={{ pathname: endpoints.list }}  >

										<img alt='' src={'./img/cancelar.svg'} />
										<span>Cancelar</span>
									</Link>
								</li>

								<li className={"nav-item " + show_delete_button}>
									<button onClick={() => apagar(dados.current.id)} className="nav-link btn-delete">

										<img alt='' src={'./img/eliminar_b.svg'} />
										<span>Eliminar</span>
									</button>
								</li>
								<li className="nav-item">
									<button type="submit" form="form" className="nav-link">

										<img alt='' src={'./img/save.svg'} />
										<span>Salvar</span>
									</button>
								</li>
							</ul>
						</div>
					</nav>
				</div>
			</main >
}
