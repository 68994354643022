import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Redirect } from 'react-router-dom';
import Hosts from '../../Hosts';


import { api_crud_base, api_crud_route, data_interface, endpoints } from './Config';

import {

	reqGET,
	reqPOST,
	reqDEL,
	reqPUT,

	// obter_cidades_por_distrito,
	// obter_freguesias_por_cidade,
	// obter_paises_em_pt,
	// obter_distrito_por_cidade,
	// obter_distritos_pt,

	// get_vat_info,


} from '../../Utils';

//import { useHistory, Redirect } from "react-router-dom";
//import TextInput from 'react-autocomplete-input';


export default function Regist(props) {

	//export default function Financeira(props) ;
	//const Fornecedor = props => {

	// BEGIN


	//var history = useHistory();

	//Para Voltar ao URL Anterior
	//history.push(endpoints.list)

	//const id = useRef('');
	const [, set_dados] = useState(null);
	const dados = useRef(null);
	//const [local_interface, set_local_interface] = useState(JSON.parse(JSON.stringify(data_interface)));

	//console.log(dados.current)


	const [isloading, setLoading] = useState(true);
	const [isRedirect, setRedirect] = useState(false);
	const [refresh, setRefresh] = useState(0);

	const [show_delete_button, set_show_delete_button] = useState('d-none');

	const [credit_types, set_credit_types] = useState([])
	const [financials, set_financials] = useState([])
	const [offices, set_offices] = useState([])
	const [responsables, set_responsables] = useState([])
	const [promotores, set_promotores] = useState([])
	const [disabled, set_disabled] = useState(true)
	const [is_visible_client_name, set_visible_client_name] = useState(false)



	//TemporÃ¡rio
	const [is_edit, set_is_edit] = useState(false)


	const custom_select_styles = {
		container: base => ({
			...base,
			width: '100%',
		}),
		control: base => ({
			...base,
			borderRadius: "10px",
			padding: "2px 10px",
			fontSize: '14px'
		}),
	}

	const load_credit_types = async () => {
		reqGET(`${api_crud_base}/credits/types`)
			.then(res => {
				//console.log(res.data)
				set_credit_types(...[res.data.map(k => ({ value: k['id'], label: k.info['name'], etapa: k.info['etapa'] }))])
				//console.log(credit_types)
			})
	}

	const load_financials = async (tipo) => {

		return await reqGET(`${api_crud_base}/financials/list/special/${tipo}`)
			.then(res => {
				//console.log(res.data)
				//set_financials(...[res.data.map(k => ({ value: k['id'], label: k['financials'] }))])
				return res.data.map(k => ({ value: k['id'], label: k['financials'] }))

				//console.log(financials)
			})
		//return tmp
	}

	const load_workflow = async (id) => {

		reqGET(`${api_crud_base}/credits/types/${id}`)
			.then(res => {
				let aux_array = [];
				//console.log(res.data[0]);
				if (res.data[0].info.workflow)
					res.data[0].info.workflow.forEach((k) => {
						load_etapas(k['id'])
							.then(res => {
								res.forEach((k_2) => {
									k_2.estado['name'] = k['name'];
								})

								aux_array.push({
									id: k['id'],
									name: k['name'],
									ordem: k['ordem'],
									estado: 'on',
									etapas: res,
									responsavel: {
										id: '',
										name: ''
									},
									notificacao: {
										id: '',
										name: ''
									},
									data: '',
									deadline: ''
								})

								/* set_estados(...[{ 
									id: k['id'], 
									name: k['name'], 
									ordem: k['ordem'], 
									estado: 'on',
									etapas: res,
									responsavel: {
										id: '',
										name: ''
									},
									data: '',
									deadline: ''
								}]) */


								set_dados(dados.current.info.workflow = aux_array);
							})
					})

				// set_estados(...[res.data[0].info.estados.map(k => ({ 
				// 	id: k['id'], 
				// 	name: k['name'], 
				// 	ordem: k['ordem'], 
				// 	estado: 'on',
				// 	etapas: etapas,
				// 	responsavel: {
				// 		id: '',
				// 		name: ''
				// 	},
				// 	data: '',
				// 	deadline: ''
				// }))])
			})
	}

	const load_etapas = async (id) => {
		return await reqGET(`${api_crud_base}/credits/states/${id}`)
			.then(res => {
				//console.log(res.data[0])
				return res.data[0].info.estados.map(k => ({
					value: k['id'],
					label: k['name'],
					ordem: k['ordem'],
					estado: { id: id, name: '' },
					sucesso: res.data[0].info.sucesso,
					insucesso: res.data[0].info.insucesso,
					activo: false
				}))
				//console.log(etapas)
			})
	}

	const load_offices = async () => {
		reqGET(`${api_crud_base}/entities/list/Escritorio`)
			.then(res => {
				//console.log(res.data)
				let tmp = res.data.map(k => ({ value: k['id'], label: k['name'], ativo: k['ativo'] }))
				set_offices(...[tmp.filter(k=> k.ativo === 'true')])//console.log(offices)
			}
			)
	}

	const load_reponsables = async (office) => {
		reqGET(`${api_crud_base}/entities/list/special/${office}/Colaborador`)
			.then(res => {
				//console.log(res.data)
				set_responsables(...[res.data.map(k => ({ value: k['id'], label: k.info['name'] }))])
				//console.log(responsables)
			}
			)
	}

	const load_promotores = async (office) => {
		reqGET(`${api_crud_base}/entities/list/special/${office}/Promotor`)
			.then(res => {
				//console.log(res.data)
				set_promotores(...[res.data.map(k => ({ value: k['id'], label: k.info['name'] }))])
				//console.log(promtores)
			}
			)
	}

	const load_cliente = async (vat) => {
		reqGET(`${api_crud_base}/entities/list/Cliente`)
			.then(res => {
				//console.log(res.data)
				// res.data.map((k) => {
				// 	if(k['vat'] === vat) {
				// 		set_dados(dados.current.info.cliente.id = k['id']);
				// 		set_dados(dados.current.info.cliente.name = k['name']);
				// 	}
				// });
				let ja_existe = false
				res.data.forEach((k) => {
					if (k['vat'] === vat) {
						ja_existe = true
						set_dados(dados.current.info.cliente.id = k['id']);
						set_dados(dados.current.info.cliente.name = k['name']);
						set_visible_client_name(true)
						//set_dados(dados.current.info.cliente.name = k['vat']);
					}

				})
				if (ja_existe === false) {

					//<Link to={{ pathname: '/client_regist', state: { item: vat, operation: 'newFromCredit' } }} />
					//console.log('NÃƒO EXISTE ________________>');
					//console.log(history)
					props.history.push(
						{
							pathname: '/client_regist',
							state: { item: { vat: vat }, operation: 'newFromCredit' }
						}

					)
				} else {
					set_disabled(false)
					//console.log(ja_existe);
				}

			}
			)
	}


	const change_credit_type = e => {
		const { label, value, etapa } = e;

		//console.log(e)

		set_financials([])
		//
		load_financials(label).then(financial_list => {

			//console.log(financial_list)
			if (financial_list.length === 0) {
				set_disabled(true)
			}
			else {
				set_disabled(false)
				set_financials(financial_list)

			}
		})

		set_dados(dados.current.info.financials['id'] = '');
		set_dados(dados.current.info.financials['name'] = '');
		set_dados(dados.current.info.tipo_credito['id'] = value);
		set_dados(dados.current.info.tipo_credito['name'] = label);

		etapa.forEach((v) => {
			v.data = ''
			v.responsavel = { id: '', name: '' }
			v.notificacao = { id: '', name: '' }
			v.deadline = ''
		})
		set_dados(dados.current.info.workflow = etapa);

		//console.log(dados.current.info.workflow)
	}

	const change_financials = e => {
		const { label, value } = e;

		set_dados(dados.current.info.financials['id'] = value);
		set_dados(dados.current.info.financials['name'] = label);

	}

	const change_office = e => {
		const { label, value } = e;

		set_responsables([])
		set_promotores([])


		load_reponsables(label)
		load_promotores(label)

		//console.log(e)
		set_dados(dados.current.info.responsavel.id = '');
		set_dados(dados.current.info.responsavel.name = '');
		set_dados(dados.current.info.promotor.id = '');
		set_dados(dados.current.info.promotor.name = '');
		set_dados(dados.current.info.escritorio.id = value);
		set_dados(dados.current.info.escritorio.name = label);
		//console.log(dados.current)
		load_workflow(dados.current.info.tipo_credito.id)
	}

	const change_responsable = e => {
		const { label, value } = e;

		//console.log(e)
		set_dados(dados.current.info.responsavel.id = value);
		set_dados(dados.current.info.responsavel.name = label);
		//console.log(dados.current)

	}

	const change_promotor = e => {
		const { label, value } = e;

		//console.log(e)
		set_dados(dados.current.info.promotor.id = value);
		set_dados(dados.current.info.promotor.name = label);
		//console.log(dados.current)

	}


	const change_etapa = async e => {
		//console.log(e);
		if (dados.current.info.workflow)
			dados.current.info.workflow.forEach(v => {
				if (e && e.estado.name === v.name) {
					v.estado.forEach(v_1 => {
						if (v_1.name === e.value) {
							//console.log(v_1.name + ' === ' + e.value);
							v_1.selected = true;
						}
						else {
							v_1.selected = false;
						}
					})
				}
			})

		set_dados(dados)
		setRefresh(refresh + 1)
	}
	const change_etapa_responsavel = async e => {
		if (dados.current.info.workflow)
			dados.current.info.workflow.forEach(v => {

				if (e.estado && e.estado.name === v.name) {
					v.responsavel.id = e.value;
					v.responsavel.name = e.label;
				}
			})
		setRefresh(refresh + 1)
	}
	const change_etapa_notificacao = async e => {
		if (dados.current.info.workflow)
			dados.current.info.workflow.forEach(v => {

				if (e.estado && e.estado.name === v.name) {
					v.notificacao.id = e.value;
					v.notificacao.name = e.label;
				}
			})
		setRefresh(refresh + 1)
	}


	const change_estado_data = async (e, name) => {
		//console.log(e);
		//console.log(name);
		//console.log(estados);

		/* setEtapaData(e); */

		if (dados.current.info.workflow)
			dados.current.info.workflow.forEach(v => {
				if (name && name === v.name) {
					v.data = e;
				}
			})

		//set_dados(dados)
		setRefresh(refresh + 1)

	}
	const change_estado_deadline = async (e, name) => {
		//console.log(e);
		//console.log(estados);

		//setEtapaDeadline(e);
		if (dados.current.info.workflow)
			dados.current.info.workflow.forEach(v => {
				if (name && name === v.name) {
					v.deadline = e;
				}
			})

		//set_dados(dados.current.info.workflow = estados);
		//set_dados(dados)
		setRefresh(refresh + 1)
	}


	/* 	const change_client = e => {
			//console.log(dados.current.info.cliente.vat)
			load_cliente(dados.current.info.cliente.vat);
			//console.log(dados.current)
		}
	 */
	const fetch_id_info = async (id_serch) => {

		return await reqGET(`${Hosts.SIMCore_API}/credits/credits/${id_serch}`)
			.then(res => {
				set_dados(dados.current = res.data[0]);

			})
			.catch(error => error/*TODO: GO TO FAIL FETCH*/)
	}

	// PARA INSTANCIAR O COMPONENTE COM O ID 
	//const props_tmp = props
	useEffect(() => {

		const clean_data = async () => {
			// DEEP COPY OF data_interface 
			set_dados(dados.current = JSON.parse(JSON.stringify(data_interface)))

		}

		clean_data();

		load_credit_types();
		load_offices();

		//setLoading(false);


		try {
			let test = props.location.state.operation;

			if (test === 'edit') {
				set_is_edit(true)
				//if (props.location.state !== undefined) {
				//console.log('->>>>>>>>>>>>>>>>:' + props.location.state.operation)
				//if (props.location.state.operation === 'edit') {
				set_show_delete_button('')
				//console.log('AQUI DENTRO DO STATE')
				fetch_id_info(props.location.state.item['id']).then(() => {
					setLoading(false);
				})
			}
			else
				if (test === 'newFromClient') {
					//console.log('------->newFromClient')

					set_show_delete_button('')
					//let tmp = props.location.state.item['cliente']
					//console.log(tmp)

					dados.current.info.cliente = props.location.state.item['cliente']
					set_visible_client_name(true)
					set_dados(dados)
					setLoading(false);
				} else



					// VAI REGISTAR
					if (test === 'null') {
						setLoading(false);
					}
					else {
						//console.log('ALI ELSE')
						setLoading(false);

						//set_show_vat_form('')
						setRedirect(true);
						/* else if (props.location.state.operation === 'new') {
							set_show_vat_form('');
							setLoading(false);
						} */
					}
		} catch {

			clean_data()

			setLoading(false);
		}

		// BACK
		//props.history.push(endpoints.list)
		return () => {
			clean_data()
		}

	}, [props])




	const handleSubmit = async e => {
		e.preventDefault();

		if (is_edit === true) {
			//alert('Atualizar PUT')

			reqPUT(api_crud_route, dados.current)
				.then(() => alert('Registo Atualizado com Sucesso!'))
				.catch(() => alert('Problema ao atualizar Registo!'))
		} else {







			//alert('Inserir POST')
			reqPOST(api_crud_route, dados.current)
				.then(() => alert('Registo Inserido com Sucesso!'))
				.catch(() => alert('Problema ao atualizar Registo!'))
		}

		props.history.push(endpoints.list)
	}

	function apagar(id, e) {
		if (window.confirm('Tem a certeza que quer apagar este Crédito?')) {

			var data = {
				id: dados.current.id,
				info: {},
				atividade: "",
				addresses: [],
				contacts: [],
				credits: []
			}

			reqDEL(Hosts.SIMCore_API + '/credits/', data)
				.then(res => {
					alert(res.status === 200 ?
						'Registo Apagado com Sucesso' : 'Problema ao Apagar Registo');

					//window.location.href = endpoints.list;
				})
				.catch(error => alert(error))
		}
	}

	const handle_change_info = (e) => {
		const { name, value } = e.target;

		/* DEBUG*/

		//console.log('NAME:' + name)
		//console.log('Value:' + value)

		set_dados(dados.current.info[name] = value)

		//console.log('Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«')
		//console.log('Dados Current:' + JSON.stringify(dados))

	}

	const handle_change_info_client = (e) => {
		const { name, value } = e.target;

		/* DEBUG*/

		/* //console.log(e)

		//console.log('NAME:' + name)
		//console.log('Value:' + value) */

		set_dados(dados.current.info.cliente[name] = value)

		if (dados.current.info.cliente[name].length < 9) {
			dados.current.info.cliente.name = ''
			set_disabled(true)
			set_visible_client_name(false)
		}

		if (dados.current.info.cliente[name].length === 9) {
			load_cliente(dados.current.info.cliente.vat)
		}


		//console.log('Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«')
		//console.log('Dados Current:' + JSON.stringify(dados))

	}

	const handle_change_commissioning = (e) => {
		const { name, value } = e.target;

		/* DEBUG*/

		//console.log('NAME:' + name)
		//console.log('Value:' + value)

		set_dados(dados.current.commissioning[name] = value)

		//console.log('Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«Ã‚Â«')
		//console.log('Dados Current:' + JSON.stringify(dados))

	}

	/* function sortResults(json, prop, asc) {
		json.sort(function (a, b) {
			if (asc) {
				return (a[prop] > b[prop]) ? 1 : ((a[prop] < b[prop]) ? -1 : 0);
			} else {
				return (b[prop] > a[prop]) ? 1 : ((b[prop] < a[prop]) ? -1 : 0);
			}
		});
	} */

	const customStyles = {
		option: (provided, state) => ({
			...provided,
		}),
		control: (provided) => ({
			...provided,
			borderRadius: "10px",
			padding: "2px 10px",
		})
	}

	return isloading ?
		<main>
			<div className={`dash-cards-2 header`}>
				<h2 className="dash-title">LOADING</h2>
				<p>LOADING</p>
				<div className="overlay">
					<div className="overlay__inner">
						<div className="overlay__content"><span className="spinner"></span></div>
					</div>
				</div>
			</div>
		</main>
		:
		isRedirect
			?
			<Redirect to={endpoints.list} replace />
			:
			<main>
				<div className={`dash-cards-2 header`}>
					<h2 className="dash-title">Créditos</h2>
					<p>Home / Créditos / Inserir Crédito</p>
				</div>

				<div className={`dash-cards-2 body`}>
					<div className="card">
						<div className="card-body">
							<form id="form" onSubmit={handleSubmit}>

								<div className="form-group">
									<span className="flex-1">
										<label>NIF (Cliente):</label>
										{/* <input type="text" disabled={disabled ? true : false} name="vat" value={dados.current.info.cliente.vat} className="form-control" placeholder="Introduza o NIF do cliente" onBlur={(e) => { change_client() }} onChange={handle_change_info_client} /> */}
										<input type="text" name="vat" maxLength="9" value={dados.current.info.cliente.vat} className="form-control" placeholder="Introduza o NIF do cliente" onChange={handle_change_info_client} />
									</span>

									<span className="flex-1" style={{ display: is_visible_client_name === true ? '' : 'none' }}>
										<label>Nome (Cliente):</label>
										{/* <input type="text" disabled={disabled ? true : false} name="vat" value={dados.current.info.cliente.vat} className="form-control" placeholder="Introduza o NIF do cliente" onBlur={(e) => { change_client() }} onChange={handle_change_info_client} /> */}
										<input type="text" disabled={true} name="name" value={dados.current.info.cliente.name} className="form-control" />
									</span>

									<span className="flex-2">

									</span>
								</div>


								<input type="hidden" name="id" value={dados.current.id} />

								<div className="form-group">
									<span className="flex-1" style={{ display: is_visible_client_name === true ? '' : 'none' }}>
										<label>Tipo de Crédito:</label>
										<Select
											className="div-select"
											options={credit_types}
											name="credit_type"
											value={dados.current.info.tipo_credito.id === '' ? null : { label: dados.current.info.tipo_credito.name, value: dados.current.info.tipo_credito.id }}
											placeholder="Introduza o tipo de crédito"
											onChange={change_credit_type}
											styles={custom_select_styles}
										/>
									</span>
									<span className="flex-1">
										<label>Entidade Financeira</label>
										<Select
											className="div-select"
											isDisabled={disabled ? true : false}
											options={financials}
											name="financials"
											value={dados.current.info.financials.id === '' ? null : { label: dados.current.info.financials.name, value: dados.current.info.financials.id }}
											placeholder={disabled ? 'Sem Financeira Associada' : "Introduza a Entidade Financeira"}
											onChange={change_financials}
											styles={custom_select_styles}
										/>
									</span>
								</div>

								<div className="form-group">
									<span className="flex-1">
										<label>Escritório:</label>
										<Select
											className="div-select"
											isDisabled={disabled ? true : false}
											options={offices}
											name="office"
											value={dados.current.info.escritorio.id === '' ? null : { label: dados.current.info.escritorio.name, value: dados.current.info.escritorio.id }}
											placeholder="Introduza o escritório"
											onChange={change_office}
											styles={custom_select_styles}
										/>
									</span>
									<span className="flex-1">
										<label>Colaborador Responsável:</label>
										<Select
											className="div-select"
											isDisabled={disabled ? true : false}
											options={responsables}
											name="responsable"
											value={dados.current.info.responsavel.id === '' ? null : { label: dados.current.info.responsavel.name, value: dados.current.info.responsavel.id }}
											placeholder="Introduza o colaborador responsável"
											onChange={change_responsable}
											styles={custom_select_styles}
										/>
									</span>

									<span className="flex-1" disabled>
										<label>Promotor:</label>

										<Select
											className="div-select"
											isDisabled={disabled ? true : false}
											options={promotores}
											name="promotor"
											value={dados.current.info.promotor.id === '' ? null : { label: dados.current.info.promotor.name, value: dados.current.info.promotor.id }}
											placeholder="Introduza o promotor"
											onChange={change_promotor}
											styles={custom_select_styles}
										/>
									</span>
								</div>



								<div className="form-group">
									<span className="flex-1">
										<label>Valor Financiado:</label>
										<input type="text" disabled={disabled ? true : false} name="valor_financiado" value={dados.current.commissioning.valor_financiado} pattern="^[0-9]+(\.[0-9]{1,2})?$" className="form-control" placeholder="Introduza o valor financiado" onChange={handle_change_commissioning} />
									</span>
									<span className="flex-1">
										<div >
											<label>Valor Estimado de Avaliação</label>
											<input type="text" disabled={disabled ? true : false} name="valor_estimado" value={dados.current.commissioning.valor_estimado} pattern="^[0-9]+(\.[0-9]{1,2})?$" className="form-control" placeholder="Introduza o valor estimado de avaliação" onChange={handle_change_commissioning} />
										</div>
									</span>
									<span className="flex-1">
										<div >
											<label>Valor de Escritura</label>
											<input type="text" disabled={disabled ? true : false} name="valor_escritura" value={dados.current.commissioning.valor_escritura} pattern="^[0-9]+(\.[0-9]{1,2})?$" className="form-control" placeholder="Introduza o valor de escritura" onChange={handle_change_commissioning} />
										</div>
									</span>
								</div>
								<div className="form-group">
									<span className="flex-2">
										<label>Observações</label>
										<textarea type="text" name="observation" disabled={disabled ? true : false} value={dados.current.info.observation} rows="4" onChange={handle_change_info} ></textarea>
									</span>
									<span className="flex-1">

									</span>
								</div>

								<div className="form-group-2">
									<h2 className="dash-title">Processos</h2>
									{
										dados.current.info.workflow.map((value, key) => {
											var responsables_aux = [];
											var etapas_aux = [];
											var selected_aux = '';
											if (responsables)
												responsables.forEach(v => {
													responsables_aux.push({ value: v.value, label: v.label, estado: { id: value.id, name: value.name } });
												})
											if (value.estado)
												value.estado.forEach(v => {
													if (v.selected)
														selected_aux = { value: v.name, label: v.name }
													etapas_aux.push({ value: v.name, label: v.name, estado: { id: value.name, name: value.name }, selected: v.selected });
												})
											return (
												<div key={key}>
													<label>{value.name}</label>
													<div className="form-group">
														<span className="flex-1">
															<label>Data</label>
															<input type="date" className="form-control" name="data" value={value.data} onChange={(e) => change_estado_data(e.target.value, value.name)} />
														</span>
														<span className="flex-1">
															<label>Responsável</label>
															{
																<Select
																	className="div-select"
																	options={responsables_aux}
																	name="estado"
																	value={value.responsavel.id === '' ? null : { label: value.responsavel.name, value: value.responsavel.id }}
																	placeholder="Introduza o responsável"
																	onChange={change_etapa_responsavel}
																	menuPlacement="top"
																	styles={customStyles}
																/>
															}
														</span>
														<span className="flex-1">
															<label>Etapa</label>
															{
																<Select
																	className="div-select"
																	options={etapas_aux}
																	name="estado"
																	value={selected_aux === '' ? null : selected_aux}
																	placeholder="Introduza a etapa"
																	onChange={change_etapa}
																	menuPlacement="top"
																	styles={customStyles}
																/>
															}
														</span>
														<span className="flex-1">
															<label>Notificação</label>
															{
																<Select
																	className="div-select"
																	options={responsables_aux}
																	name="estado"
																	value={value.notificacao.id === '' ? null : { label: value.notificacao.name, value: value.notificacao.id }}
																	placeholder="Introduza o notificação"
																	onChange={change_etapa_notificacao}
																	menuPlacement="top"
																	styles={customStyles}
																/>
															}
														</span>
														<span className="flex-1">
															<label>Deadline</label>
															<input type="date" className="form-control" name="deadline" value={value.deadline} onChange={(e) => change_estado_deadline(e.target.value, value.name)} />
														</span>
													</div>
												</div>
											)
										})
									}
								</div>

							</form>
						</div>
					</div>
				</div>

				<div className={'dash-cards-2 footer'}>
					<nav className="navbar bottom-navbar navbar-light">
						<div className="navbar-footer">
							<ul className="navbar-nav float-left"></ul>
							<ul className="navbar-nav float-right">
								<li className="nav-item">
									<Link className="nav-link btn-cancel" to={{ pathname: endpoints.list }}  >

										<img alt='' src={'./img/cancelar.svg'} />
										<span>Cancelar</span>
									</Link>
								</li>

								<li className={"nav-item " + show_delete_button}>
									<button onClick={() => apagar(dados.current.id)} className="nav-link btn-delete">

										<img alt='' src={'./img/eliminar_b.svg'} />
										<span>Eliminar</span>
									</button>
								</li>
								<li className="nav-item">
									<button disabled={disabled ? true : false} type="submit" form="form" className="nav-link">

										<img alt='' src={'./img/save.svg'} />
										<span>Salvar</span>
									</button>
								</li>
							</ul>
						</div>
					</nav>
				</div>
			</main >
}
