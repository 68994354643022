import Hosts from '../../../Hosts';

//const { getTokenInfo } = useToken();
//const object_type = 'Credits'

const api_crud_base = Hosts.SIMCore_API
const api_crud_route = `${Hosts.SIMCore_API}/credits/types`
const api_list_route = (companycode) => `${Hosts.SIMCore_API}/credits/types/${companycode}`

const base_endpoint = '/credit_types'

const endpoints = {
    base: base_endpoint,
    regist: `${base_endpoint}_regist`,
    list: `${base_endpoint}_list`,
    view: `${base_endpoint}_view`,
}

const data_interface =
{
    id: '',
    name: '',
    info: {
        companycode: '',
        name: '',
        etapa: [{
            name: '',
            ordem: '',
            selected: false,
            estado: [{
                name: '',
                ordem: '',
                initial: false,
                selected: false,
                pendente: true,
                completo: false
            }]
        }]
    }
}


export {
    endpoints,
    api_crud_base,
    api_crud_route,
    data_interface,
    api_list_route
}