import React from 'react';
import "./style.css";
import { getTokenInfo } from './../../UseToken';

function logout() {
    localStorage.clear();
    window.location.href = '/';
}
//  <img src="./img/logo-icon.png" alt="Fasquia Importante" className="light-logo-mobile" />
//<img  src="./img/logo.png" alt="Fasquia Importante" className="light-logo " />
const Header = (props) => {
    let username = getTokenInfo().name;
    let profile = getTokenInfo().profile;
    let office = getTokenInfo().office.name;

    return (
        <header>
            <nav className="navbar top-navbar navbar-light">
                <div className="navbar-header">
                    <div className="navbar-brand">
                        <a href="/">

                            <img src="./img/logo-icon.png" alt="Fasquia Importante" className="light-logo-mobile" />
                            <img src="./img/logo.png" alt="Fasquia Importante" className="light-logo " />
                        </a>
                    </div>
                </div>

                <div className="navbar-collapse collapse">
                    <ul className="navbar-nav float-left">
                    </ul>
                    <ul className="navbar-nav float-right">
                        <li className="nav-item d-none">
                            <a href="/" className="nav-link">
                                <span className="ti-search"></span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="/" className="nav-link">
                                <img alt='' src={'./img/user.svg'} />
                                <span>{`${username} [${profile}] ${profile === 'Administrador' ? '' : `[Escritório: ${office}]`}    `}</span>

                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="/" onClick={logout} className="nav-link">
                                <img alt='' src={'./img/sair.svg'} />
                                <span>Logout</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    );
}

export default Header;