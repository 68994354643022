import React, { useState, useEffect } from 'react';
import { Redirect, Link } from 'react-router-dom';

import { reqGET } from '../../Utils';
import { api_list_route, data_interface, endpoints } from './Config';

import { getTokenInfo } from './../../UseToken';

export default function View(props) {

    const [dados, set_dados] = useState(data_interface);

    const [isloading, setLoading] = useState(true);
    const [isRedirect, setRedirect] = useState(false);

    // Para Instanciar todo o Objecto 
    const fetchInfo = async (id_serch) => {

        let list_route = api_list_route(getTokenInfo().companycode)
        //console.log(list_route)
        //return await reqGET(`${api_crud_route}/${id_serch}`)
        return await reqGET(`${list_route}/${id_serch}`)
            .then(res => set_dados(res.data[0]))
            .catch(() => 404
                //  TODO: ENVIAR PÁGINA 404
                //  alert(error)); 
            );
    }

    useEffect(() => {



        if (props.location.state !== undefined) {

            fetchInfo(props.location.state.item['id']).then(() => setLoading(false))
        }
        else {
            setLoading(false);
            setRedirect(true);
        }

    }, [props]);



    return isRedirect ?
        <Redirect to={endpoints.list} replace />
        :
        isloading ?
            <main>
                <div className={`dash-cards-2 header`}>
                    <h2 className="dash-title">LOADING</h2>
                    <p>LOADING</p>
                    <div className="overlay">
                        <div className="overlay__inner">
                            <div className="overlay__content"><span className="spinner"></span></div>
                        </div>
                    </div>
                </div>
            </main>
            :
            <main>
                <div className={`dash-cards-2 header`}>
                    <h2 className="dash-title">Financeiras</h2>
                    <p>Home / Financeiras / {dados.info.name}</p>
                </div>

                <div className={`dash-cards-2 body`}>
                    <div className="card">
                        <div className="card-body">
                            <h2 className="dash-title">{dados.info.name} <small>({dados.info.name_mini})</small></h2>
                            <div className="div-break" />
                            <div className="div-group">
                                <span className="flex-1">
                                    <h4>NIF</h4>
                                    <p>{dados.info.vat}</p>
                                </span>
                                <span className="flex-1">
                                    <h4>E-mail</h4>
                                    <p>{dados.contacts[0].email}</p>
                                </span>
                            </div>
                            <div className="div-break"></div>
                            <div className="div-group">
                                <span className="flex-1">
                                    <h4>Telefone</h4>
                                    <p>{dados.contacts[0].phone}</p>
                                </span>
                                <span className="flex-1">
                                    <h4>Telemóvel</h4>
                                    <p>{dados.contacts[0].mobile_phone}</p>
                                </span>
                            </div>
                            <div className="div-break"></div>
                            <div className="div-group">
                                <span className="flex-1">
                                    <h4>País</h4>
                                    <p>{dados.addresses[0].country}</p>
                                </span>
                                <span className="flex-1">
                                    <h4>Distrito</h4>
                                    <p>{dados.addresses[0].region}</p>
                                </span>
                                <span className="flex-1">
                                    <h4>Concelho</h4>
                                    <p>{dados.addresses[0].city}</p>
                                </span>
                            </div>
                            <div className="div-break"></div>
                            <div className="div-group">
                                <span className="flex-1">
                                    <h4>Localidade</h4>
                                    <p>{dados.addresses[0].village}</p>
                                </span>
                                <span className="flex-1">
                                    <h4>Código-Postal</h4>
                                    <p>{dados.addresses[0].postal_code}</p>
                                </span>
                                <span className="flex-1">
                                    <h4>Apartado</h4>
                                    <p>{dados.addresses[0].apartado}</p>
                                </span>
                            </div>
                            <div className="div-break"></div>
                            <div className="div-group">
                                <span className="flex-2">
                                    <h4>Morada</h4>
                                    <p>{dados.addresses[0].address_detail}</p>
                                </span>
                                <span className="flex-1" />
                            </div>
                            <div className="div-break"></div>
                            <h2 className="dash-title">Dados do Gestor de Parceria</h2>
                            <div className="div-break"></div>
                            <div className="div-group">
                                <span className="flex-1">
                                    <h4>Gestor de Parceria</h4>
                                    <p>{dados.contacts[0].person_responsables[0].name}</p>
                                </span>
                                <span className="flex-1">
                                    <h4>Telemóvel (Gestor de Parceria)</h4>
                                    <p>{dados.contacts[0].person_responsables[0].mobile_phone}</p>
                                </span>
                                <span className="flex-1">
                                    <h4>Email (Gestor de Parceria)</h4>
                                    <p>{dados.contacts[0].person_responsables[0].email}</p>
                                </span>
                            </div>
                            <div className="div-break" />
                            <h2 className="dash-title">Créditos Associados</h2>
                            <div className="div-break" />


                            <div className="form-group">
                                <span className="div-no-pointer-group ">
                                    {
                                        dados.credits.map((value, key) => {
                                            return (
                                                <span key={key} className="div-no-pointer red">
                                                    {value.name}


                                                </span>
                                            )
                                        })
                                    }
                                </span>
                            </div>



                            <div className="div-break" />
                        </div>
                    </div>
                </div>

                <div className={'dash-cards-2 footer'}>
                    <nav className="navbar bottom-navbar navbar-light">
                        <div className="navbar-footer">
                            <ul className="navbar-nav float-left"></ul>
                            <ul className="navbar-nav float-right">
                                <li className="nav-item">
                                    <Link className="nav-link btn-cancel" to={{ pathname: endpoints.list }} replace>
                                        <img alt='' src={'./img/seta_2.svg'} />
                                        <span>Voltar</span>
                                    </Link>
                                </li>

                            </ul>
                        </div>
                    </nav>
                </div>
            </main >
}