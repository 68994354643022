import React, { useState } from 'react';
import Hosts from '../../Hosts'
import Utils from '../../Utils'


import { confirmAlert } from 'react-confirm-alert';

const LoginForm = (props) => {
  /*  async function loginUser(credentials) {
     return fetch(Hosts.SIMCore_API + '/' + props.loginType, {
       //return fetch(Hosts.SIMCore_API+'/auth/'+props.loginType, {
       method: 'POST',
       headers: {
         'Content-Type': 'application/json'
       },
       body: JSON.stringify(credentials)
     })
       .then(data => data.json())
     //.then(res =>res.token) */





  const [companycode, setCompanyCode] = useState('');
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');

  const [input_type, setInputType] = useState('password');




  const handleSubmit = e => {
    //e.preventDefault();
    /*  const objToken = await loginUser({
       companycode,
       username,
       password: Utils.passwordHash(password)
     }); */

    //PASSWORD -> MD5
    //console.log('Token:' + crypto.createHash('md5').update('hftpaak17').digest("hex"));

    //console.log('Token:' + objToken);
    //console.log(JSON.stringify(objToken))

    e.preventDefault();
    localStorage.clear();

    Utils.reqPOST(Hosts.SIMCore_API + '/' + props.loginType, {
      companycode,
      username,
      password: Utils.passwordHash(password)
    }).then(objToken => {

      if (objToken.token !== false) {
        //console.log('SETOU->', objToken)
        props.setToken(objToken);
        // //console.log(JSON.stringify(objToken))
        //alert('Autenticado!')


        /*  confirmAlert({
           title: 'SIM.Credit.Net',
           message: 'Sistema de Gestão de Créditos',
           buttons: [
             {
               label: 'Entrar',
               onClick: () => { */
        //console.log(objToken.user_info[0].profile.name)

        //console.log('COMAPNY-CODE: ' + objToken.user_info[0].info.companycode)
        //localStorage.setItem('companycode', companycode);
        //localStorage.setItem('companycode', companycode);
        /*  localStorage.setItem('username', objToken.user_info[0].info.name);
         localStorage.setItem('companycode', objToken.user_info[0].info.companycode);
 
         localStorage.setItem('profile', objToken.user_info[0].profile.name);
         localStorage.setItem('user_info', objToken.user_info[0]); */

        // if (getTokenInfo().companycode === companycode) {

        //}
        //props.history.push('/')
        /*  }
       }
  
     ]
   });
  */
      }
      else {
        confirmAlert({
          title: 'SIM.Credit.Net',
          message: 'Tokens de Acesso Incorretos',
          buttons: [
            {
              label: 'Voltar',
              onClick: () => { return }
            }

          ]
        });
        //alert('Não Autenticado');
        localStorage.clear();
        //setToken(token);
      }
    })
  }

  const togglePwd = async e => {
    if (input_type.localeCompare('text') === 0)
      setInputType('password');
    else
      setInputType('text');
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label>
          <p> Código Empresa</p>
          <input type="text" className="form-input" name='companycode' value={companycode} onChange={(e) => setCompanyCode(e.target.value)} />
        </label>
      </div>
      <div className="form-group">
        <label>
          <p>Utilizador</p>
          <input type="text" className="form-input" name='username' value={username} onChange={(e) => setUserName(e.target.value)} />
        </label>
      </div>
      <div className="form-group">
        <label>
          <p>Palavra-Passe</p>
          <input type={input_type} className="form-input input-pwd" value={password} onChange={(e) => setPassword(e.target.value)} />
          <img alt='' onClick={togglePwd} src={'./img/view_password.svg'} />
        </label>
      </div>
      <div className="form-group">
        <button className="form-button" type="submit">Entrar</button>
      </div>
    </form>
  );


}

/*
LoginForm.propTypes = {
    setToken: PropTypes.func.isRequired
  };
*/

export default LoginForm;
