import Regist from './Regist';
import List from './List';
import View from './View';

import { endpoints } from './Config';

const toExport = {
    regist: { endpoint: endpoints.regist, component: Regist },
    list: { endpoint: endpoints.list, component: List },
    view: { endpoint: endpoints.view, component: View },
    base: endpoints.base
};

export default toExport;