import React, { useState, useEffect } from 'react';
import { Redirect, Link } from 'react-router-dom';

import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

import DataTable from 'react-data-table-component';
import { getTokenInfo } from './../../../UseToken';
import { reqGET } from '../../../Utils';
import { api_crud_base, api_crud_route, data_interface, endpoints } from './Config';

export default function View(props) {

    const [dados, set_dados] = useState(data_interface);

    const [isloading, setLoading] = useState(true);
    const [isRedirect, setRedirect] = useState(false);

    const [data_masters, set_masters] = useState([]);
    const [data_colaboradores, set_colaboradores] = useState([]);
    const [data_promotores, set_promotores] = useState([]);
    const columns = [
        {
            name: <div className="table-header">ID</div>,
            sortable: true,
            selector: 'id',
            left: true,
        },
        {
            name: <div className="table-header">Nome</div>,
            sortable: true,
            maxWidth: '250px',
            selector: 'nome',
            left: true,
        },
        {
            name: <div className="table-header">NIF</div>,
            selector: 'nif',
            sortable: true,
            left: true,
        },
        {
            name: <div className="table-header">Telemóvel</div>,
            selector: 'mobile_phone',
            sortable: true,
            left: true,
        },
        {
            name: <div className="table-header">E-mail</div>,
            selector: 'email',
            sortable: true,
            left: true,
        }
    ];

    const column_colaboradores = [
        {
            name: <div className="table-header">ID</div>,
            sortable: true,
            selector: 'id',
            left: true,
        },
        {
            name: <div className="table-header">Nome</div>,
            sortable: true,
            maxWidth: '250px',
            selector: 'nome',
            left: true,
        },
        {
            name: <div className="table-header">NIF</div>,
            selector: 'nif',
            sortable: true,
            left: true,
        },
        {
            name: <div className="table-header">Telemóvel</div>,
            selector: 'mobile_phone',
            sortable: true,
            left: true,
        },
        {
            name: <div className="table-header">E-mail</div>,
            selector: 'email',
            sortable: true,
            left: true,
        },
        {
            name: <div className="table-header">Estado</div>, 
            selector: 'ativo',
            cell: row => <div>{row.ativo === "true" || row.ativo === true ? 'Ativo' : 'Inativo'}</div>,
            sortable: true,
            center: true,
        }
    ];

    const defaultComponentOptions = {
        rowsPerPageText: 'Registos por página:',
        rangeSeparatorText: 'de',
        noRowsPerPage: false,
        selectAllRowsItem: false,
        selectAllRowsItemText: 'Todos',
        responsive: true,
    };

    const load_entidades = async (id, tipo) => {

        reqGET(`${api_crud_base}/entities/list/special/${getTokenInfo().companycode}/${id}/${tipo}`)
            .then(res => {
                //console.log(res.data)
                if (tipo === 'Master') {
                    set_masters(...[
                        res.data.map(k => {
                            return {
                                id: k['id'],
                                nome: k.info['name'],
                                nif: k.info['vat'],
                                mobile_phone: k.contacts[0]['mobile_phone'],
                                email: k.contacts[0]['email']
                            }

                        })
                    ])
                }
                if (tipo === 'Colaborador') {
                    set_colaboradores(...[
                        res.data.map(k => {
                            return {
                                id: k['id'],
                                nome: k.info['name'],
                                nif: k.info['vat'],
                                mobile_phone: k.contacts[0]['mobile_phone'],
                                email: k.contacts[0]['email'],
                                ativo: k.info['ativo']
                            }

                        })
                    ])
                }
                if (tipo === 'Promotor') {
                    set_promotores(...[
                        res.data.map(k => {
                            return {
                                id: k['id'],
                                nome: k.info['name'],
                                nif: k.info['vat'],
                                mobile_phone: k.contacts[0]['mobile_phone'],
                                email: k.contacts[0]['email']
                            }

                        })
                    ])
                }
                //console.log(masters)
                //console.log(colaboradores)
                //console.log(promotores)
            }
            )
    }

    const change_accordion = e => {
        //console.log(e);
        if (e.includes('0'))
            load_entidades(dados.info.name, 'Master');
        if (e.includes('1'))
            load_entidades(dados.info.name, 'Colaborador');
        if (e.includes('2'))
            load_entidades(dados.info.name, 'Promotor');
    }

    // Para Instanciar todo o Objecto 
    const fetchInfo = async (id) => {
        await reqGET(`${api_crud_route}/${id}`)
            .then(res => set_dados(res.data[0]))
            .catch(() => 404
                //  TODO: ENVIAR PÁGINA 404
                //  alert(error)); 
            );
    }

    useEffect(() => {

        if (props.location.state !== undefined) {

            fetchInfo(props.location.state.item['id']).then(() => setLoading(false))
        }
        else {
            setLoading(false);
            setRedirect(true);
        }

    }, [props]);



    return isRedirect ?
        <Redirect to={endpoints.list} replace />
        :
        isloading ?
            <main>
                <div className={`dash-cards-2 header`}>
                    <h2 className="dash-title">LOADING</h2>
                    <p>LOADING</p>
                    <div className="overlay">
                        <div className="overlay__inner">
                            <div className="overlay__content"><span className="spinner"></span></div>
                        </div>
                    </div>
                </div>
            </main>
            :
            <main>
                <div className={`dash-cards-2 header`}>
                    <h2 className="dash-title">Escritórios</h2>
                    <p>Home / Escritórios / {dados.info.name}</p>
                </div>

                <div className={`dash-cards-2 body`}>
                    <div className="card">
                        <div className="card-body">
                            <h2 className="dash-title">{dados.info.name} <small>({dados.info.name_mini})</small></h2>
                            <div className="div-break" />
                            <div className="div-group">
                            <span className="flex-1">
                                    <h4>Estado</h4>
                                    <p>{dados.info.ativo===true ? "Activo": "Não Activo"}</p>
                                </span>
                                <span className="flex-1">
                                    <h4>NIF</h4>
                                    <p>{dados.info.vat}</p>
                                </span>
                                <span className="flex-1">

                                </span>
                            </div>
                            <div className="div-break"></div>
                            <div className="div-group">
                                <span className="flex-1">
                                    <h4>Telefone</h4>
                                    <p>{dados.contacts[0].phone}</p>
                                </span>
                                <span className="flex-1">
                                    <h4>Telemóvel</h4>
                                    <p>{dados.contacts[0].mobile_phone}</p>
                                </span>
                                <span className="flex-1">
                                    <h4>E-mail</h4>
                                    <p>{dados.contacts[0].email}</p>
                                </span>
                            </div>
                            <div className="div-break"></div>
                            <div className="div-group">
                                <span className="flex-1">
                                    <h4>País</h4>
                                    <p>{dados.addresses[0].country}</p>
                                </span>
                                <span className="flex-1">
                                    <h4>Distrito</h4>
                                    <p>{dados.addresses[0].region}</p>
                                </span>
                                <span className="flex-1">
                                    <h4>Concelho</h4>
                                    <p>{dados.addresses[0].city}</p>
                                </span>
                            </div>
                            <div className="div-break"></div>
                            <div className="div-group">
                                <span className="flex-1">
                                    <h4>Localidade</h4>
                                    <p>{dados.addresses[0].localidade}</p>
                                </span>
                                <span className="flex-1">
                                    <h4>Código-Postal</h4>
                                    <p>{dados.addresses[0].postal_code}</p>
                                </span>
                                {/* <span className="flex-1">
                                    <h4>Apartado</h4>
                                    <p>{dados.addresses[0].apartado}</p>
                                </span> */}
                            </div>
                            <div className="div-break"></div>
                            <div className="div-group">
                                <span className="flex-2">
                                    <h4>Morada</h4>
                                    <p>{dados.addresses[0].address_detail}</p>
                                </span>
                                <span className="flex-1" />
                            </div>

                            <div className="div-break"></div>
                            <div className="div-group">
                                <Accordion allowZeroExpanded allowMultipleExpanded onChange={change_accordion} >
                                    <AccordionItem uuid="0">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Responsaveis
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <div className="div-table flex-1">
                                                <div className="table-body">
                                                    <DataTable
                                                        noDataComponent='Sem Registos'
                                                        columns={columns}
                                                        data={data_masters}
                                                        noHeader
                                                        subHeader
                                                        pagination
                                                        paginationComponentOptions={defaultComponentOptions}
                                                        paginationPerPage={25}
                                                        paginationRowsPerPageOptions={[25, 50, 100]}
                                                    />
                                                </div>
                                            </div>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem uuid="1">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Colaboradores
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <div className="div-table flex-1">
                                                <div className="table-body">
                                                    <DataTable
                                                        noDataComponent='Sem Registos'
                                                        columns={column_colaboradores}
                                                        data={data_colaboradores}
                                                        noHeader
                                                        subHeader
                                                        pagination
                                                        paginationComponentOptions={defaultComponentOptions}
                                                        paginationPerPage={25}
                                                        paginationRowsPerPageOptions={[25, 50, 100]}
                                                    />
                                                </div>
                                            </div>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem uuid="2">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Promotores
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <div className="div-table flex-1">
                                                <div className="table-body">
                                                    <DataTable
                                                        noDataComponent='Sem Registos'
                                                        columns={columns}
                                                        data={data_promotores}
                                                        noHeader
                                                        subHeader
                                                        pagination
                                                        paginationComponentOptions={defaultComponentOptions}
                                                        paginationPerPage={25}
                                                        paginationRowsPerPageOptions={[25, 50, 100]}
                                                    />
                                                </div>
                                            </div>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'dash-cards-2 footer'}>
                    <nav className="navbar bottom-navbar navbar-light">
                        <div className="navbar-footer">
                            <ul className="navbar-nav float-left"></ul>
                            <ul className="navbar-nav float-right">
                                <li className="nav-item">
                                    <Link className="nav-link btn-cancel" to={{ pathname: endpoints.list }} replace>
                                        <img alt='' src={'./img/seta_2.svg'} />
                                        <span>Voltar</span>
                                    </Link>
                                </li>

                            </ul>
                        </div>
                    </nav>
                </div>
            </main>
}