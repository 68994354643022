//import { keys } from '@material-ui/core/styles/createBreakpoints';
import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import DataTable from 'react-data-table-component';

import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import { reqGET } from '../../Utils';
import { api_crud_base } from './Config';
import { getTokenInfo } from './../../UseToken';
//import { api_crud_base, api_crud_route, data_interface, endpoints } from './Config';


export default function View(props) {

    //import './style.css';
    //import ReactModal from 'react-modal';
    //ReactModal.setAppElement('body');

    const [angariacao_anual, set_angariacao_anual] = useState(0);
    const [angariacao_mensal, set_angariacao_mensal] = useState(0);
    const [ganho_mensal, set_ganho_mensal] = useState(0);

    const [data_creditos, set_credits] = useState([]);

    const columns_creditos = [
        // {
        //     name: <div className="table-header">Escritório</div>,
        //     selector: 'escritorio',
        //     sortable: true,
        //     left: true,
        // },
        // {
        //     name: <div className="table-header">Responsável</div>,
        //     selector: 'colaborador',
        //     sortable: true,
        //     left: true,
        // },
        // {
        //     name: <div className="table-header">Promotor</div>,
        //     selector: 'promotor',
        //     sortable: true,
        //     left: true,
        // },
        {
            name: <div className="table-header">Cliente</div>,
            selector: 'cliente',
            maxWidth: '300px',
            sortable: true,
            left: true,
        },
        {
            name: <div className="table-header">Financeira</div>,
            selector: 'financeira',
            sortable: true,
            left: true,
        },
        {
            name: <div className="table-header">Tipo de Crédito</div>,
            selector: 'tipo_credito',
            sortable: true,
            left: true,
        },
        {
            name: <div className="table-header">Valor Financiado</div>,
            selector: 'valor_financiado',
            cell: row => <div>{row.valor_financiado}€</div>,
            maxWidth: '200px',
            sortable: true,
            right: true,
        },
        {
            name: <div className="table-header">Estado</div>,
            selector: 'estado',
            cell: row => {
                let cor = '#b37db7';
                let _estado = row.estado;
                if(_estado === 'Cancelado') {
                    cor = '#ea1f3d';
                }
                else if(_estado === 'Completo') {
                    cor = '';
                }
                else if(_estado === ''){
                    cor = '#606060';
                    _estado = 'A iniciar'
                }
                return (
                    <div class="btn" style={{  backgroundColor: cor, borderColor: cor, padding: '5px' }}>{_estado}
                    </div>
                )
            },
            maxWidth: '200px',
            sortable: true,
            center: true,
        },
        {
            name: '',
            cell: row => 
                <>
                   <Link className="button button-update" to={{ pathname: '/credit_regist', state: { item: {id: row.proposta }, operation: 'edit' } }} style={{ display: '' }} replace>
                        <img alt='editar' src={'./img/editar.svg'} />
                    </Link>
                    <button data-testid="expander-button-undefined" aria-label="Collapse Row" role="button" type="button" class="sc-iCoGMd inrgiU"><svg fill="currentColor" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z"></path><path d="M0-.75h24v24H0z" fill="none"></path></svg></button>
                </>
            ,
            maxWidth: '25px',
        },
    ];

    //const ExpandableComponent = ({ data }) => <span>###{data.proposta}###</span>;
    const ExpandableComponent = ({ data }) =>
        <div className="div-expand">
            { ReactHtmlParser(data.timeline) }
        </div>;

    const defaultComponentOptions = {
        rowsPerPageText: 'Registos por página:',
        rangeSeparatorText: 'de',
        noRowsPerPage: false,
        selectAllRowsItem: false,
        selectAllRowsItemText: 'Todos',
        responsive: true,
    };

    useEffect(() => {
        const companycode = getTokenInfo().companycode;
        const office = getTokenInfo().office.id;

        set_credits([])
        set_angariacao_anual(0);
        set_angariacao_mensal(0);
        set_ganho_mensal(0);

        let newDate = new Date();
        //let today = newDate.getFullYear() + '-' + (newDate.getMonth() + 1).toString().padStart(2, "0") + '-' + newDate.getDate().toString().padStart(2, "0");
        let month = (newDate.getMonth() + 1).toString().padStart(2, "0");
        let year = newDate.getFullYear();

        const load_credits = () => {

            //Escritorio
            ///credits/credits/special/office/:companycode/:id

            //reqGET(`${api_crud_base}/credits/credits/${companycode}`)



            if (getTokenInfo().profile === 'Master' || getTokenInfo().profile === 'Administrador') {
                
                reqGET(`${api_crud_base}/credits/credits/${companycode}`)
                    .then(res => {
                        //console.log(res.data)
                        set_credits(...[
                            res.data.map(k => {
                                if (k.info.workflow) {
                                    if (k.info.workflow.length > 0) {
                                        let last = k.info.workflow.length - 1;

                                        // Desativado, a angariação é considerada aquando da simulação  (2022-05-04)
                                        if (k.info.workflow[last].data !== '' && 0) {
                                            let data_proposta = new Date(k.info.workflow[last].data);
                                            let ano_proposta = data_proposta.getFullYear();
                                            let mes_proposta = (data_proposta.getMonth() + 1).toString().padStart(2, "0");

                                            if (year === ano_proposta) {
                                                //k.info.workflow[last].estado.forEach(k_3 => {
                                                //k.info.workflow[0].estado.forEach(k_3 => {
                                                    //if (k_3.pendente === false && k_3.completo === true && k_3.selected === true) {
                                                        set_angariacao_anual(atual => parseInt(atual) + 1)
                                                    //}
                                                //})
                                            }

                                            if (year === ano_proposta && month === mes_proposta) {
                                                set_angariacao_mensal(atual => parseInt(atual) + 1)

                                                k.info.workflow[last].estado.forEach(k_3 => {
                                                    if (k_3.pendente === false && k_3.completo === true && k_3.selected === true) {
                                                        //set_ganho_mensal(atual => parseFloat(atual) + parseFloat(k.commissioning.valor_comissao))
                                                    }
                                                })
                                            }
                                        }
                                        else if (k.info.workflow[0].data !== '') {

                                            //let data_proposta = new Date(k.info.workflow[last].data);
                                            let data_proposta = new Date(k.info.workflow[0].data);
                                            let ano_proposta = data_proposta.getFullYear();
                                            let mes_proposta = (data_proposta.getMonth() + 1).toString().padStart(2, "0");

                                            if (year === ano_proposta) {
                                                //k.info.workflow[last].estado.forEach(k_3 => {
                                                //k.info.workflow[0].estado.forEach(k_3 => {
                                                    //if (k_3.pendente === false && k_3.completo === true && k_3.selected === true) {
                                                        set_angariacao_anual(atual => parseInt(atual) + 1)
                                                    //}
                                                //})
                                            }
                                            if (year === ano_proposta && month === mes_proposta) {
                                                set_angariacao_mensal(atual => parseInt(atual) + 1)

                                                k.info.workflow[last].estado.forEach(k_3 => {
                                                    if (k_3.pendente === false && k_3.completo === true && k_3.selected === true) {
                                                        //set_ganho_mensal(atual => parseFloat(atual) + parseFloat(k.commissioning.valor_comissao))
                                                    }
                                                })
                                            }
                                        }
                                    }
                                }

                                // if(k.commissioning.commissoes['Master']['payment'] !== undefined && k.commissioning.commissoes['Master'].payment.status === true) {
                                //     let data_proposta_c = new Date(k.commissioning.commissoes['Master'].payment.data);
                                //     let ano_proposta_c = data_proposta_c.getFullYear();
                                //     let mes_proposta_c = (data_proposta_c.getMonth() + 1).toString().padStart(2, "0");

                                //     if (year === ano_proposta_c && month === mes_proposta_c) {
                                //         set_ganho_mensal(atual => parseFloat(atual) + parseFloat(k.commissioning.valor_comissao))
                                //     }
                                // }
                                // else 
                                if(k.commissioning.commissoes['Escritorio']['payment'] !== undefined && k.commissioning.commissoes['Escritorio'].payment.status === true) {
                                    let data_proposta_c = new Date(k.commissioning.commissoes['Escritorio'].payment.data);
                                    let ano_proposta_c = data_proposta_c.getFullYear();
                                    let mes_proposta_c = (data_proposta_c.getMonth() + 1).toString().padStart(2, "0");

                                    if (year === ano_proposta_c && month === mes_proposta_c) {
                                        set_ganho_mensal(atual => parseFloat(atual) + (parseFloat(k.commissioning.valor_comissao) * 0.8))
                                    }
                                }
                                // else if(k.commissioning.commissoes['Colaborador']['payment'] !== undefined && k.commissioning.commissoes['Colaborador'].payment.status === true) {
                                //     let data_proposta_c = new Date(k.commissioning.commissoes['Master'].payment.data);
                                //     let ano_proposta_c = data_proposta_c.getFullYear();
                                //     let mes_proposta_c = (data_proposta_c.getMonth() + 1).toString().padStart(2, "0");

                                //     if (year === ano_proposta_c && month === mes_proposta_c) {
                                //         set_ganho_mensal(atual => parseFloat(atual) + parseFloat(k.commissioning.valor_comissao))
                                //     }
                                // }
                                // else if(k.commissioning.commissoes['Promotor']['payment'] !== undefined && k.commissioning.commissoes['Promotor'].payment.status === true) {
                                //     let data_proposta_c = new Date(k.commissioning.commissoes['Master'].payment.data);
                                //     let ano_proposta_c = data_proposta_c.getFullYear();
                                //     let mes_proposta_c = (data_proposta_c.getMonth() + 1).toString().padStart(2, "0");

                                //     if (year === ano_proposta_c && month === mes_proposta_c) {
                                //         set_ganho_mensal(atual => parseFloat(atual) + parseFloat(k.commissioning.valor_comissao))
                                //     }
                                // }

                                let aux_estado = '';
                                let aux_timeline = '<div><ul class="timeline">';
                                var i = 0;
                                if (k.info.estado)
                                    k.info.estado.forEach((v_1) => {
                                        i++;
                                        var j = 0;
                                        v_1.etapas.forEach((v_2) => {
                                            if (i === 5) {
                                                i = 1;
                                                aux_timeline = aux_timeline + '</ul><ul class="timeline">';
                                            }

                                            if (v_1.data.localeCompare("") !== 0) {
                                                if (v_2.activo) {
                                                    if (v_2.value.localeCompare(v_2.sucesso.id) === 0) {
                                                        aux_timeline = aux_timeline + '<li class="completed">'
                                                            + '<div class="timestamp">'
                                                            + '<span class="author"></span>'
                                                            + '<span class="date"></span>'
                                                            + '</div>'
                                                            + '<div class="status">'
                                                            + '<h4>' + v_1.label + '</h4>'
                                                            + '</div>'
                                                            + '<div class="on-course">'
                                                            + '<h4 class="on-course">em curso</h4>'
                                                            + '</div>'
                                                            + '</li>';
                                                    }
                                                    else if (v_2.value.localeCompare(v_2.insucesso.id) === 0) {
                                                        aux_estado = 'Cancelado';
                                                        aux_timeline = aux_timeline + '<li class="error">'
                                                            + '<div class="timestamp">'
                                                            + '<span class="author"></span>'
                                                            + '<span class="date"></span>'
                                                            + '</div>'
                                                            + '<div class="status">'
                                                            + '<h4>' + v_1.label + '</h4>'
                                                            + '</div>'
                                                            + '<div class="on-course">'
                                                            + '<h4 class="on-course">em curso</h4>'
                                                            + '</div>'
                                                            + '</li>';

                                                    }
                                                    else {
                                                        aux_estado = v_1.label;
                                                        aux_timeline = aux_timeline + '<li class="on-course">'
                                                            + '<div class="timestamp">'
                                                            + '<span class="author"></span>'
                                                            + '<span class="date"></span>'
                                                            + '</div>'
                                                            + '<div class="status">'
                                                            + '<h4>' + v_1.label + '</h4>'
                                                            + '</div>'
                                                            + '<div class="on-course">'
                                                            + '<h4 class="on-course">em curso</h4>'
                                                            + '</div>'
                                                            + '</li>';
                                                    }
                                                }
                                            }
                                            else {
                                                if (j === 0) {
                                                    j++;
                                                    aux_timeline = aux_timeline + '<li class="">'
                                                        + '<div class="timestamp">'
                                                        + '<span class="author"></span>'
                                                        + '<span class="date"></span>'
                                                        + '</div>'
                                                        + '<div class="status">'
                                                        + '<h4>' + v_1.label + '</h4>'
                                                        + '</div>'
                                                        + '<div class="on-course">'
                                                        + '<h4 class="on-course">em curso</h4>'
                                                        + '</div>'
                                                        + '</li>';
                                                }
                                            }

                                        })

                                    });
                                if (k.info.workflow)
                                    k.info.workflow.forEach((v_1) => {
                                        i++;
                                        var j = 0;
                                        if (v_1.estado)
                                            v_1.estado.forEach((v_2) => {
                                                if (i === 5) {
                                                    i = 1;
                                                    aux_timeline = aux_timeline + '</ul><ul class="timeline">';
                                                }

                                                if (v_1.data.localeCompare("") !== 0) {
                                                    if (v_2.selected) {
                                                        //console.log(v_2.selected)
                                                        if (v_2.pendente === false && v_2.completo === true) {
                                                            aux_estado = 'Completo';
                                                            aux_timeline = aux_timeline + '<li class="completed">'
                                                                + '<div class="timestamp">'
                                                                + '<span class="author"></span>'
                                                                + '<span class="date"></span>'
                                                                + '</div>'
                                                                + '<div class="status">'
                                                                + '<h4>' + v_1.name + '</h4>'
                                                                + '</div>'
                                                                + '<div class="on-course">'
                                                                + '<h4 class="on-course">em curso</h4>'
                                                                + '</div>'
                                                                + '</li>';
                                                        }
                                                        else if (v_2.selected === true && v_2.initial !== true && v_2.pendente !== true && v_2.completo === false) {
                                                            aux_estado = 'Cancelado';
                                                            aux_timeline = aux_timeline + '<li class="error">'
                                                                + '<div class="timestamp">'
                                                                + '<span class="author"></span>'
                                                                + '<span class="date"></span>'
                                                                + '</div>'
                                                                + '<div class="status">'
                                                                + '<h4>' + v_1.name + '</h4>'
                                                                + '</div>'
                                                                + '<div class="on-course">'
                                                                + '<h4 class="on-course">em curso</h4>'
                                                                + '</div>'
                                                                + '</li>';
                                                        }
                                                        else if (v_2.name === 'NA') {
                                                            aux_estado = 'Completo';
                                                            aux_timeline = aux_timeline + '<li class="completed">'
                                                                + '<div class="timestamp">'
                                                                + '<span class="author"></span>'
                                                                + '<span class="date"></span>'
                                                                + '</div>'
                                                                + '<div class="status">'
                                                                + '<h4>' + v_1.name + '</h4>'
                                                                + '</div>'
                                                                + '<div class="on-course">'
                                                                + '<h4 class="on-course">em curso</h4>'
                                                                + '</div>'
                                                                + '</li>';
                                                        }
                                                        else {
                                                            aux_estado = v_1.name;
                                                            aux_timeline = aux_timeline + '<li class="on-course">'
                                                                + '<div class="timestamp">'
                                                                + '<span class="author"></span>'
                                                                + '<span class="date"></span>'
                                                                + '</div>'
                                                                + '<div class="status">'
                                                                + '<h4>' + v_1.name + '</h4>'
                                                                + '</div>'
                                                                + '<div class="on-course">'
                                                                + '<h4 class="on-course">em curso</h4>'
                                                                + '</div>'
                                                                + '</li>';
                                                        }
                                                    }
                                                }
                                                else {
                                                    if (j === 0) {
                                                        j++;
                                                        aux_timeline = aux_timeline + '<li class="">'
                                                            + '<div class="timestamp">'
                                                            + '<span class="author"></span>'
                                                            + '<span class="date"></span>'
                                                            + '</div>'
                                                            + '<div class="status">'
                                                            + '<h4>' + v_1.name + '</h4>'
                                                            + '</div>'
                                                            + '<div class="on-course">'
                                                            + '<h4 class="on-course">em curso</h4>'
                                                            + '</div>'
                                                            + '</li>';
                                                    }
                                                }

                                            })

                                    });
                                aux_timeline += '</ul></div>';

                                let _name = k.info['Cliente']['name'].trim().split(' ')

                                return {
                                    proposta: k['id'],
                                    cliente: _name[0] + ' ' + _name[_name.length - 1],
                                    escritorio: k.info.Escritorio['name'],
                                    colaborador: k.info.Colaborador['name'],
                                    financeira: k.info.financials['name'],
                                    promotor: k.info.Promotor['name'],
                                    tipo_credito: k.info.tipo_credito['name'],
                                    valor_financiado: k.commissioning['valor_financiado'],
                                    estado: aux_estado,
                                    timeline: aux_timeline
                                }

                            })
                        ])
                        //console.log(credits)
                    })

            }
            else {

                reqGET(`${api_crud_base}/credits/credits/special/office/${companycode}/${office}`)
                    .then(res => {
                        //console.log(res.data)
                        set_credits(...[
                            res.data.map(k => {
                                if (k.info.workflow) {
                                    if (k.info.workflow.length > 0) {
                                        let last = k.info.workflow.length - 1;
                                        //if (k.info.workflow[last].data !== '') {
                                        if (k.info.workflow[0].data !== '') {
                                            let last = k.info.workflow.length - 1;
                                            //if (k.info.workflow[last].data !== '') {
                                            if (k.info.workflow[0].data !== '') {

                                                //let data_proposta = new Date(k.info.workflow[last].data);
                                                let data_proposta = new Date(k.info.workflow[0].data);
                                                let ano_proposta = data_proposta.getFullYear();
                                                let mes_proposta = (data_proposta.getMonth() + 1).toString().padStart(2, "0");

                                                if (year === ano_proposta) {
                                                    //k.info.workflow[last].estado.forEach(k_3 => {
                                                    //k.info.workflow[0].estado.forEach(k_3 => {
                                                        //if (k_3.pendente === false && k_3.completo === true && k_3.selected === true) {
                                                            set_angariacao_anual(atual => parseInt(atual) + 1)
                                                        //}
                                                    //})
                                                }
                                                if (year === ano_proposta && month === mes_proposta) {
                                                    //k.info.workflow[last].estado.forEach(k_3 => {
                                                    //k.info.workflow[0].estado.forEach(k_3 => {
                                                        //if (k_3.pendente === false && k_3.completo === true && k_3.selected === true) {
                                                            set_angariacao_mensal(atual => parseInt(atual) + 1)
                                                            set_ganho_mensal(atual => parseFloat(atual) + (parseFloat(k.commissioning.valor_comissao) * 0.8))
                                                        //}
                                                    //})
                                                }
                                            }
                                        }
                                    }
                                }

                                let aux_estado = '';
                                let aux_timeline = '<div><ul class="timeline">';
                                var i = 0;
                                if (k.info.estado)
                                    k.info.estado.forEach((v_1) => {
                                        i++;
                                        var j = 0;
                                        v_1.etapas.forEach((v_2) => {
                                            if (i === 5) {
                                                i = 1;
                                                aux_timeline = aux_timeline + '</ul><ul class="timeline">';
                                            }

                                            if (v_1.data.localeCompare("") !== 0) {
                                                if (v_2.activo) {
                                                    if (v_2.value.localeCompare(v_2.sucesso.id) === 0) {
                                                        aux_timeline = aux_timeline + '<li class="completed">'
                                                            + '<div class="timestamp">'
                                                            + '<span class="author"></span>'
                                                            + '<span class="date"></span>'
                                                            + '</div>'
                                                            + '<div class="status">'
                                                            + '<h4>' + v_1.label + '</h4>'
                                                            + '</div>'
                                                            + '<div class="on-course">'
                                                            + '<h4 class="on-course">em curso</h4>'
                                                            + '</div>'
                                                            + '</li>';
                                                    }
                                                    else if (v_2.value.localeCompare(v_2.insucesso.id) === 0) {
                                                        aux_estado = 'Cancelado';
                                                        aux_timeline = aux_timeline + '<li class="error">'
                                                            + '<div class="timestamp">'
                                                            + '<span class="author"></span>'
                                                            + '<span class="date"></span>'
                                                            + '</div>'
                                                            + '<div class="status">'
                                                            + '<h4>' + v_1.label + '</h4>'
                                                            + '</div>'
                                                            + '<div class="on-course">'
                                                            + '<h4 class="on-course">em curso</h4>'
                                                            + '</div>'
                                                            + '</li>';

                                                    }
                                                    else {
                                                        aux_estado = v_1.label;
                                                        aux_timeline = aux_timeline + '<li class="on-course">'
                                                            + '<div class="timestamp">'
                                                            + '<span class="author"></span>'
                                                            + '<span class="date"></span>'
                                                            + '</div>'
                                                            + '<div class="status">'
                                                            + '<h4>' + v_1.label + '</h4>'
                                                            + '</div>'
                                                            + '<div class="on-course">'
                                                            + '<h4 class="on-course">em curso</h4>'
                                                            + '</div>'
                                                            + '</li>';
                                                    }
                                                }
                                            }
                                            else {
                                                if (j === 0) {
                                                    j++;
                                                    aux_timeline = aux_timeline + '<li class="">'
                                                        + '<div class="timestamp">'
                                                        + '<span class="author"></span>'
                                                        + '<span class="date"></span>'
                                                        + '</div>'
                                                        + '<div class="status">'
                                                        + '<h4>' + v_1.label + '</h4>'
                                                        + '</div>'
                                                        + '<div class="on-course">'
                                                        + '<h4 class="on-course">em curso</h4>'
                                                        + '</div>'
                                                        + '</li>';
                                                }
                                            }

                                        })

                                    });
                                if (k.info.workflow)
                                    k.info.workflow.forEach((v_1) => {
                                        i++;
                                        var j = 0;
                                        if (v_1.estado)
                                            v_1.estado.forEach((v_2) => {
                                                if (i === 5) {
                                                    i = 1;
                                                    aux_timeline = aux_timeline + '</ul><ul class="timeline">';
                                                }

                                                if (v_1.data.localeCompare("") !== 0) {
                                                    if (v_2.selected) {
                                                        //console.log(v_2.selected)
                                                        if (v_2.pendente === false && v_2.completo === true) {
                                                            aux_estado = 'Completo';
                                                            aux_timeline = aux_timeline + '<li class="completed">'
                                                                + '<div class="timestamp">'
                                                                + '<span class="author"></span>'
                                                                + '<span class="date"></span>'
                                                                + '</div>'
                                                                + '<div class="status">'
                                                                + '<h4>' + v_1.name + '</h4>'
                                                                + '</div>'
                                                                + '<div class="on-course">'
                                                                + '<h4 class="on-course">em curso</h4>'
                                                                + '</div>'
                                                                + '</li>';
                                                        }
                                                        else if (v_2.selected === true && v_2.initial !== true && v_2.pendente !== true && v_2.completo === false) {
                                                            aux_estado = 'Cancelado';
                                                            aux_timeline = aux_timeline + '<li class="error">'
                                                                + '<div class="timestamp">'
                                                                + '<span class="author"></span>'
                                                                + '<span class="date"></span>'
                                                                + '</div>'
                                                                + '<div class="status">'
                                                                + '<h4>' + v_1.name + '</h4>'
                                                                + '</div>'
                                                                + '<div class="on-course">'
                                                                + '<h4 class="on-course">em curso</h4>'
                                                                + '</div>'
                                                                + '</li>';

                                                        }
                                                        else {
                                                            aux_estado = v_1.name;
                                                            aux_timeline = aux_timeline + '<li class="on-course">'
                                                                + '<div class="timestamp">'
                                                                + '<span class="author"></span>'
                                                                + '<span class="date"></span>'
                                                                + '</div>'
                                                                + '<div class="status">'
                                                                + '<h4>' + v_1.name + '</h4>'
                                                                + '</div>'
                                                                + '<div class="on-course">'
                                                                + '<h4 class="on-course">em curso</h4>'
                                                                + '</div>'
                                                                + '</li>';
                                                        }
                                                    }
                                                }
                                                else {
                                                    if (j === 0) {
                                                        j++;
                                                        aux_timeline = aux_timeline + '<li class="">'
                                                            + '<div class="timestamp">'
                                                            + '<span class="author"></span>'
                                                            + '<span class="date"></span>'
                                                            + '</div>'
                                                            + '<div class="status">'
                                                            + '<h4>' + v_1.name + '</h4>'
                                                            + '</div>'
                                                            + '<div class="on-course">'
                                                            + '<h4 class="on-course">em curso</h4>'
                                                            + '</div>'
                                                            + '</li>';
                                                    }
                                                }

                                            })

                                    });
                                aux_timeline += '</ul></div>';

                                return {
                                    proposta: k['id'],
                                    cliente: k.info['Cliente']['name'],
                                    escritorio: k.info.Escritorio['name'],
                                    colaborador: k.info.Colaborador['name'],
                                    financeira: k.info.financials['name'],
                                    promotor: k.info.Promotor['name'],
                                    tipo_credito: k.info.tipo_credito['name'],
                                    valor_financiado: k.commissioning['valor_financiado'],
                                    estado: aux_estado,
                                    timeline: aux_timeline
                                }

                            })
                        ])
                        //console.log(credits)
                    })
           
            }
        }

        load_credits(0)
    }, [props]);

    return (
        <main>
            <div className={`dash-cards-2 header`}>
                <h2 className="dash-title">Painel Principal</h2>
                <p>Painel Principal</p>
            </div>

            <div className={`dash-cards-2 highlights`}>
                <div className="card-single">
                    <div className="card-body">
                        <div>
                            <h5>Total de Angariações Anual</h5>
                            <h4>{angariacao_anual}</h4>
                        </div>
                    </div>
                </div>

                <div className="card-single">
                    <div className="card-body">
                        <div>
                            <h5>Angariações do mês atual</h5>
                            <h4>{angariacao_mensal}</h4>
                        </div>
                    </div>
                </div>

                <div className="card-single">
                    <div className="card-body">
                        <div>
                            <h5>Ganho mensal</h5>
                            <h4>{ganho_mensal.toFixed(2)}€</h4>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`dash-cards-2 body`}>
                <div className="card">
                    <div className="div-table expand">
                        <div className="table-body">
                            <DataTable
                                noDataComponent='Sem Registos'
                                columns={columns_creditos}
                                data={data_creditos}
                                noHeader
                                subHeader
                                pagination
                                paginationComponentOptions={defaultComponentOptions}
                                paginationPerPage={25}
                                paginationRowsPerPageOptions={[25, 50, 100]}
                                expandableRows
                                expandOnRowClicked
                                expandableRowsComponent={< ExpandableComponent />}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}
