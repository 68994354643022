import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { Redirect } from 'react-router-dom';
import Hosts from '../../../Hosts';

import { getTokenInfo } from './../../../UseToken';

import { api_crud_base, api_crud_route, data_interface, endpoints } from './Config';

import {

	reqGET,
	reqPOST,
	reqDEL,
	reqPUT,

} from '../../../Utils';
import { api_list_route } from './Config';
//import { ref } from 'yup';

//import { useHistory, Redirect } from "react-router-dom";
//import TextInput from 'react-autocomplete-input';


export default function Regist(props) {
	//export default function Financeira(props) ;
	//const Fornecedor = props => {

	// BEGIN


	//var history = useHistory();

	//Para Voltar ao URL Anterior
	//history.push(endpoints.list)

	//const id = useRef('');
	const [, set_dados] = useState(null);
	const dados = useRef(null);
	//const [local_interface, set_local_interface] = useState(JSON.parse(JSON.stringify(data_interface)));

	//console.log(dados.current)


	const [isloading, setLoading] = useState(true);
	const [isRedirect, setRedirect] = useState(false);
	const [refresh, setRefresh] = useState(0);

	const [show_delete_button, set_show_delete_button] = useState('d-none');

	const [is_edit, set_is_edit] = useState(false)

	const [etapas, set_etapas] = useState([])
	const [etapas_selected, set_etapas_selected] = useState([])

	const [estados, set_estados] = useState([])
	//const [estados_selected, set_estados_selected] = useState([])

	const fetch_id_info = async (id_serch) => {

		return await reqGET(`${api_list_route(getTokenInfo().companycode)}/${id_serch}`)
			.then(res => {
				console.log('FETCH INFO', res.data[0])
				set_dados(dados.current = res.data[0]);
			})
			.catch(error => error/*TODO: GO TO FAIL FETCH*/)
	}



	// PARA INSTANCIAR O COMPONENTE COM O ID 
	//const props_tmp = props
	useEffect(() => {

		const load_etapas_estados = async () => {
			reqGET(`${api_crud_base}/credits/configs`)
				.then(res => {
					//console.log(res.data)
					set_etapas(...[res.data[0].info.etapa.map(k => ({ value: k['name'], label: k['name'] }))])
					set_estados(...[res.data[0].info.estado.map(k => ({ value: k['name'], label: k['name'] }))])
					//console.log(etapas)
				}
				)
		}



		const clean_data = async () => {
			// DEEP COPY OF data_interface 
			set_dados(dados.current = JSON.parse(JSON.stringify(data_interface)))
			set_dados(dados.current.info.companycode = getTokenInfo().companycode)

		}

		clean_data();

		load_etapas_estados();

		try {
			let test = props.location.state.operation;

			if (test === 'edit') {
				set_is_edit(true)
				//if (props.location.state !== undefined) {
				//console.log('->>>>>>>>>>>>>>>>:', props.location.state)

				//if (props.location.state.operation === 'edit') {
				set_show_delete_button('')
				//console.log('AQUI DENTRO DO STATE', props.location.state.item['id'])
				fetch_id_info(props.location.state.item['id']).then(() => {
					setLoading(false);


					//console.log(dados.current)

					change_selected(dados.current.info.etapa)

					//console.log(etapas)
					//console.log(etapas_selected)
				})
			}
			else  // VAI REGISTAR
				if (props.location.state.operation === 'new') {
					setLoading(false);
				}
				else {
					//console.log('ALI ELSE')
					setLoading(false);

					setRedirect(true);
					/* else if (props.location.state.operation === 'new') {
						setLoading(false);
					} */
				}

		} catch {

			clean_data()

			setLoading(false);
		}

		// BACK
		//props.history.push(endpoints.list)
		return () => {
			clean_data()
		}

	}, [props])

	const change_etapas = async e => {
		//console.log(e);
		set_etapas_selected(e);
		if (dados.current.info.etapa.length < e.length) {
			//console.log('adicionar')
			etapas.forEach((v) => {
				if (e.length === 0) {
					dados.current.info.etapa = []
				}
				e.forEach((v_1, k_1) => {
					if (typeof v_1 !== 'undefined' && v.value === v_1) {
						//console.log(dados.current.info.etapa)
						//console.log(v.value)
						//console.log(v_1)
						if (!dados.current.info.etapa.some(v_2 => v_2.name === v_1)) {
							if (dados.current.info.etapa.length === 1 && dados.current.info.etapa[0].name === '') {
								dados.current.info.etapa = [];
							}
							dados.current.info.etapa.push({
								name: v.label,
								ordem: k_1,
								selected: false,
								estado: []
							})
						}
						else {
							//console.log('existe: ' + v_1)
						}
					}
				});
			});
		}
		else if (dados.current.info.etapa.length < e.length) {
			//console.log('retirar')
			dados.current.info.etapa.forEach((v, k) => {
				if (v.name !== e.toString()) {
					dados.current.info.etapa.splice(k, 1);

					setRefresh(refresh + 1)
				}
			})
		}
		else {
			//console.log('reordenar')
			//console.log(e)
			//console.log(dados.current.info.etapa)

			let etapas_aux = []
			e.forEach((v, k) => {
				dados.current.info.etapa.forEach((v_1) => {
					if (v === v_1.name) {
						v_1.ordem = k;
						etapas_aux.push(v_1)
					}
				})
			})
			dados.current.info.etapa = etapas_aux

			setRefresh(refresh + 1)
		}

		set_dados(dados)
	}

	const change_etapa_estados = async (e, parent_name) => {
		//console.log(e);
		//console.log(parent_name);
		//console.log(parent_name);
		//console.log(etapas[e]);

		dados.current.info.etapa.forEach((v) => {
			if (typeof v !== 'undefined' && v.name === parent_name) {
				//console.log(v)
				//console.log(v.estado.length)
				//console.log(e.length)

				if (v.estado.length < e.length) {
					//console.log('adicionar')

					if (v.name === parent_name.toString()) {
						estados.forEach((v_1) => {
							if (e.length === 0) {
								v.estado = []
							}
							e.forEach((v_2) => {
								if (typeof v_2 !== 'undefined' && v_1.value === v_2.toString()) {
									if (!v.estado.some(v_3 => v_3.name === v_2.toString())) {
										//console.log(v_1)
										//console.log(v_1)
										//console.log(v_2)
										v.estado.push({
											name: v_1.label,
											initial: false,
											selected: false,
											pendente: true,
											completo: false
										})

										setRefresh(refresh + 1)
									}
								}
							})
						})
					}

				}
				else if (v.estado.length > e.length) {
					//console.log('retirar')
					dados.current.info.etapa.forEach((v) => {
						if (v.name === parent_name.toString()) {
							if (v.estado) {
								v.estado.forEach((v_1, k_1) => {
									if (!e.includes(v_1.name)) {
										v.estado.splice(k_1, 1);

										setRefresh(refresh + 1)
									}
								})
							}
						}
					})
				}
				else {
					//console.log('reordenar')
					//console.log(e) 

					var etapas_aux = []
					dados.current.info.etapa.forEach((v) => {
						if (v.name === parent_name) {
							//console.log(v)

							e.forEach((v_1) => {
								v.estado.forEach((v_2) => {
									if (v_2.name === v_1) {
										etapas_aux.push(v_2)
									}
								})
							})

							//console.log(v.estado)
							//console.log(etapas_aux)
							v.estado = etapas_aux
						}
					})
				}
			}
		})

		//set_dados(dados);
		setRefresh(refresh + 1)
		//useForceUpdate()
		//console.log('set_dados');
	}

	function change_selected(etapas) {
		let etapas_aux = []
		if (etapas)
			etapas.forEach((v) => {
				//console.log(v)
				etapas_aux.push(v.name)
			})
		//console.log(etapas_aux)
		set_etapas_selected(etapas_aux)
		//set_dados(dados.current)
	}

	const change_estados_special = (e, tipo) => {
		const { value, parent } = e;

		//console.log(e)
		//console.log(tipo)

		dados.current.info.etapa.forEach((v) => {
			if (v.name === parent) {
				v.estado.forEach((v_1) => {
					if (v_1.name === value) {
						if (tipo === 'initial') {
							v_1.initial = true;
							v_1.pendente = false;
							v_1.completo = false;
						}
						if (tipo === 'sucesso') {
							v_1.initial = false;
							v_1.pendente = false;
							v_1.completo = true;
						}
						if (tipo === 'insucesso') {
							v_1.initial = false;
							v_1.pendente = false;
							v_1.completo = false;
						}
					}
					else {
						if (tipo === 'initial') {
							if (v_1.initial === true && v_1.pendente === false && v_1.completo === false) {
								v_1.initial = false;
								v_1.pendente = true;
								v_1.completo = false;
							}
						}
						if (tipo === 'sucesso') {
							if (v_1.initial === false && v_1.pendente === false && v_1.completo === true) {
								v_1.initial = false;
								v_1.pendente = true;
								v_1.completo = false;
							}
						}
						if (tipo === 'insucesso') {
							if (v_1.initial === false && v_1.pendente === false && v_1.completo === false) {
								v_1.initial = false;
								v_1.pendente = true;
								v_1.completo = false;
							}
						}
					}
				})
			}
		})

		setRefresh(refresh + 1)
	}

	const handleSubmit = async e => {
		e.preventDefault();

		if (is_edit === true) {
			//alert('Atualizar PUT')

			reqPUT(api_crud_route, dados.current)
				.then(() => alert('Registo Atualizado com Sucesso!'))
				.catch(() => alert('Problema ao atualizar Registo!'))
		} else {
			//alert('Inserir POST')
			reqPOST(api_crud_route, dados.current)
				.then(() => alert('Registo Inserido com Sucesso!'))
				.catch(() => alert('Problema ao atualizar Registo!'))
		}

		props.history.push(endpoints.list)
	}


	function apagar(id, e) {
		if (window.confirm('Tem a certeza que quer apagar este tipo de crédito?')) {

			var data = {
				id: dados.current.id,
				info: {}
			}

			reqDEL(Hosts.SIMCore_API + '/credits/types/', data)
				.then(res => {
					alert(res.status === 200 ?
						'Registo Apagado com Sucesso' : 'Problema ao Apagar Registo');

					window.location.href = endpoints.list;
				})
				.catch(error => alert(error))
		}
	}



	const handle_change_info = (e) => {
		const { name, value } = e.target;

		/* DEBUG*/

		//console.log('NAME:' + name)
		//console.log('Value:' + value)

		set_dados(dados.current.info[name] = value)

		//console.log('Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«Ãƒâ€šÃ‚Â«')
		//console.log('Dados Current:' + JSON.stringify(dados))

	}

	const dual_list_box_lang = {
		availableFilterHeader: 'Filter available',
		availableHeader: 'Disponiveis',
		moveAllLeft: 'Removel todos',
		moveAllRight: 'Adicionar todos',
		moveLeft: 'Remover',
		moveRight: 'Adicionar',
		moveBottom: 'Mover para o fundo',
		moveDown: 'Mover para baixo',
		moveUp: 'Mover para cima',
		moveTop: 'Mover para o topo',
		noAvailableOptions: 'Sem opções disponiveis',
		noSelectedOptions: 'Sem opções selecionadas',
		selectedFilterHeader: 'Filter selected',
		selectedHeader: 'Selecionados'
	}

	const custom_select_styles = {
		container: base => ({
			...base,
			width: '100%',
		}),
		control: base => ({
			...base,
			borderRadius: "10px",
			padding: "2px 10px",
			fontSize: '14px'
		}),
	}

	return isloading ?
		<main>
			<div className={`dash-cards-2 header`}>
				<h2 className="dash-title">LOADING</h2>
				<p>LOADING</p>
				<div className="overlay">
					<div className="overlay__inner">
						<div className="overlay__content"><span className="spinner"></span></div>
					</div>
				</div>
			</div>
		</main>
		:
		isRedirect
			?
			<Redirect to={endpoints.list} replace />
			:
			<main>
				<div className={`dash-cards-2 header`}>
					<h2 className="dash-title">Tipos de Crédito</h2>
					<p>Home / Tipos de Crédito / Inserir Crédito</p>
				</div>

				<div className={`dash-cards-2 body`}>
					<div className="card">
						<div className="card-body">
							<form id="form" onSubmit={handleSubmit}>
								<input type="hidden" name="id" value={dados.current.id} />

								<h2 className="dash-title">Dados</h2>
								<div className="form-group">
									<span className="flex-1">
										<label>Nome</label>
										<input type="text" name="name" value={dados.current.info.name} pattern="[A-Za-z\u00C0-\u00FF0-9\s\-\+]+" className="input-form" placeholder="Introduza o nome" onChange={handle_change_info} required />
									</span>
								</div>
								<div className="form-group">
									<span className="flex-1">
										<label className="dash-title">Etapas do Crédito</label>
										<DualListBox
											id={0}
											options={etapas}
											selected={etapas_selected}
											onChange={change_etapas}
											preserveSelectOrder
											showOrderButtons
											lang={dual_list_box_lang}
										/>
									</span>
								</div>
								<div className="form-group-2">
									<h2 className="dash-title">Etapas</h2>
									{
										etapas_selected.map((v) => {
											return (
												etapas.map((v_1, k_1) => {
													if (v === v_1.value) {
														//var estados_aux = [];
														var estados_selected_aux = [];
														var estados_selected_2_aux = [];
														var estado_inicial = '';
														var estado_sucesso = '';
														var estado_insucesso = '';

														dados.current.info.etapa.forEach((v_3) => {
															if (v_1.value === v_3.name) {
																// //console.log('===')
																//console.log(v_3)
																// //console.log(typeof v_1.value + ' :: ' + typeof v_3.name)
																if (v_3.estado) {
																	v_3.estado.forEach((v_4) => {
																		//console.log(v_4)
																		estados_selected_aux.push(v_4.name);
																		estados_selected_2_aux.push({ value: v_4.name, label: v_4.name, parent: v_1.value });

																		if (v_4.initial === true && v_4.pendente === false && v_4.completo === false) {
																			estado_inicial = { label: v_4.name, name: v_4.name, parent: v_1.value };
																			//console.log('estado_inicial')
																			//console.log(estado_inicial)
																		}
																		else if (v_4.initial === false && v_4.pendente === false && v_4.completo === true) {
																			estado_sucesso = { label: v_4.name, name: v_4.name, parent: v_1.value };
																			//console.log('estado_sucesso')
																			//console.log(estado_sucesso)
																		}
																		else if (v_4.initial === false && v_4.pendente === false && v_4.completo === false) {
																			estado_insucesso = { label: v_4.name, name: v_4.name, parent: v_1.value };
																			//console.log('estado_insucesso')
																			//console.log(estado_insucesso)
																		}
																	})
																}
															}
														})

														return (
															<div key={k_1}>
																<div className="form-group">
																	<span className="flex-1">
																		<label className="dash-title">{v_1.label}</label>
																		<DualListBox
																			id={k_1 + 1}
																			options={estados}
																			selected={estados_selected_aux}
																			onChange={(e) => change_etapa_estados(e, v_1.value)}
																			//onChange={change_etapa_estados}
																			preserveSelectOrder
																			showOrderButtons
																			lang={dual_list_box_lang}
																		/>
																	</span>
																</div>
																<div className="form-group">
																	<span className="flex-1">
																		<label>Inícial:</label>
																		<Select
																			className="div-select"
																			options={estados_selected_2_aux}
																			name="credit_type"
																			value={estado_inicial === '' ? null : estado_inicial}
																			placeholder={"Introduza o inícial"}
																			onChange={(e) => change_estados_special(e, 'initial')}
																			styles={custom_select_styles}
																		/>
																	</span>
																	<span className="flex-1">
																		<label>Sucesso:</label>
																		<Select
																			className="div-select"
																			options={estados_selected_2_aux}
																			name="credit_type"
																			value={estado_sucesso === '' ? null : estado_sucesso}
																			placeholder={"Introduza o sucesso"}
																			onChange={(e) => change_estados_special(e, 'sucesso')}
																			styles={custom_select_styles}
																		/>
																	</span>
																	<span className="flex-1">
																		<label>Insucesso:</label>
																		<Select
																			className="div-select"
																			options={estados_selected_2_aux}
																			name="credit_type"
																			value={estado_insucesso === '' ? null : estado_insucesso}
																			placeholder={"Introduza o insucesso"}
																			onChange={(e) => change_estados_special(e, 'insucesso')}
																			styles={custom_select_styles}
																		/>
																	</span>
																</div>
															</div>
														)
													} else
														return ''

												})
											)
										})
									}
								</div>

							</form>
						</div>
					</div>
				</div>

				<div className={'dash-cards-2 footer'}>
					<nav className="navbar bottom-navbar navbar-light">
						<div className="navbar-footer">
							<ul className="navbar-nav float-left"></ul>
							<ul className="navbar-nav float-right">
								<li className="nav-item">
									<Link className="nav-link btn-cancel" to={{ pathname: endpoints.list }}  >

										<img alt='' src={'./img/cancelar.svg'} />
										<span>Cancelar</span>
									</Link>
								</li>

								<li className={"nav-item " + show_delete_button}>
									<button onClick={() => apagar(dados.current.id)} className="nav-link btn-delete">

										<img alt='' src={'./img/eliminar_b.svg'} />
										<span>Eliminar</span>
									</button>
								</li>
								<li className="nav-item">
									<button type="submit" form="form" className="nav-link">

										<img alt='' src={'./img/save.svg'} />
										<span>Salvar</span>
									</button>
								</li>
							</ul>
						</div>
					</nav>
				</div>
			</main >
}
