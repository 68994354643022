import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

//import { Redirect } from 'react-router-dom';
//import Hosts from '../../../Hosts';
//import SideBarData from '../../Sidebar/SideBarData';


import { confirmAlert } from 'react-confirm-alert';

import { api_crud_base, api_crud_route, data_interface, endpoints } from './Config';

import { getTokenInfo } from './../../../UseToken';

import {

	reqGET,
	reqPOST,
	reqDEL,
	reqPUT,


} from '../../../Utils';
import { api_list_route } from './Config';
import UseSidebar from '../../../UseSidebar';



export default function Regist(props) {

	//confirmAlert(options);

	const [, set_dados] = useState(null);
	const dados = useRef(null);

	const [isloading, setLoading] = useState(true);
	//const [isRedirect, setRedirect] = useState(false);

	//const { sideBarDataRef } = useSidebar();

	const [save_label, set_save_label] = useState('Salvar');

	//const [sidebar_data, set_sidebar_data] = useState([]);

	/* 	const permission_types = [
			{ value: "Editar", label: "Editar" },
			{ value: "Criar", label: "Criar" },
			{ value: "Eliminar", label: "Eliminar" }
		]; */

	const [is_edit, set_is_edit] = useState(false)





	// PARA INSTANCIAR O COMPONENTE COM O ID 
	//const props_tmp = props
	useEffect(() => {


		const fetch_id_info = async (id_serch) => {

			return await reqGET(`${api_list_route(getTokenInfo().companycode)}/${id_serch}`)
				.then(res => {

					set_dados(dados.current = res.data[0]);
					console.log('Dados Current', dados.current)

				})
				.catch(error => alert(error))
		}



		//console.log('OIIIIIIIIIIIII')
		const clean_data = () => {
			// DEEP COPY OF data_interface 
			set_dados(dados.current = JSON.parse(JSON.stringify(data_interface)))
			set_dados(dados.current.info.companycode = getTokenInfo().companycode)

		}



		set_save_label('Salvar')

		clean_data();


		try {
			let test = props.location.state.operation;

			if (test === 'edit') {
				console.log('NO EDIT')


				fetch_id_info(props.location.state.item['id']).then(
					() => {

						set_is_edit(true)
						setLoading(false)

						// CASO AINDA NÃO EXISTA INFORMAÇÃO 
						//if (dados.current.info.menu.length === 0) {

						//console.log('DADOS CURRENT INFO IGUALA  ==', dados.current.info.name)
						//set_dados(dados.current.info.menu = UseSidebar().getSidebar.then(v => dados.current.info.menu = v))
						UseSidebar().getSidebar(dados.current.info.name, true).then(v => { /* console.log('FROM USESIDEBAR', v) ;*/ set_dados(dados.current.info.menu = v) })
						//console.log('MENUS', dados.current.info.menu)

						//}


						return
					}
				)




				//set_show_delete_button('')

				/* 	fetch_id_info(props.location.state.item['id']).then(() => {
				
						if (dados.current.addresses[0].country === 'Portugal') {
				
							obter_distritos_pt(set_districts)
				
							obter_cidades_por_distrito(dados.current.addresses[0].region, set_cities)
				
							obter_freguesias_por_cidade(dados.current.addresses[0].city, set_villages)
							setLoading(false);
						} })*/

				//return
			}

		} catch {

			clean_data()

			setLoading(false);
			// VAI REGISTAR
			//console.log('PERFISMENUS NEW REGIST')
			//console.log('DADOS CURRENT INFO IGUALA  ==', dados.current.info.name)
			//set_dados(dados.current.info.menu = UseSidebar().getSidebar.then(v => dados.current.info.menu = v))


			//Verificar 

			UseSidebar().getSidebar(null).then(v => { /* console.log('FROM USESIDEBAR', v); */ set_dados(dados.current.info.menu = v) })



			//console.log('MENUS', dados.current.info.menu)



			//setRedirect(false);
			//return 404

		}
		// BACK
		//props.history.push(endpoints.list)
		return () => {
			clean_data()
		}

	}, [props])


	const handleSubmit = async e => {
		e.preventDefault();

		console.log('------------NO SUBMIT-------------')
		let guardarLink = endpoints.list

		if (is_edit === true) {
			//alert('Atualizar PUT')

			//console.log('EDIA')

			// PROFILES UPDATE IN ENTITIES

			//console.log('CURRRRRRRRENT:', dados.current)

			const api_list_route_entities = companycode => `${api_crud_base}/entities/special/${companycode}/${dados.current.info.name}`

			let entities_route = api_list_route_entities(getTokenInfo().companycode)
			//console.log('NO SUBMIT 1', entities_route)


			let temp_profile = {
				name: dados.current.info.name,
				companycode: dados.current.info.companycode,
				menu: dados.current.info.menu
			};

			try {
				await reqGET(entities_route)
					.then(res => {

						console.log('NO SUBMIT 2', res)

						res.data.forEach(item => {

							item.profile = temp_profile
							//console.log('CURTD (DENTRO):', dados.current)


							//console.log('Profile', item.profile)

							//UPDATE ENTITY PROFILE 
							//console.log('PUT', item)
							reqPUT(`${api_crud_base}/entities`, item)
								//.then(() => alert('Registo Atualizado com Sucesso! HAHA'))
								.catch(() => alert('Problema ao atualizar Registo!'))


							//getSidebar().then(v => setSideBarData(v))

						})
					})
					.catch(() => { alert('Problema ao atualizar Entidades!') })


				reqPUT(api_crud_route, dados.current)
					.then(() => window.location.reload())//alert('Registo Atualizado com Sucesso!'))
					.catch(() => alert('Problema ao atualizar Registo!'))
				//getSidebar().then(v => setSideBarData(v))

				//console.log('CURRRRRRRRENT 3:', dados.current)



				//window.location.reload();
				//window.location.reload(false);
				//props.history.push(guardarLink)
				//sideBarDataRef.current = dados.current.info.menu

			} catch {
				console.log('NO CATCH')
				props.history.push(guardarLink)
			}
		} else {
			console.log('---------------> NO POST')
			reqPOST(api_crud_route, dados.current)
				.then(() => alert('Registo Inserido com Sucesso!'))
				.catch(() => alert('Problema ao atualizar Registo!'))

		}


		props.history.push(guardarLink)

	}



	function apagar() {


		confirmAlert({
			title: 'Eliminar Perfil',
			message: 'Pretende mesmo eliminar este registo ?',
			buttons: [
				{
					label: 'Sim',
					onClick: () => {
						// Verificar se existe informação associada ao Cliente
						/* reqGET(`${api_crud_base}/credits/credits/special/credits/cliente/${data.id}`).then(
							res => {
								//console.log(res.data)
								if (res.data.length > 0) {
		
									confirmAlert({
										title: 'Aviso',
										message: 'A Entidade tem registos associados. Apenas pode ser editada.',
										buttons: [
											{
												label: 'Voltar',
												onClick: () => { return }
											}
										]
									})
								} else { */
						//alert('Eliminou')



						reqDEL(api_crud_route + '/', { id: dados.current.id })
							.then(res => {
								alert(res.status === 200 ?
									'Registo Apagado com Sucesso' : 'Problema ao Apagar Registo');

								window.location.href = endpoints.list;
							})
							.catch(error => alert(error))
					}
				},

				{
					label: 'Não',
					onClick: () => { return }
				}
			]
		});
	};



	/* 	const handle_change_addresses = (e, addresses_index = 0) => {
			const { name, value } = e.target;
		
			//DEBUG
			//console.log('NAME:' + name)
			//console.log('Value:' + value) 
		
			set_dados(dados.current.addresses[addresses_index][name] = value)
		
		} */


	const handle_change_info = (e) => {
		const { name, value } = e.target;

		/* DEBUG*/
		/* 
				//console.log('NAME:' + name)
				//console.log('Value:' + value)
		 */
		set_dados(dados.current.info[name] = value)

		/* 	//console.log('Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«')
			//console.log('Dados Current:' + JSON.stringify(dados)) */

	}

	const handleSingleCheck = e => {

		const { name } = e.target;
		console.log(name)

		let ver_submenu = (node) => {
			if (node.submenu.length !== 0) {

				node.submenu.forEach(subitem => {

					if (subitem.id === name) {
						subitem.allow = !subitem.allow
					} if (name.includes(subitem.title) && (name.includes('edit'))) {
						subitem.edit = !subitem.edit

					} if (name.includes(subitem.title) && (name.includes('remove'))) {
						subitem.remove = !subitem.remove
					}
					else {
						ver_submenu(subitem)
					}

				})
			}
		}

		dados.current.info.menu.forEach(node => {

			if (node.id === name) {
				node.allow = !node.allow
			} if (name.includes(node.title) && (name.includes('edit'))) {
				node.edit = !node.edit

			} if (name.includes(node.title) && (name.includes('remove'))) {
				node.remove = !node.remove
				//console.log('no true')
			} else
				ver_submenu(node)
		})
		return
	}




	return isloading ?
		<main>
			<div className={`dash-cards-2 header`}>
				<h2 className="dash-title">LOADING</h2>
				<p>LOADING</p>
				<div className="overlay">
					<div className="overlay__inner">
						<div className="overlay__content"><span className="spinner"></span></div>
					</div>
				</div>
			</div>
		</main>
		/* 	:
			isRedirect
				?
				<Redirect to={endpoints.list} replace /> */
		:
		<main>
			<div className={`dash-cards-2 header`}>
				<h2 className="dash-title">Perfil</h2>
				<p>Home / Perfis / Inserir Perfil</p>
			</div>

			<div className={`dash-cards-2 body`}>
				<div className="card">
					<div className="card-body">

						<form id="form" onSubmit={handleSubmit}>

							<h2 className="dash-title">Perfil</h2>
							<div className="form-group">
								<span className="flex-1">
									<label>Nome do Perfil</label>
									<input type="text" name="name" value={dados.current.info.name} pattern="[a-zA-Z\u00C0-\u00FF ]+" className="input-form" placeholder="Introduza o nome" onChange={handle_change_info} required />
								</span>
							</div>

							<h2 className="dash-title">Menus</h2>
							<div className="form-group">
								<span className="flex-1">
									<label >Lista de Entradas Disponíveis</label>
									<br />
									{
										dados.current.info.menu.map((item, key) => {

											if (item.title === '')
												return <div key={key}></div>
											else
												return (

													<div key={key} className='div-list'>
														<span className="flex-1">
															<img alt='' src={"./img/" + item.icon} />
															<label key={key} className="div-checkbox">
																{item.title}
																<input
																	name={item.id}
																	className="checkbox-form"
																	type="checkbox"
																	value={item.title}
																	onChange={handleSingleCheck}
																	defaultChecked={item.allow}
																/>
																<span key={key} className="checkmark"></span>
															</label>
														</span>
														<div>

															{

																item.submenu.map((sub, key2) => {

																	if (sub.title === '')
																		return <div key={key2}></div>
																	else
																		return (

																			<div key={key2} className="form-group" >

																				<span className="flex-1">

																					<label key={sub.id} className="div-checkbox">
																						--{sub.title}
																						<input
																							name={sub.id}
																							className="checkbox-form"
																							type="checkbox"
																							value={sub.title}
																							onChange={handleSingleCheck}
																							defaultChecked={sub.allow === true ? true : false}
																						/>
																						<span className="checkmark"></span>
																					</label>
																				</span>
																				{sub.title.includes('Lista') ?
																					<span className="flex-2">

																						<ol>
																							<li>
																								<label className="div-checkbox">Editar
																									<input
																										name={sub.title + '_edit'}
																										className="checkbox-form"
																										type="checkbox"
																										value={sub.title}
																										onChange={handleSingleCheck}
																										defaultChecked={sub.edit}
																									/>
																									<span className="checkmark"></span>
																								</label>
																							</li>
																							<li>
																								<label className="div-checkbox">Remover
																									<input
																										name={sub.title + '_remove'}
																										className="checkbox-form"
																										type="checkbox"
																										value={sub.title}
																										onChange={handleSingleCheck}
																										defaultChecked={sub.remove}
																									/>
																									<span className="checkmark"></span>
																								</label>
																							</li>

																						</ol>
																					</span>
																					:
																					<></>

																				}

																			</div>
																		)
																})
															}
														</div>
														{/* <h2 className="dash-title">          </h2> */}
													</div>
												)
										}
										)
									}
								</span>
							</div>

						</form>
					</div>
				</div>
			</div>

			<div className={'dash-cards-2 footer'}>
				<nav className="navbar bottom-navbar navbar-light">
					<div className="navbar-footer">
						<ul className="navbar-nav float-left"></ul>
						<ul className="navbar-nav float-right">
							<li className="nav-item">
								<Link className="nav-link btn-cancel" to={{ pathname: endpoints.list }}  >

									<img alt='' src={'./img/cancelar.svg'} />
									<span>Cancelar</span>
								</Link>
							</li>

							<li className={"nav-item "}>
								<button onClick={() => apagar(dados.current.id)} className="nav-link btn-delete">

									<img alt='' src={'./img/eliminar_b.svg'} />
									<span>Eliminar</span>
								</button>
							</li>
							<li className="nav-item">
								<button type="submit" form="form" className="nav-link">

									<img alt='' src={'./img/save.svg'} />
									<span>{save_label}</span>
								</button>
							</li>
						</ul>
					</div>
				</nav>
			</div>
		</main >
}



/* const options = {
	title: 'Title',
	message: 'Message',
	buttons: [
		{
			label: 'Yes',
			onClick: () => alert('Click Yes')
		},
		{
			label: 'No',
			onClick: () => alert('Click No')
		}
	],
	childrenElement: () => <div />,
	customUI: ({ onClose }) => <div>Custom UI</div>,
	closeOnEscape: true,
	closeOnClickOutside: true,
	willUnmount: () => { },
	afterClose: () => { },
	onClickOutside: () => { },
	onKeypressEscape: () => { },
	overlayClassName: "overlay-custom-class-name"
}; */