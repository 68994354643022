/** Ficheiro de Configuração de Acessos Remotos */


//const SIMCore_API = 'https://web201.assec.net/api/simnet_credit'; // AssecSim's REST API for ALL DATA TYPES
const SIMCore_API = 'https://gestao.fasquiaimportante.pt/api/simnet_credit'; // AssecSim's REST API for ALL DATA TYPES



//Begin exporting all variables
module.exports = {
    SIMCore_API,
};





