import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Redirect } from 'react-router-dom';
//import Hosts from '../../../Hosts';

import { object_type, api_crud_base, api_crud_route, data_interface, endpoints } from './Config';

import { getTokenInfo } from './../../../UseToken';
import { confirmAlert } from 'react-confirm-alert';


import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import {

	reqGET,
	reqPOST,
	reqDEL,
	reqPUT,

	obter_cidades_por_distrito,
	obter_freguesias_por_cidade,
	obter_distrito_por_cidade,
	obter_paises_em_pt,
	obter_distritos_pt,

	get_vat_info,

	pattern_vat,
	pattern_email, passwordHash,

} from '../../../Utils';

//import { useHistory, Redirect } from "react-router-dom";
//import TextInput from 'react-autocomplete-input';


export default function Regist(props) {
	//export default function Financeira(props) ;
	//const Fornecedor = props => {

	// BEGIN


	//var history = useHistory();

	//Para Voltar ao URL Anterior
	//history.push(endpoints.list)

	//const id = useRef('');
	//const cenas = data_interface
	//const [local_interface, setInterface] = useState(data_interface)
	const [, set_dados] = useState(null);
	const dados = useRef(data_interface);
	const [is_edit, set_is_edit] = useState(false)

	//console.log(dados.current)

	let newDate = new Date();
	let today = newDate.getFullYear() + '-' + (newDate.getMonth() + 1).toString().padStart(2, "0") + '-' + newDate.getDate().toString().padStart(2, "0");

	const [isloading, setLoading] = useState(true);
	const [isRedirect, setRedirect] = useState(false);

	const [show_vat_form, set_show_vat_form] = useState('');
	const [show_delete_button, set_show_delete_button] = useState('d-none');
	const [show_district_city_village, set_show_district_city_village] = useState('');

	const [disabled, set_disabled] = useState(false)

	const [hidePessoal, set_hide_pessoal] = useState('')
	const [hideEmpresarial, set_hide_empresarial] = useState('')

	const entity_types = [
		{ value: "Pessoal", label: "Pessoal" },
		{ value: "Empresarial", label: "Empresarial" }
	];

	const identification_types = [
		{ value: "Bilhete de Identidade", label: "Bilhete de Identidade" },
		{ value: "Cartão de Cidadão", label: "Cartão de Cidadão" }
	];

	const [offices, set_offices] = useState([])
	const [responsables, set_responsables] = useState([])
	const [countries, set_countries] = useState([])
	const [districts, set_districts] = useState([])
	const [cities, set_cities] = useState([])
	const [villages, set_villages] = useState([])

	const custom_select_styles = {
		container: base => ({
			...base,
			width: '100%',
		}),
		control: base => ({
			...base,
			borderRadius: "10px",
			padding: "2px 10px",
			fontSize: '14px'
		}),
	}



	const change_country = e => {

		set_cities([])
		set_villages([])

		set_dados(dados.current.addresses[0].city = '');
		set_dados(dados.current.addresses[0].village = '');
		set_dados(dados.current.addresses[0].region = '');

		if (e.value === "Portugal") {

			set_show_district_city_village('')

			if (districts.length === 0) {
				//console.log('DISTRITOS VAZIOS')
				obter_distritos_pt(set_districts)
			}
		}
		else {
			set_show_district_city_village('d-none')
		}

		set_dados(dados.current.addresses[0].country = e.value);
	}

	const change_district = e => {

		set_cities([])
		set_villages([])

		obter_cidades_por_distrito(e.value, set_cities)

		set_dados(dados.current.addresses[0].city = '');
		set_dados(dados.current.addresses[0].village = '');
		set_dados(dados.current.addresses[0].region = e.value);
	}

	const change_city = e => {

		set_villages([])

		obter_freguesias_por_cidade(e.value, set_villages)

		set_dados(dados.current.addresses[0].village = '');
		set_dados(dados.current.addresses[0].city = e.value);
	}

	const change_village = e => {
		set_dados(dados.current.addresses[0].village = e.value);
		set_dados(dados)
	}

	const change_entity_type = e => {
		set_dados(dados.current.info.entity_type = e.value);

		if (e.value.localeCompare('Pessoal') === 0) {
			set_hide_pessoal('');
			set_hide_empresarial('d-none');
		}
		else {
			set_hide_pessoal('d-none');
			set_hide_empresarial('');
		}
	}

	const load_reponsables = async (office) => {
		//console.log(`${api_crud_base}/entities/list/special/${getTokenInfo().companycode}/${office}/Colaborador`)
		reqGET(`${api_crud_base}/entities/list/special/${getTokenInfo().companycode}/${office}/Colaborador`)
			.then(res => {
				//console.log(res.data)
				set_responsables(...[res.data.map(k => ({ value: k['id'], label: k.info['name'] }))])
				//console.log(responsables)
			}
			)
	}

	const change_office = e => {
		const { label, value } = e;

		set_dados(dados.current.info.office.name = label);
		set_dados(dados.current.info.office.value = value);

		load_reponsables(label)

	}

	const change_responsable = e => {
		const { label, value } = e;

		set_dados(dados.current.contacts[0].person_responsables[0].name = label);
		set_dados(dados.current.contacts[0].person_responsables[0].id = value);
	}

	const change_identification_type = e => {
		set_dados(dados.current.info.identificacao.tipo_identificacao = e.value);
	}

	const change_district_by_city = async e => {

		return await obter_distrito_por_cidade(e.value)
			.then(res =>
				change_district({ value: res })
			).catch(() => { return } /* alert('Distrito não encontrado!') */)
	}

	const load_offices = async () => {
		reqGET(`${api_crud_base}/entities/list/${getTokenInfo().companycode}/Escritorio`)
			.then(res => {
				//console.log(res.data)
				let tmp = res.data.map(k => ({ value: k['id'], label: k['name'], ativo: k['ativo'] }))
				set_offices(...[tmp.filter(k=> k.ativo === 'true')])//console.log(offices)
			}
			)
	}

	const fetch_id_info = async (id_serch) => {

		return await reqGET(`${api_crud_route}/${id_serch}`)
			.then(res => {
				set_dados(dados.current = res.data[0]);
				//console.log(res)

			})
			.catch(error => alert(error)/*TODO: GO TO FAIL FETCH*/)
	}
	const clean_data = () => {
		// DEEP COPY OF data_interface 
		set_dados(dados.current = JSON.parse(JSON.stringify(data_interface)))
		set_dados(dados.current.info.companycode = getTokenInfo().companycode)
		set_dados(dados.current.info.office = getTokenInfo().office)
		//console.log(dados.current)
	}

	// PARA INSTANCIAR O COMPONENTE COM O ID 
	//const props_tmp = props
	useEffect(() => {



		clean_data();

		obter_paises_em_pt(set_countries);

		load_offices();

		try {
			let test = props.location.state.operation;

			if (test === 'edit') {
				//console.log('NO EDIT')
				set_is_edit(true)

				set_show_vat_form('d-none')

				set_show_delete_button('')

				fetch_id_info(props.location.state.item['id']).then(() => {

					//console.log(dados.current)
					if (dados.current.addresses[0].country === 'Portugal') {

						obter_distritos_pt(set_districts)

						obter_cidades_por_distrito(dados.current.addresses[0].region, set_cities)

						obter_freguesias_por_cidade(dados.current.addresses[0].city, set_villages)
						setLoading(false);
					}
					else {
						set_show_district_city_village('d-none')
						setLoading(false);
					}
				})
				return
			}


		} catch {
			// VAI REGISTAR
			set_show_vat_form('');

			clean_data()

			setLoading(false);

			setRedirect(false);
			return

		}
		// BACK
		//props.history.push(endpoints.list)
		return () => {
			clean_data()
		}

	}, [props])



	const handleSubmit = async e => {
		e.preventDefault();

		if (is_edit === true) {
			//alert('Atualizar PUT')

			confirmAlert({
				title: 'Edição',
				message: 'Tem a certeza que pretende continuar?',
				buttons: [
					{
						label: 'Sim',
						onClick: () => {
							let password = dados.current.info.password;
							dados.current.info.password = passwordHash(password);

							reqPUT(api_crud_route, dados.current)
								.then(() => { props.history.push(endpoints.list) })
								.catch(() => alert('Problema ao atualizar Registo!'))
						}
					},
					{
						label: 'Não',
						onClick: () => { return }
					}
				]
			})
		} else {
			confirmAlert({
				title: 'Novo Registo',
				message: 'Tem a certeza que pretende continuar?',
				buttons: [
					{
						label: 'Sim',
						onClick: () => {
							let password = dados.current.info.password;
							dados.current.info.password = passwordHash(password);

							reqPOST(api_crud_route, dados.current)
								.then(() => { props.history.push(endpoints.list) })
								.catch(() => alert('Problema ao atualizar Registo!'))
						}
					},
					{
						label: 'Não',
						onClick: () => { return }
					}
				]
			})
		}
	}

	const handleSubmitVat = async e => {
		e.preventDefault();

		setLoading(true);
		get_vat_info(dados.current.info.vat)
			.then(res => {
				//console.log('---------- VAT INFO --------------')
				//console.log(res)

				if ((res.data.name !== '---') && (res.data.name !== '')) {
					clean_data();
					set_dados(dados.current.info.vat = res.data.vatNumber);
					set_dados(dados.current.info.name = res.data.name);
					set_dados(dados.current.addresses[0].address_detail = res.data.address);

					let aMorada = res.data.address.split("\n");
					// Verificar se pais é PT
					if (res.data.countryCode === 'pt') {
						//console.log('----------------------> É PT')

						change_country({ value: 'Portugal' })


						set_dados(dados.current.addresses[0].street_name = aMorada[1]);

						let city = aMorada[1];
						if (city !== undefined) {
							city = city.toLowerCase();
							city = city.charAt(0).toUpperCase() + city.slice(1);
							change_district_by_city({ value: city }).then(() => change_city({ value: city }))


							// After District -> City
							change_district_by_city({ value: city }).then(() => change_city({ value: city }))

							let aMoradaCP = aMorada[2].split(" ");
							set_dados(dados.current.addresses[0].postal_code = aMoradaCP[0]);
						}
					} else {
						//console.log('---------------------->NÃO È PT')
						set_dados(dados.current.addresses[0].street_name = aMorada[1]);
					}
				}
				else {


					confirmAlert({
						title: 'Aviso',
						message: 'VAT não encotrado na base VIES.',
						buttons: [
							{
								label: 'Voltar',
								onClick: () => { return }
							}
						]
					})
				}
				setLoading(false)
			})

			.catch(error => { setLoading(false); alert(error) });
	}



	function apagar() {
		confirmAlert({
			title: 'Eliminar Entidade',
			message: 'Pretende mesmo eliminar este registo ?',
			buttons: [
				{
					label: 'Sim',
					onClick: () => {
						// Verificar se existe informação associada ao Cliente
						//reqGET(`${api_crud_base}/credits/credits/special/credits/${object_type}/${getTokenInfo().companycode}/${dados.current.id}`).then(
						reqGET(`${api_crud_base}/credits/credits/special/credits/${getTokenInfo().companycode}/${object_type}/${dados.current.id}`).then(

							res => {
								//console.log(res.data)
								if (res.data.length > 0) {

									confirmAlert({
										title: 'Aviso',
										message: 'A Entidade tem registos associados. Apenas pode ser editada.',
										buttons: [
											{
												label: 'Voltar',
												onClick: () => { return }
											}
										]
									})
								} else {
									//alert('Eliminou')
									reqDEL(api_crud_route + '/', { id: dados.current.id })
										.then(res => {
											/* alert(res.status === 200 ?
												'Registo Apagado com Sucesso' : 'Problema ao Apagar Registo'); */

											props.history.push(endpoints.list);
										})
										.catch(error => alert(error))
								}
							}
						)
					}
				},
				{
					label: 'Não',
					onClick: () => { return }
				}
			]
		});
	};


	const load_entity = async (vat, entity) => {
		reqGET(`${api_crud_base}/entities/list/${getTokenInfo().companycode}/${entity}`)
			.then(res => {
				//console.log(res.data)
				// res.data.map((k) => {
				// 	if(k['vat'] === vat) {
				// 		set_dados(dados.current.info.Cliente.id = k['id']);
				// 		set_dados(dados.current.info.Cliente.name = k['name']);
				// 	}
				// });

				let ja_existe = false
				res.data.forEach((k) => {
					//console.log(res.data)
					if (k['vat'] === vat) {
						ja_existe = true
						//set_visible_client_name(true)
						//set_dados(dados.current.info.Cliente.name = k['vat']);
					}
				})

				if (ja_existe === false) {
					//<Link to={{ pathname: '/client_regist', state: { item: vat, operation: 'newFromCredit' } }} />

					//console.log('NãO EXISTE ________________>');
					return

				} else {
					confirmAlert({
						title: 'Aviso',
						message: 'Já Existe um Registo com este VAT.',
						buttons: [
							{
								label: 'Voltar',
								onClick: () => { props.history.push(endpoints.list) }
							},

						]
					})

					//console.log(ja_existe);
				}
			}
			)
	}

	const handle_change_contacts_person_responsables = (e, contacts_index = 0, person_responsables_index = 0) => {
		const { name, value } = e.target;

		/* DEBUG*/
		//console.log('NAME:' + name)
		//console.log('Value:' + value)

		set_dados(dados.current.contacts[contacts_index].person_responsables[person_responsables_index][name] = value)

		//console.log('«««««««««««««««««««««««')
		//console.log('Dados Current:' + JSON.stringify(dados))

	}



	const handle_change_addresses = (e, addresses_index = 0) => {
		const { name, value } = e.target;

		/* DEBUG*/
		//console.log('NAME:' + name)
		//console.log('Value:' + value)

		set_dados(dados.current.addresses[addresses_index][name] = value)

		//console.log('«««««««««««««««««««««««')
		//console.log('Dados Current:' + JSON.stringify(dados))

	}


	const handle_change_info = (e) => {
		const { name, value } = e.target;

		/* DEBUG*/

		//console.log('NAME:' + name)
		//console.log('Value:' + value)

		set_dados(dados.current.info[name] = value)

		if ((is_edit === false) && (name === 'vat')) {
			if (dados.current.info['vat'].length === 9) {
				load_entity(dados.current.info['vat'], object_type)
			}
		}

		//console.log('«««««««««««««««««««««««')
		//console.log('Dados Current:' + JSON.stringify(dados))

	}

	const handle_change_password = (e) => {
		const { value } = e.target;

		if (dados.current.info.password !== '' && value === dados.current.info.password) {
			set_disabled(false)
		}
		else {
			alert('Password de confirmação diferente da password inserida')
			set_disabled(true)
		}
	}

	const handle_change_contacts = (e, index = 0) => {
		const { name, value } = e.target;

		/* DEBUG*/

		//console.log('NAME:' + name)
		//console.log('Value:' + value)

		set_dados(dados.current.contacts[index][name] = value)

		//console.log('«««««««««««««««««««««««')
		//console.log('Dados Current:' + JSON.stringify(dados))

	}

	const handle_change_info_identification = (e) => {
		const { name, value } = e.target;

		/* DEBUG*/

		//console.log('NAME:' + name)
		//console.log('Value:' + value)

		set_dados(dados.current.info.identificacao[name] = value)

		//console.log('Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«')
		//console.log('Dados Current:' + JSON.stringify(dados))

	}

	return isloading ?
		<main>
			<div className={`dash-cards-2 header`}>
				<h2 className="dash-title">LOADING</h2>
				<p>LOADING</p>
				<div className="overlay">
					<div className="overlay__inner">
						<div className="overlay__content"><span className="spinner"></span></div>
					</div>
				</div>
			</div>
		</main>
		:
		isRedirect
			?
			<Redirect to={endpoints.list} replace />
			:
			<main>
				<div className={`dash-cards-2 header`}>
					<h2 className="dash-title">Promotores</h2>
					<p>Home / Promotores / Inserir Promotor</p>
				</div>

				<div className={`dash-cards-2 body`}>
					<div className="card">
						<div className="card-body">

							<form className={show_vat_form} id="form-vat" onSubmit={handleSubmitVat}>
								<div className="form-group">
									<span className="flex-1">
										{/*<input type="text" name="dados.info.vat" value={dados.info.vat} pattern="[0-9]{9}" className="input-form" placeholder="Introduza o NIF" onChange={e => set_dados({ ...dados, info: { ...dados.info, vat: e.target.value } })} required /> */}
										<input type="text" pattern={pattern_vat} minLength="9" inputMode='numeric' name="vat" value={dados.current.info.vat} className="input-form" placeholder="Introduza o NIF" onChange={handle_change_info} required />
										<span className="validity"></span>
									</span>
									<span className="flex-1">
										<input type="submit" value="Pesquisar" />
									</span>
								</div>
							</form>

							<form id="form" onSubmit={handleSubmit}>
								<input type="hidden" name="id" value={dados.current.id} />

								<h2 className="dash-title">Dados Promotor</h2>
								<div className="form-group">
									<span className="flex-1">
										<label>Nome</label>
										<input type="text" name="name" value={dados.current.info.name} className="input-form" placeholder="Introduza o nome" onChange={handle_change_info} required />
									</span>
									<span className="flex-1">
										<label>NIF</label>
										<input type="text" pattern={pattern_vat} minLength="9" inputMode='numeric' name="vat" disabled={true} value={dados.current.info.vat} className="input-form" placeholder="Número de Contribuinte" onChange={handle_change_info} required />
									</span>
									<span className="flex-1">
										<label>Tipo de Promotor</label>
										<Select
											className="div-select"
											options={entity_types}
											name="client_type"
											value={dados.current.info.entity_type === '' ? null : { label: dados.current.info.entity_type, value: dados.current.info.entity_type }}
											placeholder={"Introduza o tipo de Promotor"}
											onChange={change_entity_type}
											styles={custom_select_styles}
										/>
									</span>
								</div>
								<div className="form-group">
									<span className="flex-1">
										<label>Escritório</label>
										<Select
											className="div-select"
											options={offices}
											name="office"
											value={dados.current.info.office.name === '' ? null : { label: dados.current.info.office.name, value: dados.current.info.office.id }}
											placeholder={"Introduza o escritório"}
											onChange={change_office}
											styles={custom_select_styles}
										/>
									</span>
									<span className="flex-1">
										<label>Colaborador</label>
										<Select
											className="div-select"
											options={responsables}
											name="office"
											value={dados.current.contacts[0].person_responsables[0].name === '' ? null : { label: dados.current.contacts[0].person_responsables[0].name, value: dados.current.contacts[0].person_responsables[0].id }}
											placeholder={"Introduza o colaborador"}
											onChange={change_responsable}
											styles={custom_select_styles}
										/>
									</span>
								</div>
								<div className={hidePessoal !== '' ? hidePessoal : 'form-group'}>
									<span className="flex-1">
										<label>Tipo de Identificação</label>
										<Select
											className="div-select"
											options={identification_types}
											name="identification_type"
											value={dados.current.info.identificacao.tipo_identificacao === '' ? null : { label: dados.current.info.identificacao.tipo_identificacao, value: dados.current.info.identificacao.tipo_identificacao }}
											placeholder={"Introduza o tipo de identificação"}
											onChange={change_identification_type}
											styles={custom_select_styles}
										/>
									</span>
									<span className="flex-1">
										<label>Número de Identificação</label>
										<input type="text" pattern="\d*" inputMode='numeric' maxLength="11" name="numero_identificacao" value={dados.current.info.identificacao.numero_identificacao} className="input-form" placeholder="Introduza o número de identificação" onChange={handle_change_info_identification} />
									</span>
									<span className="flex-1">
										<label>Validade</label>
										<input type="date" name="validade_identificacao" value={dados.current.info.identificacao.validade_identificacao} min={today} className="input-form" placeholder="Introduza a validade" onChange={handle_change_info_identification} />
									</span>
								</div>
								<div className="form-group">
									<span className="flex-1">
										<label>Telefone</label>

										<PhoneInput
											defaultCountry="PT"
											placeholder="Introduza o número de telefone"
											value={dados.current.contacts[0].phone !== '' ? dados.current.contacts[0].phone : null}
											onChange={e => set_dados(dados.current.contacts[0].phone = e)} />
										{/* <input type="tel" name="phone" value={dados.current.contacts[0].phone} pattern="[0-9]{9}" className="input-form" placeholder="Introduza o número de telefone" onChange={handle_change_contacts} /> */}
									</span>
									<span className="flex-1">
										<label>Telemóvel</label>
										<PhoneInput
											defaultCountry="PT"
											placeholder="Introduza o número de telemóvel"
											value={dados.current.contacts[0].mobile_phone !== '' ? dados.current.contacts[0].mobile_phone : null}
											onChange={e => set_dados(dados.current.contacts[0].mobile_phone = e)} />
										{/* <input type="tel" name="mobile_phone" value={dados.current.contacts[0].mobile_phone} pattern="[0-9]{9}" className="input-form" placeholder="Introduza o telemóvel" onChange={handle_change_contacts} /> */}
									</span>
									<span className="flex-1">
										<label>E-mail</label>
										<input type="email" pattern={pattern_email} name="email" value={dados.current.contacts[0].email} className="input-form" placeholder="Introduza o e-mail" onChange={handle_change_contacts} />
									</span>
								</div>
								<div className="form-group">
									<span className="flex-1">
										<label>País</label>
										<Select
											className="div-select"
											options={countries}
											name="pais"
											value={dados.current.addresses[0].country === '' ? null : { label: dados.current.addresses[0].country, value: dados.current.addresses[0].country }}
											placeholder={"Introduza o país"}
											onChange={change_country}
											styles={custom_select_styles}
										/>
									</span>
									<span className={`flex-1 ${show_district_city_village}`}>
										<label>Distrito</label>
										<Select
											className="div-select"
											options={districts}
											name="distrito"
											value={dados.current.addresses[0].region === '' ? null : { label: dados.current.addresses[0].region, value: dados.current.addresses[0].region }}
											placeholder={"Introduza o distrito"}
											onChange={change_district}
											styles={custom_select_styles}
										/>
									</span>
									<span className={`flex-1 ${show_district_city_village}`}>
										<label>Concelho</label>
										<Select
											className="div-select"
											options={cities}
											name="concelho"
											value={dados.current.addresses[0].city === '' ? null : { label: dados.current.addresses[0].city, value: dados.current.addresses[0].city }}
											placeholder={"Introduza o concelho"}
											onChange={change_city}
											styles={custom_select_styles}
										/>
									</span>
								</div>
								<div className="form-group">

									<span className={`flex-1 ${show_district_city_village}`}>
										<label>Freguesia</label>
										<Select
											className="div-select"
											options={villages}
											name="freguesia"
											value={dados.current.addresses[0].village === '' ? null : { label: dados.current.addresses[0].village, value: dados.current.addresses[0].village }}
											placeholder={"Introduza a Freguesia"}
											onChange={change_village}
											styles={custom_select_styles}
										/>
									</span>
									<span className={`flex-1 `}>
										<label>Localidade</label>
										<input type="text" name="localidade" value={dados.current.addresses[0].localidade} className="input-form" placeholder="Introduza a Localidade" onChange={handle_change_addresses} required />
									</span>
									<span className="flex-1">
										<label>Código-Postal</label>
										<input type="text" name="postal_code" value={dados.current.addresses[0].postal_code} className="input-form" placeholder="Introduza o código-postal" onChange={handle_change_addresses} required />
									</span>
									<span className="flex-1"></span>
								</div>
								<div className="form-group">
									<span className="flex-2">
										<label>Morada</label>
										<textarea name="address_detail" value={dados.current.addresses[0].address_detail} className="input-form" rows="4" onChange={handle_change_addresses} required>
										</textarea>
									</span>
									<span className="flex-1"></span>
								</div>

								<h2 className={hideEmpresarial !== '' ? hideEmpresarial : 'dash-title'}>Dados Intermediário</h2>
								<div className={hideEmpresarial !== '' ? hideEmpresarial : 'form-group'}>
									<span className="flex-1">
										<label>Nome</label>
										<input type="text" name="name" value={dados.current.contacts[0].person_responsables[0].name} className="input-form" placeholder="Introduza o intermediário" onChange={handle_change_contacts_person_responsables} />
									</span>
									<span className="flex-1">
										<label>Contacto Telefónico (Intermediário)</label>
										<PhoneInput
											defaultCountry="PT"
											placeholder="Introduza o contacto telefónico"
											value={dados.current.contacts[0].person_responsables[0].mobile_phone !== '' ? dados.current.contacts[0].person_responsables[0].mobile_phone : null}
											onChange={e => set_dados(dados.current.contacts[0].person_responsables[0].mobile_phone = e)} />
										{/* <input type="tel" name="mobile_phone" value={dados.current.contacts[0].mobile_phone} pattern="[0-9]{9}" className="input-form" placeholder="Introduza o telemóvel" onChange={handle_change_contacts} /> */}
									</span>
									<span className="flex-1">
										<label>E-mail</label>
										<input type="email" pattern={pattern_email} name="email" value={dados.current.contacts[0].person_responsables[0].email} className="input-form" placeholder="Introduza o e-mail do intermediário" onChange={handle_change_contacts_person_responsables} />
									</span>
								</div>

								<h2 className="dash-title">Dados Login</h2>
								<div className="form-group">
									<span className="flex-1">
										<label>Username</label>
										<input type="text" name="username" value={dados.current.info.username} pattern="[A-Za-z0-9.]+" className="input-form" placeholder="Introduza o username" onChange={handle_change_info} />
									</span>
									<span className="flex-1">
										<label>Password</label>
										<input type="password" name="password" min="9" className="input-form" placeholder="Introduza a password" onChange={handle_change_info} />
									</span>
									<span className="flex-1">
										<label>Confirmacao da Password</label>
										<input type="password" name="password_conf" min="9" className="input-form" placeholder="Introduza a confirmação da password" onBlur={handle_change_password} />
									</span>
								</div>
							</form>
						</div>
					</div>
				</div>

				<div className={'dash-cards-2 footer'}>
					<nav className="navbar bottom-navbar navbar-light">
						<div className="navbar-footer">
							<ul className="navbar-nav float-left"></ul>
							<ul className="navbar-nav float-right">
								<li className="nav-item">
									<Link className="nav-link btn-cancel" to={{ pathname: endpoints.list }}  >

										<img alt='' src={'./img/cancelar.svg'} />
										<span>Cancelar</span>
									</Link>
								</li>

								<li className={"nav-item " + show_delete_button}>
									<button onClick={apagar} className="nav-link btn-delete">

										<img alt='' src={'./img/eliminar_b.svg'} />
										<span>Eliminar</span>
									</button>
								</li>
								<li className="nav-item">
									<button disabled={disabled ? true : false} type="submit" form="form" className="nav-link">

										<img alt='' src={'./img/save.svg'} />
										<span>Salvar</span>
									</button>
								</li>
							</ul>
						</div>
					</nav>
				</div>
			</main >
}
