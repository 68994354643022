import React, { useEffect, useState } from 'react';
//import { Router, Route, Switch, useHistory } from 'react-router-dom';
import { Redirect, Route, Switch } from 'react-router-dom';
//import { createBrowserHistory } from 'history';



import Login from './components/Login/Login';



import Header from './components/Header/Header';
import Sidebar from './components/Sidebar/Sidebar';
//import Main from './components/Main/Main';
import Footer from './components/Footer/Footer';


import Corporate from './components/Corporate/Corporate'

import Financeira from './components/Financeira/Init'
//import Financeira2 from './components/Financeira2/Init'
//import FinanceiraC from './components/Financeira/Config'


import Cliente from './components/Entities/Cliente/Init'
import Colaborador from './components/Entities/Colaborador/Init'
import Promotor from './components/Entities/Promotor/Init'
import Escritorio from './components/Entities/Escritorio/Init'
import Master from './components/Entities/Master/Init'

import Mapa_types from './components/Mapas/Mapa_types/Init'
import Mapa_config from './components/Mapas/Mapa_config/Init'
import Mapas from './components/Mapas/Mapas/Init'
import Mapas_Creditos from './components/Mapas/Mapas/Credits/Init'
import Mapas_Comissoes from './components/Mapas/Mapas/Comissions/Init'
import Mapas_Clientes from './components/Mapas/Mapas/Clients/Init'
import Mapas_Pagamento from './components/Mapas/Mapas/Payment/Init'

import Credit_Commission from './components/Credits/Commissions/Init'

import Credits from './components/Credits/Credits/Init'
import Credit_types from './components/Credits/Credit_types/Init'
import Credit_config from './components/Credits/Credit_config/Init'



import Main from './components/Main/Init'




//import Perfis_Menus from './components/WEBContent/Perfis_Menus/Init'
import Perfil from './components/WEBContent/PerfisMenus/Init'




/* 
import CreditoTipoLista from './components/CreditoTipo/CreditoTipoLista';
import CreditoTipo from './components/CreditoTipo/CreditoTipo';

import CreditoEstadoLista from './components/CreditoEstado/CreditoEstadoLista';
import CreditoEstado from './components/CreditoEstado/CreditoEstado';

import CreditoEstadoEtapaLista from './components/CreditoEstado/CreditoEstadoEtapaLista';
import CreditoEstadoEtapa from './components/CreditoEstado/CreditoEstadoEtapa';

import PerfilTipoLista from './components/PerfilTipo/PerfilTipoLista';
import PerfilTipo from './components/PerfilTipo/PerfilTipo';

import Credito from './components/Credito/Credito';
import CreditoLista from './components/Credito/CreditoLista';

import CreditoLista2 from './components/Proposta/PropostaLista';

 */



import Support_ADM from './components/WEBContent/Support/Init'
import Support from './components/Support/Support';

import ContactList from './components/Contact/ContactList';

//import UseSidebar from './UseSidebar'
//import useToken, { getTokenInfo } from './UseToken';
import useToken from './UseToken';


//import SideBarDataComp from './components/Sidebar/SideBarData';




/* 
function Redir() {
  return (() => <Redirect to="/" />)

}


const NoMatch = () => (
  <p>No Match</p>
); */




function App() {

  const { token, setToken } = useToken();
  //const menus = [];
  //const { sideBarData, setSideBarData, getSidebar } = useSidebar();


  //const [menus, setMenus] = useState([])

  const [isloading, setLoading] = useState(true);







  //let sidebar_info = []


  const handleFavIcon = () => {

    function getFaviconEl() {
      return document.getElementById("favicon");
    }

    const favicon = getFaviconEl(); // Accessing favicon element

    //console.log('FavICON', favicon)
    //favicon.href = "https://sim.assec.pt/favicon.ico";
    favicon.href = "favicon.ico";
  };

  // 
  document.title = `${document.title}-Fasquia Importante`
  handleFavIcon()




  //Comentado para evitar API





  //return (

  /*  useEffect(() => {
     //setLoading(false)
     let teste = async () => {
       let aa = await UseSidebar().getSidebar(getTokenInfo().profile)
       //.then(v => setMenus(v))
       setMenus(aa)
 
       setLoading(false)
     }
 
 
     teste()
 
 
     return () => { };
   }, []) */



  useEffect(() => {


    //setLoading(true)
    /*   let teste = async () => {
        //console.log('No Menu')
        // let aa = await UseSidebar().getSidebar(getTokenInfo().profile)
        //.then(v => setMenus(v))
        //setMenus(aa)
  
      } */

    if (token) {

      //teste()
      setLoading(false)
    }


    return () => { };
  }, [token])






  if (!token) {
    console.log('---> APP.JS: ANTES DO TOKEN')
    return <Login setToken={setToken} />
  }





  //menus = UseSidebar().getSidebar(null).then(v => { console.log('FROM USESIDEBAR', v); set_dados(dados.current.info.menu = v) })


  //console.log('---> sideBarData', sideBarData.then(v => { return v }))
  /* if (sideBarData.length === 0) {
    //getSidebar()
    //UseSidebar().getSidebar().then(v => setSideBarData(v))
    console.log('---> APP.JS: DEPOIS DO TOKEN')
    //sidebar_info = []
    //load_menu_from_profile()
    //.then(v => { setSideBarData(v); console.log('DEPOIS DO LOGIN:', sideBarData) })
  }
 */





  let checkAuthorization = (path, component) => {

    /*  console.log('checkAuthorization', path)
     //console.log(component)
 
     let redir = () => <Redirect to='/' />
 
     //let Redir = () => { return () => <Redirect to="/" /> }
 
     let tmp = menus.filter(v => v.path === path && v.allow === true)
     //console.log(path, tmp.length)
 
     if (tmp.length === 0) {
 
       let tmp2 = []
       let tmp3 = []
      
       tmp2 = menus.map(v => v.submenu.filter(s => (s.path === path && s.edit === true)))
 
       tmp3 = tmp2.filter(v => v.length > 0)
 
 
       return tmp3.length > 0 ? component : redir
 
     }
     else */
    return component



  }



  return isloading ?
    <main>
      <div className={`dash-cards-2 header`}>
        <h2 className="dash-title">LOADING</h2>
        <p>LOADING</p>
        <div className="overlay">
          <div className="overlay__inner">
            <div className="overlay__content"><span className="spinner"></span></div>
          </div>
        </div>
      </div>
    </main>
    :
    <>
      <Header />
      <Sidebar
      /*  sidebar_info={
         sideBarData
       }
    */
      />

      <div className="main-content">

        <Switch>

          {/*   <Route path="/" exact component={Main} />Main */}

          <Route path={Main.view.endpoint} exact component={Main.view.component} />


          <Route path="/corporate" exact component={
            checkAuthorization("/corporate", Corporate)
          } />


          {/*    <Route path={Financeira2.base.endpoint} exact component={
            checkAuthorization(Financeira2.base.endpoint, Financeira2.base.component)
          } /> */}




          <Route path={Credit_config.list.endpoint} exact
            component={checkAuthorization(Credit_config.list.endpoint, Credit_config.list.component)} />



          <Route path={Credits.regist.endpoint} exact
            component={checkAuthorization(Credits.regist.endpoint, Credits.regist.component)} />

          <Route path={Credits.list.endpoint} exact
            component={checkAuthorization(Credits.list.endpoint, Credits.list.component)} />

          <Route path={Credits.view.endpoint} exact
            component={checkAuthorization(Credits.view.endpoint, Credits.view.component)} />



          {/*        <Route path={Credit_types.regist.endpoint} exact
            component={checkAuthorization(Credit_types.regist.endpoint, Credit_types.regist.component)} />

          <Route path={Credit_types.list.endpoint} exact
            component={checkAuthorization(Credit_types.list.endpoint, Credit_types.list.component)} />

          <Route path={Credit_types.view.endpoint} exact
            component={checkAuthorization(Credit_types.view.endpoint, Credit_types.view.component)} /> */}



          {/* AS  C.I.A.L */}

          <Route path={Credit_types.regist.endpoint} exact
            component={Credit_types.regist.component} />

          <Route path={Credit_types.list.endpoint} exact
            component={checkAuthorization(Credit_types.list.endpoint, Credit_types.list.component)} />

          <Route path={Credit_types.view.endpoint} exact
            component={Credit_types.view.component} />



          <Route path={Mapas_Creditos.view.endpoint} exact
            component={checkAuthorization(Mapas_Creditos.view.endpoint, Mapas_Creditos.view.component)} />

          <Route path={Mapas_Comissoes.view.endpoint} exact
            component={checkAuthorization(Mapas_Comissoes.view.endpoint, Mapas_Comissoes.view.component)} />

          <Route path={Mapas_Clientes.view.endpoint} exact
            component={checkAuthorization(Mapas_Clientes.view.endpoint, Mapas_Clientes.view.component)} />

          <Route path={Credit_Commission.view.endpoint} exact
            component={checkAuthorization(Credit_Commission.view.endpoint, Credit_Commission.view.component)} />

          <Route path={Mapas_Pagamento.view.endpoint} exact
            component={checkAuthorization(Mapas_Pagamento.view.endpoint, Mapas_Pagamento.view.component)} />






          <Route path={Perfil.regist.endpoint} exact
            component={checkAuthorization(Perfil.regist.endpoint, Perfil.regist.component)} />

          <Route path={Perfil.list.endpoint} exact
            component={checkAuthorization(Perfil.list.endpoint, Perfil.list.component)} />

          <Route path={Perfil.view.endpoint} exact
            component={checkAuthorization(Perfil.view.endpoint, Perfil.view.component)} />



          <Route path={Financeira.regist.endpoint} exact component={
            checkAuthorization(Financeira.regist.endpoint, Financeira.regist.component)
          } />

          <Route path={Financeira.list.endpoint} exact component={
            checkAuthorization(Financeira.list.endpoint, Financeira.list.component)
          } />

          <Route path={Financeira.view.endpoint} exact component={
            checkAuthorization(Financeira.view.endpoint, Financeira.view.component)
          } />



          <Route path={Cliente.regist.endpoint} exact component={
            checkAuthorization(Cliente.regist.endpoint, Cliente.regist.component)
          } />

          <Route path={Cliente.list.endpoint} exact component={
            checkAuthorization(Cliente.list.endpoint, Cliente.list.component)
          } />

          <Route path={Cliente.view.endpoint} exact component={
            checkAuthorization(Cliente.view.endpoint, Cliente.view.component)
          } />



          <Route path={Colaborador.regist.endpoint} exact component={
            checkAuthorization(Colaborador.regist.endpoint, Colaborador.regist.component)
          } />

          <Route path={Colaborador.list.endpoint} exact component={
            checkAuthorization(Colaborador.list.endpoint, Colaborador.list.component)
          } />

          <Route path={Colaborador.view.endpoint} exact component={
            checkAuthorization(Colaborador.view.endpoint, Colaborador.view.component)
          } />



          <Route path={Promotor.regist.endpoint} exact component={
            checkAuthorization(Promotor.regist.endpoint, Promotor.regist.component)
          } />

          <Route path={Promotor.list.endpoint} exact component={
            checkAuthorization(Promotor.list.endpoint, Promotor.list.component)
          } />

          <Route path={Promotor.view.endpoint} exact component={
            checkAuthorization(Promotor.view.endpoint, Promotor.view.component)
          } />


          <Route path={Escritorio.regist.endpoint} exact component={
            checkAuthorization(Escritorio.regist.endpoint, Escritorio.regist.component)
          } />

          <Route path={Escritorio.list.endpoint} exact component={
            checkAuthorization(Escritorio.list.endpoint, Escritorio.list.component)
          } />

          <Route path={Escritorio.view.endpoint} exact component={
            checkAuthorization(Escritorio.view.endpoint, Escritorio.view.component)
          } />


          <Route path={Master.regist.endpoint} exact component={
            checkAuthorization(Master.regist.endpoint, Master.regist.component)
          } />

          <Route path={Master.list.endpoint} exact component={
            checkAuthorization(Master.list.endpoint, Master.list.component)
          } />

          <Route path={Master.view.endpoint} exact component={
            checkAuthorization(Master.view.endpoint, Master.view.component)
          } />


          <Route path={Support_ADM.regist.endpoint} exact component={
            checkAuthorization(Support_ADM.regist.endpoint, Support_ADM.regist.component)
          } />

          <Route path={Support_ADM.list.endpoint} exact component={
            checkAuthorization(Support_ADM.list.endpoint, Support_ADM.list.component)
          } />

          <Route path={Support_ADM.view.endpoint} exact component={
            checkAuthorization(Support_ADM.view.endpoint, Support_ADM.view.component)
          } />

          <Route path={"/support"} exact component={
            checkAuthorization("/support", Support)
          } />


          <Route path={"/contact_list"} exact component={
            checkAuthorization("/contact_list", ContactList)
          } />


          {/* QUANDO O ENDEREÇO NÃO É VÁLIDO */}
          {/* <Route path='*' component={() => <Redirect to="/" />} /> */}
          <Route path='*' component={() => <Redirect to="/" />} />

        </Switch >
      </div >
      <Footer />

    </>

}

export default App;


/*

    <Route path="/corporate" exact={true} component={Corporate} />
             <Corporate inputsContent={CorporateContent} onSubmit={() => //console.log('oiiiiiiiiiiiii')} />    </Route>


        <Route path="/user" exact>
          <User inputsContent={UserContent} onSubmit={() => //console.log('oiiiiiiiiiiiii')} />
        </Route>

        <Route path="/preferences" >
          <Preferences />
        </Route>

        <Route path="/datatable">
          <DataTable
                                    noDataComponent='Sem Registos'Example />
        </Route>

        <Route path="/datatable2">
          <DataTable
                                    noDataComponent='Sem Registos'Example2 />
        </Route>

        <Route path="/form">
          <FormTemplate inputsContent={FormContent} onSubmit={() => //console.log('oiiiiiiiiiiiii')} />
        </Route>
        */


/*

import ColaboradorLista from './components/Colaborador/ColaboradorLista';
import Colaborador from './components/Colaborador/Colaborador';
import ColaboradorView from './components/Colaborador/ColaboradorView';

import PromotorLista from './components/Promotor/PromotorLista';
import Promotor from './components/Promotor/Promotor';
import PromotorView from './components/Promotor/PromotorView';

import EscritorioLista from './components/Escritorio/EscritorioLista';
import Escritorio from './components/Escritorio/Escritorio';
import EscritorioView from './components/Escritorio/EscritorioView';

import MasterBlank from './components/Master/MasterBlank';
import MasterLista from './components/Master/MasterLista';
import Master from './components/Master/Master';

*/