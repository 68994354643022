import React from 'react';

import './Login.css';



// or
//import { Tabs } from '@material-ui/core';
import LoginForm from "./LoginForm";

const Login = ({ setToken }) => {




  return (
    <div>

      <div className="login-wrapper">
        <div className="div-left" style={{
          backgroundImage: `url('./img/bg-login.jpg')`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}>

        </div>
        <div className="div-right">
          <div className="form-header">
            <img alt='' src="./img/logo-vertical.png" />
            <h3>Bem-vindo</h3>
          </div>

          <div className="div-form">
            <LoginForm setToken={setToken} loginType='login' />
          </div>
          <div className="form-footer">
            Intermediário de Crédito autorizado pelo Banco de Portugal n.º 2749
          </div>
        </div>
      </div>

    </div>

  );

}

export default Login;

