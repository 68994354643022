
export default function SidebarData(props) {

    //console.log('SidebarData', props)
    let pathver = false
    try {
        if (props.rotas !== undefined)
            pathver = false
    } catch {
        pathver = true
    }

    return [
        /*    {
               id: '0',
               title: 'Painel Principal 222',
               path: pathver ? ' ' : props.rotas.Main.view.endpoint,
               icon: 'painel.svg',
               icon_b: 'painel_b.svg',
               cName: '',
               allow: true,
               create: true,
               edit: true,
               view: true,
               remove: true,
               submenu: []
           }, */
        {
            id: '1',
            title: 'Painel Principal',
            path: pathver ? ' ' : props.rotas.Main.view.endpoint,
            icon: 'painel.svg',
            icon_b: 'painel_b.svg',
            cName: '',
            allow: true,
            create: true,
            edit: true,
            view: true,
            remove: true,
            submenu: []
        },
        {
            id: '2',
            title: 'Corporate',
            path: pathver ? ' ' : '/corporate',
            icon: 'corporate.svg',
            icon_b: 'corporate_b.svg',
            cName: '',
            allow: true,
            create: true,
            edit: true,
            view: true,
            remove: true,

            submenu: []
        },
        {
            id: '3',
            title: 'Financeiras',
            path: pathver ? ' ' : props.rotas.Financeira.list.endpoint,
            icon: 'entidades.svg',
            icon_b: 'entidades_b.svg',
            cName: '',
            allow: true,
            create: true,
            edit: true,
            view: true,
            remove: true,

            submenu: [
                {
                    id: '31',
                    title: 'Lista de Financeiras',
                    path: pathver ? ' ' : props.rotas.Financeira.list.endpoint,
                    icon: '',
                    cName: '',
                    allow: true,
                    create: true,
                    edit: true,
                    view: true,
                    remove: true,

                    submenu: []
                },
                {
                    id: '32',
                    title: 'Inserir Financeira',
                    path: pathver ? ' ' : props.rotas.Financeira.regist.endpoint,
                    icon: '',
                    cName: '',
                    allow: true,
                    create: true,
                    edit: true,
                    view: true,
                    remove: true,
                    submenu: []
                },
                {
                    id: '33',
                    title: '',
                    path: pathver ? ' ' : props.rotas.Financeira.view.endpoint,
                    icon: '',
                    cName: '',
                    allow: true,
                    create: true,
                    edit: true,
                    view: true,
                    remove: true,
                    submenu: []
                },

            ]
        },
        {
            id: '4',
            title: 'Clientes',
            path: pathver ? ' ' : props.rotas.Cliente.list.endpoint,
            icon: 'clientes.svg',
            icon_b: 'clientes_b.svg',
            cName: '',
            allow: true,
            create: true,
            edit: true,
            view: true,
            remove: true,
            submenu: [
                {
                    id: '41',
                    title: 'Lista de Clientes',
                    path: pathver ? ' ' : props.rotas.Cliente.list.endpoint,
                    icon: '',
                    cName: '',
                    allow: true,
                    create: true,
                    edit: true,
                    view: true,
                    remove: true,
                    submenu: []
                },
                {
                    id: '42',
                    title: 'Inserir Cliente',
                    path: pathver ? ' ' : props.rotas.Cliente.regist.endpoint,
                    icon: '',
                    cName: '',
                    allow: true,
                    create: true,
                    edit: true,
                    view: true,
                    remove: true,
                    submenu: []
                },
                {
                    id: '43',
                    title: '',
                    path: pathver ? ' ' : props.rotas.Cliente.view.endpoint,
                    icon: '',
                    cName: '',
                    allow: true,
                    create: true,
                    edit: true,
                    view: true,
                    remove: true,
                    submenu: []
                }
            ]
        },

        {
            id: '5',
            title: 'Colaborador',
            path: pathver ? ' ' : props.rotas.Colaborador.list.endpoint,
            icon: 'clientes.svg',
            icon_b: 'clientes_b.svg',
            cName: '',
            allow: true,
            create: true,
            edit: true,
            view: true,
            remove: true,
            submenu: [
                {
                    id: '51',
                    title: 'Lista Colab.',
                    path: pathver ? ' ' : props.rotas.Colaborador.list.endpoint,
                    icon: '',
                    cName: '',
                    allow: true,
                    create: true,
                    edit: true,
                    view: true,
                    remove: true,
                    submenu: []
                },
                {
                    id: '52',
                    title: 'Inserir Colaborador',
                    path: pathver ? ' ' : props.rotas.Colaborador.regist.endpoint,
                    icon: '',
                    cName: '',
                    allow: true,
                    create: true,
                    edit: true,
                    view: true,
                    remove: true,
                    submenu: []
                },
                {
                    id: '53',
                    title: '',
                    path: pathver ? ' ' : props.rotas.Colaborador.view.endpoint,
                    icon: '',
                    cName: '',
                    allow: true,
                    create: true,
                    edit: true,
                    view: true,
                    remove: true,
                    submenu: []
                }
            ]
        },

        {
            id: '6',
            title: 'Promotor',
            path: pathver ? ' ' : props.rotas.Promotor.list.endpoint,
            icon: 'clientes.svg',
            icon_b: 'clientes_b.svg',
            cName: '',
            allow: true,
            create: true,
            edit: true,
            view: true,
            remove: true,
            submenu: [
                {
                    id: '61',
                    title: 'Lista de Promotores',
                    path: pathver ? ' ' : props.rotas.Promotor.list.endpoint,
                    icon: '',
                    cName: '',
                    allow: true,
                    create: true,
                    edit: true,
                    view: true,
                    remove: true,
                    submenu: []
                },
                {
                    id: '62',
                    title: 'Inserir Promotor',
                    path: pathver ? ' ' : props.rotas.Promotor.regist.endpoint,
                    icon: '',
                    cName: '',
                    allow: true,
                    create: true,
                    edit: true,
                    view: true,
                    remove: true,
                    submenu: []
                },
                {
                    id: '63',
                    title: '',
                    path: pathver ? ' ' : props.rotas.Promotor.view.endpoint,
                    icon: '',
                    cName: '',
                    allow: true,
                    create: true,
                    edit: true,
                    view: true,
                    remove: true,
                    submenu: []
                }
            ]
        },

        {
            id: '7',
            title: 'Escritório',
            path: pathver ? ' ' : props.rotas.Escritorio.list.endpoint,
            icon: 'clientes.svg',
            icon_b: 'clientes_b.svg',
            cName: '',
            allow: true,
            create: true,
            edit: true,
            view: true,
            remove: true,
            submenu: [
                {
                    id: '71',
                    title: 'Lista de Escritórios',
                    path: pathver ? ' ' : props.rotas.Escritorio.list.endpoint,
                    icon: '',
                    cName: '',
                    allow: true,
                    create: true,
                    edit: true,
                    view: true,
                    remove: true,
                    submenu: []
                },
                {
                    id: '72',
                    title: 'Inserir Escritório',
                    path: pathver ? ' ' : props.rotas.Escritorio.regist.endpoint,
                    icon: '',
                    cName: '',
                    allow: true,
                    create: true,
                    edit: true,
                    view: true,
                    remove: true,
                    submenu: []
                },
                {
                    id: '73',
                    title: '',
                    path: pathver ? ' ' : props.rotas.Escritorio.view.endpoint,
                    icon: '',
                    cName: '',
                    allow: true,
                    create: true,
                    edit: true,
                    view: true,
                    remove: true,
                    submenu: []
                }
            ]
        },

        {
            id: '8',
            title: 'Master',
            path: pathver ? ' ' : props.rotas.Master.list.endpoint,
            icon: 'clientes.svg',
            icon_b: 'clientes_b.svg',
            cName: '',
            allow: true,
            create: true,
            edit: true,
            view: true,
            remove: true,
            submenu: [
                {
                    id: '81',
                    title: 'Lista de Masters',
                    path: pathver ? ' ' : props.rotas.Master.list.endpoint,
                    icon: '',
                    cName: '',
                    allow: true,
                    create: true,
                    edit: true,
                    view: true,
                    remove: true,
                    submenu: []
                },
                {
                    id: '82',
                    title: 'Inserir Master',
                    path: pathver ? ' ' : props.rotas.Master.regist.endpoint,
                    icon: '',
                    cName: '',
                    allow: true,
                    create: true,
                    edit: true,
                    view: true,
                    remove: true,
                    submenu: []
                },
                {
                    id: '83',
                    title: '',
                    path: pathver ? ' ' : props.rotas.Master.view.endpoint,
                    icon: '',
                    cName: '',
                    allow: true,
                    create: true,
                    edit: true,
                    view: true,
                    remove: true,
                    submenu: []
                }
            ]
        },



        {
            id: '9',
            title: 'Crédito',
            path: pathver ? ' ' : props.rotas.Credits.list.endpoint,
            icon: 'credito.svg',
            icon_b: 'credito_b.svg',
            cName: '',
            allow: true,
            create: true,
            edit: true,
            view: true,
            remove: true,
            submenu: [
                {
                    id: '91',
                    title: 'Lista de Créditos',
                    path: pathver ? ' ' : props.rotas.Credits.list.endpoint,
                    icon: '',
                    cName: '',
                    allow: true,
                    create: true,
                    edit: true,
                    view: true,
                    remove: true,
                    submenu: []
                },
                {
                    id: '92',
                    title: 'Novo Crédito',
                    path: pathver ? ' ' : props.rotas.Credits.regist.endpoint,
                    icon: '',
                    cName: '',
                    allow: true,
                    create: true,
                    edit: true,
                    view: true,
                    remove: true,
                    submenu: []
                },

            ]
        },


        {
            id: '10',
            title: 'Configuração',
            path: pathver ? ' ' : props.rotas.Credit_config.list.endpoint,
            icon: 'credito.svg',
            icon_b: 'credito_b.svg',
            cName: '',
            allow: true,
            create: true,
            edit: true,
            view: true,
            remove: true,
            submenu: [
                // Créditos
                {
                    id: '101',
                    title: 'Etapas-Estados',
                    path: pathver ? ' ' : props.rotas.Credit_config.list.endpoint,
                    icon: 'credito.svg',
                    icon_b: 'credito_b.svg',
                    cName: '',
                    allow: true,
                    create: true,
                    edit: true,
                    view: true,
                    remove: true,
                    submenu: [],
                },

                {
                    id: '102',
                    title: 'Tipos de Crédito',
                    path: pathver ? ' ' : props.rotas.Credit_types.list.endpoint,
                    icon: 'credito.svg',
                    icon_b: 'credito_b.svg',
                    cName: '',
                    allow: true,
                    create: true,
                    edit: true,
                    view: true,
                    remove: true,
                    submenu: [],
                },
                {
                    id: '103',
                    title: 'Suporte',
                    path: pathver ? ' ' : props.rotas.Support_ADM.list.endpoint,
                    icon: 'suporte.svg',
                    icon_b: 'suporte_b.svg',
                    cName: '',
                    allow: true,
                    create: true,
                    edit: true,
                    view: true,
                    remove: true,
                    submenu: []
                },
                {
                    id: '104',
                    title: '',
                    path: pathver ? ' ' : props.rotas.Support_ADM.regist.endpoint,
                    icon: '',
                    icon_b: '',
                    cName: '',
                    allow: true,
                    create: true,
                    edit: true,
                    view: true,
                    remove: true,
                    submenu: []
                },

            ]

        },

        {
            id: '11',
            title: 'Perfis',
            path: pathver ? ' ' : props.rotas.Perfil.list.endpoint,
            icon: 'master.svg',
            icon_b: 'master_b.svg',
            cName: '',
            allow: true,
            create: true,
            edit: true,
            view: true,
            remove: true,
            submenu: [
                {
                    id: '111',
                    title: 'Lista de Perfis',
                    path: pathver ? ' ' : props.rotas.Perfil.list.endpoint,
                    icon: '',
                    cName: '',
                    allow: true,
                    create: true,
                    edit: true,
                    view: true,
                    remove: true,
                    submenu: []
                },
                {
                    id: '112',
                    title: 'Novo Perfil',
                    path: pathver ? ' ' : props.rotas.Perfil.regist.endpoint,
                    icon: '',
                    cName: '',
                    allow: true,
                    create: true,
                    edit: true,
                    view: true,
                    remove: true,
                    submenu: []
                },

            ]
        },






        {
            id: '12',
            title: 'Comissões',
            path: pathver ? ' ' : props.rotas.Credit_Commission.view.endpoint,
            icon: 'comissoes.svg',
            icon_b: 'comissoes_b.svg',
            cName: '',
            allow: true,
            create: true,
            edit: true,
            view: true,
            remove: true,
            submenu: [
                {
                    id: '121',
                    title: 'Processar Comissões',
                    path: pathver ? ' ' : props.rotas.Credit_Commission.view.endpoint,
                    icon: '',
                    cName: '',
                    allow: true,
                    create: true,
                    edit: true,
                    view: true,
                    remove: true,
                    submenu: []
                },
                {
                    id: '122',
                    title: 'Mapa de Comissões',
                    path: pathver ? ' ' : props.rotas.Mapas_Comissoes.view.endpoint,
                    icon: '',
                    cName: '',
                    allow: true,
                    create: true,
                    edit: true,
                    view: true,
                    remove: true,
                    submenu: []
                },
                {
                    id: '123',
                    title: 'Mapa de Clientes',
                    path: pathver ? ' ' : props.rotas.Mapas_Clientes.view.endpoint,
                    icon: '',
                    cName: '',
                    allow: true,
                    create: true,
                    edit: true,
                    view: true,
                    remove: true,
                    submenu: []
                },
                {
                    id: '124',
                    title: 'Mapa de Créditos',
                    path: pathver ? ' ' : props.rotas.Mapas_Creditos.view.endpoint,
                    icon: '',
                    cName: '',
                    allow: true,
                    create: true,
                    edit: true,
                    view: true,
                    remove: true,
                    submenu: []
                },
            ]
        },
        {
            id: '13',
            title: 'Faturação',
            path: pathver ? ' ' : props.rotas.Mapas_Pagamento.view.endpoint,
            icon: 'faturacao.svg',
            icon_b: 'faturacao_b.svg',
            cName: '',
            allow: true,
            create: true,
            edit: true,
            view: true,
            remove: true,
            submenu: []
        },
        {
            id: '14',
            title: 'Suporte',
            path: pathver ? ' ' : '/support',
            icon: 'suporte.svg',
            icon_b: 'suporte_b.svg',
            cName: '',
            allow: true,
            create: true,
            edit: true,
            view: true,
            remove: true,
            submenu: []
        },
        {
            id: '15',
            title: 'Contactos',
            path: pathver ? ' ' : '/contact_list',
            icon: 'contactos.svg',
            icon_b: 'contactos_b.svg',
            cName: '',
            allow: true,
            create: true,
            edit: true,
            view: true,
            remove: true,
            submenu: []
        },

        /*   {
              id: '17',
              title: 'Master',
              path: pathver ? ' ' : '/masterblank',
              icon: 'master.svg',
              icon_b: 'master_b.svg',
              cName: '',
              allow: true,
              create: true,
              edit: true,
              view: true,
              remove: true,
              submenu: [
                  {
                      id: '171',
                      title: 'Perfil de Entidade',
                      path: pathver ? ' ' : '/masterperfiltipolista',
                      icon: '',
                      cName: '',
                      allow: true,
                      create: true,
                      edit: true,
                      view: true,
                      remove: true,
                      submenu: [
                          {
                              id: '1712',
                              title: '',
                              path: pathver ? ' ' : '/masterperfiltipo',
                              icon: '',
                              cName: '',
                              allow: true,
                              create: true,
                              edit: true,
                              view: true,
                              remove: true,
                              submenu: []
                          }
                      ]
                  },
                  {
                      id: '172',
                      title: 'Tipo de Crédito',
                      path: pathver ? ' ' : '/mastercreditotipolista',
                      icon: '',
                      cName: '',
                      allow: true,
                      create: true,
                      edit: true,
                      view: true,
                      remove: true,
                      submenu: [
                          {
                              id: '1721',
                              title: '',
                              path: pathver ? ' ' : '/creditotipo',
                              icon: '',
                              cName: '',
                              allow: true,
                              create: true,
                              edit: true,
                              view: true,
                              remove: true,
                              submenu: []
                          },
                      ]
                  },
                  {
                      id: '173',
                      title: 'Tipo de Estado',
                      path: pathver ? ' ' : '/mastercreditoestadolista',
                      icon: '',
                      cName: '',
                      allow: true,
                      create: true,
                      edit: true,
                      view: true,
                      remove: true,
                      submenu: [
                          {
                              id: '1731',
                              title: '',
                              path: pathver ? ' ' : '/mastercreditoestado',
                              icon: '',
                              cName: '',
                              allow: true,
                              create: true,
                              edit: true,
                              view: true,
                              remove: true,
                              submenu: []
                          },
                      ]
                  },
                  {
                      id: '174',
                      title: 'Tipo de Etapas',
                      path: pathver ? ' ' : '/mastercreditoestadoetapalista',
                      icon: '',
                      cName: '',
                      allow: true,
                      create: true,
                      edit: true,
                      view: true,
                      remove: true,
                      submenu: [
                          {
                              id: '1741',
                              title: '',
                              path: pathver ? ' ' : '/mastercreditoestadoeapa',
                              icon: '',
                              cName: '',
                              allow: true,
                              create: true,
                              edit: true,
                              view: true,
                              remove: true,
                              submenu: []
                          },
                      ]
                  },
              ]
          }, */


        /*  {
             id: '18',
             title: 'Financeiras2',
             //path: pathver ? ' ' : props.rotas.Financeira.list.endpoint,
             path: '/financial',
             icon: 'entidades.svg',
             icon_b: 'entidades_b.svg',
             cName: '',
             allow: true,
             create: true,
             edit: true,
             view: true,
             remove: true,
             submenu: [],
         } */

    ]

}