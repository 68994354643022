import React, { useState, useEffect } from "react";
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { Link } from 'react-router-dom';

import { api_crud_route, api_list_route, api_crud_base, endpoints } from './Config';

import { reqGET, reqDELETE } from '../../../Utils';

import { getTokenInfo } from './../../../UseToken';

export default function List() {



    const [data, setData] = useState([]);
    const [isloading, setLoading] = useState(true);

    useEffect(() => {
        const companycode = getTokenInfo().companycode;
        const office = getTokenInfo().office.id;


        if (getTokenInfo().profile === 'Master' || getTokenInfo().profile === 'Administrador') {
            reqGET(api_list_route(getTokenInfo().companycode))
                .then(res => { 

                    res.data.forEach((v) => {
                        if(v.info.workflow)
                            v.info.workflow.forEach((v_1) => {
                                if (v_1.estado)
                                    v_1.estado.forEach((v_2) => {
                                        let aux_estado = '';

                                        if (v_1.data.localeCompare("") !== 0) {
                                            if (v_2.selected) {
                                                if (v_2.pendente === false && v_2.completo === true) {
                                                    aux_estado = 'Completo';
                                                    console.log(aux_estado)
                                                    v.info.estado = aux_estado
                                                }
                                                else if (v_2.selected === true && v_2.initial !== true && v_2.pendente !== true && v_2.completo === false) {
                                                    aux_estado = 'Cancelado';
                                                    console.log(aux_estado)
                                                    v.info.estado = aux_estado

                                                }
                                                else {
                                                    aux_estado = v_1.name;
                                                    console.log(aux_estado)
                                                    v.info.estado = aux_estado
                                                }
                                            }
                                        }
                                        else {
                                        }
                                        
                                    })

                            });
                    })

                    setData(res.data); 
                    setLoading(false) })
                .catch(() => 404

                    //  TODO: ENVIAR PÃ�GINA 404
                    //alert(error)); 
                )
        }
        else {

            //reqGET(api_list_route(getTokenInfo().companycode))
            reqGET(`${api_crud_base}/credits/credits/special/office/${companycode}/${office}`)
                .then(res => { setData(res.data); setLoading(false) })
                .catch(() => 404

                    //  TODO: ENVIAR PÃ�GINA 404
                    //alert(error)); 
                )
        }
    }, []);


    //APAGAR REGISTO
    function apagar(id) {
        if (window.confirm('Tem a certeza que quer apagar esta Entidade?')) {

            reqDELETE(api_crud_route, { id: id })
                .then(() => {
                    setLoading(true);
                    alert('Registo Apagado com Sucesso');
                })
                .catch(e => alert('Problema ao Apagar Registo'))
        }
    }

    const columns = [
        {
            name: 'Cliente',
            selector: 'info.Cliente.name',
            cell: row => {
                let _name = row.info['Cliente']['name'].trim().split(' ')
                
                return(
                    <div>
                        {_name[0] + ' ' + _name[_name.length - 1]}
                    </div>
                )
            },
            

            sortable: true,
            let: true,
        },
        {
            name: 'NIF',
            selector: 'info.Cliente.vat',
            maxWidth: '110px',
            minWidth: '10px',
            sortable: true,
            left: true,
        },
        {
            name: 'Colaborador',
            selector: 'info.Colaborador.name',
            sortable: true,
            left: true,
        },
        {
            name: 'Tipo de Crédito',
            selector: 'info.tipo_credito.name',
            sortable: true,
            left: true,
        },
        {
            name: 'Banco',
            selector: 'info.financials.name',
            sortable: true,
            left: true,
        },
        {
            name: 'Valor',
            cell: row => <div>{row.commissioning.valor_financiado}€</div>,
            maxWidth: '150px',
            minWidth: '150px',
            sortable: true,
            left: true,
        },
        {
            name: <div className="table-header">Estado</div>,
            selector: 'estado',
            cell: row => {
                let cor = '#b37db7';
                let _estado = row.info.estado;
                if(_estado === 'Cancelado') {
                    cor = '#ea1f3d';
                }
                else if(_estado === 'Completo') {
                    cor = '';
                }
                else if(_estado === undefined || _estado === ''){
                    cor = '#606060';
                    _estado = 'A iniciar'
                }
                return (
                    <div className={"btn " + _estado} style={{  backgroundColor: cor, borderColor: cor, padding: '5px' }}>{_estado}
                    </div>
                )
            },
            maxWidth: '200px',
            sortable: true,
            center: true,
        },
        {
            name: '',
            right: true,
            maxWidth: '150px',
            minWidth: '150px',
            export: false,
            //print: false,

            cell: row =>

                <span>

                    <Link className="button button-update button-view" to={{ pathname: endpoints.view, state: { item: row, operation: 'view' } }} replace>
                        <img alt='visualizar' src={'./img/view.svg'} />
                    </Link>

                    {/*style={{ display: 'none' }}*/}
                    <Link className="button button-update" to={{ pathname: endpoints.regist, state: { item: row, operation: 'edit' } }} style={{ display: '' }} replace>
                        <img alt='editar' src={'./img/editar.svg'} />
                    </Link>


                    <button onClick={() => apagar(row['id'])} className="button btn-delete" style={{ display: 'none' }}>
                        <img alt='apagar' src={'./img/eliminar.svg'} />
                    </button>
                </span >

        },
    ];

    const defaultComponentOptions = {
        rowsPerPageText: 'Registos por página:',
        rangeSeparatorText: 'de',
        noRowsPerPage: false,
        selectAllRowsItem: false,
        selectAllRowsItemText: 'Todos',
    };


    const tableData = {
        columns,
        data,
        filterPlaceholder: 'Procurar'
    };


    return (isloading ?
        <main>
            <div className={`dash-cards-2 header`}>
                <h2 className="dash-title">LOADING</h2>
                <p>LOADING</p>
                <div className="overlay">
                    <div className="overlay__inner">
                        <div className="overlay__content"><span className="spinner"></span></div>
                    </div>
                </div>

            </div>
        </main>

        :

        <main>
            <div className={`dash-cards-2 header`}>
                <h2 className="dash-title">Créditos</h2>
                <p>Home / Créditos / Lista de Créditos</p>
            </div>

            <div className={`dash-cards-2 body`}>
                <div className="card">
                    <div className="div-table">
                        <div className="table-body">
                            <DataTableExtensions {...tableData}>

                                <DataTable
                                    noDataComponent='Sem Registos'
                                    noHeader
                                    pagination
                                    exportHeaders
                                    paginationComponentOptions={defaultComponentOptions}
                                    paginationPerPage={25}
                                    paginationRowsPerPageOptions={[25, 50, 100]}
                                />
                            </DataTableExtensions>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}