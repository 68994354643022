//import LocalStorage from 'localStorage'
import Hosts from '../../../Hosts';


const object_type = '/web/content'

const api_crud_base = Hosts.SIMCore_API
const api_crud_route = `${Hosts.SIMCore_API}/web/content`
//const api_list_route = `${Hosts.SIMCore_API}/financials`
const api_list_route = companycode => `${Hosts.SIMCore_API}/web/content/type/${companycode}/support`


const base_endpoint = '/adm_support'

const endpoints = {
    base: base_endpoint,
    regist: `${base_endpoint}_regist`,
    list: `${base_endpoint}_list`,
    view: `${base_endpoint}_view`,
}

const data_interface = {
    id: '',
    info: {
        companycode: '',
        name: '',
        type: 'support',
    },
    content: {
        question: '',
        answer: ''
    }
}

export {
    object_type,
    endpoints,
    api_crud_base,
    api_crud_route,
    data_interface,
    api_list_route
}


