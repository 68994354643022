import React, { useState, useEffect } from "react";
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "../../assets/dataTable.css";
import { Link, useLocation } from 'react-router-dom';

import { api_crud_route, api_list_route, endpoints } from './Config';

import { reqGET, reqDELETE } from '../../Utils';

import { getTokenInfo } from './../../UseToken';
import UseSidebar from './../../UseSidebar';


//import Hosts from '../../Hosts';

export default function List() {

    const [data, setData] = useState([]);
    const [isloading, setLoading] = useState(true);
    const { pathname } = useLocation();

    const [, set_SIDE_BAR] = useState([])
    const [permissions, set_permissions] = useState({})


    //const [edit_button, show_edit_button] = useState('none');



    //console.log(sideBarData)

    //console.log('Permissoes')


    useEffect(() => {


        console.log('PathName', pathname)


        let get_permissions = (e, sidebar) => {

            let handleSingleCheck = (e, sidebar) => {

                const name = e;
                //console.log('PathName', name)
                //console.log('SIDEBAR', sidebar)

                let ver_submenu = (node) => {
                    if (node.submenu.length !== 0) {

                        node.submenu.forEach(subitem => {

                            if (subitem.path === name) {
                                //console.log('NO SUBITEM ', name)
                                set_permissions({ edit: subitem.edit, view: true, remove: subitem.remove });
                            }
                            else {
                                ver_submenu(subitem)
                            }

                        })
                    }
                }

                sidebar.forEach(node => {
                    //console.log('Node ', node)
                    if (node.path === name) {
                        //console.log('NO ITEM ', node.path)
                        //console.log(node)
                        set_permissions({ edit: node.edit, view: true, remove: node.remove });
                        //console.log('no true')
                    } else
                        ver_submenu(node)
                })
            }


            handleSingleCheck(e, sidebar)

        }

        // getTokenInfo().companycode

        //console.log(data_interface)





        reqGET(api_list_route(getTokenInfo().companycode))
            .then(res => {
                setData(res.data);


                UseSidebar().getSidebar(getTokenInfo().profile)
                    .then(v => {
                        //console.log(v);
                        get_permissions(pathname, v)
                        set_SIDE_BAR(v)
                        setLoading(false)
                    }/* set_SIDE_BAR(v) */);




            })
            .catch(() => 404

                //  TODO: ENVIAR PÝGINA 404
                //alert(error)); 


            )
    }, [pathname]);


    //APAGAR REGISTO
    function apagar(id) {
        if (window.confirm('Tem a certeza que quer apagar esta Entidade Financeira?')) {


            reqDELETE(api_crud_route, { id: id })
                .then(() => {
                    setLoading(true);
                    alert('Registo Apagado com Sucesso');
                })
                .catch(e => alert('Problema ao Apagar Registo'))
        }
    }

    const columns = [
        {
            name: 'Nome',
            selector: 'name',
            cell: row => <div>{row.info.name}</div>,
            width: '350px',
            sortable: true,
            let: true,
        },
        {
            name: 'Gestor de Parceria',
            selector: 'contacts[0].person_responsables[0].name',
            sortable: true,
            left: true,
        },
        {
            name: 'Telemóvel (Gestor de Parceria)',
            selector: 'contacts[0].person_responsables[0].mobile_phone',
            sortable: true,
            left: true,
        },
        {
            name: 'E-mail (Gestor de Parceria)',
            selector: 'contacts[0].person_responsables[0].email',
            sortable: true,
            left: true,

            //cellExport: row => ({ Title: 'asdasd', Example: 'oi' }),
        },
        {
            name: '',
            right: true,

            export: false,
            //print: false,

            cell: row =>

                <span>

                    <Link className="button button-update button-view" to={{ pathname: endpoints.view, state: { item: row, operation: 'view' } }} replace>
                        <img alt='visualizar' src={'./img/view.svg'} />
                    </Link>

                    {/*style={{ display: permissions.edit ? 'none':'' }}*/}
                    <Link className="button button-update" style={{ display: permissions.edit ? '' : 'none' }} to={{ pathname: (permissions.edit ? endpoints.regist : '/'), state: { item: row, operation: 'edit' } }} replace>
                        <img alt='editar' src={'./img/editar.svg'} />
                    </Link>


                    <button onClick={() => apagar(row['id'])} className="button btn-delete" style={{ display: 'none' }}>
                        <img alt='apagar' src={'./img/eliminar.svg'} />
                    </button>
                </span >

        },
    ];

    const defaultComponentOptions = {
        rowsPerPageText: 'Registos por página:',
        rangeSeparatorText: 'de',
        noRowsPerPage: false,
        selectAllRowsItem: false,
        selectAllRowsItemText: 'Todos',
    };






    const tableData = {
        columns,
        data,
        filterPlaceholder: 'Procurar'
    };


    return (isloading ?
        <main>
            <div className={`dash-cards-2 header`}>
                <h2 className="dash-title">LOADING</h2>
                <p>LOADING</p>
                <div className="overlay">
                    <div className="overlay__inner">
                        <div className="overlay__content"><span className="spinner"></span></div>
                    </div>
                </div>

            </div>
        </main>

        :

        <main>
            <div className={`dash-cards-2 header`}>
                <h2 className="dash-title">Financeiras</h2>
                <p>Home / Financeiras / Lista de Financeiras</p>
            </div>

            <div className={`dash-cards-2 body`}>
                <div className="card">
                    <div className="div-table">
                        <div className="table-body">
                            <DataTableExtensions {...tableData}>

                                <DataTable
                                    noDataComponent='Sem Registos'
                                    noHeader
                                    pagination
                                    exportHeaders
                                    paginationComponentOptions={defaultComponentOptions}
                                    paginationPerPage={25}
                                    paginationRowsPerPageOptions={[25, 50, 100]}
                                />
                            </DataTableExtensions>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}