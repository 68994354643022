import Hosts from '../../../Hosts';

//const object_type = 'Credits'

const api_crud_base = Hosts.SIMCore_API
const api_crud_route = `${Hosts.SIMCore_API}/credits/credits`
//const api_list_route = `${Hosts.SIMCore_API}/credits/credits/${getTokenInfo().companycode}`

const api_list_route = companycode => `${Hosts.SIMCore_API}/credits/credits/${companycode}`



const base_endpoint = '/credit'

const endpoints = {
    base: base_endpoint,
    regist: `${base_endpoint}_regist`,
    list: `${base_endpoint}_list`,
    view: `${base_endpoint}_view`,
}

const data_interface = {
    id: '',
    info: {
        companycode: '',
        tipo_credito: {
            id: '',
            name: ''
        },
        financials: {
            id: '',
            name: ''
        },
        Cliente: {
            id: '',
            vat: '',
            name: ''
        },
        Escritorio: {
            id: '',
            name: ''
        },
        Colaborador: {
            id: '',
            name: ''
        },
        Promotor: {
            id: '',
            name: ''
        },
        Master: {
            id: '',
            name: ''
        },

        workflow: [],

        history: [],

        observation: ''
    },
    commissioning: {
        valor_estimado: '',
        valor_financiado: '',
        valor_escritura: '',
        valor_comissao: '',
        commissoes: {
            Escritorio: {
                id: '',
                name: '',
                valor: '30',
                payment: {
                    data: "",
                    status: false
                },
            },
            Colaborador: {
                id: '',
                name: '',
                valor: '0',
                payment: {
                    data: "",
                    status: false
                },
            },
            Promotor: {
                id: '',
                name: '',
                valor: '50',
                payment: {
                    data: "",
                    status: false
                },
            },
            Master: {
                id: '',
                name: '',
                valor: '20',
                payment: {
                    data: "",
                    status: false
                },
            }
        }
    }
}

export {
    endpoints,
    api_crud_base,
    api_crud_route,
    data_interface,
    api_list_route
}