//import LocalStorage from 'localStorage'
import Hosts from '../../Hosts';


const object_type = 'financials'

const api_crud_base = Hosts.SIMCore_API
const api_crud_route = `${Hosts.SIMCore_API}/financials`
//const api_list_route = `${Hosts.SIMCore_API}/financials`
const api_list_route = companycode => `${Hosts.SIMCore_API}/financials/${companycode}`


const base_endpoint = '/financial'

const endpoints = {
    base: base_endpoint,
    regist: `${base_endpoint}_regist`,
    list: `${base_endpoint}_list`,
    view: `${base_endpoint}_view`,
}

//console.log()
const data_interface = {
    id: '',
    info: {
        companycode: '',
        username: '',
        password: '',
        name: '',
        rgpd: false,
        ativo: true,
        name_mini: '',
        vat: '',
        office: { id: '', name: '' },
        observation: ''
    },
    addresses: [{
        village: '',
        city: '',
        type: [],
        region: '',
        country: '',
        apartado: '',
        localidade: '',
        contats: [],
        principal: '',
        observation: '',
        postal_code: '',
        street_name: '',
        address_detail: '',
    }],
    contacts: [{
        email: '',
        phone: '',
        principal: 'true',
        extensions: [],
        observation: '',
        mobile_phone: '',
        building_number: '',
        person_responsables: [{
            name: '',
            email: '',
            extensions: '',
            observation: '',
            mobile_phone: ''
        }],
    }],
    credits: []
}

export {
    object_type,
    endpoints,
    api_crud_base,
    api_crud_route,
    data_interface,
    api_list_route
}


