var crypto = require('crypto');
const Hosts = require('../src/Hosts');

const requests = async (url, request_type, body_data) => {

    let localtoken = (JSON.parse(localStorage.getItem('token')))
    //console.log('TOKEN NO UTILS:', localtoken)
    const requestOptions = {
        method: request_type,

        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + (localtoken === null ? '' : localtoken.token)
        },
        body: JSON.stringify(body_data)

    }
    //console.log(localtoken)
    return await fetch(url, requestOptions).then(response => response.json())
    //.then(res => { //console.log(res); return res })
}

const reqGET = async (url) => {
    return await requests(url, 'GET')
}

const reqPOST = async (url, data) => {
    return await requests(url, 'POST', data)
}

const reqPUT = async (url, data) => {
    return await requests(url, 'PUT', data)
}

const reqDEL = async (url, data) => {
    return await requests(url, 'DELETE', data)
}

const load_countries_pt = async () => {
    return await reqGET(`${Hosts.SIMCore_API}/countries/langpt/list`)
}

const load_districts_pt = async () => {
    return await reqGET(`${Hosts.SIMCore_API}/countries/pt/districts`)
}

const load_cities_of_district_pt = async district => {
    return await reqGET(`${Hosts.SIMCore_API}/countries/pt/citiesofdistrict/${district}`)
}

const load_district_of_city_pt = async city => {
    return await reqGET(`${Hosts.SIMCore_API}/countries/pt/districtofcity/${city}`)
}

const load_freguesias_pt = async city => {
    return await reqGET(`${Hosts.SIMCore_API}/countries/pt/villagesofcity/${city}`)
}

const get_vat_info = async vat => {
    return await reqGET(`${Hosts.SIMCore_API}/vat/${vat}`)
}


const select_option_util_fun = (arg, set_fun, field) => {
    set_fun(...[arg.map(k => ({ value: k[field], label: k[field] }))])
}

const select_regist_util_fun = (arg, field) => {
    return arg[0][field]
}


const obter_cidades_por_distrito = (distrito, set_fun) => load_cities_of_district_pt(distrito).then(res => select_option_util_fun(res.data, set_fun, 'langpt_cc'))

const obter_distrito_por_cidade = (cidade) => load_district_of_city_pt(cidade).then(res => { return select_regist_util_fun(res.data, 'langpt_dt') })

const obter_freguesias_por_cidade = (cidade, set_fun) => load_freguesias_pt(cidade).then(res => select_option_util_fun(res.data, set_fun, 'langpt_fr'))

const obter_paises_em_pt = set_fun => load_countries_pt().then(res => select_option_util_fun(res.data, set_fun, 'langpt'))

const obter_distritos_pt = set_fun => load_districts_pt().then(res => select_option_util_fun(res.data, set_fun, 'langpt_dt'))


const passwordHash = password => crypto.createHash('md5').update(password).digest("hex")


const pattern_vat =
    '^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$'

const pattern_email = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';

module.exports = {
    reqGET,
    reqPOST,
    reqPUT,
    reqDEL,

    obter_cidades_por_distrito,
    obter_distrito_por_cidade,
    obter_freguesias_por_cidade,
    obter_paises_em_pt,
    obter_distritos_pt,



    get_vat_info,

    passwordHash,

    pattern_vat,
    pattern_email,

}