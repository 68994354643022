import React, { useEffect, useState } from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

import { reqGET } from '../../Utils';
import Hosts from '../../Hosts';
import { getTokenInfo } from '../../UseToken';

export default function Support() {

    const [data, setData] = useState([]);

    useEffect(() => {

        reqGET(`${Hosts.SIMCore_API}/web/content/type/${getTokenInfo().companycode}/support`)
            .then(res => {
                setData(res.data);
            })
            .catch(() => 404


            )
    }, []);

    return (
        <main>
            <div className={`dash-cards-2 header`}>
                <h2 className="dash-title">Suporte</h2>
                <p>Home / Suporte</p>
            </div>

            <div className={`dash-cards-2 body`}>
                <div className="card">
                    <br />
                    <Accordion allowZeroExpanded allowMultipleExpanded >
                        {
                        data.map((v, k) => {
                            return (
                                <AccordionItem key={k}>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            {v.content.question}
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>{v.content.answer}</p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            )
                        })
                        }
                    </Accordion>
                </div>
            </div>
        </main>
    )
}
