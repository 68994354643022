import React, { useState, useEffect } from 'react';
import { Redirect, Link } from 'react-router-dom';

import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

import DataTable from 'react-data-table-component';

import { getTokenInfo } from './../../../UseToken';
import { reqGET } from '../../../Utils';
import { api_crud_base, api_crud_route, data_interface, endpoints } from './Config';

export default function View(props) {

    const [dados, set_dados] = useState(data_interface);

    const [isloading, setLoading] = useState(true);
    const [isRedirect, setRedirect] = useState(false);

    const [hidePessoal, set_hide_pessoal] = useState('')
    const [hideEmpresarial, set_hide_empresarial] = useState('')

    const [data_creditos, set_credits] = useState([]);
    const columns_creditos = [
        {
            name: <div className="table-header">Proposta</div>,
            sortable: true,
            maxWidth: '100px',
            selector: 'proposta',
            left: true,
        },
        {
            name: <div className="table-header">Escritório</div>,
            selector: 'escritorio',
            sortable: true,
            left: true,
        },
        {
            name: <div className="table-header">Responsável</div>,
            selector: 'responsavel',
            sortable: true,
            left: true,
        },
        {
            name: <div className="table-header">Promotor</div>,
            selector: 'promotor',
            sortable: true,
            left: true,
        },
        {
            name: <div className="table-header">Tipo de Crédito</div>,
            selector: 'tipo_credito',
            sortable: true,
            left: true,
        },
        {
            name: <div className="table-header">Valor Financiado</div>,
            cell: row => <div>{row.valor_financiado}€</div>,
            maxWidth: '150px',
            sortable: true,
            left: true,
        },
        {
            name: <div className="table-header">Estado</div>,
            cell: row => 'Saber mais +',
            maxWidth: '150px',
            left: true,
        },
    ];

    //const ExpandableComponent = ({ data }) => <span>###{data.proposta}###</span>;
    const ExpandableComponent = ({ data }) =>
        <div className="div-expand">
            <div dangerouslySetInnerHTML={{ __html: data.timeline }} />
        </div>;

    const defaultComponentOptions = {
        rowsPerPageText: 'Registos por página:',
        rangeSeparatorText: 'de',
        noRowsPerPage: false,
        selectAllRowsItem: false,
        selectAllRowsItemText: 'Todos',
        responsive: true,
    };

    const load_credits = async (id) => {

        // credits/credits/special/vat/:companycode/:vat

        reqGET(`${api_crud_base}/credits/credits/special/vat/${getTokenInfo().companycode}/${id}`)
            .then(res => {
                //console.log(res.data)
                set_credits(...[
                    res.data.map(k => {
                        let aux_estado = '';
                        let aux_timeline = '<div><ul class="timeline">';
                        var i = 0;
                        if (k.info.estado)
                            k.info.estado.forEach((v_1) => {

                                i++;
                                var j = 0;
                                v_1.etapas.forEach((v_2) => {
                                    if (i === 5) {
                                        i = 1;
                                        aux_timeline = aux_timeline + '</ul><ul class="timeline">';
                                    }

                                    if (v_1.data.localeCompare("") !== 0) {
                                        if (v_2.activo) {
                                            if (v_2.value.localeCompare(v_2.sucesso.id) === 0) {
                                                aux_timeline = aux_timeline + '<li class="completed">'
                                                    + '<div class="timestamp">'
                                                    + '<span class="author"></span>'
                                                    + '<span class="date"></span>'
                                                    + '</div>'
                                                    + '<div class="status">'
                                                    + '<h4>' + v_1.label + '</h4>'
                                                    + '</div>'
                                                    + '<div class="on-course">'
                                                    + '<h4 class="on-course">em curso</h4>'
                                                    + '</div>'
                                                    + '</li>';
                                            }
                                            else if (v_2.value.localeCompare(v_2.insucesso.id) === 0) {
                                                aux_estado = 'Cancelado';
                                                aux_timeline = aux_timeline + '<li class="error">'
                                                    + '<div class="timestamp">'
                                                    + '<span class="author"></span>'
                                                    + '<span class="date"></span>'
                                                    + '</div>'
                                                    + '<div class="status">'
                                                    + '<h4>' + v_1.label + '</h4>'
                                                    + '</div>'
                                                    + '<div class="on-course">'
                                                    + '<h4 class="on-course">em curso</h4>'
                                                    + '</div>'
                                                    + '</li>';

                                            }
                                            else {
                                                aux_estado = v_1.label;
                                                aux_timeline = aux_timeline + '<li class="on-course">'
                                                    + '<div class="timestamp">'
                                                    + '<span class="author"></span>'
                                                    + '<span class="date"></span>'
                                                    + '</div>'
                                                    + '<div class="status">'
                                                    + '<h4>' + v_1.label + '</h4>'
                                                    + '</div>'
                                                    + '<div class="on-course">'
                                                    + '<h4 class="on-course">em curso</h4>'
                                                    + '</div>'
                                                    + '</li>';
                                            }
                                        }
                                    }
                                    else {
                                        if (j === 0) {
                                            j++;
                                            aux_timeline = aux_timeline + '<li class="">'
                                                + '<div class="timestamp">'
                                                + '<span class="author"></span>'
                                                + '<span class="date"></span>'
                                                + '</div>'
                                                + '<div class="status">'
                                                + '<h4>' + v_1.label + '</h4>'
                                                + '</div>'
                                                + '<div class="on-course">'
                                                + '<h4 class="on-course">em curso</h4>'
                                                + '</div>'
                                                + '</li>';
                                        }
                                    }

                                })

                            });
                        if (k.info.workflow)
                            k.info.workflow.forEach((v_1) => {
                                i++;
                                var j = 0;
                                if (v_1.estado)
                                    v_1.estado.forEach((v_2) => {
                                        if (i === 5) {
                                            i = 1;
                                            aux_timeline = aux_timeline + '</ul><ul class="timeline">';
                                        }

                                        if (v_1.data.localeCompare("") !== 0) {
                                            if (v_2.selected) {
                                                //console.log(v_2.selected)
                                                if (v_2.pendente === false && v_2.completo === true) {
                                                    aux_timeline = aux_timeline + '<li class="completed">'
                                                        + '<div class="timestamp">'
                                                        + '<span class="author"></span>'
                                                        + '<span class="date"></span>'
                                                        + '</div>'
                                                        + '<div class="status">'
                                                        + '<h4>' + v_1.name + '</h4>'
                                                        + '</div>'
                                                        + '<div class="on-course">'
                                                        + '<h4 class="on-course">em curso</h4>'
                                                        + '</div>'
                                                        + '</li>';
                                                }
                                                else if (v_2.selected === true && v_2.initial !== true && v_2.pendente !== true && v_2.completo === false) {
                                                    aux_estado = 'Cancelado';
                                                    aux_timeline = aux_timeline + '<li class="error">'
                                                        + '<div class="timestamp">'
                                                        + '<span class="author"></span>'
                                                        + '<span class="date"></span>'
                                                        + '</div>'
                                                        + '<div class="status">'
                                                        + '<h4>' + v_1.name + '</h4>'
                                                        + '</div>'
                                                        + '<div class="on-course">'
                                                        + '<h4 class="on-course">em curso</h4>'
                                                        + '</div>'
                                                        + '</li>';

                                                }
                                                else {
                                                    aux_estado = v_1.label;
                                                    aux_timeline = aux_timeline + '<li class="on-course">'
                                                        + '<div class="timestamp">'
                                                        + '<span class="author"></span>'
                                                        + '<span class="date"></span>'
                                                        + '</div>'
                                                        + '<div class="status">'
                                                        + '<h4>' + v_1.name + '</h4>'
                                                        + '</div>'
                                                        + '<div class="on-course">'
                                                        + '<h4 class="on-course">em curso</h4>'
                                                        + '</div>'
                                                        + '</li>';
                                                }
                                            }
                                        }
                                        else {
                                            if (j === 0) {
                                                j++;
                                                aux_timeline = aux_timeline + '<li class="">'
                                                    + '<div class="timestamp">'
                                                    + '<span class="author"></span>'
                                                    + '<span class="date"></span>'
                                                    + '</div>'
                                                    + '<div class="status">'
                                                    + '<h4>' + v_1.name + '</h4>'
                                                    + '</div>'
                                                    + '<div class="on-course">'
                                                    + '<h4 class="on-course">em curso</h4>'
                                                    + '</div>'
                                                    + '</li>';
                                            }
                                        }

                                    })

                            });
                        aux_timeline += '</ul></div>';

                        return {
                            proposta: k['id'],
                            escritorio: k.info.Escritorio['name'],
                            responsavel: k.info.Colaborador['name'],
                            promotor: k.info.Promotor['name'],
                            tipo_credito: k.info.tipo_credito['name'],
                            valor_financiado: k.commissioning['valor_financiado'],
                            estado: aux_estado,
                            timeline: aux_timeline
                        }

                    })
                ])
                //console.log(credits)
            })
    }

    const change_accordion = e => {
        if (e.includes('0'))
            load_credits(dados.info.vat);
        //console.log(e);
    }

    // Para Instanciar todo o Objecto 
    const fetchInfo = async (id) => {
        await reqGET(`${api_crud_route}/${id}`)
            .then(res => {
                set_dados(res.data[0]);

                if (res.data[0].info.tipo_cliente === 'Empresarial') {
                    set_hide_pessoal('d-none');
                    set_hide_empresarial('');

                }
                else // SE EMPRESARIAL 
                {
                    set_hide_pessoal('');
                    set_hide_empresarial('d-none');

                }
            })
            .catch(() => 404
                //  TODO: ENVIAR PÝGINA 404
                //  alert(error)); 
            );
    }

    useEffect(() => {
        if (props.location.state !== undefined) {
            fetchInfo(props.location.state.item['id'])
                .then(() => {
                    setLoading(false)
                })
        }
        else {
            setLoading(false);
            setRedirect(true);
        }
    }, [props]);

    return isRedirect ?
        <Redirect to={endpoints.list} replace />
        :
        isloading ?
            <main>
                <div className={`dash-cards-2 header`}>
                    <h2 className="dash-title">LOADING</h2>
                    <p>LOADING</p>
                    <div className="overlay">
                        <div className="overlay__inner">
                            <div className="overlay__content"><span className="spinner"></span></div>
                        </div>
                    </div>
                </div>
            </main>
            :
            <main>
                <div className={`dash-cards-2 header`}>
                    <h2 className="dash-title">Clientes</h2>
                    <p>Home / Clientes / {dados.info.name}</p>
                </div>

                <div className={`dash-cards-2 body`}>
                    <div className="card">
                        <div className="card-body">
                            <h2 className="dash-title">{dados.info.name}</h2>
                            <div className="div-break" />
                            <div className="div-group">
                                <span className="flex-1">
                                    <h4>NIF</h4>
                                    <p>{dados.info.vat}</p>
                                </span>
                                <span className="flex-1">
                                    <h4>Tipo de Cliente</h4>
                                    <p>{dados.info.tipo_cliente}</p>
                                </span>
                                <span className="flex-1">

                                </span>
                            </div>
                            <div className="div-break"></div>
                            <div className={hidePessoal !== '' ? hidePessoal : 'div-group'}>
                                <span className="flex-1">
                                    <h4>Tipo de Identificação</h4>
                                    <p>{dados.info.identificacao.tipo_identificacao}</p>
                                </span>
                                <span className="flex-1">
                                    <h4>Número de Identificação</h4>
                                    <p>{dados.info.identificacao.numero_identificacao}</p>
                                </span>
                                <span className="flex-1">
                                    <h4>Validade</h4>
                                    <p>{dados.info.identificacao.validade_identificacao}</p>
                                </span>
                            </div>
                            <div className="div-break"></div>
                            <div className={hidePessoal !== '' ? hidePessoal : 'div-group'}>
                                <span className="flex-1">
                                    <h4>Data de Nascimento</h4>
                                    <p>{dados.info.data_nascimento}</p>
                                </span>
                                <span className="flex-1">
                                    <h4>Estado Civil</h4>
                                    <p>{dados.info.estado_civil}</p>
                                </span>
                                <span className="flex-1">

                                </span>
                            </div>
                            <div className="div-break"></div>
                            <div className="div-group">
                                <span className="flex-1">
                                    <h4>Telefone</h4>
                                    <p>{dados.contacts[0].phone}</p>
                                </span>
                                <span className="flex-1">
                                    <h4>Telemóvel</h4>
                                    <p>{dados.contacts[0].mobile_phone}</p>
                                </span>
                                <span className="flex-1">
                                    <h4>E-mail</h4>
                                    <p>{dados.contacts[0].email}</p>
                                </span>
                            </div>
                            <div className="div-break"></div>
                            <div className="div-group">
                                <span className="flex-1">
                                    <h4>País</h4>
                                    <p>{dados.addresses[0].country}</p>
                                </span>
                                <span className="flex-1">
                                    <h4>Distrito</h4>
                                    <p>{dados.addresses[0].region}</p>
                                </span>
                                <span className="flex-1">
                                    <h4>Concelho</h4>
                                    <p>{dados.addresses[0].city}</p>
                                </span>
                            </div>
                            <div className="div-break"></div>
                            <div className="div-group">
                                <span className="flex-1">
                                    <h4>Freguesia</h4>
                                    <p>{dados.addresses[0].village}</p>
                                </span>
                                <span className="flex-1">
                                    <h4>Código-Postal</h4>
                                    <p>{dados.addresses[0].postal_code}</p>
                                </span>
                                <span className="flex-1">

                                </span>
                            </div>
                            <div className="div-break"></div>
                            <div className="div-group">
                                <span className="flex-2">
                                    <h4>Morada</h4>
                                    <p>{dados.addresses[0].address_detail}</p>
                                </span>
                                <span className="flex-1" />
                            </div>
                            <div className="div-break"></div>
                            <h2 className={hidePessoal !== '' ? hidePessoal : 'dash-title'}>Dados Emprego</h2>
                            <div className="div-break"></div>
                            <div className={hidePessoal !== '' ? hidePessoal : 'div-group'}>
                                <span className="flex-1">
                                    <h4>Situação</h4>
                                    <p>{dados.info.dados_emprego.situacao_emprego}</p>
                                </span>
                                <span className="flex-1">
                                    <h4>Profissão</h4>
                                    <p>{dados.info.dados_emprego.profissao}</p>
                                </span>
                                <span className="flex-1">
                                    <h4>Entidade Patronal</h4>
                                    <p>{dados.info.dados_emprego.entidade_patronal}</p>
                                </span>
                            </div>
                            <div className={hidePessoal !== '' ? hidePessoal : 'div-group'}>
                                <span className="flex-1">
                                    <h4>Vencimento Mensal</h4>
                                    <p>{dados.info.dados_emprego.vencimento_mensal}</p>
                                </span>
                                <span className="flex-1">
                                    <h4>Outros Rendimentos</h4>
                                    <p>{dados.info.dados_emprego.outros_rendimentos}</p>
                                </span>
                                <span className="flex-1">

                                </span>
                            </div>

                            <div className="div-break"></div>
                            <h2 className={hideEmpresarial !== '' ? hideEmpresarial : 'dash-title'}>Dados Intermediário</h2>
                            <div className="div-break"></div>
                            <div className={hideEmpresarial !== '' ? hideEmpresarial : 'div-group'}>
                                <span className="flex-1">
                                    <h4>Nome</h4>
                                    <p>{dados.contacts[0].person_responsables[0].name}</p>
                                </span>
                                <span className="flex-1">
                                    <h4>Contacto</h4>
                                    <p>{dados.contacts[0].person_responsables[0].mobile_phone}</p>
                                </span>
                                <span className="flex-1">
                                    <h4>Email</h4>
                                    <p>{dados.contacts[0].person_responsables[0].email}</p>
                                </span>
                            </div>
                            <div className="div-break"></div>
                            <div className="div-group">
                                <span className="flex-1">
                                    <h4>Autorização RGPD</h4>
                                    <p>{dados.info.rgpd === true ? 'Sim, o Cliente autorizou a recolha de dados pessoais' : 'Não, o Cliente não autorizou a recolha de dados pessoais'}</p>
                                </span>
                                <span className="flex-1">
                                    <h4>Cliente activo</h4>
                                    <p>{dados.info.ativo === true ? 'Sim' : 'Não'}</p>
                                </span>
                            </div>

                            <div className="div-break"></div>
                            <div className="div-group">
                                <Accordion allowZeroExpanded allowMultipleExpanded onChange={change_accordion} >
                                    <AccordionItem uuid='0'>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Créditos Associados
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <div className="div-table expand flex-1">
                                                <div className="table-body">
                                                    <DataTable
                                                        noDataComponent='Sem Registos'
                                                        columns={columns_creditos}
                                                        data={data_creditos}
                                                        noHeader
                                                        subHeader
                                                        pagination
                                                        paginationComponentOptions={defaultComponentOptions}
                                                        paginationPerPage={25}
                                                        paginationRowsPerPageOptions={[25, 50, 100]}
                                                        expandableRows
                                                        expandOnRowClicked
                                                        expandableRowsComponent={< ExpandableComponent />}
                                                    />
                                                </div>
                                            </div>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'dash-cards-2 footer'}>
                    <nav className="navbar bottom-navbar navbar-light">
                        <div className="navbar-footer">
                            <ul className="navbar-nav float-left"></ul>
                            <ul className="navbar-nav float-right">
                                <li className="nav-item">
                                    <Link className="nav-link btn-cancel" to={{ pathname: endpoints.list }} replace>
                                        <img alt='' src={'./img/seta_2.svg'} />
                                        <span>Voltar</span>
                                    </Link>
                                </li>

                            </ul>
                        </div>
                    </nav>
                </div>
            </main>
}