import React from 'react'

export default function Corporate() {

    return (
        <main>
            <div className={`dash-cards-2 header`}>
                <h2 className="dash-title">Corporate</h2>
                <p>Home / Corporate</p>
            </div>

            <div className={`dash-cards-2 body`}>
                <div className="card">
                    <h2 className="dash-title">A nossa história</h2>
                    <br />
                    <p>
                        A Fasquia Importante tem o objetivo de prestar o melhor serviço aos nossos
                        clientes na área da mediação de seguros e de intermediação de crédito.
                        Com protocolo com a maioria dos bancos e companhias de seguros a operar em
                        Portugal, temos ajudado vários particulares e empresas a melhorar as condições
                        dos seus créditos e seguros por todo o país. A nossa equipa de colaboradores é
                        composta por profissionais experientes, com formação certificada e atualizada, e
                        stão motivados para o ajudar, com rigor, profissionalismo, transparência,
                        competência e simpatia.
                    </p>
                    <br />
                    <p>
                        Temos protocolos com várias agências imobiliárias, agentes imobiliários, empresas
                        de construção civil e stands automóvel, o que é demonstrativo da qualidade dos
                        nossos serviços e da confiança que os nossos parceiros depositam em nós.
                    </p>
                    <br />
                    <p>
                        A Fasquia Importante é uma empresa jovem, em crescimento e que está preparada para enfrentar o
                        futuro, através do seu crescimento orgânico ao nível de escritórios e protocolos com profissionais
                        da área que nos tem permitido ter abrangência total em Portugal e levarmos os nossos serviços
                        até si.
                    </p>
                </div>
            </div>
        </main>
    )
}
